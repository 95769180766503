import { VALIDATE_TOKEN, CHANGE_STATE } from '../actions/auth';

const INITIAL_STATE = {
  validate: false,
  isFetching: true,
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${VALIDATE_TOKEN}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      validate: false,
    };
  case `${VALIDATE_TOKEN}_PENDING`:
    return {
      ...state,
      isFetching: true,
    };
  case `${VALIDATE_TOKEN}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      validate: true,
    };

  case `${CHANGE_STATE}`:
    return {
      ...state,
      isFetching: action.payload.state,
      validate: true,
    };

  default:
    return state;
  }
};

export default auth;
