import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Form, Layout, Button, Spin, message
} from 'antd';

import { getDownloadUrl } from '../../../service/firebaseStorageService';

import { createMicrosite, getMicrositeById, editMicrosite } from '../../../actions/microsite';
import { showProjects } from '../../../actions/projects';

import messages from './commons/culture';
import SelectsProjects from './commons/selectsProjects';

import BreadcrumbComponent from '../../commons/breadcrumb';
import FormMicro from './commons/formMicrosite';

const { Content } = Layout;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const ContainerLoading = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  background-color: #FFF;
  padding: 24px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  border-bottom: 0.5px solid #d1d1d1;
  margin-bottom: 25px;
  margin-top: ${props => props.first ? 0 : '40px'};
  font-family: 'Roboto';
  color: #808080;
  font-weight: 500;
  font-size: 1.5em;
`;

const FormProject = ({
  form, intl, match, history
}) => {
  const [targetKeys, setTargetKeys] = useState([]);
  const [logoImageUrl, setLogoImageUrl] = useState(null);
  const [bankCount, setbankCount] = useState(false);

  const onChange = nextTargetKeys => setTargetKeys(nextTargetKeys);

  const dispatch = useDispatch();
  const microsite = useSelector(state => state.microsite);
  const projects = useSelector(state => state.projects);

  useEffect(() => {
    dispatch(showProjects())
      .then((respProjects) => {
        if (match.params.id) {
          dispatch(getMicrositeById(match.params.id))
            .then((respMicrosite) => {
              const projectsMicrosite = [];
              getDownloadUrl(respMicrosite.value.microsite.logoImage.key).then(url => setLogoImageUrl(url));

              respProjects.value.list.forEach((project, index) => respMicrosite.value.microsite.projectList.forEach((item) => {
                if (project.id === item.id) projectsMicrosite.push(index);
              }));

              setTargetKeys(projectsMicrosite);
            });
        }
      });
  }, []);

  const formItemLayout = { labelCol: { lg: { span: 6 } }, wrapperCol: { lg: { span: 14 } } };
  const isEdit = !!match.params.id || false;

  const titlesBreadcrumb = [
    intl.formatMessage(messages.start),
    intl.formatMessage(messages.microsTitle),
    intl.formatMessage(messages[match.params.id ? 'editMicro' : 'createMicroButton'])
  ];

  const handleFeaturedChange = (value) => {
    setbankCount(value.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (targetKeys.length === 0) message.error(intl.formatMessage(messages.errorSelect));
      else if (!err) {
        const model = {
          ...values,
          projectList: targetKeys.map(key => projects.list[key]),
          logoImage: {
            name: values.logoImage.fileList[0].name,
            key: values.logoImage.fileList[0].response,
          },
        };

        if (match.params.id) {
          return dispatch(editMicrosite(model, match.params.id))
            .then(() => history.push('/admin/microsite'))
            .catch((error) => {
              const { data } = error.response;

              if (data && data.code === 'accessLink_exists') return message.error(intl.formatMessage(messages.linkExist));
            });
        }
        dispatch(createMicrosite(model))
          .then(() => history.push('/admin/microsite'))
          .catch((error) => {
            const { data } = error.response;

            if (data && data.code === 'accessLink_exists') return message.error(intl.formatMessage(messages.linkExist));
          });
      }
    });
  };

  let logoImageEdit = false;
  if (match.params.id && logoImageUrl) {
    if (logoImageUrl) {
      logoImageEdit = [{
        name: microsite.micrositeByID.logoImage.name,
        status: 'done',
        url: logoImageUrl,
        response: logoImageUrl,
        uid: 1
      }];
    }
  }

  if (microsite.isFetching || projects.isFetching) return (<ContainerLoading><Spin size="large" /></ContainerLoading>);

  return (
    <Content style={{ margin: '0 16px' }}>
      <BreadcrumbComponent
        titles={titlesBreadcrumb}
      />
      <Container>
        <Form {...formItemLayout} onSubmit={handleSubmit}>

          <Title first>Datos básicos</Title>
          <FormMicro
            form={form}
            intl={intl}
            messages={messages}
            isEdit={isEdit}
            micrositeByID={match.params.id ? (microsite.micrositeByID || {}) : {}}
            logoImageEdit={logoImageEdit}
            handleFeaturedChange={handleFeaturedChange}
            bankCount={bankCount}
          />

          <Title>Selección de proyectos publicados</Title>
          <SelectsProjects
            projects={projects.list.filter(project => project.status === 'published')}
            onChange={onChange}
            targetKeys={targetKeys}
            intl={intl}
            messages={messages}
          />

          <ButtonContainer>
            <Button
              style={{ marginRight: 15 }}
              size="large"
            ><Link to="/admin/microsite/">{intl.formatMessage(messages.cancel)}</Link>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={microsite.isFetchingCreate}
            >{intl.formatMessage(messages[match.params.id ? 'editMicro' : 'createMicroButton'])}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    </Content>
  );
};

const WrappedProjectForm = Form.create({ name: 'formProject' })(FormProject);

export default injectIntl(WrappedProjectForm, { withRef: false });
