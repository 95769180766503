import React from 'react';
import { connect } from 'react-redux';
import ReduxHOC from './redux';
import CultureHOC from './culture/culture';
import Layout from './routes/index';

class App extends React.Component {
  render() {
    return (
      <Layout />
    );
  }
}

const mapStateToProps = state => ({
  culture: state.culture.culture,
});

const culturalizedApp = CultureHOC(App);
const connectedApp = connect(mapStateToProps)(culturalizedApp);

const reduxApp = ReduxHOC(connectedApp);

export default reduxApp;
