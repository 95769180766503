import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Layout, Menu, Icon } from 'antd';

import { changeCulture } from '../../../../actions/culture';
import messages from './menuBarCulture';

import './menuBar.css';

const { Sider } = Layout;

// const SubMenus = Menu.SubMenu;

class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false };
  }

  // changeCulture = (culture) => {
  //   this.props.changeCulture(culture);
  //   setTimeout(() => { window.location.reload(); }, 5);
  // }

  handleCollapse = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  }

  render() {
    const { location, intl } = this.props;
    const { collapsed } = this.state;
    let defaultSelect = '2';

    if (location.pathname.includes('/admin/projects')) defaultSelect = '2';
    else if (location.pathname.includes('/admin/microsite')) defaultSelect = '3';
    else if (location.pathname.includes('/admin/users')) defaultSelect = '4';
    else if (location.pathname.includes('/admin/transactions')) defaultSelect = '5';
    else if (location.pathname.includes('/admin/releases')) defaultSelect = '6';
    else if (location.pathname.includes('/admin/countries')) defaultSelect = '7';

    return (
      <Sider
        breakpoint="md"
        collapsible
        collapsed={collapsed}
        onCollapse={this.handleCollapse}
      >
        <Menu theme="dark" defaultSelectedKeys={[defaultSelect]} mode="inline">
          <Menu.Item key="1">
            <Link to="/">
              <Icon type="home" />
              <span>{intl.formatMessage(messages.goToPropiInvest)}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/admin/projects">
              <Icon type="project" />
              <span>{intl.formatMessage(messages.projects)}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/admin/microsite">
              <Icon type="project" />
              <span>{intl.formatMessage(messages.micro)}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/admin/users">
              <Icon type="user" />
              <span>{intl.formatMessage(messages.users)}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to="/admin/transactions">
              <Icon type="fund" />
              <span>{intl.formatMessage(messages.transactions)}</span>
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="6">
            <Link to="/admin/returns">
              <Icon type="rollback" />
              <span>{intl.formatMessage(messages.returnFund)}</span>
            </Link>
          </Menu.Item> */}
          <Menu.Item key="6">
            <Link to="/admin/releases">
              <Icon type="bank" />
              <span>{intl.formatMessage(messages.fundRelease)}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/admin/countries">
              <Icon type="global" />
              <span>{intl.formatMessage(messages.countries)}</span>
            </Link>
          </Menu.Item>

          {/* <SubMenus key="sub1" title={<span><Icon type="flag" /><span>{intl.formatMessage(messages.language)}</span></span>}>
            <Menu.Item key="8">
              <div onClick={() => this.changeCulture('en-US')}>
                {intl.formatMessage(messages.english)}
              </div>
            </Menu.Item>
            <Menu.Item key="9`">
              <div onClick={() => this.changeCulture('es-AR')}>
                {intl.formatMessage(messages.spanish)}
              </div>
            </Menu.Item>
          </SubMenus> */}
        </Menu>
      </Sider>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changeCulture,
}, dispatch);

const connectedMenuBar = connect(null, mapDispatchToProps)(injectIntl(MenuBar));

export default withRouter(connectedMenuBar);
