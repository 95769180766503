import React, { Component } from 'react';

class PageTitle extends Component {

  componentDidMount() {
    document.title = this.props.title;
  }

  render() {
    const PageComponent = this.props.component;

    return (
      <PageComponent />
    );
  }
}

export default PageTitle;
