import React from 'react';
import { Breadcrumb } from 'antd';

const breadcrumb = ({ titles }) => (
  <Breadcrumb style={{ margin: '16px 0' }}>
    {titles.map(title => (<Breadcrumb.Item key={title}>{title}</Breadcrumb.Item>))}
  </Breadcrumb>
);

export default breadcrumb;
