import React from 'react';
import {
  Table, Button, Icon
} from 'antd';

const DataTable = ({
  data,
  loading,
  intl,
  messages,
  editAction
}) => {
  const columns = [{
    title: intl.formatMessage(messages.idTitle),
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.countryTable),
    dataIndex: 'description',
    key: 'description',
    sorter: (a, b) => a.description.localeCompare(b.description),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.currencyTable),
    dataIndex: 'currency',
    key: 'currency',
    sorter: (a, b) => a.currency.localeCompare(b.currency),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.MPAccessTokenTable),
    dataIndex: 'MPAccessToken',
    key: 'MPAccessToken',
    sorter: (a, b) => (a.MPAccessToken - b.MPAccessToken),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.codeTable),
    dataIndex: 'code',
    key: 'code',
    sorter: (a, b) => a.code.localeCompare(b.code),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.actionTitle),
    key: 'action',
    dataIndex: '_id',
    // eslint-disable-next-line react/display-name
    render: (_id, record) => (
      <span>
        <Button
          onClick={() => editAction(record.id)}
          className="buttonActions"
          title="editar"
        ><Icon type="edit" />
        </Button>
      </span>
    )
  }];
  return (
    <Table dataSource={data} columns={columns} rowKey="_id" loading={loading} />
  );
};

export default DataTable;
