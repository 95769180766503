import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import config from '../../../config';

import {
  Layout, Breadcrumb, Row, Col, Modal, Button, Table, Tag
} from 'antd';
import moment from 'moment';

import { getTransactions } from '../../../actions/transactions';
import DataTable from './table';

import messages from './culture';

import '../../commons/css/users.css';

const tags = {
  new: 'cyan',
  error: 'red',
  pending: 'orange',
  expired: 'red',
  returned: 'green',
  released: 'green',
  rejectedRelease: 'magenta',
  returnedRelease: 'green',
  returnedWithErrors: 'red',
  returnedReject: 'red',
  accredited: 'green',
  onBindCall: 'red',
  rejected: 'magenta',
};

class Users extends Component {
  state = { visible: false, select: [] }

  componentDidMount() {
    this.props.get();
  }

  showModal = (select) => {
    this.setState({ visible: true, select });
  };

  editTransaction = (transaction) => {
    const { history } = this.props;
    history.push({ pathname: `/admin/transactions/edit/${transaction.id}` });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleExport = () => {
    window.open(config.API_URL+''+config.API_VERSION+'/transactions/xls-transactions?token='+this.props.token,'_blank');
  }

  render() {
    
    const { transactions, intl } = this.props;

    const columns = [{
      title: intl.formatMessage(messages.createdAt),
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ['descend', 'ascend'],
      // eslint-disable-next-line react/display-name
      render: date => (
        <span>{moment(date).format('DD/MM/YYYY hh:mm:ss')}</span>
      )
    }, {
      title: intl.formatMessage(messages.investorUserTitle),
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ['descend', 'ascend'],
    }, {
      title: intl.formatMessage(messages.statusTitle),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      // eslint-disable-next-line react/display-name
      render: status => (<Tag color={tags[status]}>{intl.formatMessage(messages[status])}</Tag>)
    }];

    return (
      <Layout style={{ margin: '0 16px' }}>
        <Row>
          <Col span={21}>
            <Breadcrumb>
              <Breadcrumb.Item>{intl.formatMessage(messages.start)}</Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(messages.transactions)}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={this.handleExport} className="buttonUsers">Exportar</Button>
          </Col>
        </Row>
        <div className="content">
          <DataTable
            data={transactions.list}
            loading={transactions.isFetching}
            intl={intl}
            messages={messages}
            showModal={this.showModal}
            editAction={this.editTransaction}
          />
        </div>
        <Modal
          title={intl.formatMessage(messages.history)}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          style={{ display: 'flex', flex: 1, minWidth: 520 }}
          footer={[
            <div key="submit" style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <Button key="submit" type="primary" onClick={this.handleCancel}>
                {intl.formatMessage(messages.close)}
              </Button>
            </div>
          ]}
        >
          <div style={{ minWidth: 520 }}>
            <Table dataSource={this.state.select} columns={columns} rowKey="_id" pagination={false} />
          </div>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ transactions: state.transactions, token: state.user.token });

const mapDispatchToProps = dispatch => bindActionCreators({ get: getTransactions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Users));
