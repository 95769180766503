import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import uuidv4 from 'uuid/v4';
import config from '../config/index';

const settings = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  appId: config.FIREBASE_APP_ID
};

firebase.initializeApp(settings);

firebase.auth().signInAnonymously();

export const uploadImage = data => new Promise((resolve, reject) => {
  const storageRef = firebase.storage().ref();
  const file = storageRef.child(`${uuidv4()}.jpg`);
  file
    .put(data)
    .then((snapshot) => {
      resolve(snapshot);
    })
    .catch(error => reject(error));
});

export const getDownloadUrl = fileName => new Promise((resolve, reject) => {
  const storageRef = firebase.storage().ref();
  const file = storageRef.child(fileName);
  file
    .getDownloadURL()
    .then(url => resolve(url))
    .catch(error => reject(error));
});
