// import React from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import { EditorState, ContentState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// // import { convertFromHTML } from 'draft-convert';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// import htmlToDraft from 'html-to-draftjs';

// import {
//   updateEditorState,
//   cleanEditorState
// } from '../../../actions/textEditor';
// import '../css/textEditor.css';

// class TextEditor extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { editorState: EditorState.createEmpty() };
//   }

//   componentDidUpdate(prevProps) {
//     if (
//       !prevProps.initialValue
//       && prevProps.initialValue !== this.props.initialValue
//     ) {
//       const blocksFromHtml = htmlToDraft(this.props.initialValue);
//       const { contentBlocks, entityMap } = blocksFromHtml;
//       const contentState = ContentState.createFromBlockArray(
//         contentBlocks,
//         entityMap
//       );
//       this.setState({
//         editorState: EditorState.createWithContent(contentState)
//       });
//     }
//   }

//   componentWillUnmount = () => {
//     this.props.cleanState();
//   };

//   focusEditor = () => {
//     if (this.editor) {
//       this.editor.focus();
//     }
//   };

//   onEditorStateChange = (editorState) => {
//     const htmlContent = draftToHtml(
//       convertToRaw(editorState.getCurrentContent())
//     );
//     this.props.onChange(htmlContent);
//     this.setState({ editorState });
//   };

//   render() {
//     return (
//       <Editor
//         toolbar={{
//           options: ['inline', 'blockType', 'list', 'textAlign']
//         }}
//         editorState={this.state.editorState}
//         wrapperClassName="text-editor-wrapper"
//         toolbarClassName="text-editor-toolbar"
//         onEditorStateChange={this.onEditorStateChange}
//       />
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     editorState: state.textEditor
//   };
// }

// const mapDispatchToProps = dispatch => bindActionCreators(
//   {
//     updateState: updateEditorState,
//     cleanState: cleanEditorState
//   },
//   dispatch
// );

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TextEditor);
