import axios from 'axios';

export const CREATE_INVEST = 'CREATE_INVEST';

export const createInvest = model => ({
  type: CREATE_INVEST,
  payload: axios
    .post('/invest/', model)
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});
