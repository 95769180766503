import axios from 'axios';

export const SHOW_USERS = 'SHOW_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_STATE = 'UPDATE_STATE';
export const GET_USER_BY_ROL = 'GET_USER_BY_ROL';
export const GET_USER = 'GET_USER';
export const UPDATE_USER_EDIT = 'UPDATE_USER_EDIT';
export const DELETE_ACCOUNT_BANK = 'DELETE_ACCOUNT_BANK';

export const showUsers = () => ({
  type: SHOW_USERS,
  payload: axios.get('/users/')
    .then(response => ({ list: response.data }))
    .catch(error => Promise.reject(error))
});

export const getUsersByRol = rol => ({
  type: GET_USER_BY_ROL,
  payload: axios.get(`/users/rol/${rol}`)
    .then(response => ({ usersByRol: response.data }))
    .catch(error => Promise.reject(error))
});

export const updateUser = model => ({
  type: UPDATE_USER,
  payload: axios.post('/users/validate', model)
    .then(() => {
      // eslint-disable-next-line no-param-reassign
      model.user.state = model.status;
      return ({ user: model.user });
    }).catch(error => Promise.reject(error))
});

export const deleteAccountBank = accountBankIndex => ({
  type: DELETE_ACCOUNT_BANK,
  payload: accountBankIndex
});

export const getUser = username => ({
  type: GET_USER,
  payload: axios.get(`/users/${username}`)
    .then(response => ({ user: response.data }))
    .catch(error => Promise.reject(error))
});

export const updateUserEdit = model => ({
  type: UPDATE_USER_EDIT,
  payload: axios.put('/users/', model)
    .then(json => ({ user: json.data }))
});
