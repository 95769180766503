import React from 'react';
import {
  Table, Tag, Button, Icon
} from 'antd';

const tags = {
  new: 'cyan',
  moderate: 'orange',
  accepted: 'green',
  rejected: 'magenta'
};

const DataTable = ({
  data,
  loading,
  acceptUserAction,
  rejectUserAction,
  editAction,
  intl,
  messages
}) => {
  const columns = [
    {
      title: intl.formatMessage(messages.idTitle),
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: intl.formatMessage(messages.nameTitle),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: intl.formatMessage(messages.lastNameTitle),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: intl.formatMessage(messages.usernameTitle),
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: intl.formatMessage(messages.stateTitle),
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => a.state.localeCompare(b.state),
      sortDirections: ['descend', 'ascend'],
      // eslint-disable-next-line react/display-name
      render: state => (
        <Tag color={tags[state]}>{intl.formatMessage(messages[state])}</Tag>
      )
    },
    {
      title: intl.formatMessage(messages.rolesTitle),
      dataIndex: 'roles',
      key: 'roles',
      sorter: (a, b) => {
        const aCompare = a.roles.split(' ');
        const bCompare = b.roles.split(' ');
        return aCompare[0].localeCompare(bCompare[0]);
      },
      sortDirections: ['descend', 'ascend'],
      // eslint-disable-next-line react/display-name
      render: text => intl.formatMessage(messages[text])
    },
    {
      title: intl.formatMessage(messages.actionTitle),
      key: 'action',
      dataIndex: '_id',
      // eslint-disable-next-line react/display-name
      render: (_id, record) => (
        <span>
          <Button
            onClick={() => editAction(_id, record)}
            className="buttonActions"
            title={intl.formatMessage(messages.viewEditButton)}
          >
            <Icon type="search" />
          </Button>
        </span>
      )

    }
  ];

  return (
    <Table dataSource={data} columns={columns} rowKey="_id" loading={loading} />
  );
};
export default DataTable;
