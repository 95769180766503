import {
  GET_RETURN_FUND,
  // APROVE_RETURN,
  // REJECT_RETURN,
  CHANGE_RETURN_STATUS
} from '../actions/returnFund';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingProject: false,
  didInvalidate: false,
  list: [],
};

const returnFund = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case `${GET_RETURN_FUND}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      list: []
    };
  case `${GET_RETURN_FUND}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      list: []
    };
  case `${GET_RETURN_FUND}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      list: action.payload.data
    };

  case `${CHANGE_RETURN_STATUS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
    };
  case `${CHANGE_RETURN_STATUS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${CHANGE_RETURN_STATUS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      list: [
        ...state.list.slice(0, action.payload.index),
        action.payload.data,
        ...state.list.slice(action.payload.index + 1),
      ]
    };

    // case `${APROVE_RETURN}_REJECTED`:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     didInvalidate: true,
    //   };
    // case `${APROVE_RETURN}_PENDING`:
    //   return {
    //     ...state,
    //     isFetching: true,
    //     didInvalidate: false,
    //   };
    // case `${APROVE_RETURN}_FULFILLED`:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     didInvalidate: false,
    //     list: [
    //       ...state.list.slice(0, action.payload.index),
    //       action.payload.data,
    //       ...state.list.slice(action.payload.index + 1),
    //     ]
    //   };

    // case `${REJECT_RETURN}_REJECTED`:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     didInvalidate: true,
    //   };
    // case `${REJECT_RETURN}_PENDING`:
    //   return {
    //     ...state,
    //     isFetching: true,
    //     didInvalidate: false,
    //   };
    // case `${REJECT_RETURN}_FULFILLED`:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     didInvalidate: false,
    //     list: [
    //       ...state.list.slice(0, action.payload.index),
    //       action.payload.data,
    //       ...state.list.slice(action.payload.index + 1),
    //     ]
    //   };

  default:
    return state;
  }
};

export default returnFund;
