import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import {
  Layout,
  Row,
  Col,
  Form,
  Button,
  Alert,
  message
} from 'antd';

import {
  FormItemInput,
  FormItemSelect,
  FormItemInputNumber,
} from '../../commons/formComponents/formComponents';
import {
  verifyNumber,
  validateCUIT,
  validateCBU,
  validateCBUAlias
} from '../../commons/validations';

import { updateInvestor } from '../../../actions/registration';

import messages from './culture';

import './registration.css';

const { Content } = Layout;

const culture = {
  duplicatedCBU: 'El cbu ingresado ya existe',
  duplicatedCBUAlias: 'El alias de cbu ingresado ya existe',
  emailExists: 'Email ya registrado',
  mailValidate: 'El mail ingresado no es válido!',
  invalidFormat: 'El formato ingresado no es válido.',
  invalidCUIT: 'El CUIT es inválido',
};

const RegisterContainer = styled.div`
  & {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 5rem 10%;
  }

  & h1 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0;
  }

  & h4.form-title {
    opacity: 0.55;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
  }

  & input, & .ant-select, & .ant-select-selection, & .ant-select-selection__rendered {
    height: 45px;
  }

  & .ant-select-selection-selected-value {
    display: flex !important;
    align-items: center;
    height: 45px;
  }

  & .ant-form-item {
    margin-bottom: 1rem;
  }
`;

const RegisterButton = styled.div`
  & button {
    border-radius: 5px;
    height: 45px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
    border-color: transparent;
    margin-top: ${props => props.secondary ? '1.5rem' : '2rem'};
  }

  & button:hover {
    border-color: transparent;
    color: #fff;
  }

  & button:active {
    border-color: transparent;
    color: #fff;
  }

  & button:focus {
    border-color: transparent;
    color: #fff;
  }
`;

class DataForm extends Component {
  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'auto'
    });
  }

  componentDidUpdate() {
    const { form } = this.props;

    if (form.getFieldError('cbu') && form.getFieldError('cbuAlias')) {
      if (form.getFieldValue('cbu')) form.setFields({ cbuAlias: { errors: null } });
      else if (form.getFieldValue('cbuAlias')) form.setFields({ cbu: { errors: null } });
    }
  }

  handleSubmit = (e) => {
    const { form, update, username } = this.props;

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {

        const model = {
          username,
          mpUserName: values.mpUserName,
          bankInformation: [{
            cbu: values.cbu,
            cbuAlias: values.cbuAlias,
            cuit: values.cuit,
            businessName: values.businessName,
            accountType: values.accountType,
            numAccount: values.numAccount,
          }],
        };

        if (values.payPalUser) model.payPalUser = values.payPalUser;

        update(model)
          .catch((error) => {
            if (error.response.status === 422) {
              if (error.response.data.errorField) {
                const fieldError = {};
                fieldError[error.response.data.errorField] = {
                  value: values[error.response.data.errorField],
                  errors: [new Error(culture[error.response.data.errorReason])]
                };
                return form.setFields(fieldError);
              }
              message.error(error.response.data.error);
            }
          });
      }
    });
  }

  render() {
    const { form, investor, intl } = this.props;

    const cbuValidators = [
      { min: 22, message: `${intl.formatMessage(messages.dataCampOf)} 22 ${intl.formatMessage(messages.dataCharacter)}` },
      { validator: verifyNumber(form, intl.formatMessage(messages.numericCamp)) },
      { validator: validateCBU(form, intl.formatMessage(messages.invalidCBU)) }
    ];

    const cbuAliasValidators = [
      { min: 6, message: `${intl.formatMessage(messages.dataCampOf)} 6 ${intl.formatMessage(messages.dataCharacter)}` },
      { validator: validateCBUAlias(form, intl.formatMessage(messages.invalidCBUAlias)) }
    ];

    if (!form.getFieldValue('cbu')) {
      cbuAliasValidators.push({ required: true, message: intl.formatMessage(messages.obligatoryFieldInput) });
    }

    if (!form.getFieldValue('cbuAlias')) {
      cbuValidators.push({ required: true, message: intl.formatMessage(messages.obligatoryFieldInput) });
    } else {
      const requiredValidatorIndex = cbuValidators.indexOf(v => v.required);
      if (requiredValidatorIndex > -1) {
        cbuValidators.splice(requiredValidatorIndex, 1);
      }
    }

    return (
      <Content style={{ background: '#eee', paddingBottom: 20, paddingTop: 100 }}>
        <Row style={{ marginBottom: 20 }} type="flex" justify="center">
          <Col md={{ span: 10 }} xs={{ span: 20 }}>
            {investor.error ? <Alert message={investor.messageResponse} type="error" className="messageResponse" /> : null }
          </Col>
        </Row>

        <Row type="flex" justify="center">
          <Col md={{ span: 10 }} xs={{ span: 20 }}>
            <RegisterContainer>
              <h1 className="primary-text">
                {intl.formatMessage(messages.dataTitle)}
              </h1>
              <h4 className="form-title">
                {intl.formatMessage(messages.dataTitleBank)}
              </h4>
              <Form onSubmit={this.handleSubmit}>
                <FormItemInputNumber
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.cuitLabel)}
                  field="cuit"
                  maxLength={11}
                  rules={[
                    { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                    { min: 11, message: `${intl.formatMessage(messages.dataCampOf)} 11 ${intl.formatMessage(messages.dataCharacter)}` },
                    { validator: verifyNumber(form, intl.formatMessage(messages.numericCamp)) },
                    { validator: validateCUIT(form, intl.formatMessage(messages.invalidCuit)) }
                  ]}
                />
                <FormItemInputNumber
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.accountNumberLabel)}
                  field="numAccount"
                  // placeholder={intl.formatMessage(messages.placeholderNumAccount)}
                  rules={[
                    { max: 10, message: `${intl.formatMessage(messages.dataCampOf)} 10 ${intl.formatMessage(messages.dataCharacter)}` },
                    { min: 10, message: `${intl.formatMessage(messages.dataCampOf)} 10 ${intl.formatMessage(messages.dataCharacter)}` },
                    { validator: verifyNumber(form, intl.formatMessage(messages.numericCamp)) }
                  ]}
                />
                <FormItemInput
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.headlineLabel)}
                  field="businessName"
                  rules={[
                    { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                    { max: 30, message: `${intl.formatMessage(messages.maxCharacters)} 30` },
                  ]}
                />
                <FormItemSelect
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.typeAccountLabel)}
                  field="accountType"
                  options={[
                    { key: 1, name: intl.formatMessage(messages.accountTypeCC), value: 'CC' },
                    { key: 2, name: intl.formatMessage(messages.accountTypeCA), value: 'CA', },
                  ]}
                />
                <FormItemInputNumber
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.cbuLabel)}
                  maxLength={22}
                  field="cbu"
                  rules={cbuValidators}
                />
                <FormItemInputNumber
                  FormComponent={Form}
                  form={form}
                  maxLength={20}
                  placeholder={intl.formatMessage(messages.cbuAliasLabel)}
                  field="cbuAlias"
                  rules={cbuAliasValidators}
                />

                <FormItemInput
                  FormComponent={Form}
                  form={form}
                  placeholder="PayPal"
                  field="payPalUser"
                  rules={[
                    { max: 15, message: `${intl.formatMessage(messages.maxCharacters)} 15` },
                  ]}
                />
                <FormItemInput
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.mpUserName)}
                  field="mpUserName"
                  rules={[
                    { type: 'email', message: intl.formatMessage(messages.emailInputError) },
                    { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                    { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
                  ]}
                />

                <Form.Item>
                  <RegisterButton>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={investor.isFetching}
                    >
                      {intl.formatMessage(messages.textSend)}
                    </Button>
                  </RegisterButton>
                </Form.Item>

              </Form>
            </RegisterContainer>
          </Col>
        </Row>
        <Row>
          <Col span={10} push={7}>
            {investor.error ? <Alert message={investor.messageResponse} type="error" className="messageResponse" /> : null }
          </Col>
        </Row>
      </Content>

    );
  }
}

const Data = Form.create({ name: 'registerStepTwo' })(DataForm);

const mapStateToProps = state => ({
  investor: state.registration,
  username: state.user.username
});

const mapDispatchToProps = dispatch => bindActionCreators({
  update: updateInvestor,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Data));
