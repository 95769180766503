import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import {
  Layout,
  Spin,
  Button,
  Icon
} from 'antd';

import messages from './culture';
import { accountConfirmationToken } from '../../../actions/registration';

const { Content } = Layout;

const ContainerLoading = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
`;

const PaymentSuccessContainer = styled.div`
  width: 700px;

  & {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  & h1 {
    font-family: Roboto;
    font-size: 92px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 1.2rem 0;
  }

  & p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    max-width: 510px;
  }

  & i {
    font-size: 163px;
    position: absolute;
    top: 0;
    color: #fff;
    opacity: 0.1;
    right: 24px;
  }
`;

const GoBackButton = styled.div`
  & button {
    background-color: transparent;
    border-radius: 5px;
    border: solid 1.5px #ffffff;
    height: 60px;
    width: 330px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.8px;
    text-align: center;
    color: #ffffff;
    margin-top: 1rem;
  }

  & button:hover {
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    background-color: transparent;
  }

  & button:active {
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    background-color: transparent;
  }

  & button:focus {
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    background-color: transparent;
  }
`;

const Container = styled(Content)`
  padding-top: 150px;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
`;

class AccountConfirmation extends Component {
  state = { visible: null, loading: true }

  componentDidMount() {
    const { confirmationToken, match } = this.props;
    const { token } = match.params;

    confirmationToken(token)
      .then((response) => {
        const { confirmed } = response.value;
        if (confirmed) this.setState({ visible: true, loading: false });
        else this.setState({ visible: false, loading: false });
      }).catch(() => {
        this.setState({ visible: false, loading: false });
      });
    window.scroll({
      top: 0,
      behavior: 'auto'
    });
  }

  goToLogin = () => {
    this.props.history.push('/account/login');
  }

  render() {
    const { visible, loading } = this.state;
    const { intl } = this.props;

    if (loading) return (<ContainerLoading><Spin size="large" /></ContainerLoading>);

    return (
      <Container className="success">
        {!visible ? (
          <PaymentSuccessContainer>
            <Icon type="exclamation-circle" />
            <h1>{intl.formatMessage(messages.accountConfirmationError)}</h1>
          </PaymentSuccessContainer>
        ) : (
          <PaymentSuccessContainer>
            <Icon type="check-circle" />
            <h1>{intl.formatMessage(messages.accountConfirmationTitle)}</h1>
            <p>{intl.formatMessage(messages.accountConfirmationText)}</p>
            <GoBackButton>
              <Button
                onClick={this.goToLogin}
              >{intl.formatMessage(messages.accountConfirmationButton)}
              </Button>
            </GoBackButton>
          </PaymentSuccessContainer>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({ state });

const mapDispatchToProps = dispatch => bindActionCreators({
  confirmationToken: accountConfirmationToken,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AccountConfirmation));
