import axios from 'axios';

export const CHANGE_FIELD = 'CHANGE_FIELD';
export const SEND_FORM = 'SEND_FORM';

export const changeField = field => ({
  type: CHANGE_FIELD,
  payload: { field }
});

export const sendForm = (model, url) => ({
  type: SEND_FORM,
  payload: axios
    .post(url, model)
    .catch(error => Promise.reject(error))
});
