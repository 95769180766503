import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Form,
  Button,
  Layout,
  Breadcrumb,
  message,
  Tooltip,
  Input,
  Spin
} from 'antd';

import { getDownloadUrl } from '../../../service/firebaseStorageService';

import { FormItemSelect, FormItemInput, } from '../../commons/formComponents/formComponents';
import { specialCharacter, validatePassword } from '../../commons/validations';

import { createUser } from '../../../actions/user';
import { getUser, updateUserEdit, deleteAccountBank } from '../../../actions/users';

const { Content } = Layout;

const styles = {
  content: { padding: 24, background: '#fff', minHeight: 360 },
};

const messages = defineMessages({
  requiredField: {
    id: 'general.validations.requiredField',
    //defaultMessage: 'Campo requerido!',
  },
  maxCharacters: {
    id: 'general.validations.maxCharacters',
    defaultMessage: 'El máximo de caracteres es de',
  },
  minCharacters: {
    id: 'general.validations.minCharacters',
    defaultMessage: 'El mínimo de caracteres es de',
  },
  selectDate: {
    id: 'general.selectDate',
    defaultMessage: 'Por favor, seleccione una fecha'
  },
  duplicatedCBU: {
    id: 'admin.user.create.ducplicatedCBU',
    defaultMessage: 'Por favor verifique que los CBU ingresados no sean iguales'
  },
  emailAlreadyExistent: {
    id: 'admin.user.create.emailAlreadyExistent',
    defaultMessage: 'Email ya registrado'
  },
  invalidEmail: {
    id: 'general.validations.invalidEmail',
    defaultMessage: 'El mail ingresado no es válido'
  },
  field: {
    id: 'general.Field',
    defaultMessage: 'Campo de'
  },
  characters: {
    id: 'general.characters',
    defaultMessage: 'caracteres'
  },
  uploadImage: {
    id: 'general.uploadImage',
    defaultMessage: 'Click para subir archivo'
  },
  breadCrumbHome: { id: 'general.breadCrumb.home' },
  breadCrumbUsers: { id: 'general.breadCrumb.users' },
  breadCrumbCreate: { id: 'general.breadCrumb.create' },
  firstName: { id: 'admin.user.firstName' },
  lastName: { id: 'admin.user.lastName' },
  country: { id: 'general.country' },
  countryPlaceholder: { id: 'general.countryPlaceHolder' },
  statePlaceHolder: { id: 'general.statePlaceHolder' },
  state: { id: 'general.state' },
  city: { id: 'general.city' },
  roles: { id: 'general.roles' },
  rolesPlaceholder: { id: 'general.rolesPlaceholder' },
  password: { id: 'general.user.password' },
  obligatoryFieldInput: { id: 'login.input.error.obligatoryField' },
  inputTooltipPass: { id: 'register.input.tooltip' },
  administratorRole: { id: 'general.roles.administrator' },
  realStateDevRole: { id: 'general.roles.realStateDeveloper' },
  image: { id: 'admin.user.create.image' },
  birthdate: { id: 'general.user.birthdate' },
  birthdatePlaceholder: { id: 'admin.user.create.birthdatePlaceholder' },
  email: { id: 'general.email' },
  mainPhone: { id: 'admin.user.mainPhone' },
  secondaryPhone: { id: 'admin.user.secondaryPhone' },
  createUserButton: { id: 'admin.user.create.createUserButton' },
  cancelButton: { id: 'project.create.input.cancelButton' },
  errorImage: { id: 'project.id.error.image' },
  placeholderLocation: { id: 'register.data.placeholderLocation' },
  address: { id: 'profile.complete.text.address' },
  floor: { id: 'profile.complete.text.floor' },
  department: { id: 'profile.complete.text.department' },
  addressIdentication: { id: 'profile.complete.text.addressVerification' },
  loadImage: { id: 'register.data.loadImage' },
  tooltipImage: { id: 'register.data.tooltipImage' },
  cp: { id: 'profile.complete.text.cp' },
  documentNumber: { id: 'profile.complete.text.documentNumber' },
  imagesIdentification: { id: 'profile.complete.text.imagesIdentification' },
  positiveIdentificationFront: { id: 'profile.complete.text.Front' },
  positiveIdentificationBack: { id: 'profile.complete.text.Back' },
  tooltipImageNit: { id: 'register.data.tooltipImageNit' },
  website: { id: 'profile.complete.text.website' },
  bankData: { id: 'profile.complete.title.bankData' },
  placeholderTypeAccount: { id: 'register.data.placeholderTypeAccount' },
  accountTypeCA: { id: 'profile.complete.text.accountTypeCA' },
  accountTypeCC: { id: 'profile.complete.text.accountTypeCC' },
  currency: { id: 'project.create.input.currency' },
  dolar: { id: 'project.create.option.dolar' },
  pesos: { id: 'project.create.option.pesos' },

  accountType: { id: 'profile.complete.text.typeAccount' },
  businessName: { id: 'profile.complete.text.businessName' },
  cuit: { id: 'profile.complete.text.cuit' },
  cbu: { id: 'profile.complete.text.cbu' },
  cbuAlias: { id: 'profile.complete.text.cbuAlias' },
  numAccount: { id: 'profile.complete.text.numAccount' },
  placeholderNumAccount: { id: 'register.data.placeholderNumAccount' },
  addAccountBank: { id: 'users.form.button.addAccount' },

  duplicatedCBUAlias: { id: 'admin.user.create.duplicatedCBUAlias' },
  emailExists: { id: 'admin.user.create.emailExists' },
  numericCamp: { id: 'validate.numericCamp' },
  invalidCuit: { id: 'validate.invalidCuit' },
  invalidCBU: { id: 'validate.invalidCBU' },
  invalidCBUAlias: { id: 'validate.invalidCBUAlias' },
  validatePassword: { id: 'validate.validatePassword' },
  validateAge: { id: 'validate.validateAge' },
  specialCharacter: { id: 'validate.specialCharacter' },
  validURL: { id: 'validate.validURL' },
  onlyImageComponent: { id: 'validate.onlyImage' },
  cannotExceedImageComponent: { id: 'validate.cannotExceedImage' },
  selectValidAddress: { id: 'validate.selectValidAddress' },
  errorSelectAddress: { id: 'validate.errorSelectAddress' },
  mpUserName: { id: 'profile.complete.text.mpUserName' },
});

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const ContainerLoading = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

class RegistrationForm extends Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    const {
      get, match, isEdit, form, intl
    } = this.props;

    const passwordFieldRules = [
      { required: true, message: intl.formatMessage(messages.requiredField) },
      { max: 20, message: `${intl.formatMessage(messages.maxCharacters)} 20` },
      { min: 8, message: `${intl.formatMessage(messages.minCharacters)} 8` },
      { validator: validatePassword(form, intl.formatMessage(messages.validatePassword)) }
    ];
    if (isEdit) {
      passwordFieldRules.splice(0, 1);
    }

    this.setState({ passwordFieldRules });

    if (match.params.id) {
      get(match.params.id).then((resp) => {
        const {
          image,
        } = resp.value.user;

        if (image && image.key) getDownloadUrl(image.key).then(url => this.setState({ userProfileImageUrl: url }));
      });
    }

    // eslint-disable-next-line no-sequences
    setTimeout(() => (this.setState({ loading: false }), 4000));
  }

  isAdmin = () => {
    const { form, userInfo, isEdit } = this.props;

    return ((form.getFieldValue('roles') === 'admin')
      || (isEdit && !form.getFieldValue('roles') && userInfo.userEdit.roles === 'admin'));
  }

  isInversor = () => {
    const { form, userInfo, isEdit } = this.props;

    return ((form.getFieldValue('roles') === 'inversor')
      || (isEdit && !form.getFieldValue('roles') && userInfo.userEdit.roles === 'inversor'));
  }

  isDeveloper = () => {
    const { form, userInfo, isEdit } = this.props;
    return ((form.getFieldValue('roles') === 'developer')
      || (isEdit && !form.getFieldValue('roles') && userInfo.userEdit.roles === 'developer'));
  }

  userHasProfileImage = () => {
    const {
      userInfo
    } = this.props;
    return (userInfo && userInfo.userEdit && userInfo.userEdit.image && userInfo.userEdit.image.key);
  }

  handleSubmit = (e) => {
    const {
      form, create, history, match, updateUser, isEdit, intl
    } = this.props;

    // const { location, requiredCP, preLocation } = this.state;

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
 
        const duplicatedErrors = {};

        

        if (Object.keys(duplicatedErrors).length > 0) {
          form.setFields(duplicatedErrors);
          return;
        }

        const model = {
          ...values
        };

        if (!isEdit || values.password) model.password = values.password;
        if (values.birthdate) model.birthdate = values.birthdate.format();

        if (match.params.id) {
          updateUser(model)
            .then(() => history.push('/admin/users'))
            .catch((error) => {
              if (error.response.status === 422) {
                if (error.response.data.errorField) {
                  const fieldError = {};
                  fieldError[error.response.data.errorField] = { value: values[error.response.data.errorField], errors: [new Error(intl.formatMessage(messages[error.response.data.errorReason]))] };
                  return form.setFields(fieldError);
                }
                message.error(error.response.data.error);
              }
            });
        } else {
          create(model)
            .then(() => history.push('/admin/users'))
            .catch((error) => {
              if (error.response.status === 422) {
                if (error.response.data.errorField) {
                  const fieldError = {};
                  fieldError[error.response.data.errorField] = { value: values[error.response.data.errorField], errors: [new Error(intl.formatMessage(messages[error.response.data.errorReason]))] };
                  return form.setFields(fieldError);
                }
                message.error(error.response.data.error);
              }
            });
        }
      }
    });
  }

  // handleChangeLocation = (result, requiredCP) => {
  //   this.setState({ location: result, requiredCP });
  // }

  onUploaded = (result, error, field, file) => {
    const { form, intl } = this.props;

    if (error) return message.error(intl.formatMessage(messages.errorImage));
    form.setFields({ [field]: { value: { key: result, name: file.name } } });
  };

  render() {
    const {
      form,
      intl,
      userInfo,
      match,
      isEdit
    } = this.props;
    const {
      loading,
      userProfileImageUrl,
    } = this.state;

    const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };

    if (userInfo.isFetchingUser || loading) return (<ContainerLoading><Spin size="large" /></ContainerLoading>);

    const userImage = [];

    if (match.params.id && Object.keys(userInfo.userEdit).length) {
      if (userInfo.userEdit) {
        if (userProfileImageUrl) {
          userImage.push({
            uid: 1,
            status: 'done',
            url: userProfileImageUrl
          });
        }
      }

      if (isEdit) {
        if (this.userHasProfileImage() && !userProfileImageUrl) return (<ContainerLoading><Spin size="large" /></ContainerLoading>);
      }


    }


    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbHome)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbUsers)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbCreate)}</Breadcrumb.Item>
        </Breadcrumb>
        <div style={styles.content}>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.firstName)}
              initialValue={match.params.id ? userInfo.userEdit.name : null}
              field="name"
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 200, message: `${intl.formatMessage(messages.maxCharacters)} 200` },
                { validator: specialCharacter(form, intl.formatMessage(messages.specialCharacter)) }
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.lastName)}
              field="lastName"
              initialValue={match.params.id ? userInfo.userEdit.lastName : null}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 200, message: `${intl.formatMessage(messages.maxCharacters)} 200` },
                { validator: specialCharacter(form, intl.formatMessage(messages.specialCharacter)) }
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.email)}
              field="username"
              initialValue={match.params.id ? userInfo.userEdit.username : null}
              disabled={!!match.params.id}
              rules={[
                { type: 'email', message: intl.formatMessage(messages.invalidEmail) },
                { required: true, message: intl.formatMessage(messages.requiredField) },
              ]}
            />
            {(!this.props.isEdit) && (
              <FormItemSelect
                FormComponent={Form}
                form={form}
                label={intl.formatMessage(messages.roles)}
                field="roles"
                placeholder={intl.formatMessage(messages.roles)}
                initialValue={match.params.id ? userInfo.userEdit.roles : null}
                options={[
                  { key: 1, name: intl.formatMessage(messages.administratorRole), value: 'admin' },
                  { key: 2, name: intl.formatMessage(messages.realStateDevRole), value: 'developer', },
                ]}
                rules={[
                  { required: true, message: intl.formatMessage(messages.requiredField) },
                ]}
              />
            )}
            {(this.isAdmin() || this.isInversor()) && (
              <Form.Item label={intl.formatMessage(messages.password)}>
                <Tooltip
                  trigger={['focus']}
                  placement="topLeft"
                  title={intl.formatMessage(messages.inputTooltipPass)}
                >
                  {form.getFieldDecorator('password', {
                    rules: this.state.passwordFieldRules,
                  })(<Input.Password />)}
                </Tooltip>
              </Form.Item>
            )}
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.mpUserName)}
              initialValue={match.params.id ? userInfo.userEdit.mpUserName : null}
              field="mpUserName"
              rules={[
                { type: 'email', message: intl.formatMessage(messages.invalidEmail) },
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label="PayPal"
              initialValue={match.params.id ? userInfo.userEdit.payPalUser : null}
              field="payPalUser"
              rules={[
                { max: 15, message: `${intl.formatMessage(messages.maxCharacters)} 15` },
              ]}
            />

            <ButtonContainer>
              <Button
                style={{ marginRight: 15 }}
                size="large"
              ><Link to="/admin/users/">{intl.formatMessage(messages.cancelButton)}</Link>
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={userInfo.isFetchingCreate}
              >{intl.formatMessage(messages.createUserButton)}
              </Button>
            </ButtonContainer>
          </Form>
        </div>
      </Content>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);

const mapStateToProps = state => ({ userInfo: state.users });

const mapDispatchToProps = dispatch => bindActionCreators({
  create: createUser,
  get: getUser,
  updateUser: updateUserEdit,
  deleteAccountBank
}, dispatch);

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);

export default injectIntl(connectedForm, {
  withRef: false,
});
