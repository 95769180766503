import React from 'react';
import styled from 'styled-components';
import { Col } from 'antd';

const Title = styled.div`
  opacity: 0.35;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid;
  margin-bottom: 5px;
`;

const InputEditComponent = ({
  title,
  children
}) => (
  <Col xs={24} md={12} style={{ marginBottom: 20 }}>
    <Title>{title}</Title>
    {children}
  </Col>
);

export default InputEditComponent;
