import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Button,
  Form,
  message,
} from 'antd';

import { updateUserEdit } from '../../../../../../actions/users';

import BasicDataEdit from './basicDataEdit';

const ButtonAddAccount = styled.div`
  & {
    text-align: center;
    margin-top: 1rem;
  }
  & button {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-transform: uppercase;
  }

  & button:hover {
    border-color: transparent;
    color: #fff;
  }
`;

class myDataUser extends Component {
  state = {
    first: true
  }

  componentDidUpdate() {
    const { form, userInfo } = this.props;
    const { getFieldError, setFields, getFieldValue } = form;

    if (this.state.first) this.setState({ first: false });

    userInfo.bankInformation.forEach((bank, i) => {
      if (getFieldError(`cbu${i}`) && getFieldError(`cbuAlias${i}`)) {
        if (getFieldValue(`cbu${i}`) && !getFieldValue(`cbuAlias${i}`)) setFields({ [`cbuAlias${i}`]: { errors: null, value: null } });
        else if (getFieldValue(`cbuAlias${i}`) && !getFieldValue(`cbu${i}`)) setFields({ [`cbu${i}`]: { errors: null, value: null } });
      }
    });
  }

  hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  handleSubmit = (e) => {
    const {
      form, updateUser, userInfo, intl, messages
    } = this.props;

    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const model = {
          ...values,
          username: userInfo.username,
          state: 'accepted',
        };

        const bankInformation = [];

        Object.keys(values).map((key) => {
          if (key.includes('cbuAlias')) {
            if (values[key]) bankInformation[key.slice(8)] = { ...bankInformation[key.slice(8)], [key.slice(0, 8)]: values[key] };
            delete model[key];
          } else if (key.includes('cbu') && !key.includes('cbuAlias')) {
            if (values[key]) bankInformation[key.slice(3)] = { ...bankInformation[key.slice(3)], [key.slice(0, 3)]: values[key] };
            delete model[key];
          } else if (key.includes('cuit')) {
            if (values[key]) bankInformation[key.slice(4)] = { ...bankInformation[key.slice(4)], [key.slice(0, 4)]: values[key] };
            delete model[key];
          } else if (key.includes('businessName')) {
            if (values[key]) bankInformation[key.slice(12)] = { ...bankInformation[key.slice(12)], [key.slice(0, 12)]: values[key] };
            delete model[key];
          } else if (key.includes('accountType')) {
            if (values[key]) bankInformation[key.slice(11)] = { ...bankInformation[key.slice(11)], [key.slice(0, 11)]: values[key] };
            delete model[key];
          } else if (key.includes('numAccount')) {
            if (values[key]) bankInformation[key.slice(10)] = { ...bankInformation[key.slice(10)], [key.slice(0, 10)]: values[key] };
            delete model[key];
          }
          return bankInformation;
        });

        const cbus = [];
        const cbuAliases = [];

        const duplicatedErrors = {};

        bankInformation.forEach((bankInfo, index) => {
          if (bankInfo.cbu) {
            const duplicatedIndexWith = cbus.indexOf(bankInfo.cbu);
            if (duplicatedIndexWith >= 0) {
              if (!duplicatedErrors[`cbu${duplicatedIndexWith}`]) {
                duplicatedErrors[`cbu${duplicatedIndexWith}`] = { value: bankInfo.cbu, errors: [new Error(intl.formatMessage(messages.duplicatedCBU))] };
              }
              duplicatedErrors[`cbu${index}`] = { value: bankInfo.cbu, errors: [new Error(intl.formatMessage(messages.duplicatedCBU))] };
            }
            cbus[index] = bankInfo.cbu;
          }

          if (bankInfo.cbuAlias) {
            const duplicatedIndexWith = cbuAliases.indexOf(bankInfo.cbuAlias);
            if (duplicatedIndexWith >= 0) {
              if (!duplicatedErrors[`cbuAlias${duplicatedIndexWith}`]) {
                duplicatedErrors[`cbuAlias${duplicatedIndexWith}`] = { value: bankInfo.cbuAlias, errors: [new Error(intl.formatMessage(messages.duplicatedCBUAlias))] };
              }
              duplicatedErrors[`cbuAlias${index}`] = { value: bankInfo.cbuAlias, errors: [new Error(intl.formatMessage(messages.duplicatedCBUAlias))] };
            }
            cbuAliases[index] = bankInfo.cbuAlias;
          }
        });

        if (Object.keys(duplicatedErrors).length > 0) {
          form.setFields(duplicatedErrors);
          return;
        }

        model.bankInformation = bankInformation;

        if (values.birthdate) model.birthdate = values.birthdate.format();

        updateUser(model)
          .then(() => window.location.reload())
          .catch((error) => {
            if (error.response.status === 422) {
              if (error.response.data.errorField) {
                const fieldError = {};
                fieldError[error.response.data.errorField] = { value: values[error.response.data.errorField], errors: [new Error(intl.formatMessage(messages[error.response.data.errorReason]))] };
                return form.setFields(fieldError);
              }
              message.error(error.response.data.error);
            }
          });
      }
    });
  }

  render() {
    const {
      userInfo,
      intl,
      messages,
      form,
      loading
    } = this.props;

    return (
      <div style={{ flex: 1 }}>
        <Form onSubmit={this.handleSubmit} style={{ flex: 1 }}>

          <Row>

            <Col span={12} offset={6}>
              <BasicDataEdit
                messages={messages}
                userInfo={userInfo}
                form={form}
                intl={intl}
              />
              {/** 
             
                   <BankDataEdit
              messages={messages}
              userInfo={userInfo}
              form={form}
              intl={intl}
              first={first}
            />      
             **/}

            </Col>


          </Row>

          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <ButtonAddAccount>
              <Button type="primary" htmlType="submit" loading={loading}>
                {intl.formatMessage(messages.sendText)}
              </Button>
            </ButtonAddAccount>
          </div>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ updateUser: updateUserEdit }, dispatch);

const mapStateToProps = state => ({
  loading: state.users.isFetchingCreate,
});

const WrappedMyDataForm = Form.create({ name: 'myDataUser' })(myDataUser);

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(WrappedMyDataForm);

export default injectIntl(connectedForm, { withRef: false });
