import axios from 'axios';

import history from '../history';
import config from '../config/index';

const { API_VERSION, API_URL } = config;

export const initializeAxios = () => {
  const api_url = `${API_URL}${API_VERSION}`;
  axios.defaults.baseURL = api_url;

  axios.interceptors.response.use(
    response => response,
    (error) => {
      if (error.response && error.response.status === 401) history.push({ pathname: '/account/login', state: { remove: true } });
      return Promise.reject(error);
    }
  );
};

export const addAxiosToken = token => axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
