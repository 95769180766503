import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import FooterComponent from '../components/admin/commons/footer/footer';
import HeaderComponent from '../components/admin/commons/header/header';
import Dashboard from '../components/admin/dashboard/index';
import MenuBar from '../components/admin/commons/menu/menuBar';
import Projects from '../components/admin/projects/index';
import CreateProject from '../components/admin/projects/create/formProject';
import Microsiste from '../components/admin/microsite/index';
import CreateMicrosite from '../components/admin/microsite/createMicrosite';
import FormUser from '../components/admin/users/formUser';
import Users from '../components/admin/users/index';
// import ReturnFund from '../components/admin/returnFund';
import FundRelease from '../components/admin/fundRelease/index';
import Transactions from '../components/admin/transactions/index';
import EditTransaction from '../components/admin/transactions/transactionEdit';
import Countries from '../components/admin/countries/index';
import CreateCountries from '../components/admin/countries/create/formCountry';
import CountryTransaction from '../components/admin/countries/countryEdit';

const styles = {
  content: { padding: 24, background: '#fff', minHeight: 360 },
  appLayout: { minHeight: '100vh' },
};

const adminRoutes = () => (
  <Layout style={styles.appLayout}>
    <MenuBar />
    <Layout>
      <HeaderComponent />
      <Route exact path="/admin" component={Dashboard} />

      <Route exact path="/admin/project" component={Projects} />
      <Route exact path="/admin/projects" component={Projects} />
      <Route exact path="/admin/projects/create" component={CreateProject} />
      <Route exact path="/admin/projects/edit/:id" render={props => <CreateProject isEdit {...props} />} />

      <Route exact path="/admin/users" component={Users} />
      <Route exact path="/admin/users/create" component={FormUser} />
      <Route exact path="/admin/users/edit/:id" isEdit render={props => <FormUser isEdit {...props} />} />

      <Route exact path="/admin/transactions" component={Transactions} />
      <Route exact path="/admin/transactions/edit/:id" component={EditTransaction} />

      <Route exact path="/admin/microsite" component={Microsiste} />
      <Route exact path="/admin/microsite/create" component={CreateMicrosite} />
      <Route exact path="/admin/microsite/edit/:id" render={props => <CreateMicrosite isEdit {...props} />} />

      {/* <Route exact path="/admin/returns" component={ReturnFund} /> */}
      <Route exact path="/admin/releases" component={FundRelease} />

      <Route exact path="/admin/countries" component={Countries} />
      <Route exact path="/admin/countries/create" component={CreateCountries} />
      <Route exact path="/admin/countries/edit/:id" component={CountryTransaction} />

      <FooterComponent name="Wibai ©2019" />
    </Layout>
  </Layout>
);

export default adminRoutes;
