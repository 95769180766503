import axios from 'axios';

export const GET_RETURN_FUND = 'GET_RETURN_FUND';
// export const APROVE_RETURN = 'APROVE_RETURN';
// export const REJECT_RETURN = 'REJECT_RETURN';
export const CHANGE_RETURN_STATUS = 'CHANGE_RETURN_STATUS';

export const getReturnFund = () => ({
  type: GET_RETURN_FUND,
  payload: axios
    .get('/returns')
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});

export const changeReturnStatus = (model, index) => ({
  type: CHANGE_RETURN_STATUS,
  payload: axios
    .post('/returns/changeReturnStatus', model)
    .then(response => ({ data: response.data, index }))
    .catch(error => Promise.reject(error))
});

// export const aproveReturns = (id, index) => ({
//   type: APROVE_RETURN,
//   payload: axios
//     .post(`/returns/approve/${id}`)
//     .then(response => ({ data: response.data, index }))
//     .catch(error => Promise.reject(error))
// });

// export const rejectReturns = (id, index) => ({
//   type: REJECT_RETURN,
//   payload: axios
//     .post(`/returns/reject/${id}`)
//     .then(response => ({ data: response.data, index }))
//     .catch(error => Promise.reject(error))
// });
