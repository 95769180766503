import React from 'react';
import moment from 'moment';
import {
  Table, Tag, Button, Icon, Divider
} from 'antd';

const tags = {
  new: 'cyan',
  error: 'red',
  pending: 'orange',
  expired: 'red',
  returned: 'green',
  released: 'green',
  rejectedRelease: 'magenta',
  returnedRelease: 'green',
  returnedWithErrors: 'red',
  returnedReject: 'red',
  accredited: 'green',
  OnBindCall: 'red',
  rejected: 'magenta',
};

const DataTable = ({
  data, loading, intl, messages, showModal, editAction
}) => {
  const columns = [{
    title: intl.formatMessage(messages.idTitle),
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.createdAtTitle),
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    sortDirections: ['descend', 'ascend'],
    // eslint-disable-next-line react/display-name
    render: createdAt => (
      <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
    )
  }, {
    title: intl.formatMessage(messages.investorUserTitle),
    dataIndex: 'investorUser',
    key: 'investorUser',
    sorter: (a, b) => a.investorUser.localeCompare(b.investorUser),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.projectNameTitle),
    dataIndex: 'projectName',
    key: 'projectName',
    sorter: (a, b) => a.projectName.localeCompare(b.projectName),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.amountTitle),
    dataIndex: 'amount',
    key: 'amount',
    sorter: (a, b) => (a.amount - b.amount),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.originIdTitle),
    dataIndex: 'originId',
    key: 'originId',
    sorter: (a, b) => a.originId.localeCompare(b.originId),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.transactionIdTitle),
    dataIndex: 'transactionId',
    key: 'transactionId',
    sorter: (a, b) => a.transactionId.localeCompare(b.transactionId),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.statusTitle),
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
    sortDirections: ['descend', 'ascend'],
    // eslint-disable-next-line react/display-name
    render: status => (<Tag color={tags[status]}>{intl.formatMessage(messages[`${status}Transaction`])}</Tag>),
  }, {
    title: intl.formatMessage(messages.actionTitle),
    key: 'action',
    dataIndex: '_id',
    // eslint-disable-next-line react/display-name
    render: (_id, record) => (
      <span>
        <Button
          onClick={() => showModal(record.history)}
          className="buttonActions"
          title={intl.formatMessage(messages.history)}
        >
          <Icon type="history" />
        </Button>

        {record.transactionFeeAmount === 0 && (
        <span>
        <Divider type="vertical" />
        <Button
          onClick={() => editAction(record)}
          className="buttonActions"
          title={intl.formatMessage(messages.edit)}
        >

          <Icon type="edit" />
        </Button>
      </span>
        )}


      </span>
    )
  }];
  return (
    <Table dataSource={data} columns={columns} rowKey="_id" loading={loading} />
  );
};

export default DataTable;
