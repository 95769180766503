import React, { Component } from 'react';
import { Layout, Breadcrumb, message } from 'antd';

const { Content } = Layout;

const styles = {
  content: { padding: 24, background: '#fff', minHeight: 360 },
};

class Dashboard extends Component {
  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.messageProps) message.success(location.state.messageProps);
  }

  render() {
    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Test</Breadcrumb.Item>
          <Breadcrumb.Item>Menu</Breadcrumb.Item>
        </Breadcrumb>
        <div style={styles.content}>
          Content.
        </div>
      </Content>
    );
  }
}

export default Dashboard;
