import { defineMessages } from 'react-intl';

const messages = defineMessages({
  start: { id: 'admin.transactions.table.breadcrumb.start' },
  microsTitle: { id: 'admin.transactions.table.breadcrumb.micros' },
  editMicro: { id: 'admin.transactions.editMicroButton' },
  breadCrumbHome: { id: 'admin.transactions.edit.breadcrumb.start' },
  actionTitle: { id: 'users.table.title.action' },
  cancel: { id: 'admin.transactions.edit.cancel' },

  newMicroButton: { id: 'micro.index.button.newMicro' },

  idTitle: { id: 'admin.transactions.table.title.id' },
  linkToAccess: { id: 'admin.transactions.table.title.linkToAccess' },
  name: { id: 'admin.transactions.table.title.name' },
  descp: { id: 'admin.transactions.table.title.descp' },
  terms: { id: 'admin.transactions.table.title.terms', defaultMessage: 'Terminos y condiciones' },
  MPAccessTokenTable: { id: 'admin.country.table.MPAccessToken' },

  yes: { id: 'project.create.option.yes' },
  no: { id: 'project.create.option.no' },
  bank: { id: 'micro.edit.bank' },
  bankTit: { id: 'micro.edit.bankTitle' },
  bankCuit: { id: 'micro.edit.bankCuit' },
  bankCbu: { id: 'micro.edit.bankCbu' },
  bankCount: { id: 'micro.edit.bankCount' },
  bankType: { id: 'micro.edit.bankType' },
  bankUnicCode: { id: 'micro.edit.unicCode' },

  color: { id: 'admin.transactions.table.title.color' },
  createMicroButton: { id: 'admin.transactions.createMicroButton' },
  maxCharacters: { id: 'general.validations.maxCharacters', defaultMessage: 'El máximo de caracteres es de' },
  minCharacters: { id: 'general.validations.minCharacters', defaultMessage: 'El mínimo de caracteres es de' },
  numericCamp: { id: 'validate.numericCamp' },
  specialCharacter: { id: 'validate.specialCharacter' },
  hexColor: { id: 'validate.hexColor' },
  onlyText: { id: 'validate.onlyText' },
  requiredField: { id: 'general.validations.requiredField' },
  creationDate: { id: 'project.table.key.creationDate' },
  errorSelect: { id: 'admin.microsite.error.selectProject' },
  linkExist: { id: 'admin.microsite.error.linkExist' },
  validURL: { id: 'validate.validURL' },
  logoImage: { id: 'admin.microsite.logoImage' },
  uploadImage: { id: 'general.uploadImage', defaultMessage: 'Click para subir archivo' },
  draft: { id: 'project.table.status.draft' },
  published: { id: 'project.table.status.published' },
  breadcrumbThirdEdit: { id: 'project.create.breadcrumb.thirdEdit' },
  questionActivate: { id: 'project.index.questionActivate' },
  questionInactivate: { id: 'project.index.questionInactivate' },
  questionActivateMicro: { id: 'micro.index.questionActivate' },
  questionInactivateMicro: { id: 'micro.index.questionInactivate' },
  activateButton: { id: 'project.table.button.activate' },
  inactivateButton: { id: 'project.table.button.inactivate' },
  activeKey: { id: 'micro.table.key.active' },
  active: { id: 'project.table.status.active' },
  inactive: { id: 'project.table.status.inactive' },
  finished: { id: 'project.table.status.finished' },

});

export default messages;
