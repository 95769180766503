import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

import ProjectCard from '../../../home/projectCard';

import {
  Row
} from 'antd';

const Title = styled.div`
  margin-bottom: 30px;
  font-size: 22px;
  color: #7d5ef0;
  font-weight: bold;
  font-family: Roboto-BolD;
`;

const MarginedContainer = styled.div`
  & {
    margin-top: 6rem;
    padding: 0 140.5px;
  }

  @media (max-width: 768px) {
    & {
      padding: 0 15px;
    }
  }
`;

const MyInvestmentsUser = ({
  projects,
  intl,
  messages
}) => (
      <MarginedContainer>
      {projects.length !== 0 && (
        <Title>
          <Icon type="smile" theme="outlined" style={{ marginRight: 5 }} />
        Proyectos que apoyo
        </Title>
      )}

      {projects.length !== 0 && (
        projects.map(project => (
          (project && project != null ?
            <Row gutter={24} type="flex" justify="center">
            <ProjectCard
              key={project._id}
              project={project}
              intl={intl}
              messages={messages}
            /> </Row>: ''
          )
        ))
      )}

      </MarginedContainer>
  );

export default MyInvestmentsUser;
