import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import styled from 'styled-components';

import { projectList, featuredProjects  } from '../../../actions/projects';

const InfoContainer = styled.div`
  & {
    position: relative;
    user-select: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
  }

  & .slides {
    height: 300px;
    overflow: hidden;
    position: relative;
    width: 84%;
    display: flex;
  }

  & .slides ul {
    list-style: none;
    position: relative;
    width: 100%;
    padding-inline-start: 80px;
  }

  @keyframes anim_slides_enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  & .slides ul li {
    opacity: 0;
    position: absolute;
    top: 2rem;
  }

  & .slides ul li.animate_enter {
    animation-name: anim_slides_enter;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-delay: 1;
    animation-play-state: running;
    animation-fill-mode: forwards;
    z-index: 999
  }

  @keyframes anim_titles {
    0% {
      left: 100%;
      opacity: 0;
    }
    100% {
      left: 0%;
      opacity: 1;
    }
  }

  & .slides ul li h1 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    font-weight: 600;
  }

  & .slides ul li p {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  & .slides ul li a {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: 0.28px;
    text-align: left;
    color: #ffffff;
    left: 0%;
  }

  & .slides ul li.animate_enter a {
    animation-name: anim_titles;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-delay: 1;
    animation-play-state: running;
    animation-fill-mode: forwards;
  }

  & div:last-child {
    display: flex;
    padding-inline-start: 80px;
    align-items: center;
    color: #fff;
  }

  & div:last-child div {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    width: 90px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  & div:last-child span {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: 2px;
    text-align: left;
    color: #ffffff;
  }

  & div:last-child i {
    cursor: pointer;
    transition: all 0.25s ease-in;
  }

  & div:last-child i:hover {
    transform: scale(1.2);
  }

  @media (max-width: 768px) {
    & div:last-child,
    & .slides ul {
      padding-inline-start: 20px;
    }
  }
`;

const TagContainer = styled.div`
  & {
    border-radius: 20px;
    background-color: #ffffff;
    padding: 5px 15px;
    display: inline-block;
  }

  & span {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: 0.28px;
    text-align: left;
    color: #3b308f;
  }
`;

class InfoSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0
    };
  }

  componentDidMount = () => {

    const { featuredProjects, country } = this.props;
    featuredProjects(country);

    this.setLoop();
  };

  componentWillUnmount = () => {
    clearInterval(this.loop);
  };

  next = () => {
    const { currentIndex } = this.state;
    const { projects } = this.props;
    if (currentIndex === projects.length - 1) {
      this.setState({
        currentIndex: 0
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1
      });
    }
  };

  prev = () => {
    const { currentIndex } = this.state;
    const { projects } = this.props;
    if (currentIndex === 0) {
      this.setState({
        currentIndex: projects.length - 1
      });
    } else {
      this.setState({
        currentIndex: currentIndex - 1
      });
    }
  };

  setLoop = () => {
    this.loop = setInterval(() => this.next(), 7500);
  };

  truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    
    return str.slice(0, num) + '...'
  }

  render() {
    const { projects } = this.props;
    const { currentIndex } = this.state;
    return (
      <InfoContainer>
        <div className="slides">
          {projects && (
            <ul>
              {projects.length > 0 ? (
                projects.map((item, i) => (
                  <li
                    key={`InfoSliderItem_${i}`}
                    className={i === currentIndex ? 'animate_enter' : ''}
                  >
                    <TagContainer>
                      <span>{item.featuredCategory}</span>
                    </TagContainer>
                    <h1>{item.name}</h1>
                    <p>
                      {this.truncateString(item.briefDescription, 100)}
                    </p>
                    <Link to={"/projects/"+item.id}>VER PROYECTO</Link>
                  </li>
                ))
              ) : (
                <h2 style={{ color: '#FFF' }}>Sin proyectos destacados</h2>
              )}
            </ul>
          )}
        </div>

        {(projects.length > 0 ? <div>
          <div>
            <Icon type="left" onClick={this.prev} />
            <Icon type="right" onClick={this.next} />
          </div>
          {projects && (
            <span>
              {projects.length > 0 ? currentIndex + 1 : currentIndex}/
              {projects.length}
            </span>
          )}
        </div> : '')}
        
      </InfoContainer>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects.featureProjects,
  country: state.culture.country
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getProjects: projectList,
    featuredProjects: featuredProjects,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSlider);

