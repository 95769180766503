export const CHANGE_CULTURE = 'CHANGE_CULTURE';
export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';
export const STOP_RELOADING = 'STOP_RELOADING';

export const changeCulture = culture => ({
  type: CHANGE_CULTURE,
  payload: culture
}
);

export const changeCountry = (country) => ({
  type: CHANGE_COUNTRY,
  payload: country
}
);

export const stopReLoading = () => ({
  type: STOP_RELOADING
});
