import axios from 'axios';

import { changeUserState, loginData } from './user';
import { changeState } from './auth';
import { addAxiosToken } from '../service/axios';

export const CREATE_INVESTOR = 'CREATE_INVESTOR';
export const UPDATE_INVESTOR = 'UPDATE_INVESTOR';
export const CONFIMATION_ACCOUNT = 'CONFIMATION_ACCOUNT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESEND_CONFIRMATION = 'RESEND_CONFIRMATION';

export const createInvestor = model => ({
  type: CREATE_INVESTOR,
  payload: axios.post('/account/register/step1 ', model)
    .then(response => ({ status: response }))
    .catch(error => Promise.reject(error))
});

export const createUserGoogle = token => ({
  type: CREATE_INVESTOR,
  payload: axios.post('account/register/step1/google', token)
    .then(json => ({ user: json.data }))
});

export const updateInvestor = model => (dispatch) => {
  const response = dispatch({
    type: UPDATE_INVESTOR,
    payload: axios.put('/account/register/step2', model)
      .then(json => ({ response: json.data }))
      .catch(error => Promise.reject(error))
  });

  response.then(() => dispatch(changeUserState('accepted', true)));
  return Promise.resolve(response);
};

export const accountConfirmationToken = token => ({
  type: CONFIMATION_ACCOUNT,
  payload: axios.get(`/account/register/accountConfirmationToken/${token}`)
    .then(response => ({ confirmed: response.data.confirmed }))
    .catch(error => Promise.reject(error))
});

export const resetPassword = model => ({
  type: RESET_PASSWORD,
  payload: axios.post('/account/resetpassword', model)
    .catch(error => Promise.reject(error))
});

export const reSendConfirmation = model => ({
  type: RESEND_CONFIRMATION,
  payload: axios.post('/account/resendconfirmation', model)
    .catch(error => Promise.reject(error))
});

export const changePassword = model => (dispatch) => {
  const response = dispatch({
    type: CHANGE_PASSWORD,
    payload: axios.post('/account/resetpassword/renew', model)
      .then(json => json.data)
      .catch(error => Promise.reject(error))
  });

  response.then((data) => {
    dispatch(changeState(false));
    if (data.value.success) {
      addAxiosToken(data.value.token);
      dispatch(loginData(data.value));
    }
  });

  return Promise.resolve(response);
};
