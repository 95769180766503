import axios from 'axios';

export const CREATE_MICROSITE = 'CREATE_MICROSITE';
export const GET_MICROSITES = 'GET_MICROSITES';
export const EDIT_MICROSITE = 'EDIT_MICROSITE';
export const GET_MICROSITE_BY_ID = 'GET_MICROSITE_BY_ID';
export const ACTIVE_MICROSITE = 'ACTIVE_MICROSITE';

export const getAllMicrosites = () => ({
  type: GET_MICROSITES,
  payload: axios
    .get('/microsite/')
    .then(response => ({ microsites: response.data }))
    .catch(error => Promise.reject(error))
});

export const getMicrositeById = id => ({
  type: GET_MICROSITE_BY_ID,
  payload: axios
    .get(`/microsite/${id}`)
    .then(response => ({ microsite: response.data }))
    .catch(error => Promise.reject(error))
});

export const getMicrositeByName = accessLink => ({
  type: GET_MICROSITE_BY_ID,
  payload: axios
    .get(`/microsite/getByName/${accessLink}`)
    .then(response => ({ microsite: response.data }))
    .catch(error => Promise.reject(error))
});

export const createMicrosite = model => ({
  type: CREATE_MICROSITE,
  payload: axios
    .post('/microsite/create', model)
    .catch(error => Promise.reject(error))
});

export const editMicrosite = (model, id) => ({
  type: EDIT_MICROSITE,
  payload: axios
    .put('/microsite/', { ...model, id })
    .catch(error => Promise.reject(error))
});

export const activeMicrosite= id => ({
  type: ACTIVE_MICROSITE,
  payload: axios
    .put(`/microsite/active/${id}/`)
    .then(response => ({ messageResponse: response.data }))
    .catch(error => Promise.reject(error))
});
