import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pendingTag: { id: 'profile.tag.pending' },
  enabledTag: { id: 'profile.tag.enabled' },
  toCorrectTag: { id: 'profile.tag.toCorrect' },
  titleNoComplete: { id: 'profile.noComplete.title' },
  firstContentNoComplete: { id: 'profile.noComplete.firstContent' },
  secondContentNoComplete: { id: 'profile.noComplete.secondContent' },
  buttonNoComplete: { id: 'profile.noComplete.button' },
  myDataTab: { id: 'profile.complete.tab.myData' },
  myInvestsTab: { id: 'profile.complete.tab.myInvests' },

  basicDateTitle: { id: 'profile.complete.title.basicDate' },
  name: { id: 'profile.complete.text.name' },
  mpUserName: { id: 'profile.complete.text.mpUserName' },
  lastName: { id: 'profile.complete.text.lastName' },
  payPalUser: { id: 'profile.complete.text.payPalUser' },
  floorText: { id: 'profile.complete.text.floor' },
  departmentText: { id: 'profile.complete.text.department' },
  birthdateText: { id: 'profile.complete.text.birthdate' },
  secondaryPhoneText: { id: 'profile.complete.text.secondaryPhone' },
  specialCharacter: { id: 'validate.specialCharacter' },
  accountTypeCA: { id: 'profile.complete.text.accountTypeCA' },
  accountTypeCC: { id: 'profile.complete.text.accountTypeCC' },
  invalidEmail: {
    id: 'general.validations.invalidEmail',
    defaultMessage: 'El mail ingresado no es válido'
  },

  bankDataTitle: { id: 'profile.complete.title.bankData' },
  accountTypeCAText: { id: 'profile.complete.text.accountTypeCA' },
  accountTypeCCText: { id: 'profile.complete.text.accountTypeCC' },
  cuitText: { id: 'profile.complete.text.cuit' },
  headlineText: { id: 'profile.complete.text.headline' },
  cbuText: { id: 'profile.complete.text.cbu' },
  numberAccountText: { id: 'profile.complete.text.numberAccount' },
  cbuAliasText: { id: 'profile.complete.text.cbuAlias' },
  typeAccount: { id: 'profile.complete.text.typeAccount' },
  currency: { id: 'project.create.input.currency' },
  dolar: { id: 'project.create.option.dolar' },
  pesos: { id: 'project.create.option.pesos' },
  sendText: { id: 'profile.complete.text.send' },

  requiredField: { id: 'general.validations.requiredField' },
  maxCharacters: { id: 'general.validations.maxCharacters' },
  minCharacters: { id: 'general.validations.minCharacters' },
  selectDate: { id: 'general.selectDate' },
  numericCamp: { id: 'validate.numericCamp' },
  invalidCuit: { id: 'validate.invalidCuit' },
  invalidCBU: { id: 'validate.invalidCBU' },
  duplicatedCBU: { id: 'admin.user.create.ducplicatedCBU' },
  invalidCBUAlias: { id: 'validate.invalidCBUAlias' },
  duplicatedCBUAlias: { id: 'admin.user.create.duplicatedCBUAlias' },
  validateAge: { id: 'validate.validateAge' },
  validURL: { id: 'validate.validURL' },
  selectValidAddress: { id: 'validate.selectValidAddress' },
  errorSelectAddress: { id: 'validate.errorSelectAddress' },
  placeholderLocation: { id: 'register.data.placeholderLocation' },

  nameProyect: { id: 'profile.myInvestments.title.nameProyect' },
  invests: { id: 'profile.myInvestments.subTitle.invests' },
  return: { id: 'profile.myInvestments.subTitle.return' },
  amount: { id: 'profile.myInvestments.table.amount' },
  date: { id: 'profile.myInvestments.table.date' },
  status: { id: 'profile.myInvestments.table.status' },
  percentage: { id: 'profile.myInvestments.table.percentage' },
  note: { id: 'profile.myInvestments.table.note' },
  allCamp: { id: 'profile.myInvestments.filter.all' },
  titleAlert: { id: 'profile.myInvestments.alert.title' },
  descriptionAlert: { id: 'profile.myInvestments.alert.description' },

  pending: { id: 'admin.transactions.status.pending' },
  expired: { id: 'admin.transactions.status.expired' },
  accredited: { id: 'admin.transactions.status.accredited' },
  released: { id: 'admin.transactions.status.released' },
  rejectedRelease: { id: 'admin.transactions.status.noReleased' },
  returned: { id: 'admin.transactions.status.refunded' },
  returnedWithErrors: { id: 'admin.transactions.table.card.returnedWithErrors' },
  error: { id: 'admin.transactions.status.error' },
  returnedReject: { id: 'admin.transactions.status.noRefunded' },
  onBindCall: { id: 'admin.transactions.status.errorBind' },

  pendingReturns: { id: 'admin.transactions.table.card.pending' },
  onBindCallReturns: { id: 'admin.transactions.table.card.onBindCallReturnAndRelease' },
  returnedReturns: { id: 'admin.transactions.table.card.released' },
  rejectedReturns: { id: 'admin.transactions.table.card.rejected' },
  errorReturns: { id: 'admin.transactions.table.card.error' },
  returnedWithErrorsReturns: { id: 'admin.transactions.table.card.returnedWithErrors' },
  rejectWithErrorsReturns: { id: 'admin.transactions.table.card.rejectWithErrors' },
});

export default messages;
