/* eslint react/style-prop-object: 0 */
import React, { Component } from 'react';
import moment from 'moment';
import { injectIntl, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  Layout, Row, Col, Button, Progress, Timeline, Spin, Icon
} from 'antd';
import calculateSize from 'calculate-size';

import { getProjectById } from '../../../actions/projects';
import Slider from './slider';
import SupportProject from './supportProyect';

import '../../commons/css/project.css';
import './homeProject.css';

import messages from '../../admin/projects/create/culture/messages';
// import ProjectDetails from './projectDetails';
// import ContactForm from '../commons/contactForm';
import RemoteImage from '../../commons/remoteImage';

const { Content } = Layout;

// const rewards = [
//   {
//     "createdAt":"2019-09-12T18:25:41.058Z",
//     "_id":"5d5ede258bc5e800168f0184",
//     "title":"Recompensa 1",
//     "description":"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat null. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
//     "amount": 100
//   },
//   {
//     "createdAt":"2019-09-15T18:25:41.058Z",
//     "_id":"5d5ede258bc5e800168f0185",
//     "title":"Recompensa 2",
//     "description":"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat null. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
//     "amount": 200
//   },
//   {
//     "createdAt":"2019-09-18T18:25:41.058Z",
//     "_id":"5d5ede258bc5e800168f0186",
//     "title":"Recompensa 3",
//     "description":"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat null. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
//     "amount": 300
//   },
//   {
//     "createdAt":"2019-09-30T18:25:41.058Z",
//     "_id":"5d5ede258bc5e800168f0187",
//     "title":"Recompensa 4",
//     "description":"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat null. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
//     "amount": 400
//   }
// ];

const Container = styled.div`
  & {
    background: #fff;
    min-height: 500px;
  }

  & .main-row {
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #d1d1d1;
    background-color: #ffffff;
    padding: 1.5rem;
  }

  & .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  & .project-header {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  & .project-header h1 {
    font-family: Roboto;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: left;
    margin: 0 10px;
    margin-top: 15px;
  }

  & .project-header h2 {
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #b7b7b7;
    text-transform: uppercase;
  }

  & .project-header p {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 1rem;
  }

  & .project-header h3 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #5f5d5d;
  }

  & .project-header .amountLabel {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #5f5d5d;
    margin: 0;
  }

  & .project-header .currencyLabel {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #5f5d5d;
  }

  & .project-header .progressMetadata {
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  & .project-header .progressMetadata span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #5f5d5d;
  }

  & .project-header .progressMetadata span:last-child {
    margin-left: 2rem;
  }

  & .project-header .progressMetadata b {
    font-size: 18px;
    margin-right: 5px;
  }

  & #sendBtn {
    height: 56px;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 1.2rem;
  }

  & #formTitle {
    font-family: Roboto;
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    margin-bottom: 3rem;
    margin-top: 9rem;
  }

  @media (max-width: 768px) {
    & {
      padding-right: 10px;
      padding-left: 10px;
      margin: 0;
    }

    & .project-header {
      margin-top: 5rem;
      justify-content: flex-start;
    }

    & .project-header .progressMetadata {
      display: inline-grid;
    }

    & .project-header .progressMetadata span:last-child {
      margin-left: 0 !important;
    }

    & .recaudado{
      margin-top: 1rem;
    }
   
  }
`;

const CustomTimeline = styled.div`
  & {
    margin-top: 2.5rem;
  }

  .hideOnDesktop {
    display: none;
  }

  .hideOnMobile {
    display: block;
  }

  & .hideOnMobile .ant-timeline {
    display: flex;
  }

  & .hideOnDesktop .ant-timeline .ant-timeline-item-head {
    top: 50%;
    transform: translateY(-50%);
  }

  & .hideOnDesktop .ant-timeline .ant-timeline-item-tail {
    height: 100% !important;
    display: block !important;
  }

  & .hideOnDesktop p,
  & .hideOnDesktop h4 {
    text-align: center;
  }

  .custom-timeline-item {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .custom-timeline-item .ant-timeline-item-head {
    width: 20px;
    height: 20px;
    left: 50% !important;
    transform: translateX(-50%) !important;
    margin-left: 0px !important;
  }

  .custom-timeline-item .ant-timeline-item-tail {
    display: block !important;
    left: 0px !important;
    top: auto !important;
    height: 3px !important;
    width: 100% !important;
  }

  .custom-timeline-item .ant-timeline-item-content {
    flex: 1;
    left: 0 !important;
    text-align: center !important;
    top: 0 !important;
    margin: 0 !important;
  }

  @media (max-width: 768px) {
    .hideOnMobile {
      display: none;
    }

    .hideOnDesktop {
      display: block;
    }

    .custom-timeline-item {
      margin-left: 15px;
    }

    .custom-timeline-item .ant-timeline-item-head {
      margin-left: -5px !important;
    }
  }
`;

const CustomTimelineItem = styled(Timeline.Item)`
  &.ant-timeline-item-left .ant-timeline-item-content {
    padding-bottom: calc(170px + ${props => (props.size ? props.size : 0)}px);
  }

  &.ant-timeline-item-right .ant-timeline-item-content {
    padding-top: calc(170px + ${props => (props.size ? props.size : 0)}px);
  }

  @media (max-width: 992px) {
    & h4 {
      font-size: 16px !important;
    }

    & p {
      font-size: 12px !important;
    }
  }
`;

// const TagsContainer = styled.div`
//   & {
//     display: flex;
//     flex-wrap: wrap;
//   }

//   & .ant-tag {
//     height: 25px;
//     border-radius: 4px;
//     border: solid 1px #48dd98;
//     font-size: 14px;
//     font-weight: normal;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: 1.5;
//     letter-spacing: normal;
//     text-align: left;
//     color: #48dd98;
//     background-color: transparent;
//     display: flex;
//     justify-content: center;
//     text-transform: uppercase;
//   }

//   @media (max-width: 992px) {
//     & .ant-tag {
//       margin-top: 1rem;
//     }
//   }
// `;

// const ProjectCharacteristics = styled.div`
//   border-radius: 4px;
//   background-color: #ededed;
//   padding: 1rem;
//   margin-top: 8rem;
// `;

const CampaignProgressContainer = styled.div`
  & p {
    margin: 0 !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.88 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #464646 !important;
  }

  & span.amount {
    font-family: Roboto;
    font-size: 28px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.54;
    letter-spacing: normal;
    text-align: left;
    margin-right: 5px;
  }
`;

const CampaignDescriptionSection = styled.div`
  & {
    padding: 4rem 2rem;
  }

  & h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.54px;
    text-align: left;
    color: #ffffff;
  }

  & p {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
`;

// const ProjectCharacteristicItem = styled.div`
//   & {
//     display: flex;
//     justify-content: space-between;
//     font-family: 'Roboto';
//     font-size: 14px;
//     font-weight: 300;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: 1.18;
//     letter-spacing: normal;
//     text-align: left;
//     color: #2f2f2f;
//     padding: .5rem 0;
//   }
// `;

// const ProjectCharacteristicTitle = styled.div`
//   & {
//     display: flex;
//     justify-content: space-between;
//     font-family: 'Roboto';
//     font-size: 14px;
//     font-weight: 600;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: 1.33;
//     letter-spacing: normal;
//     text-align: left;
//     color: #2f2f2f;
//     text-transform: uppercase;
//     margin-bottom: 1.5rem;
//     padding: 0 1rem;
//   }
// `;

const RewardsContainer = styled.div`
  & {
    background-color: rgba(255, 255, 255, 0.21);
    padding: 2rem 1rem;
    margin-top: 2rem;
  }

  & .ant-col:first-child {
    padding-top: 0;
  }

  .reward-card {
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #d1d1d1;
    background-color: #ffffff;
    padding: 2rem;
    width: 100%;
  }

  .rewards-content {
    max-height: 500px;
    overflow-y: auto;
  }

  .rewards-content::-webkit-scrollbar {
    width: 8px;
  }

  .rewards-content::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.39);
  }

  & h2 {
    font-family: Roboto;
    font-size: 20.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }

  & i {
    margin-right: 10px;
  }

  & .ant-col {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }

  & h3 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }

  & p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }

  & span.amount {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: left;
  }

  & button {
    display: block;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    color: #ffffff;
    margin-top: 1.5rem;
    width: 100%;
    height: 45px;
  }

  @media (max-width: 992px) {
    & {
      padding: 2rem 0px;
    }

    & .rewards-content {
      max-height: 100%;
    }
  }
`;

const AdvancesContainer = styled.div`
  & {
    margin-top: 5rem;
    margin-bottom: 5rem;
    font-family: Roboto;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #5f5d5d;
  }

  & h2 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.39;
    letter-spacing: normal;
    text-align: left;
  }

  & i {
    margin-right: 10px;
  }

  & h4 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
  }

  & p {
    font-size: 12px;
    margin: 0 auto ;
    max-width: 286px;
  }

  & p:first-child {
    font-size: 14px;
    margin-bottom: 0rem;
  }
`;

const MarginedContent = styled.div`
  & {
    margin: 0 150px;
  }

  @media (max-width: 768px) {
    & {
      margin: 0 10px;
    }
  }
`;

const ContainerSping = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const P = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin-top: 1rem;
  word-wrap: break-word
`;

const P2 = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  word-wrap: break-word;
  white-space: pre-line;
`;

class Project extends Component {
  constructor(props) {
    super(props);
    this.supportProyect = React.createRef();
    this.state = {
      daysLeft: 0,
      hourLeft: 0,
      minutesLeft: 0,
      isDate: false
    };
  }

  componentDidMount = () => {
    window.scroll({
      top: 0,
      behavior: 'auto'
    });

    this.props.getProjectById(this.props.match.params.id).then((result) => {
      //const campaign = (this.props.projects.project.campaigns || []).find(c => c.status === 'active');
      const campaign = result.value.project.campaigns[0];
      const currentDate = moment();
      const diffTime = moment(campaign.publishedAt)
        .add(campaign.days, 'days').diff(currentDate, 'seconds')

      this.setState({
        daysLeft: Math.floor(diffTime / 86400),
        hourLeft: Math.floor((diffTime % 86400) / 3600),
        minutesLeft: Math.floor(((diffTime % 86400) % 3600) / 60),
        isDate: (diffTime <= 0 ? 'finished' : false)
      });

      if (diffTime <= 0) {
        this.setState({ isDate: 'finished' });
      }

      if (this.props.match.params.investment) {
        this.props.handleChangeDrawer(campaign, result.value.project)
      }

    });



  };

  handleSupport = () => {
    this.supportProyect.current.showDrawer();
  };

  handleEndDate = (endDate) => {
    const newDate = moment(new Date());
    const endDateFormated = moment(new Date(endDate));
    const diff = endDateFormated.diff(newDate, 'days');
    return <span>{diff}</span>;
  };

  handleAdvancesDesktop = advances => (
    <Timeline mode="alternate">
      {advances
        ? advances
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map(advance => (
            <CustomTimelineItem
              className="custom-timeline-item primary"
              key={advance._id}
              size={
                calculateSize(
                  advance.description ? advance.description : '-',
                  {
                    font: 'Roboto',
                    fontSize: '14px',
                    lineHeight: '1.5',
                    width: '286px'
                  }
                ).height
              }
            >
              <div>
                <div className="img-container">
                  {(advance.image ? <RemoteImage url={advance.image.key} style={'imgDesktop'} /> : '' )}
                </div>
                <div>
                  <p>
                    {advance.createdAt
                      ? moment(new Date(advance.createdAt)).format(
                        'DD/MM/YYYY'
                      )
                      : '-'}
                  </p>
                  <h4>{advance.title ? advance.title : '-'}</h4>
                  <p>{advance.description ? advance.description : '-'}</p>
                </div>
              </div>
            </CustomTimelineItem>
          ))
        : ''}
    </Timeline>
  );

  handleAdvancesMobile = advances => (
    <Timeline mode="alternate">
      {advances
        ? advances
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map(advance => (
            // eslint-disable-next-line no-underscore-dangle
            <Timeline.Item className="primary" key={advance._id}>
              <div>
                <div>
                  <p>
                    {advance.createdAt
                      ? moment(new Date(advance.createdAt)).format(
                        'DD/MM/YYYY'
                      )
                      : '-'}
                  </p>
                  <h4>{advance.title ? advance.title : '-'}</h4>
                  <p>{advance.description ? advance.description : '-'}</p>
                </div>
                <div className="img-container">
                  {(advance.image ? <RemoteImage url={advance.image.key} /> : '' )}
                </div>
              </div>
            </Timeline.Item>
          ))
        : ''}
    </Timeline>
  );

  render() {
    const {
      projects, intl, handleChangeDrawer
    } = this.props;
    const { project } = projects;
    const {
      isDate, daysLeft, hourLeft, minutesLeft
    } = this.state;

    let campaign;

    const campaignActive = (project.campaigns || []).find(
      c => c.status === 'active' || c.status === 'pending'
    );
    const campaignFinished = (project.campaigns || []).find(
      c => c.status === 'finished'
    );

    if (campaignActive) campaign = campaignActive;
    else campaign = campaignFinished;
    const percent = campaign && campaign.totalRaised && campaign.objective
      ? (campaign.totalRaised * 100) / campaign.objective
      : 0;

    return (
      <Content>
        <SupportProject ref={this.supportProyect} />
        {!projects.isFetching ? (
          <Container style={{ padding: '0'}}>
            <div
              className="primary-bg"
              style={{ padding: '130px 1rem 1rem 1rem' }}
            >
              <MarginedContent>
                <Row className="main-row">
                  {project.name ? (
                    <Col md={24} style={{ marginBottom: '4rem' }}>
                      <Row gutter={24} type="flex">
                        <Col xs={24} md={24} lg={13}>
                          <Slider
                            className="sliderHome"
                            images={project.images || null}
                          />
                        </Col>
                        <Col md={24} lg={11}>
                          <div className="project-header">
                            <div>
                              <h2>
                                {`${project.developer.name} ${project.developer.lastName}`
                                  || ''}
                              </h2>
                              <h1 className="primary-text">{project.name}</h1>
                              <P>{project.briefDescription}</P>
                            </div>
                            <div>
                              <div>
                                <div className="progressMetadata">
                                  {isDate !== 'finished' ? (
                                    <React.Fragment>

                                      <span>
                                        <b style={{ fontSize: 20 }}> {daysLeft} {intl.formatMessage(messages.day)}{daysLeft !== 1 ? 's' : ''}{' '}</b>
                                        <b style={{ fontSize: 20 }}> {hourLeft} hs</b>
                                        <b style={{ fontSize: 20 }}> {minutesLeft} min</b>

                                        {intl.formatMessage(messages.remaining)}s
                                    </span>
                                    </React.Fragment>
                                  ) : (
                                      <span>
                                        <b>
                                          {intl.formatMessage(messages.cFinished)}
                                        </b>
                                      </span>
                                    )}
                                  <span>
                                    <b style={{ fontSize: 20 }}>
                                      {campaign.totalInvestors}
                                    </b>
                                    {campaign.totalInvestors === 1
                                      ? intl.formatMessage(messages.totalInvest)
                                      : intl.formatMessage(
                                        messages.totalInvests
                                      )}
                                  </span>
                                </div>
                                <CampaignProgressContainer>
                                  <p>
                                    <span className="amount warn-text">
                                      $
                                      <FormattedNumber
                                        value={campaign.totalRaised}
                                        currency="USD"
                                      />{' '}
                                      {project.country.currency}
                                    </span>{' '}
                                    <div className="recaudado">
                                    recaudados de $
                                    <FormattedNumber
                                      value={campaign.objective}
                                      currency="USD"
                                    />{' '}
                                    {project.country.currency}
                                    </div>
                                  </p>
                                </CampaignProgressContainer>
                                <Progress
                                  percent={percent || 0}
                                  showInfo={false}
                                  status="active"
                                  strokeWidth={12}
                                />
                                {campaign
                                  && campaign.status === 'active'
                                  && isDate !== 'finished' && (
                                    <Button
                                      block
                                      id="sendBtn"
                                      type="primary"
                                      onClick={() => handleChangeDrawer(campaign, project)
                                      }
                                    >
                                      APORTAR
                                    </Button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                      <Col md={24} style={{ marginBottom: '2rem' }}>
                        <div
                          style={{
                            width: '100%',
                            height: 400,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Spin size="large" />
                        </div>
                      </Col>
                    )}
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={14}>
                    <CampaignDescriptionSection>
                      <h2>DESCRIPCIÓN DEL VENDEDOR</h2>
                      <P2>{project.completeDescription}</P2>
                    </CampaignDescriptionSection>
                  </Col>
                  <Col md={24} lg={10}>
                    {campaign && campaign.rewards.length > 0 && (
                      <RewardsContainer>
                        <h2>Recompensas</h2>
                        <Row
                          type="flex"
                          justify="center"
                          className="rewards-content"
                        >
                          {campaign.rewards.map(reward => {
                            if (reward.active) {
                              return <Col xs={24} key={reward.title}>
                              <div className="reward-card">
                                <div>
                                  <h3>{reward.title}</h3>
                                  <p>{reward.description}</p>
                                </div>
                                <div>
                                  <span className="amount primary-text">
                                    $
                                  <FormattedNumber
                                      value={reward.amount}
                                      currency="USD"
                                    />{' '}
                                    {project.country.currency}
                                  </span>
                                  {campaign
                                    && campaign.status === 'active'
                                    && isDate !== 'finished' && (
                                      <Button
                                        type="primary"
                                        onClick={() => handleChangeDrawer(
                                          campaign,
                                          project,
                                          reward
                                        )
                                        }
                                      >
                                        APORTAR
                                      </Button>
                                    )}
                                </div>
                              </div>
                            </Col>
                            }else{
                              return ''
                            }

                          })}
                        </Row>
                      </RewardsContainer>
                    )}
                  </Col>
                </Row>
              </MarginedContent>
            </div>
            <MarginedContent>
              <Row>
                <Col md={24}>
                  {project.advances && project.advances.length > 0 && (
                    <AdvancesContainer>
                      <h2 className="primary-text">
                        <Icon type="calendar" className="primary-text" />
                        Seguimiento
                      </h2>
                      <CustomTimeline>
                        <div className="hideOnMobile">
                          {this.handleAdvancesDesktop(project.advances)}
                        </div>
                        <div className="hideOnDesktop">
                          {this.handleAdvancesMobile(project.advances)}
                        </div>
                      </CustomTimeline>
                    </AdvancesContainer>
                  )}
                </Col>
              </Row>
            </MarginedContent>
          </Container>
        ) : (
            <div style={{ height: '100vh', marginTop: 150 }}>
              <Row>
                <Col md={24}>
                  <ContainerSping>
                    <Spin size="large" />
                  </ContainerSping>
                </Col>
              </Row>
            </div>
          )}
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects,
  user: state.user
});

const mapDispatchToProps = dispatch => bindActionCreators({ getProjectById }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Project, { withRef: false }));
