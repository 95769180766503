import React, { Component } from 'react';
import update from 'react-addons-update'; 
import { Icon } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import '../../commons/css/slider.css';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactPlayer from 'react-player';
import RemoteImage from '../../commons/remoteImage';
import RemoteVideo from '../../commons/remoteVideo';

const ThumbnailsContainer = styled.div`
  & {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0px;
  }
`;

const Thumbnail = styled.li`
  & {
    transition: border 0.15s ease-in;
    display: inline-block;
    width: 85px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
    height: 60px;
    opacity: 0.8;
    border: none;
  }

  & img {
    width: 100%;
    height: 100%;
    display: inline-block;
    pointer-events: none;
    vertical-align: top;
  }

  &.selected,
  &:hover {
    border: 3px solid #333;
    opacity: 1;
  }
`;

const ThumbnailPlayerContainer = styled.div`
  & {
    position: relative;
    width: 100%;
    height: 95%;
  }

  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: ${props => (props.thumbnail ? '16px' : '72px')};
    cursor: ${props => (props.thumbnail ? 'auto' : 'pointer')};
  }

  & i.hide {
    display: none;
  }

  & .player-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${props => props.thumbnail ? '#000' : 'rgb(237, 237, 237)'};
  }

  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 88% !important;
    margin-left: 6%;
  }

`;
class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      thumbs: [],
      currentIndex: 0,
      videoState: [],
      autoPlay: true
    };

    this.props.images.forEach((file, index) => {
      this.state.videoState.push({ autoPlay: true, index: index })
    });

  }

  onFetch = (fetchedUrl) => {
    if (!this.state.thumbs.some(thumb => thumb.index === fetchedUrl.index)) {
      this.setState(prevState => ({
        thumbs: prevState.thumbs.concat(fetchedUrl)
      }));
    }
  };

  onIndexChange = (index) => {
    
    let copyVideoState =  Object.assign(this.state.videoState)
    copyVideoState.forEach((element)=>{
      element.autoPlay = true;
    })

    this.setState({
      videoState: copyVideoState,
      currentIndex: index
    });

  };

  goToIndex = (index) => {

    let copyVideoState =  Object.assign(this.state.videoState)
    copyVideoState.forEach((element)=>{
      element.autoPlay = true;
    })
    
    this.setState({
      currentIndex: index,
      videoState: copyVideoState,
      autoPlay: true
    });
  };

  getFileExtension = file => file.slice(file.lastIndexOf('.'), file.length);

  isVideo = (file) => {
    const fileExtension = this.getFileExtension(file);
    return (
      fileExtension.includes('mp4')
      || fileExtension.includes('webm')
      || fileExtension.includes('ogv')
    );
  };

  playVideo = (i) => {
    this.setState({
      autoPlay: false,
      videoState: update(this.state.videoState, {
        [i]: {
          autoPlay: { $set: false },
          index: { $set: i }
        }
      })
    })

  };

  stopVideo = (i) => {

    let copyVideoState =  Object.assign(this.state.videoState)
    copyVideoState.forEach((element)=>{
      element.autoPlay = true;
    })

    this.setState({
      autoPlay: true,
      videoState: copyVideoState
    })
  };

  render() {
    const { autoPlay, videoState } = this.state;

    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          backgroundColor: '#ededed'
        }}
      >
        <Carousel
          autoPlay={autoPlay}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          className="dotsOnTop"
          styles={styles}
          infiniteLoop
          selectedItem={this.state.currentIndex}
          onChange={this.onIndexChange}
          interval={5000}
        >
          {this.props.images
            ? this.props.images.map((img, i) => {
              if (this.isVideo(img.name)) {
                return (
                  <ThumbnailPlayerContainer key={img.key}>
                  
                    <div className="player-wrapper">
                      <RemoteVideo
                        url={img.key}
                        name={img.name}
                        onFetch={this.onFetch}
                        index={i}
                        playing={!videoState[i].autoPlay}
                        onPause={()=>{this.stopVideo(i)}}
                        onEnded={()=>{this.stopVideo(i)}}
                        controls={true}
                      />
                    </div>
                  </ThumbnailPlayerContainer>
                );
              }
              return (
                <div key={img.key} style={{ height: '100%' }}>
                  <RemoteImage
                    url={img.key}
                    name={img.name}
                    onFetch={this.onFetch}
                    index={i}
                  />
                </div>
              );
            })
            : null}
        </Carousel>
        {this.state.thumbs.length > 1 && (
          <ThumbnailsContainer>
            {this.state.thumbs
              .sort((a, b) => a.index - b.index)
              .map((thumbnail, i) => (
                <Thumbnail
                  key={`slider_thumbnail_${i}`}
                  className={`${
                    this.state.currentIndex === i ? 'selected' : ''
                    }`}
                  onClick={() => this.goToIndex(i)}
                >
                  {this.isVideo(thumbnail.name) ? (
                    <ThumbnailPlayerContainer thumbnail>
                      <Icon type="play-circle" theme="filled" />
                      <div className="player-wrapper">
                        <ReactPlayer
                          url={thumbnail.url}
                          className="react-player"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </ThumbnailPlayerContainer>
                  ) : (
                      <img src={thumbnail.url} alt={`Slider_thumbnail${i}`} />
                    )}
                </Thumbnail>
              ))}
          </ThumbnailsContainer>
        )}
      </div>
    );
  }
}

export default Slider;
