import React from 'react';
import {
  Table, Button, Icon, Divider, Tag
} from 'antd';

const tags = {
  pending: 'orange',
  onBindCall: 'red',
  released: 'green',
  rejected: 'magenta',
  error: 'red',
  releasedWithErrors: 'red',
};

const DataTable = ({
  data,
  loading,
  intl,
  messages,
  // handleChangeModal,
  viewProject,
  handleChangeModalAcction
}) => {
  const columns = [{
    title: intl.formatMessage(messages.idTitle),
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => (a.id - b.id),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.projectName),
    dataIndex: 'projectName',
    key: 'projectName',
    sorter: (a, b) => a.projectName.localeCompare(b.projectName),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.currency),
    dataIndex: 'currency',
    key: 'currency',
    sorter: (a, b) => a.currency.localeCompare(b.currency),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.totalReturnAmount),
    dataIndex: 'totalReleaseAmount',
    key: 'totalReleaseAmount',
    sorter: (a, b) => a.totalReturnAmount.localeCompare(b.totalReturnAmount),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.statusTitle),
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
    sortDirections: ['descend', 'ascend'],
    // eslint-disable-next-line react/display-name
    render: (status) => {
      let statusTag = '';

      if (status === 'rejected') statusTag = 'rejectedRelease';
      else statusTag = status;

      return (
        <Tag color={tags[status]}>
          {intl.formatMessage(messages[statusTag])}
        </Tag>
      );
    },
  }, {
    title: intl.formatMessage(messages.actionTitle),
    key: 'action',
    dataIndex: '_id',
    // eslint-disable-next-line react/display-name
    render: (_id, record, index) => (
      <span>
        {(record.status === 'pending' || record.status === 'error') && (
          <React.Fragment>
            <Button
              className="buttonActions"
              title={intl.formatMessage(messages.approve)}
              onClick={() => handleChangeModalAcction('accept', _id, index)}
            >
              <Icon type="check-circle" />
            </Button>
            <Divider type="vertical" />
            <Button
              className="buttonActions"
              title={intl.formatMessage(messages.reject)}
              onClick={() => handleChangeModalAcction('reject', _id, index)}
            >
              <Icon type="close-circle" />
            </Button>
            <Divider type="vertical" />
          </React.Fragment>
        )}
        {/* <Button
          className="buttonActions"
          title={intl.formatMessage(messages.seeTransactions)}
          onClick={() => handleChangeModal(record.transactions)}
        >
          <Icon type="fund" />
        </Button>
        <Divider type="vertical" /> */}
        <Button
          className="buttonActions"
          title={intl.formatMessage(messages.seeProject)}
          onClick={() => viewProject(record.projectId)}
        >
          <Icon type="eye" />
        </Button>
      </span>
    )
  }];

  return (
    <Table dataSource={data} columns={columns} rowKey="_id" loading={loading} />
  );
};

export default DataTable;
