import { defineMessages } from 'react-intl';

const messages = defineMessages({
  goToPropiInvest: { id: 'admin.menuBar.goToPropiInvest' },
  projects: { id: 'admin.menuBar.projects' },
  micro: { id: 'admin.menuBar.micro' },
  users: { id: 'admin.menuBar.users' },
  notifications: { id: 'admin.menuBar.notifications' },
  fundRelease: { id: 'admin.menuBar.fundRelease' },
  returnFund: { id: 'admin.menuBar.returnFund' },
  english: { id: 'admin.menuBar.english' },
  spanish: { id: 'admin.menuBar.spanish' },
  language: { id: 'admin.menuBar.language' },
  transactions: { id: 'admin.menuBar.transactions' },
  countries: { id: 'admin.menuBar.countries' },
});

export default messages;
