import { defineMessages } from 'react-intl';

const messages = defineMessages({
  breadcrumbStart: { id: 'users.breadcrumb.start' },
  breadcrumbUsers: { id: 'users.breadcrumb.users' },
  newUser: { id: 'users.button.newUser' },
  accceptModal: { id: 'users.modal.acccept' },
  rejectModal: { id: 'users.modal.reject' },
  new: { id: 'users.table.name.new' },
  moderate: { id: 'users.table.name.moderate' },
  accepted: { id: 'users.table.name.accepted' },
  rejected: { id: 'users.table.name.rejected' },
  admin: { id: 'users.table.roles.admin' },
  developer: { id: 'users.table.roles.developer' },
  inversor: { id: 'users.table.roles.inversor' },
  idTitle: { id: 'users.table.title.id' },
  nameTitle: { id: 'users.table.title.name' },
  lastNameTitle: { id: 'users.table.title.lastName' },
  usernameTitle: { id: 'users.table.title.username' },
  stateTitle: { id: 'users.table.title.state' },
  rolesTitle: { id: 'users.table.title.roles' },
  actionTitle: { id: 'users.table.title.action' },
  viewEditButton: { id: 'users.table.button.viewEdit' },
  acceptedButton: { id: 'users.table.button.accepted' },
  rejectedButton: { id: 'users.table.button.rejected' },
});

export default messages;
