import React from 'react';
import { Layout, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from './micrositelogo.png';

import { signoutUser } from '../../../../actions/user';

const { Header } = Layout;

const styles = {
  header: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#002140'
  },
  image: {
    // width: 64,
    height: 30,
    marginLeft: 10
  },
};

const HeaderComponent = ({ signout }) => (
  <Header style={styles.header}>
    <img src={logo} alt="logo" style={styles.image} />
    <Button
      icon="logout"
      size="large"
      type="danger"
      style={{ marginRight: 15, width: 55 }}
      onClick={signout}
    />
  </Header>
);

const mapDispatchToProps = dispatch => bindActionCreators({
  signout: signoutUser,
}, dispatch);

export default connect(null, mapDispatchToProps)(HeaderComponent);
