import React from 'react';
import styled from 'styled-components';
import { Row, Icon, Card } from 'antd';

import InputComponent from '../commons/inputComponent';

const TypographyTitle = styled.div`
  opacity: 0.62;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #5c5c5c;
`;

const ContainerDataAndIcon = styled.div`
  margin-top: 28.5px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
`;


const IconContainer = styled(Icon)`
  object-fit: contain;
  width: 21px;
  height: 21px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 18px;
  margin-bottom: 5px;
  cursor: ${props => props.pointer};
`;

const IconAuditContainer = styled(Icon)`
  object-fit: contain;
  margin-right: 7px;
  font-size: 18px;
  color: #5c5c5c;
  opacity: 0.7;
`;

const Title = styled.div`
  margin-bottom: 30px;
  font-size: 22px;
  color: #7d5ef0;
  font-weight: 600;
  font-weight: bold;
  font-family: Roboto-BolD;
`;

const myDataUserContainer = ({
  userInfo,
  intl,
  messages,
  handleChange
}) => (
  <Card style={{ margin: 20, flex: 1 }}>
    <Title>
      {intl.formatMessage(messages.myDataTab)}
    </Title>
    <ContainerDataAndIcon>
      <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
        <IconAuditContainer type="audit" />
        <TypographyTitle>{intl.formatMessage(messages.basicDateTitle)}</TypographyTitle>
      </div>
      <IconContainer
        type="edit"
        onClick={userInfo.state !== 'moderate' ? handleChange : undefined}
        pointer={(userInfo.state === 'moderate' ? 'intial' : 'pointer')}
      />
    </ContainerDataAndIcon>

    <Row gutter={24} style={{ marginTop: 40 }}>
      <InputComponent
        title={intl.formatMessage(messages.name)}
        text={userInfo.name || '-'}
      />
      <InputComponent
        title={intl.formatMessage(messages.lastName)}
        text={userInfo.lastName || '-'}
      />
      <InputComponent
        title={intl.formatMessage(messages.mpUserName)}
        text={userInfo.mpUserName || '-'}
      />
      <InputComponent
        title={intl.formatMessage(messages.payPalUser)}
        text={userInfo.payPalUser || '-'}
      />
    </Row>
  </Card>
);

export default myDataUserContainer;
