import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Layout, Row, Spin
} from 'antd';

import '../registration.css';
import { getDownloadUrl } from '../../../../service/firebaseStorageService';
import { getUserInfo } from '../../../../actions/user';
import { getTransactionsByUser } from '../../../../actions/transactions';

// import NoCompleteComponent from './noComplete';
import DataUser from './dataUser/dataUser';

import messages from './profileCulture';

const { Content } = Layout;

const ContainerLoading = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;


const InnerHeader = styled.div`
  & {
    width: 100%;
    position: relative;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & h1 {
    font-family: Roboto;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
`;



class Profile extends Component {
  state = { url: false, edit: false };

  componentDidMount() {
    window.scroll({ top: 0, behavior: 'auto' });

    const { user, get, getTransactions } = this.props;
    const { image } = user.userInfo;

    get(user.username);
    getTransactions();

    if (image) {
      getDownloadUrl(image.key).then(url => this.setState({ url }));
    }
  }

  handleChangeEdit = () => {
    this.setState({ edit: true });
  };

  render() {
    const { user, intl, transactions } = this.props;
    const {
      edit
    } = this.state;

    if (user.isFetching || transactions.isFetching) {
      return (
        <div style={{ background: '#fff', paddingTop: 50, paddingBottom: 50 }}>
          <ContainerLoading>
            <Spin size="large" />
          </ContainerLoading>
        </div>
      );
    }

    return (
      <Content style={{ background: '#fff', paddingBottom: 100 }}>
        <InnerHeader className="primary-bg">
          <h1>Mi Perfil</h1>
        </InnerHeader>
        <Row
          type="flex"
          justify="center"
          style={{ marginTop: 0, alignItems: 'unset' }}
        >
          {/* {user.state === 'new'
            ? (<NoCompleteComponent intl={intl} messages={messages} />)
            : ( */}
          <DataUser
            intl={intl}
            messages={messages}
            userInfo={user.userInfo}
            edit={edit}
            projects={transactions.userTransactions}
            handleChange={this.handleChangeEdit}
          />
          {/* )} */}
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    get: getUserInfo,
    getTransactions: getTransactionsByUser
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Profile));
