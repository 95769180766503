import React from 'react';
import { Form } from 'antd';
import styled from 'styled-components';
import { FormItemInput, FormItemInputTooltipColor, UploadImage, FormItemTextArea, FormItemRadio } from '../../../commons/formComponents/formComponents';

import {
  specialCharacter,
  hexColor,
  onlyTextAndNumber
} from '../../../commons/validations';

const Title = styled.div`
  border-bottom: 0.5px solid #d1d1d1;
  margin-bottom: 25px;
  margin-top: ${props => props.first ? 0 : '40px'};
  font-family: 'Roboto';
  color: #808080;
  font-weight: 500;
  font-size: 1.5em;
`;

const FormMicrosite = ({
  form,
  intl,
  messages,
  logoImageEdit,
  micrositeByID,
  isEdit,
  handleFeaturedChange,
  bankCount
}) => (
  <React.Fragment>
    <FormItemInput
      FormComponent={Form}
      form={form}
      label={intl.formatMessage(messages.name)}
      field="name"
      initialValue={micrositeByID.name}
      rules={[
        { required: true, message: intl.formatMessage(messages.requiredField) },
        { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
        { validator: specialCharacter(form, intl.formatMessage(messages.specialCharacter)) }
      ]}
    />
    <FormItemInputTooltipColor
      FormComponent={Form}
      form={form}
      label={intl.formatMessage(messages.color)}
      field="color"
      initialValue={
        micrositeByID.color
      }
      changeHandler={({ color }) => form.setFieldsValue({ color })}
      rules={[
        { required: true, message: intl.formatMessage(messages.requiredField) },
        { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
        { validator: hexColor(form, intl.formatMessage(messages.hexColor)) }
      ]}
    />
    <FormItemInput
      FormComponent={Form}
      form={form}
      label={intl.formatMessage(messages.linkToAccess)}
      field="accessLink"
      addonBefore={`${window.location.host}/microsite/`}
      initialValue={micrositeByID.accessLink}
      rules={[
        { required: true, message: intl.formatMessage(messages.requiredField) },
        { max: 15, message: `${intl.formatMessage(messages.maxCharacters)} 15` },
        { validator: onlyTextAndNumber(form, intl.formatMessage(messages.onlyText)) }
      ]}
    />
    {(!isEdit || logoImageEdit) && (
      <UploadImage
        FormComponent={Form}
        intl={intl}
        messages={messages}
        form={form}
        label={intl.formatMessage(messages.logoImage)}
        field="logoImage"
        defaultFileList={logoImageEdit || []}
        name={intl.formatMessage(messages.uploadImage)}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) }
        ]}
        unique
        setDidMount
        value={logoImageEdit}
        maxImagesLength={1}
      />
    )}

     <FormItemInput
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.MPAccessTokenTable)}
        field="MPAccessToken"
        initialValue={micrositeByID.MPAccessToken}
        rules={[
          { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
        ]}
      />



    
    <FormItemTextArea
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.terms)}
        field="terms"
        initialValue={micrositeByID.terms}
        minRows={3}
        maxRows={600}
        rules={[
         ]}
      />  

      <Title>Datos Bancarios</Title>

      <FormItemRadio
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.bank)}
        field="bank"
        initialValue={micrositeByID.bank}
        options={[
          { key: 1, name: intl.formatMessage(messages.yes), value: true },
          { key: 2, name: intl.formatMessage(messages.no), value: false }
        ]}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) },
        ]}
        onChange= {handleFeaturedChange}
      />

      <FormItemInput
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.bankTit)}
        field="bankTit"
        initialValue={micrositeByID.bankTit}
        rules={[
          { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
          { required: (bankCount || micrositeByID.BankCount ? true : false), message: intl.formatMessage(messages.requiredField) }
        ]}
      />
      <FormItemInput
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.bankCuit)}
        field="bankCuit"
        initialValue={micrositeByID.bankCuit}
        rules={[
          { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
          { required: (bankCount || micrositeByID.BankCount ? true : false), message: intl.formatMessage(messages.requiredField) }
        ]}
      />
      <FormItemInput
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.bankCbu)}
        field="bankCbu"
        initialValue={micrositeByID.bankCbu}
        rules={[
          { max: 22, message: `${intl.formatMessage(messages.maxCharacters)} 22` },
          { required: (bankCount || micrositeByID.BankCount ? true : false), message: intl.formatMessage(messages.requiredField) }
        ]}
      />
      <FormItemInput
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.bankCount)}
        field="bankCount"
        initialValue={micrositeByID.bankCount}
        rules={[
          { required: (bankCount || micrositeByID.BankCount ? true : false), message: intl.formatMessage(messages.requiredField) }
        ]}
      />

      <FormItemInput
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.bankType)}
        field="bankType"
        initialValue={micrositeByID.bankType}
        rules={[
          { required: (bankCount || micrositeByID.BankCount ? true : false), message: intl.formatMessage(messages.requiredField) }
        ]}
      />
  </React.Fragment>
);

export default FormMicrosite;
