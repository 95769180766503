import { defineMessages } from 'react-intl';

const messages = defineMessages({
  filterTitle: { id: 'project.filter.title' },
  active: { id: 'project.filter.option.active' },
  finished: { id: 'project.filter.option.finished' },
  ecology: { id: 'project.create.option.ecology' },
  social: { id: 'project.create.option.social' },
  tripleImpact: { id: 'project.create.option.tripleImpact' },
  collaborativeEconomy: { id: 'project.create.option.collaborativeEconomy' },
  inclusion: { id: 'project.create.option.inclusion' },
  education: { id: 'project.create.option.education' },
});

export default messages;
