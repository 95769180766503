import React, { useState } from "react";
import shortid from "shortid";
import { Row, Button, Icon, Form, Checkbox, Modal } from "antd";
import styled from "styled-components";

import { FormItemSelect } from "../formComponents/formComponents";
import logoMp from '../../../images/mercado_pago.png';

const LineInput = styled.div`
  flex: 1;
  border-bottom: 1px solid #d1d1d1;
  margin: 10px;
`;

const ProjectTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.85;
  letter-spacing: normal;
  color: #6e48fa;
`;

const RewardsTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #6e48fa;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CostTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

const AmountTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #6e48fa;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-top: 30px;
`;

const NextButton = styled(Button)`
  { 
    width: 75%;
    height: 75px;
    border-radius: 5px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #6e48fa;
    margin-top: 30px;
    margin-left: 13%;
  }

  & .logoMp {
    background-image: url(${logoMp});
    height: 60%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

const FinishButton = styled.div`
  height: 56px;
  border-radius: 5px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 1.2rem;
  background-color: #3eda6e;
  border-color: #3eda6e;
  padding-top: 4%;
  cursor: pointer;
`;

export default function Test({
  form,
  project,
  rewardDefault,
  intl,
  messages,
  mercadoPago,
  transfer,
  isLoading,
  isLoadingTf
}) {

  const [terms, setTerms] = useState(0);
  const [shortidState, setShortId] = useState(0);
  const [termsModal, setTermsModal] = useState(false);
  const [cuenta, setCuenta] = useState(false);
  const { name, campaigns, microsite } = project;

  let rewardInfo = rewardDefault;

  const handleSubmit = e => {
    e.preventDefault();


    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        mercadoPago(values).then(resp => {
          window.open(resp.value.response.data, "_self");
        });
      }
    });
  };

  if (form.getFieldValue("rewardId")) {
    campaigns[0].rewards.forEach(reward => {
      if (reward._id === form.getFieldValue("rewardId")) rewardInfo = reward;
    });
  }

  const rewardsOptions = () => {

    let rewardsActives = campaigns[0].rewards.reduce(function (result, reward) {
      if (reward.active === true) {
        result.push({
          key: 1,
          name: reward.title,
          value: reward._id
        })
      }
      return result;
    }, []);

    return rewardsActives;

  }

  const checkBox = (e) => {

    form.validateFieldsAndScroll((err, values) => {
      console.log(err, 'err')
      if (!err) {
        switch (e.target.checked) {
          case true:
            setTerms(1);
            break;
          case false:
            setTerms(0);
            break;
          default:
            break;
        }
      }
    });


  }

  const showModal = () => {
    setTermsModal(!termsModal);
  };

  const showCount = () => {

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const shortIdRef = shortid.generate().substr(0, 8);
        setShortId(shortIdRef);
        values.idRef = shortIdRef;
        transfer(values).then(resp => {
          setCuenta(!cuenta);
        });
      }
    });


  };

  const finishTransfer = () => {
    window.location.reload();
  }

  return (


    <React.Fragment>

      <Form onSubmit={handleSubmit}>
        <ProjectTitle>{name}</ProjectTitle>
        <Row className={(terms === 1 ? 'display-none' : '')} style={{ marginTop: 12, padding: "0 34px" }}>
          {/* <Row span={24} type="flex" justify="center" style={{ marginBottom: 20 }}>
              <StepDot opacity="1" right="8px" />
              <StepDot opacity="0.33" />
            </Row> */}

          <RewardsTitle>
            <Icon type="smile" theme="outlined" style={{ marginRight: 5 }} />
            {intl.formatMessage(messages.rewards)}
          </RewardsTitle>
          <FormItemSelect
            FormComponent={Form}
            form={form}
            placeholder={intl.formatMessage(messages.inputSelect)}
            field="rewardId"
            options={rewardsOptions()}
            initialValue={rewardDefault._id}
            rules={[
              {
                required: true,
                message: intl.formatMessage(messages.requiredField)
              }
            ]}
          />

          <h4>{rewardInfo.title}</h4>
          <p>{rewardInfo.description}</p>

          {rewardInfo && (
            <InputContainer>
              <CostTitle>{intl.formatMessage(messages.valueReward)}</CostTitle>
              <LineInput />
              <AmountTitle>${rewardInfo.amount} ARS</AmountTitle>
            </InputContainer>
          )}
        </Row>

        <Row className={(terms === 1 ? 'display-none' : '')} span={24} type="flex" justify="center">

          {(project.microsite && microsite.terms && microsite.terms.trim() !== '' ?
            <React.Fragment>
              <a onClick={showModal}>
                Leer Términos y Condiciones <br />
              </a>
            </React.Fragment> :
            <React.Fragment>
              <a target='_blank' href="/terminos-y-condiciones">
                Leer Términos y Condiciones <br />
              </a>
            </React.Fragment>)}



        </Row>

        <Row span={24} type="flex" justify="center" style={{ marginTop: 10 }}>

          <div className={(terms === 1 ? 'display-none' : '')}>
            <Checkbox onChange={checkBox} checked={terms == 1 ? true : false}>Acepto Términos y Condiciones </Checkbox>
          </div>
        </Row>

        {((project.microsite && project.microsite.bank && project.microsite.bank === true) ||
          (project.microsite && project.microsite.MPAccessToken && project.microsite.MPAccessToken.trim() != '') ?
          <React.Fragment>
            <Row style={{ marginTop: 12, padding: "0 34px" }}>

              <div className={(cuenta ? 'display-none' : '')} >
                <RewardsTitle className={(terms === 1 ? '' : 'display-none')} >
                  <Icon type="shopping-cart" theme="outlined" style={{ marginRight: 5 }} />
          Check Out
        </RewardsTitle>

                <NextButton className={(terms === 1 && project.microsite.MPAccessToken.trim() != '' ? '' : 'display-none')} htmlType="submit" loading={isLoading}>
                  <span className="logoMp"></span>
                </NextButton>

                <NextButton onClick={showCount} className={(terms === 1 && project.microsite.bank === true ? '' : 'display-none')} loading={isLoadingTf}>
                  Transferencia Bancaria
        </NextButton>

              </div>

              <div className={(cuenta ? '' : 'display-none')} >

                <RewardsTitle  >
                  <Icon type="shopping-cart" theme="outlined" style={{ marginRight: 5 }} />
            Datos para transferencia bancaria
            </RewardsTitle>

                <ul style={{ marginTop: '2%', width: '100%', listStyle: 'none' }}>
                  <li style={{ marginTop: 10 }}><strong>Titular:</strong> {project.microsite.bankTit}</li>
                  <li style={{ marginTop: 10 }}><strong>Cuit:</strong> {project.microsite.bankCuit}</li>
                  <li style={{ marginTop: 10 }}><strong>CBU:</strong> {project.microsite.bankCbu}</li>
                  <li style={{ marginTop: 10 }}><strong>N° Cuenta:</strong> {project.microsite.bankCount}</li>
                  <li style={{ marginTop: 10 }}><strong>Tipo:</strong> {project.microsite.bankType}</li>
                  <li style={{ marginTop: 10 }}><strong>Código Único:</strong> {shortidState}</li>
                  <li style={{ marginTop: 30 }}>
                        Por favor, toma nota del código único y no olvides agregarlo en el concepto de la transferencia en tu home banking para que podamos identificar tu aporte.
                  </li>
                </ul>

                <FinishButton onClick={finishTransfer} >
                  Finalizar
            </FinishButton>

              </div>

            </Row>

          </React.Fragment> :

          <React.Fragment >
            <Row className={(terms === 1 ? '' : 'display-none')} style={{ marginTop: 12, padding: "0 34px" }}>
              <RewardsTitle>
                <Icon type="shopping-cart" theme="outlined" style={{ marginRight: 5 }} />
        Check Out
        </RewardsTitle>

              <NextButton htmlType="submit" loading={isLoading}>
                <span className="logoMp"></span>

              </NextButton>
            </Row>
          </React.Fragment>

        )}
      </Form>


      <Modal
        title="Terminos y Condiciones"
        visible={termsModal}
        onOk={showModal}
        onCancel={showModal}
      >
        {(microsite && microsite.terms ? microsite.terms : '')}
      </Modal>

    </React.Fragment>

  );
}
