import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import {
  Row, Col, Select, Form, Tag, Icon, Button
} from 'antd';

const { Option } = Select;

const ContainerTags = styled(Tag)`
  margin-top: 15px;
  height: 45px;
  border-radius: 400px;
  border: solid 1px #707070;
  background-color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #5c5c5c;
  padding: 13px 20px;
`;

const TitleFilter = styled.div`
  font-family: Roboto;
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #585858;
  margin-bottom: 20px;
  margin-top: 60px;


`;
const ButtonDiv = styled.div`
  @media (max-width: 768px) {
    & button {
      margin-top: 1.5rem;
    }
  }
`;

class Projects extends Component {
  state = { tags: [], query: [] }

  handleSubmit = (e) => {
    const { filter, form, country } = this.props;
    const { query } = this.state;

    e.preventDefault();

    form.validateFieldsAndScroll(() => {
      let filterQuery = '';
      query.forEach(q => filterQuery = `${filterQuery}${q}`);
      filter(filterQuery, country).then(() => form.setFieldsValue({ status: undefined, category: undefined }));
    });
  }

  handleChange = (event) => {
    const { name, value } = JSON.parse(event);
    const { tags } = this.state;

    if (!tags.includes(name)) {
      this.setState(prevState => ({ tags: [...prevState.tags, name], query: [...prevState.query, value] }));
    }
  }

  handleRemove = (index) => {
    const { query } = this.state;

    const newQueryState = [
      ...query.slice(0, index),
      ...query.slice(index + 1),
    ];

    this.setState(prevState => ({
      tags: [
        ...prevState.tags.slice(0, index),
        ...prevState.tags.slice(index + 1),
      ],
      query: newQueryState
    }));
  }

  render() {
    const { tags } = this.state;
    const {
      intl,
      // messages,
      projectFilter,
      form,
      messagesFilter
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <TitleFilter><Icon type="filter" style={{ fontSize: 30, marginRight: 4 }} />{intl.formatMessage(messagesFilter.filterTitle)}</TitleFilter>
          <Row type="flex" gutter={{ lg: 32, md: 16, sm: 8 }} style={{ alignItems: 'center' }}>
            <Col lg={4} md={5} sm={5} xs={24}>
              <Form.Item>
                {getFieldDecorator('status')(
                  <Select
                    placeholder="Estado"
                    onChange={this.handleChange}
                    disabled={projectFilter.isFetchingProject}
                  >
                    <Option
                      value={JSON.stringify({
                        name: intl.formatMessage(messagesFilter.active),
                        value: '&campaigns.status=active'
                      })}
                    >
                      {intl.formatMessage(messagesFilter.active)}
                    </Option>
                    <Option
                      value={JSON.stringify({
                        name: intl.formatMessage(messagesFilter.finished),
                        value: '&campaigns.status=finished'
                      })}
                    >
                      {intl.formatMessage(messagesFilter.finished)}
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col lg={6} md={7} sm={7} xs={24}>
              <Form.Item>
                {getFieldDecorator('category')(
                  <Select
                    placeholder="Categoria"
                    onChange={this.handleChange}
                    disabled={projectFilter.isFetchingProject}
                  >
                    <Option
                      value={JSON.stringify({
                        name: intl.formatMessage(messagesFilter.ecology),
                        value: '&category=ecology'
                      })}
                    >
                      {intl.formatMessage(messagesFilter.ecology)}
                    </Option>
                    <Option
                      value={JSON.stringify({
                        name: intl.formatMessage(messagesFilter.social),
                        value: '&category=social'
                      })}
                    >
                      {intl.formatMessage(messagesFilter.social)}
                    </Option>
                    <Option
                      value={JSON.stringify({
                        name: intl.formatMessage(messagesFilter.tripleImpact),
                        value: '&category=tripleImpact'
                      })}
                    >
                      {intl.formatMessage(messagesFilter.tripleImpact)}
                    </Option>
                    <Option
                      value={JSON.stringify({
                        name: intl.formatMessage(messagesFilter.collaborativeEconomy),
                        value: '&category=collaborativeEconomy'
                      })}
                    >
                      {intl.formatMessage(messagesFilter.collaborativeEconomy)}
                    </Option>
                    <Option
                      value={JSON.stringify({
                        name: intl.formatMessage(messagesFilter.inclusion),
                        value: '&category=inclusion'
                      })}
                    >
                      {intl.formatMessage(messagesFilter.inclusion)}
                    </Option>
                    <Option
                      value={JSON.stringify({
                        name: intl.formatMessage(messagesFilter.education),
                        value: '&category=education'
                      })}
                    >
                      {intl.formatMessage(messagesFilter.education)}
                    </Option>

                  </Select>
                )}
              </Form.Item>
              {/* <FormItemInput
                FormComponent={Form}
                form={form}
                placeholder="Categoria"
                field="category"
              /> */}
            </Col>
            <Col lg={4} md={5} sm={5} xs={24}>
              <ButtonDiv>
                <Button
                  type="primary"
                  htmlType="submit"
                >Buscar
                </Button>
              </ButtonDiv>
            </Col>
          </Row>
        </Form>
        <Col>
          {tags.map((tag, i) => (<ContainerTags key={tag} closable onClose={() => this.handleRemove(i, tag)}>{tag}</ContainerTags>))}
        </Col>
      </React.Fragment>
    );
  }
}

const ProjectsFormFilter = Form.create({ name: 'formProjectFilter' })(Projects);

export default injectIntl(ProjectsFormFilter);
