import React, { Component } from 'react';
import {
  Form, Button, Icon, Card
} from 'antd';

import { FormItemInput, FormItemInputDecimal, FormItemTextArea, FormItemRadio } from '../../../../commons/formComponents/formComponents';
import { verifyMaxLength, verifyNumber } from '../../../../commons/validations';

class CreateReward extends Component {
  remove = (item) => {
    const { form, deleteReward, deleteRedux } = this.props;
    const rewardsKeys = form.getFieldValue('rewardsKeys');

    if (deleteRedux) deleteReward(item);

    form.setFieldsValue({ rewardsKeys: rewardsKeys.filter(key => key !== item) });
  };

  add = () => {
    const { form } = this.props;
    const rewardsKeys = form.getFieldValue('rewardsKeys');

    let largest = 0;

    for (let i = 0; i <= rewardsKeys.length - 1; i++) {
      if (rewardsKeys[i] > largest) {
        largest = rewardsKeys[i];
      }
    }

    const nextKeys = rewardsKeys.concat(largest + 1);

    form.setFieldsValue({ rewardsKeys: nextKeys });
  };

  render() {
    const {
      form, messages, intl, rewardsEdit
    } = this.props;

    form.getFieldDecorator('rewardsKeys', { initialValue: [] });

    const rewardsKeys = form.getFieldValue('rewardsKeys');

    const formItems = rewardsKeys.map((item, index) => {
      form.getFieldDecorator(`idA${item}`, {
        initialValue: { ...item }
      });

      return (
        <Card key={item} style={{ marginTop: 10, marginBottom: 15 }}>
          <div style={{ display: (rewardsEdit[index] && rewardsEdit[index].investments ? 'none' :'flex'), flex: 1, justifyContent: 'flex-end' }}>
            <Button type="danger" onClick={() => this.remove(item)}>
              <Icon type="delete" />
            </Button>
          </div>
          <FormItemInput
            FormComponent={Form}
            form={form}
            label={intl.formatMessage(messages.rewardsTitle)}
            field={`titleR${item}`}
            initialValue={rewardsEdit[index] ? rewardsEdit[index].title : null}
            rules={[
              { required: true, message: intl.formatMessage(messages.requiredField) },
              { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
            ]}
          />
          <FormItemTextArea
            FormComponent={Form}
            form={form}
            label={intl.formatMessage(messages.description)}
            field={`descriptionR${item}`}
            initialValue={rewardsEdit[index] ? rewardsEdit[index].description : null}
            minRows={5}
            maxRows={7}
            rules={[
              { required: true, message: intl.formatMessage(messages.requiredField) },
              { max: 500, message: `${intl.formatMessage(messages.maxCharacters)} 500` },
            ]}
          />
          <FormItemRadio
            FormComponent={Form}
            form={form}
            label={intl.formatMessage(messages.visibility)}
            field={`activeR${item}`}
            initialValue={rewardsEdit[index] ? rewardsEdit[index].active : null}
            options={[
              { key: 1, name: intl.formatMessage(messages.yes), value: true },
              { key: 2, name: intl.formatMessage(messages.no), value: false }
            ]}
            rules={[
              { required: true, message: intl.formatMessage(messages.requiredField) },
            ]}
          />
          {/* <FormItemInput
            FormComponent={Form}
            form={form}
            label={intl.formatMessage(messages.currency)}
            field={`currencyR${item}`}
            initialValue={rewardsEdit[index] ? rewardsEdit[index].currency : null}
            rules={[
              { required: true, message: intl.formatMessage(messages.requiredField) },
              { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
            ]}
          /> */}
          <FormItemInputDecimal
            FormComponent={Form}
            form={form}
            label={intl.formatMessage(messages.rewardsAmount)}
            field={`amountR${item}`}
            initialValue={rewardsEdit[index] ? rewardsEdit[index].amount : null}
            max={999999999999999}
            min={0}
            disabled={(rewardsEdit[index] && rewardsEdit[index].investments ? true : false)}
            rules={[
              { required: true, message: intl.formatMessage(messages.requiredField) },
              { validator: verifyMaxLength(form, 15, `${intl.formatMessage(messages.maxCharacters)} 15`) },
              { validator: verifyNumber(form, intl.formatMessage(messages.numericCamp)) }
            ]}
          />
        </Card>
      );
    });

    return (
      <div>
        {formItems}
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Button type="dashed" onClick={this.add} style={{ marginBottom: 16, marginTop: 16 }}>
            <Icon type="plus-circle" />
            {intl.formatMessage(messages.rewardsCreate)}
          </Button>
        </div>
      </div>
    );
  }
}

export default CreateReward;
