import React, { Component } from 'react';
import { Button } from 'antd';

class SupportStep2 extends Component {

    state = { visible: false };

    changeVisibility = () => {
        this.setState({
            visible: true
        });
    };

    render() {
        if (!this.state.visible) {
            return null;
        }
        return (
            <div className="supportStep2">
                <p>Recueda copiar el código para luego ingresarlo al momento de hacer la transeferencia</p>
                <h3>Código:</h3>
                <h4>DDSEHGDS32DHT</h4>
                <Button type="button" className="bt-support green">Copiar código</Button>
                <div className="bankData">
                    <p>Datos bancarios</p>
                    <p>Santander Río</p>
                    <p>Cuenta N° 0000000000000000</p>
                </div>
                <Button type="button" className="btn btn-download">Descargar pdf</Button>
            </div>
        );
    }
}

export default SupportStep2;
