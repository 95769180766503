import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  Layout, Row, Col, Button, Icon
} from 'antd';

import './home.css';
import '../../commons/css/slider.css';

import topBanner from '../../../images/web02.jpg';

import FeatureProjects from './featureProjects';

import messages from './cultureHome';
import InfoSlider from './infoSlider';

import firstButton from './icons/1.png';
import secondButton from './icons/2.png';
import thirdButton from './icons/3.png';
import fourButton from './icons/4.png';
import fiveButton from './icons/5.png';
import sixButton from './icons/6.png';

const { Content } = Layout;

const Container = styled.div`
  & {
    background: #fff;
    min-height: 500px;
  }

  & .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  & .firstButton {
    height: 200px;
    position: relative;
  }
  
  & .firstButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
      height: 90%;
    background-image: url(${firstButton});
        background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0px 5%;
  }


& .secondButton {
  height: 200px;
  position: relative;
}

& .secondButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${secondButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

& .thirdButton {
  height: 200px;
  position: relative;
}

& .thirdButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${thirdButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

& .fourButton {
  height: 200px;
  position: relative;
}

& .fourButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${fourButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

& .fiveButton {
  height: 200px;
  position: relative;
}

& .fiveButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${fiveButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

& .sixButton {
  height: 200px;
  position: relative;
}

& .sixButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${sixButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

  @media (max-width: 768px) {
    & {
      margin: 0;
    }
  }
`;

const TopBanner = styled.div`
  & {
    position: relative;
  }

  & .bg {
    width: 100%;
    height: 685px;
    position: relative;
  }

  & .bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${topBanner});
    background-size: cover;
    background-position: 74% 0;
  }

  & .banner-content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  & .banner-container {
    position: relative;
  }

  & h1 {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }

  & hr {
    width: 227px;
    border-color: rgba(255, 255, 255, 0.5);
  }

  & p {
    max-width: 566px;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 536px;
    margin-top: 5rem;
  }

  & .buttons > div:first-child {
    margin-right: 20px;
  }

  @media (max-width: 535px) {
    & .buttons {
      justify-content: center;
    }

    & .buttons > div:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

const MarginedContainer = styled.div`
  & {
    margin-top: 6rem;
    padding: 0 140.5px;
  }

  & .inner-content {
    display: flex;
  }

  & h2 {
    font-family: Roboto;
    font-size: 22.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
    margin-left: 2rem;
  }

  & h2.subtitle {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 55px;
    color: #3f069b;
  }

  & .separator {
    width: 90px;
    border-color: #3c0199;
    border-width: 0.75px;
    margin-bottom: 2rem;
  }

  & .description {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    max-width: 603px;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 536px;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  & .buttons > div:first-child {
    margin-right: 20px;
  }

  & #contactHeader {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  & .contact-title {
    display: flex;
    width: 100%;
    margin-bottom: 1.5em;
    align-items: center;
    justify-content: center;
  }

  & .contact-title h3 {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    margin: 0;
  }

  & .contact-title i {
    font-size: 24px;
    margin-right: 8px;
  }

  & .phones-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    font-family: 'Roboto';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    max-width: 315px;
  }

  & .phones-container > div:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }

  & .phones-container h3 {
    font-family: 'Roboto';
    font-size: 29px;
    font-weight: bold;
    color: #46eac4;
  }

  & .phones-container > div:not(:last-child) p {
    margin-bottom: 2rem;
  }

  & .phones-container > div:last-child h3 {
    margin-top: 2rem;
  }

  & .button-more-projects {
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
  }

  @media (max-width: 565px) {
    & .buttons {
      justify-content: center;
    }

    & .buttons > div:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    & {
      padding: 0 15px;
    }
  }
`;

const TopInfoMessageContainer = styled.div`
  & {
    display: flex;
    float: right;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 2.5rem;
  }

  & h1 {
    font-family: Roboto;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #FFF;
    text-shadow: none;
  }

  & p {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #FFF;
    margin-top: 1rem;
  }

  & div {
    display: flex;
    margin-top: 1.5rem;
    flex-wrap: wrap;
  }

  & #registerButton button {
    width: 225px;
    height: 43.2px;
    border-radius: 5px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.2px;
    text-align: center;
    color: #3eda6d;
    margin-right: 20px;
    background: #FFF;
  }

  & #readMoreButton button {
    width: 225px;
    height: 43.2px;
    border-radius: 5px;
    border: solid 1px #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.2px;
    text-align: center;
    color: #FFF;
    background: transparent;
  }

`;

const GraySection = styled.div`
  & {
    margin-top: 6rem;
    padding: 5rem 250px;
    background-color: rgba(0, 0, 0, 0.03);
  }

  & .inner-content {
    display: flex;
    flex-direction: ${props => props.vertical ? 'column' : 'row'};
  }

  & .inner-content div.flex-1 {
    flex: 1;
  }

  & .inner-content .main-container {
    padding: 0 2rem;
  }

  & .inner-content h3 {
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #7d5ef0;
  }

  & .inner-content h1 {
    font-family: Roboto;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.68;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  & .inner-content h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: center;
    color: #464646;
  }

  & .inner-content p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
  }

  & .inner-content i {
    color: #7d5ef0;
  }

  & .inner-content ul li {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }

  & .inner-content button {
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
  }

  @media (max-width: 992px) {
    & {
      padding: 5rem 0px;
    }
  }

  @media (max-width: 768px) {
    
  }
`;


const HideOnMobile = styled.div`

  & {
    padding-left: 6rem;
  }

  @media (max-width: 992px) {
    & {
      padding-left: 0;
    }
  }

  @media (max-width: 768px) {
    & {
      display: none;
    }
  }
`;

const Home = (props) => {
  const { handleChangeDrawer, intl, projects, user } = props;

  return (
    <Content className="home">
      <Container>
        <TopBanner>
          <div className="bg" />
          <div className="banner-content">
            <Row style={{ width: '100%' }} type="flex">
              <Col md={12} xs={0}>
                <HideOnMobile>
                  <InfoSlider />
                </HideOnMobile>
              </Col>
              <Col md={12} xs={24}>
                <TopInfoMessageContainer>
                  <h1>Si es con impacto, es en Wibai</h1>
                  <p>Los proyectos sustentables del momento te están esperando.</p>
                  <div>
                    <div id="registerButton">
                      <Button block type="primary">

                        {(user.state === 'accepted' ? 
                        <Link to="/projects">
                          VER PROYECTOS
                        </Link>
                        :
                        <Link to="/account/register">
                          REGISTRARSE
                        </Link>
                        )}
                        
                      </Button>
                    </div>
                    <div id="readMoreButton">
                      <Button block>
                        <Link to="/projects">
                          LEER MÁS
                        </Link>
                      </Button>
                    </div>
                  </div>
                </TopInfoMessageContainer>
              </Col>
            </Row>
          </div>
        </TopBanner>
        <MarginedContainer>
          <Row>
            <Col span={24}>
              <div className="inner-content">
                <h2>Proyectos</h2>
              </div>
              <div>
                <FeatureProjects
                  projectFilter={projects.featureProjects}
                  handleChangeDrawer={handleChangeDrawer}
                  intl={intl}
                  messages={messages}
                  onlyActive
                />
              </div>
            </Col>
          </Row>
          <Row>
              <Col md={24}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button block type="primary" className="button-more-projects" style={{ width: 350, marginTop: '2rem' }}>
                    <Link to="/projects">
                      VER TODOS LOS PROYECTOS
                    </Link>
                  </Button>
                </div>
              </Col>
            </Row>
        </MarginedContainer>
        
            {
        /****
        <GraySection>
              <div className="inner-content">
                <div className="flex-1" style={{ backgroundColor: '#e4e4e4' }} />
                <div className="flex-1 main-container">
                  <h3>NUEVO</h3>
                  <h1>Título de la Sección</h1>
                  <ul>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, est molestias! Iure sequi fugit, magnam accusamus natus, fuga, voluptas dicta architecto saepe suscipit optio numquam a quod provident! Ipsam, laudantium.</li>
                    <li>Lorem ipsum dolor sit.</li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis iusto velit corporis fugit nesciunt minus illum officia consequatur! Asperiores alias sint harum quibusdam ipsum, nihil reprehenderit commodi quo voluptate perspiciatis!</li>
                  </ul>
                  <Button block type="primary">MAS INFORMACIÓN</Button>
                </div>
              </div>
            </GraySection>
            <MarginedContainer>
              <Row>
                <Col md={24}>
                  <CustomCarouselContainer>
                    <Button icon="left" onClick={onCarouselPrevButtonClick} />
                    <CustomCarousel autoplay dots={false} ref={carouselRef}>
                      <div className="carousel-content">
                        <div className="main-container flex-1">
                          <h5>HISTORIAS DE CAMPAÑAS</h5>
                          <h1>Nombre del Proyecto</h1>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        <div className="flex-1" style={{ backgroundColor: '#e4e4e4', marginRight: '2rem' }} />
                      </div>
                      <div className="carousel-content">
                        <div className="main-container flex-1">
                          <h5>HISTORIAS DE CAMPAÑAS</h5>
                          <h1>Nombre del Proyecto</h1>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        <div className="flex-1" style={{ backgroundColor: '#e4e4e4', marginRight: '2rem' }} />
                      </div>
                    </CustomCarousel>
                    <Button icon="right" onClick={onCarouselNextButtonClick} />
                  </CustomCarouselContainer>
                </Col>
              </Row>
            </MarginedContainer> 
        ****/

            }
        
        
        <GraySection vertical>
          <div className="inner-content">
            <Row>
              <Col md={24}>
                <h1 style={{ textAlign: 'center' }}>Categorías</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={8}>
                <div style={{ padding: '1rem 2rem' }}>
                  <div className="firstButton"></div>
                  <h2>Ecología</h2>
                  <p>Proyectos cuyo modelo de negocio incorpore como parte esencial prácticas, servicios o productos que generen un impacto positivo en el medio ambiente o contemplen el desarrollo de soluciones a la problemática ambiental.</p>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div style={{ padding: '1rem 2rem' }}>
                  <div className="secondButton"></div>
                  <h2>Social</h2>
                  <p>
                  Proyectos cuya misión comprenda el impacto en la comunidad atacando alguna de las siguientes problemáticas: pobreza, hambre, seguridad o salud.
                  </p>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div style={{ padding: '1rem 2rem' }}>
                  <div className="thirdButton"></div>
                  <h2>Inclusión</h2>
                  <p>Proyectos que a través de un producto o servicio promuevan la concientización y evolución en las áreas de diversidad e inclusión.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={8}>
                <div style={{ padding: '1rem 2rem' }}>
                  <div className="fourButton"></div>
                  <h2>Triple Impacto</h2>
                  <p>
                  Proyectos con impacto económico, social y ecológico.
                  </p>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div style={{ padding: '1rem 2rem' }}>
                  <div className="fiveButton"></div>
                  <h2>Educación</h2>
                  <p>Proyectos que busquen generar valor y desarrollar nuevas soluciones en el campo de la educación y el acceso a ella.</p>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div style={{ padding: '1rem 2rem' }}>
                  <div className="sixButton"></div>
                  <h2>Economía Colaborativa</h2>
                  <p>Proyectos basados en la economía de red.</p>
                </div>
              </Col>
            </Row>
          </div>
        </GraySection>
      </Container>
    </Content>
  );
};

const mapStateToProps = state => ({ projects: state.projects, user: state.user  });

export default connect(mapStateToProps)(injectIntl(Home));
