import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Form, Layout, Button, Spin, message
} from 'antd';

import {
  createProject, getProjectById, editProject, deleteAdvance, deleteReward
} from '../../../../actions/projects';
import { getUsersByRol } from '../../../../actions/users';
import { getAllCountries } from '../../../../actions/country';
import { getDownloadUrl } from '../../../../service/firebaseStorageService';

import BreadcrumbComponent from '../../../commons/breadcrumb';

import Campaign from './components/campaign';
import BasicData from './components/basicDate';
import Advance from './components/advance';
import Rewards from './components/rewards';

import messages from './culture/messages';

const { Content } = Layout;

const Title = styled.div`
  border-bottom: 0.5px solid #d1d1d1;
  margin-bottom: 25px;
  margin-top: ${props => props.first ? 0 : '40px'};
  font-family: 'Roboto';
  color: #808080;
  font-weight: 500;
  font-size: 1.5em;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const ContainerLoading = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  background-color: #FFF;
  padding: 24px;
  margin-bottom: 40px;
`;

export class FormProject extends Component {
  state = {
    imagesUrl: {}, mainImageUrl: null, imagesAdvanceUrl: {}, advanceImagesLength: false, featuredState: null
  };

  componentDidMount() {
    const {
      match,
      getProject,
      form,
      getUsers,
      getCountries
    } = this.props;
    getCountries();

    getUsers('developer').then(() => {
      if (match.params.id) {
        getProject(match.params.id)
          .then((resp) => {
            const {
              advances,
              mainImage,
              images,
              campaigns
            } = resp.value.project;
            if (advances.length !== 0) this.setState({ advanceImagesLength: advances.length });

            const advanceKeys = [];

            advances.forEach((item, index) => {
              if (item.image) {
                getDownloadUrl(item.image.key).then((url) => {
                  this.setState(prevState => ({
                    imagesAdvanceUrl: {
                      ...prevState.imagesAdvanceUrl,
                      [index]: url
                    }
                  }));
                });
              }

              advanceKeys.push(index);
            });

            const rewardsKeys = campaigns[0].rewards.map((item, index) => index);

            form.setFieldsValue({ advanceKeys, rewardsKeys });

            getDownloadUrl(mainImage.key).then(url => this.setState({ mainImageUrl: url }));

            images.forEach((image, index) => {
              getDownloadUrl(image.key).then(url => this.setState(prevState => ({ imagesUrl: { ...prevState.imagesUrl, [index]: url } })));
            });
          });
      }
    });
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }


  handleFeaturedChange = (value) => {
    this.setState({ featuredState: value.target.value });
  };

  handleSubmit = (e) => {
    const {
      form,
      create,
      history,
      intl,
      match,
      edit,
      users
    } = this.props;

    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const imagesFilter = values.images.fileList.filter(img => (img.response && img.name));
        const advances = [];
        const rewards = [];

        Object.keys(values).forEach((key) => {
          if (key.includes('titleA') && values.advanceKeys.includes(Number(key.slice(6)))) advances[key.slice(6)] = { ...advances[key.slice(6)], title: values[key] };
          else if (key.includes('idA') && values.advanceKeys.includes(Number(key.slice(3)))) advances[key.slice(3)] = { ...advances[key.slice(3)], id: values[key] };
          else if (key.includes('descriptionA') && values.advanceKeys.includes(Number(key.slice(12)))) advances[key.slice(12)] = { ...advances[key.slice(12)], description: values[key] };
          else if (key.includes('imageA') && values.advanceKeys.includes(Number(key.slice(6))) && values[key]) {
            advances[key.slice(6)] = {
              ...advances[key.slice(6)],
              image: {
                key: values[key].fileList[0].response,
                name: values[key].fileList[0].name
              }
            };
          } else if (key.includes('titleR') && values.rewardsKeys.includes(Number(key.slice(6)))) rewards[key.slice(6)] = { ...rewards[key.slice(6)], title: values[key] };
          else if (key.includes('descriptionR') && values.rewardsKeys.includes(Number(key.slice(12)))) rewards[key.slice(12)] = { ...rewards[key.slice(12)], description: values[key] };
          else if (key.includes('activeR') && values.rewardsKeys.includes(Number(key.slice(7)))) rewards[key.slice(7)] = { ...rewards[key.slice(7)], active: values[key] };
          else if (key.includes('amountR') && values.rewardsKeys.includes(Number(key.slice(7)))) rewards[key.slice(7)] = { ...rewards[key.slice(7)], amount: values[key] };
        });

        let developer;
        users.usersByRol.map((user) => {
          if (values.developer === user.username) developer = user._id;
          return users;
        });

        const model = {
          name: values.name,
          country: values.country,
          developer,
          briefDescription: values.briefDescription,
          completeDescription: values.completeDescription,
          featuredProject: values.featuredProject,
          category: values.category,
          mainImage: {
            name: values.mainImage.fileList[0].name,
            key: values.mainImage.fileList[0].response,
          },
          images: imagesFilter.map(img => ({ key: img.response, name: img.name })),
          microsite: values.microsite,
          campaigns: [{
            objective: values.objective,
            totalRaised: values.totalRaised,
            days: values.days,
            totalInvestors: values.totalInvestors,
            rewards: rewards.filter(el => el != null),
          }],
          featuredCategory: values.featuredCategory,
          advances: advances.filter(el => el != null),
        };

        if (match.params.id && this.props.project.project.campaigns[0].publishedAt) model.campaigns[0].publishedAt = this.props.project.project.campaigns[0].publishedAt;
        if (match.params.id && this.props.project.project.campaigns[0].status) model.campaigns[0].status = this.props.project.project.campaigns[0].status;

        if (match.params.id) {
          edit(model, match.params.id)
            .then(() => history.push('/admin/projects'))
            .catch((error) => {
              const { data, status } = error.response;

              if (data && data.code === 'name_exists') return message.error(intl.formatMessage(messages.nameExists));
              if (status === 400) message.error(intl.formatMessage(messages.createError));
            });
        } else {
          create(model)
            .then(() => history.push('/admin/projects'))
            .catch((error) => {
              const { data, status } = error.response;

              if (data && data.code === 'name_exists') return message.error(intl.formatMessage(messages.nameExists));
              if (status === 400) message.error(intl.formatMessage(messages.createError));
            });
        }
      }
    });
  }

  render() {
    const {
      form,
      users,
      intl,
      project,
      match,
      countries
    } = this.props;
    const {
      mainImageUrl,
      imagesUrl,
      imagesAdvanceUrl,
      advanceImagesLength,
      featuredState
    } = this.state;
    const formItemLayout = { labelCol: { lg: { span: 6 } }, wrapperCol: { lg: { span: 14 } } };

    const isEdit = !!match.params.id || false;

    let basicDateEdit = false;
    let campaignEdit = false;
    let advanceEdit = false;
    let rewardsEdit = false;

    const titlesBreadcrumb = [intl.formatMessage(messages.breadcrumbFirst), intl.formatMessage(messages.breadcrumbSecond)];
    if (match.params.id) titlesBreadcrumb.push(intl.formatMessage(messages.breadcrumbThirdEdit));
    else titlesBreadcrumb.push(intl.formatMessage(messages.breadcrumbThird));

    if (match.params.id && Object.keys(project.project).length) {
      const {
        name,
        developer,
        mainImage,
        images,
        briefDescription,
        completeDescription,
        campaigns,
        featuredProject,
        advances,
        category,
        country,
        featuredCategory,
        micrositio
      } = project.project;

      if (project.project) {
        basicDateEdit = {
          name,
          developer: developer.username,
          mainImageDidMount: [mainImage],
          imagesDidMount: images,
          briefDescription,
          category,
          country: country._id,
          completeDescription,
          featuredProject,
          featuredCategory,
          micrositio
        };

        if (mainImageUrl) {
          basicDateEdit.mainImage = [{
            name: mainImage.name,
            status: 'done',
            url: mainImageUrl,
            response: mainImageUrl,
            uid: 1
          }];
        }

        if (Object.keys(imagesUrl).length === images.length) {
          const imagesDidMount = images.map((image, index) => ({
            uid: index,
            id: image._id,
            name: image.name,
            status: 'done',
            url: imagesUrl[index],
            response: image.key
          }));

          basicDateEdit.images = imagesDidMount;
        }

        if (advances.length !== 0) {
          advanceEdit = advances.map((advance, index) => {
            let model = {
              title: advance.title,
              description: advance.description,
            };

            if (advance.image) {
              model = {
                ...model,
                image: advance.image,
                imageDidMount: {
                  uid: index,
                  id: advance.image._id,
                  name: advance.image.name,
                  status: 'done',
                  url: imagesAdvanceUrl[index],
                  response: advance.image.key
                }
              };
            } else model = { ...model, notImage: true };

            return model;
          });
        }

        if (campaigns[0].rewards.length !== 0) {
          rewardsEdit = campaigns[0].rewards.map(reward => ({
            title: reward.title,
            description: reward.description,
            active: reward.active,
            // currency: reward.currency,
            amount: reward.amount,
            investments: reward.investments
          }));
        }

        campaignEdit = {
          objective: campaigns[0].objective,
          totalRaised: campaigns[0].totalRaised,
          days: campaigns[0].days.toString(),
          totalInvestors: campaigns[0].totalInvestors.toString(),
        };
      }
    }

    if (countries.isFetching || users.isFetching || project.isFetchingProject) return (<ContainerLoading><Spin size="large" /></ContainerLoading>);

    return (
      <Content style={{ margin: '0 16px' }}>
        <BreadcrumbComponent
          titles={titlesBreadcrumb}
        />
        <Container>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Title first>{intl.formatMessage(messages.basicData)}</Title>
            <BasicData
              form={form}
              users={users}
              intl={intl}
              messages={messages}
              basicDateEdit={basicDateEdit}
              isEdit={isEdit}
              countries={countries.list}
              handleFeaturedChange={this.handleFeaturedChange}
              featuredState={featuredState}
            />

            <Title>{intl.formatMessage(messages.campaign)}</Title>
            <Campaign
              form={form}
              intl={intl}
              messages={messages}
              isEdit={isEdit}
              campaignEdit={campaignEdit}
            />
            <Rewards
              form={form}
              intl={intl}
              messages={messages}
              isEdit={isEdit}
              rewardsEdit={rewardsEdit}
              deleteRedux={isEdit}
              deleteReward={this.props.deleteReward}
            />
            <Title>{intl.formatMessage(messages.advance)}</Title>
            <Advance
              form={form}
              intl={intl}
              messages={messages}
              advanceEdit={advanceEdit}
              deleteAdvance={this.props.deleteAdvance}
              advanceImagesLength={advanceImagesLength}
              deleteRedux={isEdit}
            />

            <ButtonContainer>
              <Button
                style={{ marginRight: 15 }}
                size="large"
              ><Link to="/admin/projects/">{intl.formatMessage(messages.cancelButton)}</Link>
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={project.isFetching}
              >{intl.formatMessage(messages.createProjectButton)}
              </Button>
            </ButtonContainer>
          </Form>
        </Container>
      </Content>
    );
  }
}

const WrappedProjectForm = Form.create({ name: 'formProject' })(FormProject);

const mapStateToProps = state => ({
  users: state.users,
  project: state.projects,
  countries: state.countries
});

const mapDispatchToProps = dispatch => bindActionCreators({
  create: createProject,
  getUsers: getUsersByRol,
  getProject: getProjectById,
  getCountries: getAllCountries,
  edit: editProject,
  deleteAdvance,
  deleteReward,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WrappedProjectForm, { withRef: false }));
