import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import auth from './auth';
import culture from './culture';
import projects from './projects';
import user from './user';
import users from './users';
import microsite from './microsite';
import invest from './invest';
import contactForm from './contactForm';
import registration from './registration';
import transactions from './transactions';
import returnFund from './returnFund';
import fundReleases from './fundReleases';
import countries from './country';

const allReducers = combineReducers({
  projects,
  auth,
  culture,
  user,
  users,
  microsite,
  invest,
  contactForm,
  registration,
  transactions,
  fundReleases,
  returnFund,
  countries,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
    Object.keys(state).forEach(key => storage.removeItem(`persist:${key}`));
    const newState = { auth: { ...state.auth } };

    return allReducers(newState, action);
  }

  return allReducers(state, action);
};

export default rootReducer;
