import React from 'react';
import {
  Transfer, Table, Tag
} from 'antd';

import difference from 'lodash/difference';

const projectStatusColors = {
  draft: 'cyan',
  published: 'green',
  finished: 'red'
};

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      if(direction === 'left'){
        filteredItems = filteredItems.filter(item => !item.microsite || item.microsite === '');
      }

      

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const SelectProject = ({
  projects,
  onChange,
  targetKeys,
  intl,
  messages
}) => {
  const leftTableColumns = [
    {
      dataIndex: 'name',
      title: 'Nombre',
    },
    {
      dataIndex: 'status',
      title: 'Estado',
      // eslint-disable-next-line react/display-name
      render: (text,record) => <Tag color={projectStatusColors[( record.campaignStatus === 'finished' ? 'finished' : text)]}>{intl.formatMessage(messages[( record.campaignStatus === 'finished' ? 'finished' : text)])}</Tag>,
    },
    {
      dataIndex: 'developer',
      title: 'Creador del proyecto',
      render: (text,record) => <Tag>{record.developer.username}</Tag>,
    
    },
  ];

  const rightTableColumns = [
    {
      dataIndex: 'name',
      title: 'Nombre',
    },
    {
      dataIndex: 'status',
      title: 'Estado',
      // eslint-disable-next-line react/display-name
      render: (text,record) => <Tag color={projectStatusColors[( record.campaignStatus === 'finished' ? 'finished' : text)]}>{intl.formatMessage(messages[( record.campaignStatus === 'finished' ? 'finished' : text)])}</Tag>,
    },
    {
      dataIndex: 'developer',
      title: 'Creador del proyecto',
      render: (text,record) => <Tag>{record.developer.username}</Tag>,
    
    }
  ];

  const mockDataa = projects.map((project, index) => ({ ...project, key: index }));

  return (
    <div>
      <TableTransfer
        dataSource={mockDataa}
        targetKeys={targetKeys}
        showSearch
        onChange={onChange}
        // filterOption={(inputValue, item) => item.name.indexOf(inputValue) !== -1}
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
        filterOption={(inputValue, item) => item.name.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1}
        operations={['Agregar Proyecto', 'Quitar Proyecto']}
      />
    </div>
  );
};

export default SelectProject;
