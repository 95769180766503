import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { removeToken } from '../actions/user';

import Register from '../components/web/registration/register';
import ConfirmationRequired from '../components/web/registration/confirmationRequired';
import Data from '../components/web/registration/data';
import AccountConfirmation from '../components/web/registration/accountConfirmation';
import ChangePassword from '../components/web/registration/recoverPassword/newPassword';
import Login from '../components/web/registration/login';
import RecoverPassword from '../components/web/registration/recoverPassword/recoverPassword';
import HeaderComponent from '../components/web/commons/header/header';

const styles = {
  content: { padding: 24, background: '#fff', minHeight: 360 },
  appLayout: {
    display: 'flex',
    minHeight: '100vh',
    flex: 1,
    background: '#fff'
  }
};

const Container = styled.div`
  & {
    font-family: 'Roboto';
    font-size: 16px;
  }

  & main {
    padding-left: 80px !important;
    padding-right: 80px !important;
    background-color: #fff;
  }

  & main.login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & header {
    height: auto;
    padding: 10px 80px !important;
  }

  & footer > div {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  @media (max-width: 768px) {
    & main {
      padding-left: 0 !important;
      padding-right: 0 !important;
      background-color: #fff;
    }

    & header {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    & footer > div {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }
`;

class Routes extends Component {
  componentDidMount() {
    const { remove, location, token } = this.props;

    if (location.state && location.state.remove && token) remove();
  }

  render() {
    return (
      <Container>
        <Layout style={styles.appLayout}>
          <HeaderComponent />
          <Route exact path="/account/login/" component={Login} />
          <Route exact path="/account/register" component={Register} />
          <Route
            exact
            path="/account/register/confirmrequired"
            component={ConfirmationRequired}
          />
          <Route exact path="/account/data" component={Data} />
          <Route
            exact
            path="/account/register/confirmation/:token"
            component={AccountConfirmation}
          />
          <Route
            exact
            path="/account/recoverPassword"
            component={RecoverPassword}
          />
          <Route
            exact
            path="/account/changePassword/:token"
            component={ChangePassword}
          />
        </Layout>
      </Container>
    );
  }
}

const mapStateToProps = state => ({ token: state.user.token });

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    remove: removeToken
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
