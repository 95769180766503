import React, { Component, Fragment } from 'react';
import { Spin } from 'antd';
import ReactPlayer from 'react-player';
import { getDownloadUrl } from '../../service/firebaseStorageService';

const styles = {
  spin: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

class RemoteVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null
    };
  }

  componentDidMount = () => {
    getDownloadUrl(this.props.url).then(url => this.setState({ url }, () => {
      if (this.props.onFetch) {
        this.props.onFetch({
          index: this.props.index,
          url,
          name: this.props.name
        });
      }
    }));
  };

  render() {
    const { url } = this.state;
    const { playing, onPause, onEnded, controls, } = this.props;
    return (
      <Fragment>
        {url ? (
          <ReactPlayer
            url={url}
            className="react-player"
            width="100%"
            height="100%"
            playing={playing}
            controls={controls}
            onPause={onPause}
            onEnded={onEnded}
          />
        ) : (
          <Spin style={styles.spin} />
        )}
      </Fragment>
    );
  }
}

export default RemoteVideo;
