const config = {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8088/',
  API_VERSION: process.env.REACT_APP_API_VERSION || 'v1',
  PORT: process.env.PORT || '3000',
  TOKEN:
    process.env.REACT_APP_API_TOKEN
    || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3QzQGVtYWlsLmNvbSIsInJvbGVzIjoiaW52ZXJzb3IiLCJpYXQiOjE1NTkxNTI2MDcsImV4cCI6MTU1OTIzOTAwN30.UPOkxpgNzn04c625n3v_9mbgE8aFXE3s_hqmL79DeLc',
  GOOGLE_ID_CLIENT:
    process.env.REACT_APP_GOOGLE_CLIENT_ID
    || '965799415078-opu8j12elfddouk8hcfdd05ppiac2nv5.apps.googleusercontent.com',
  FIREBASE_API_KEY: 'AIzaSyBE5h298HtfmnMWmUaQsxRMYj-twy_msjI',
  FIREBASE_AUTH_DOMAIN: 'wibai-1caab.firebaseapp.com',
  FIREBASE_STORAGE_BUCKET: 'wibai-1caab.appspot.com',
  FIREBASE_APP_ID: '1:243487930529:web:702ee931c2a617f10098e0'
};

module.exports = config;
