import en_US from 'antd/lib/locale-provider/en_US';
import appLocaleData from 'react-intl/locale-data/en';

const culture = {
  'general.breadCrumb.home': 'Home',
  'general.breadCrumb.users': 'Users',
  'general.breadCrumb.create': 'Create',
  'admin.user.firstName': 'Name',
  'admin.user.lastName': 'Last Name',
  'general.country': 'Country',
  'general.countryPlaceHolder': 'Choose your country',
  'general.state': 'State',
  'general.statePlaceHolder': 'Choose your state',
  'general.city': 'City',
  'general.roles': 'Roles',
  'general.rolesPlaceholder': 'Choose your role',
  'general.user.password': 'Password',
  'general.address': 'Address',
  'general.roles.administrator': 'Administrator',
  'general.roles.realStateDeveloper': 'Real Estate Developer',
  'admin.user.create.image': 'Image',
  'general.user.birthdate': 'Birthdate',
  'admin.user.create.birthdatePlaceholder': 'Choose your birthdate',
  'general.email': 'Email',
  'admin.user.mainPhone': 'Main phone',
  'admin.user.secondaryPhone': 'Secondary phone',
  'admin.user.create.createUserButton': 'Create user',
  'general.validations.requiredField': 'Required field',
  'general.validations.maxCharacters': 'Max characters ',
  'general.validations.minCharacters': 'Min characters ',
  'general.selectDate': 'Choose a date',
  'admin.user.create.ducplicatedCBU': 'The CBU is already registered',
  'admin.user.create.emailAlreadyExistent': 'The Email is already registered',
  'general.validations.invalidEmail': 'Invalid email format',
  'general.field': 'Field',
  'general.characters': 'Characters',
  'general.uploadImage': 'Click to upload an image',

  'project.create.breadcrumb.first': 'Home',
  'project.create.breadcrumb.second': 'Projects',
  'project.create.breadcrumb.third': 'Create',
  'project.create.breadcrumb.thirdEdit': 'Edit',

  'project.create.error.400': 'Check your data and try again',
  'project.create.error.name_exists':
    'The name of the project already exists, try another.',

  'project.create.title.basicData': 'Basic data',
  'project.create.title.typification': 'Typification',
  'project.create.title.characteristicsTransaction':
    'Characteristics of the transaction',
  'project.create.title.projectDetail': 'Project Detail',
  'project.create.title.campaign': 'Campaign',
  'project.create.title.advance': 'Updates',

  'project.create.input.totalInvestmentAmount': 'Total Investment Amount',
  'project.create.input.propiInvestInvestmentAmount': 'Goal',
  'project.create.input.metersBuilt': 'Sq Meters',
  'project.create.input.terrainValue': 'Terrain Cost',
  'project.create.input.costAcquisition': 'Cost of acquisition',
  'project.create.input.costConstruction': 'Cost of construction',
  'project.create.input.closingDate': 'Closing date',
  'project.create.input.createProjectButton': 'Save',
  'project.create.input.cancelButton': 'Cancel',
  'project.create.input.typeAsset': 'Type of Asset',
  'project.create.input.typeFormat': 'Type of Format',
  'project.create.input.typeOperation': 'Type of Operation',
  'project.create.input.nameProyect': 'Name',
  'project.create.input.realEstateDeveloper': 'Real Estate Developer',
  'project.create.input.briefDescription': 'Brief description',
  'project.create.input.regulatoryEntity': 'Mark this project as featured?',
  'project.create.input.projectLocation': 'Project Location',
  'project.create.input.riskLevel': 'Risk level',
  'project.create.rewards.title': 'Titulo',
  'project.create.rewards.MPMercadoPago': 'Mercado Pago',
  'project.create.rewards.create': 'Agregar Recompensa',

  'project.create.input.type': 'Type',
  'project.create.input.text': 'Text',
  'project.create.input.image': 'Image',
  'project.create.input.order': 'Order',

  'project.create.input.title': 'Title',
  'project.create.input.objective': 'Goal',
  'project.create.input.currency': 'Currency',
  'project.create.input.days': 'Duration',
  'project.create.input.minimunAmountOfInvestment': 'Minimum Investment',
  'project.create.input.minimunPercentageOfCollection':
    'Minimum percentage accepted to complete',
  'project.create.input.maximunPercentageOfCollection':
    'Maximum percentage accepted to complete',
  'project.create.input.annualProjectedProfitability':
    'Annual projected profitability',
  'project.create.input.tir': 'Targeted Investor IRR',
  'project.create.input.equity': 'Targeted Equity Multiple',
  'project.create.input.periodofInvestment': 'Targeted Investment Period',
  'project.create.input.projectedAnnualProfit': 'Annual profit',
  'project.create.input.capitalRecovery': 'Return of investment',
  'project.create.input.accountBankForRelease':
    "Real Estate Developer's Bank account",
  'project.create.input.accountBank': "Real Estate Developer's Bank account ",
  'project.create.input.amountRaised': 'Amount raised',
  'project.create.input.contributors': 'Contributing',
  'project.create.input.mainImage': 'Main Image',
  'project.create.input.completeDescription': 'Complete description',

  'project.create.input.description': 'Description',

  'project.create.option.yes': 'Yes',
  'project.create.option.no': 'No',

  'project.create.option.high': 'High',
  'project.create.option.medium': 'Medium',
  'project.create.option.low': 'Low',

  'project.create.option.ecology': 'Ecología',
  'project.create.option.social': 'Social',
  'project.create.option.tripleImpact': 'Triple Impacto',
  'project.create.option.collaborativeEconomy': 'Economía Colaborativa',
  'project.create.option.inclusion': 'Inclusión',
  'project.create.option.education': 'Educación',

  'project.create.option.purchaseOption': 'Purchase Option',
  'project.create.option.ticket': 'Contract of Sale',
  'project.create.option.concessionRentalContract': 'Rental/Lease Contract',
  'project.create.option.commercialSociety': 'Commercial Company',
  'project.create.option.escrow': 'Trust',
  'project.create.option.trustProperty': 'Property on Trust',
  'project.create.option.commonInvestmentFund': 'Investment Fund',
  'project.create.option.propertytitle': 'Property Title',
  'project.create.option.mortgage': 'Mortgage',

  'project.create.option.buySell': 'Buy and sell',
  'project.create.option.buyBuildSell': 'Buy, Add Value and Sell',
  'project.create.option.buyRentSell': 'Buy, Rent and Sell',
  'project.create.option.buyBuildRentSell': 'Buy, Add Value, Rent and Sell',

  'project.create.option.onlyText': 'Text only',
  'project.create.option.imageAndText': 'Text & image',
  'project.create.option.onlyImage': 'Image only',

  'project.create.option.dolar': 'Dollars',
  'project.create.option.pesos': 'Pesos',

  'project.create.button.addAdvance': 'Add an update',
  'project.create.button.addDetail': 'Add project detail',
  'admin.project.create.images': 'Images',

  'project.index.questionPublish':
    'Are you sure the project is ready to be published?',
  'project.index.questionActivate':
    'Are you sure you want to activate the project?',
  'project.index.questionInactivate':
    'Are you sure you want to inactivate the project?',
  'project.index.tab.start': 'Start',
  'project.index.tab.project': 'Projects',
  'project.index.button.newProject': 'New Project',
  'micro.index.button.newMicro': 'Nuevo Micrositio',
  'micro.index.questionActivate':
  'Está seguro que desea activar el mirositio ?',
'micro.index.questionInactivate':
  'Está seguro que desea inactivar el mirositio ?',

  'project.table.key.id': 'Id',
  'project.table.key.name': 'Name',
  'project.table.key.developer': 'Project creator',
  'project.table.key.status': 'Campaign',
  'project.table.key.active': 'Project',
  'project.table.key.creationDate': 'Creation Date',
  'project.table.key.percentage': 'Pledge',
  'project.table.key.campaignStatus': 'Campaign Status',
  'project.table.key.action': 'Action',

  'project.table.status.draft': 'Draft',
  'project.table.status.published': 'Published',
  'project.table.status.pending': 'Pending',
  'project.table.status.finished': 'Finished',
  'project.table.status.active': 'Active',
  'project.table.status.inactive': 'Inactive',
  'project.table.status.canceled': 'canceled',
  'project.table.button.publish': 'Publish',
  'project.table.button.activate': 'Activate',
  'project.table.button.inactivate': 'Inactivate',
  'project.table.button.returns': 'View returns',

  'project.returns.table.description': 'Descripción',
  'project.returns.table.returnPercentage': 'Porcentaje de retorno',
  'project.returns.breadcrumb.project': 'Proyecto',
  'project.returns.breadcrumb.returns': 'Retornos',
  'project.returns.button.add': 'Cargar retorno',
  'project.returns.button.confirm': 'Confirmar retorno',

  // Home

  'home.header.admin': 'vista de administrador',
  'home.header.projects': 'Proyectos',
  'home.header.profile': 'PERFIL',
  'home.header.signout': 'CERRAR SESIÓN',
  'home.header.headerMenuDrawer': 'Menú',

  'home.title.invest': 'Invertí en el mercado inmobiliario.',
  'home.text.content':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.',
  'home.button.readMore': 'LEER MAS',
  'home.button.register': 'REGISTRARME',
  'home.button.moreProject': 'VER TODOS LOS PROYECTOS',
  'home.title.project': 'Descubrí los proyectos de nuestra comunidad',
  'home.text.contentProject':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'home.title.questionProp': '¿Qué es Wibai?',
  'home.title.crowfunding': 'CROWDFUNDING INMOBILIARIO',
  'home.text.crowfunding':
    'Wibai es la primera plataforma de financiamiento colectivo de proyectos inmobiliarios de Argentina.',
  'home.title.forAll': 'ES PARA TODOS',
  'home.text.forAll':
    'Para que los pequeños y medianos colaboradores puedan participar.',
  'home.title.simpleAndAccesible': 'SIMPLE Y ACCESIBLE',
  'home.text.simpleAndAccesible':
    'Invertí desde cualquier lugar y en cualquier momento tanto Pesos como Dólares.',
  'home.title.questionWhyProp': '¿Por que invertir con Wibai?',
  'home.title.howProccess': '¿Cómo es el Proceso?',
  'home.oneProccess.registerProccess': 'REGISTRARSE',
  'home.oneProccessContent.registerProccess':
    'Para que los pequeños y medianos colaboradores .',
  'home.twoProccess.choseProccess': 'ELEGÍ',
  'home.twoProccessContent.choseProccess':
    'Para que los pequeños y medianos colaboradores.',
  'home.threeProccess.investProccess': 'INVERTÍ',
  'home.threeProccessContent.investProccess':
    'Para que los pequeños y medianos colaboradores.',
  'home.fourProccess.winProccess': 'GANÁ',
  'home.fourProccessContent.winProccess':
    'Para que los pequeños y medianos colaboradores.',
  'home.title.titleNumber': 'Los números nos avalan:',
  'home.oneTitleNumber.investNumber': '+43000',
  'home.twoTitleNumber.approvedNumber': '$763MM',
  'home.threeTitleNumber.projectsNumber': '9',
  'home.fourTitleNumber.capitalNumber': '$328MM',
  'home.oneContentNumber.investNumber': 'colaboradores registrados',
  'home.twoContentNumber.approvedNumber': 'Aprobados por CFA',
  'home.threeContentNumber.projectsNumber': 'Proyectos fondeados',
  'home.fourContentNumber.capitalNumber': 'Capital invertido',
  'home.title.readyInvest': 'Estoy listo para invertir.',
  'home.text.readyInvestContent':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.',
  'home.button.readyButton': 'EMPEZAR',
  'home.button.submit': 'ENVIAR',
  'home.title.wantCommunicate': 'Quiero comunicarme.',
  'home.title.email': 'Por correo:',
  'home.content.emailContent':
    'Dejanos tu pregunta en el siguiente formulario y nos estaremos comunicando con vos para brindarte toda la información que necesites.',
  'home.title.phone': 'Telefónicamente:',
  'home.content.phoneContent':
    'Líneas Telefónicas Rotativas. Lunes a Viernes de 10:00 a 18:00.',
  'home.content.phoneContentWssp':
    'Comunicación por WhatsApp. Lunes a Viernes de 10:00 a 18:00.',

  'home.input.name': 'Name *',
  'home.inputMessage.name': 'John Smith',
  'home.input.email': 'Email *',
  'home.inputMessage.email': 'user@mail.com',
  'home.inputMessageError.email': 'Invalid Email',
  'home.input.phone': 'Phone *',
  'home.inputMessage.phone': '(+54) 11 3333 3333',
  'home.input.message': 'Message *',
  'home.inputMessage.message': 'Type your message',
  'home.form.success': 'Thank you! We received your message.',

  'moderate.title.success': 'Registro Exitoso',
  'moderate.text.success': 'Sus datos han sido enviado correctamente.',

  'home.footer.title.invest': 'INVERSIONES',
  'home.footer.question.propi': '¿Por qué Wibai?',
  'home.footer.title.company': 'LA EMPRESA',
  'home.footer.content.aboutUs': '¿Que hacemos?',
  'home.footer.title.access': 'ACCESOS',
  'home.footer.content.register': 'Registrarse',
  'home.footer.content.login': 'Iniciar Sesión',
  'home.footer.content.rightsReserved':
    '© 2019 Wibai. Todos los derechos reservados. Diseñado y desarrollado en Buenos Aires.',
  'home.footer.content.termsAndConditions': 'Términos y condiciones',
  'home.footer.content.privacyPolicy': 'Política de Privacidad',
  'home.footer.content.contentFooter':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',

  'home.featureProjects.noResults': 'There were no results for your search',
  'home.featureProjects.seeAllProjects': 'View more',
  'home.featureProjects.titleInvest': 'Invest',
  'home.featureProjects.daysFeatureProjects': 'day',
  'home.featureProjects.remainingDaysFeatureProjects': 'remaining',
  'home.featureProjects.tirFeatureProjects': 'IRR',
  'home.featureProjects.multipleFeatureProjects': 'Equity Multiple',
  'home.featureProjects.investFeatureProjects': 'Investment',

  'project.content':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'project.breadcrumb.home': 'Home',
  'project.breadcrumb.allProjects': 'View All',

  'project.filter.title': 'Filtrar',
  'project.filter.selectTitle': 'TIR',
  'project.filter.option.active': 'Activo',
  'project.filter.option.finished': 'Finalizado',

  'profile.tag.pending': 'Pending',
  'profile.tag.enabled': 'Enabled',
  'profile.tag.toCorrect': 'Needs Review',

  'profile.noComplete.title': 'Sign Up',
  'profile.noComplete.firstContent': 'Register With just a few clicks ',
  'profile.noComplete.secondContent': 'to start investing with us',
  'profile.noComplete.button': 'Complete your profile',
  'profile.complete.tab.myData': 'Contact Info',
  'profile.complete.tab.myInvests': 'My Investments',
  'profile.complete.title.basicDate': 'Basic Contact Information',
  'profile.complete.text.name': 'Nombre',
  'profile.complete.text.document': 'DNI',
  'profile.complete.text.lastName': 'Apellido',
  'profile.complete.text.payPalUser': 'Usuario de PayPal',
  'profile.complete.text.website': 'Web Site',
  'profile.complete.text.mpUserName': 'Usuario de Mercado Pago',
  'profile.complete.text.floor': 'Floor',
  'profile.complete.text.department': 'Apt.',
  'profile.complete.text.birthdate': 'Birthdate',
  'profile.complete.text.secondaryPhone': 'Secondary phone',

  'profile.complete.title.bankData': 'Bank Account',
  'profile.complete.text.accountTypeCA': 'Savings Account',
  'profile.complete.text.accountTypeCC': 'Checking Account',
  'profile.complete.text.cuit': 'CUIT/CUIL',
  'profile.complete.text.headline': 'Account Holder',
  'profile.complete.text.cbu': 'CBU',
  'profile.complete.text.numberAccount': 'Account Number',
  'profile.complete.text.cbuAlias': 'Alias',
  'profile.complete.text.send': 'GUARDAR PERFIL',
  'profile.complete.text.typeAccount': 'Account Type',
  'profile.complete.text.businessName': 'Account Holder',
  'profile.complete.text.numAccount': 'Account Number',
  'profile.complete.text.cp': 'Zip Code',
  'profile.complete.text.addressVerification': 'Address Verification',
  'profile.complete.text.profilePicture': 'Profile Picture',
  'profile.complete.text.documentNumber': 'ID Number',
  'profile.complete.text.imagesIdentification': 'Identification Images',
  'profile.complete.text.Front': 'Front',
  'profile.complete.text.Back': 'Back',

  'profile.myInvestments.title.nameProyect': 'Project Name',
  'profile.myInvestments.subTitle.invests': 'Investments',
  'profile.myInvestments.subTitle.return': 'Return',
  'profile.myInvestments.table.amount': 'Amount',
  'profile.myInvestments.table.date': 'Date',
  'profile.myInvestments.table.status': 'Status',
  'profile.myInvestments.table.percentage': 'Percentage',
  'profile.myInvestments.table.note': 'Note',
  'profile.myInvestments.filter.all': 'All Projects',
  'profile.myInvestments.alert.title': "You don't have investments yet",
  'profile.myInvestments.alert.description':
    'All investments you make on Wibai will be listed here.',
  'profile.myInvestments.status.pending': 'Pending',
  'profile.myInvestments.status.expired': 'Expired',
  'profile.myInvestments.status.returned': 'Returned',
  'profile.myInvestments.status.released': 'Published',
  'profile.myInvestments.status.accredited': 'Received',
  'profile.myInvestments.status.rejectedRelease': 'Rejected Release',
  'profile.myInvestments.status.returnedRelease': 'Returned Release',

  'project.investDrawer.title.yourInvest': 'Investment',
  'project.investDrawer.text.yourInvest':
    'Enter the amount and source of funds you want to invest',
  'project.investDrawer.title.yourCode': 'Code',
  'project.investDrawer.text.yourCode':
    'Copy this code on the additional data field when you are transfering the funds.',

  'project.investDrawer.button.next': 'APORTAR',
  'project.investDrawer.valueReward': 'Valor del aporte',
  'project.investDrawer.select.rewards': 'Recompensas',
  'project.investDrawer.select.input': 'Selecciona cuanto quieres aportar',

  'project.investDrawer.messageError.number': 'Investment Amount',
  'project.investDrawer.messageError.origin': 'Source of Funds',

  'project.investDrawer.modal.title.moderate': 'The user is pending approval',
  'project.investDrawer.modal.title.new': 'New user',
  'project.investDrawer.modal.title.rejected': 'The user was not approved',

  'project.investDrawer.modal.title.user_not_exists':
    'The user is not allowed to make this transaction.',
  'project.investDrawer.modal.title.amount_lower':
    'The selected amount is less than the minimum investment for this project.',
  'project.investDrawer.modal.title.currency_not_supported':
    'The currency is not supported for this project.',

  'project.investDrawer.modal.newOne':
    'Your user is not allowed to make investments yet. You need to complete your ',
  'project.investDrawer.modal.newLink': 'profile',
  'project.investDrawer.modal.newTwo':
    'and submit it for review by the Wibai team.',

  'project.investDrawer.modal.rejectedOne':
    'Your user is not allowed to make investments yet. You need to complete your ',
  'project.investDrawer.modal.rejectedLink': 'profile',
  'project.investDrawer.modal.rejectedTwo':
    'and submit it for review by the Wibai team.',

  'project.investDrawer.modal.moderate':
    'Your user is not allowed to make investments yet. You will receive an email notification when your information gets reviewed. For more information, you can contact us at investors@propinvest.com.',

  'project.investDrawer.modal.rejected':
    'Your user is not allowed to make investments yet. You need to review the information submitted on your <Link to="/profile">profile</Link> and submit your changes for review by the Wibai team.',

  'project.investDrawer.stepOne.origin': 'Source of funds',
  'project.investDrawer.stepOne.currency': 'USD',

  'project.investDrawer.stepTwo.nationalAccountData': 'Local Bank Account',
  'project.investDrawer.stepTwo.headline': 'Account Holder',
  'project.investDrawer.stepTwo.cuit': 'CUIT',
  'project.investDrawer.stepTwo.cbuOrAlias': 'CBU/Alias',
  'project.investDrawer.stepTwo.accountNumber': 'Account number',

  'project.investDrawer.pdf.date': 'Date',
  'project.investDrawer.pdf.headline': 'Account Holder',
  'project.investDrawer.pdf.cuit': 'CUIT',
  'project.investDrawer.pdf.cbuOrAlias': 'CBU/ALIAS',
  'project.investDrawer.pdf.accountNumber': 'Account Number',
  'project.investDrawer.pdf.currency': 'Currency',
  'project.investDrawer.pdf.code': 'Code',
  'project.campaign.finished': 'Finished Campaign',

  'login.title': 'Log In',
  'login.input.user': 'User:',
  'login.input.error.mail': 'Invalid email',
  'login.input.password': 'Password:',
  'login.input.error.password': 'The maximum of characters allowed is 20',
  'login.input.error.obligatoryField': 'Mandatory field',
  'login.text.forgotPassword': 'Forgot my password',
  'login.text.notHaveAccount': 'New here? Sign Up',
  'login.button.login': 'Log In',
  'login.button.loginGoogle': 'Log In with Google',
  'login.error.INVALID_USER_PASS': 'Invalid user or password',
  'login.error.WRONG_PROVIDER':
    'You should choose "Log In With Google" to Log In',
  'login.error.USER_NOT_CONFIRMED':
    'You need to confirm your email account before logging in',
  'login.button.reSendMail': 'Resend confirmation email',
  'login.message.error.email': 'Está pendiente la confirmación del mail',
  'login.modal.mailConfirm': "We've sent you a confirmation email",
  'login.message.userInvalid': 'The username is invalid',

  'register.title': 'Sign Up',
  'register.input.name': 'Name',
  'register.input.lastName': 'Last Name',
  'register.input.username': 'Email',
  'register.input.password': 'Password',

  'register.input.passwordConfirmation': 'Password confirmation',
  'register.input.error.mail': 'Invalid email',
  'register.input.error.password': "Passwords don't match",
  'register.button.text.send': 'Submit',
  'register.accountSucces': 'Account created successfully',
  'register.button.text.registerGoogle': 'Sign Up with Google',
  'register.input.tooltip':
    'Password requirements: at least one uppercase letter and one lowercase letter. At least one special character. Between 8 and 20 characters.',

  'recoverPassword.title': 'Forgot My Password',
  'recoverPassword.subTitle': 'Email address',
  'recoverPassword.success':
    "We've sent you an email with instructions to recover your password.",
  'recoverPassword.error.user': 'Username is invalid',
  'newPass.success': 'Password changed successfully',
  'newPass.title': 'Choose a new password',
  'newPass.input.confirm': 'Confirm password',

  'confirmationRequired.title': 'Email sent',
  'confirmationRequired.text':
    "Thank you for signing up. We've sent you a confirmation email",

  'accountConfirmation.title': 'Email account confirmed',
  'accountConfirmation.text': 'Your email account has been confirmed!',
  'accountConfirmation.button': 'Log In',

  'project.id.minimunAmountOfInvestment': 'Minimum amout of investment',
  'project.id.totalInvest': 'inversor',
  'project.id.totalInvests': 'colaboradores',
  'project.id.button.toInvest': 'invest',
  'project.create.input.category': 'Categoría',
  'project.id.button.closingDay': 'Closing Day',
  'project.id.button.multipleEquity': 'Equity Multiple',
  'project.id.year': 'year',
  'project.id.day': 'day',
  'project.id.remaining': 'remaining',

  'project.id.data.campOf': 'Field',
  'project.id.data.character': 'characters',
  'project.id.error.image': 'There was an error uploading the image',

  'register.data.title': 'Sign Up',
  'register.data.subTitlePesonal': 'Contact Info',
  'register.data.placeholderLocation': 'Example St., City, State.',
  'register.data.loadImage': 'Upload images',
  'register.data.tooltipImage':
    'Bank statement or address certification. Your name and address should be clearly displayed on the uploaded document. The issuance date of the document should not be older than 90 days. The uploaded image should be in color and in high definition.',
  'register.data.tooltipImageMax': 'Max Size: 5 MB',
  'register.data.tooltipImageNit':
    'The images must be clear, in color and without any type of cuts or not readable areas',
  'register.data.placeholderNumAccount': 'Bank account number',
  'register.data.placeholderTypeAccount': 'Choose an Account Type',

  'users.breadcrumb.start': 'Home',
  'users.breadcrumb.users': 'Users',
  'users.button.newUser': 'New User',
  'users.modal.acccept': 'Are you sure you want to approve this user?',
  'users.modal.reject': 'Are you sure you want to reject this user?',
  'users.table.name.new': 'Pending Approval',
  'users.table.name.moderate': 'Incomplete',
  'users.table.name.accepted': 'Enabled',
  'users.table.name.rejected': 'To be corrected',
  'users.table.roles.admin': 'Administrator',
  'users.table.roles.developer': 'Developer',
  'users.table.roles.inversor': 'colaborador',
  'users.table.title.id': 'Id',
  'users.table.title.name': 'Name',
  'users.table.title.lastName': 'Last Name',
  'users.table.title.username': 'User',
  'users.table.title.state': 'State',
  'users.table.title.roles': 'Role',
  'users.table.title.action': 'Actions',
  'users.table.button.viewEdit': 'View/Edit',
  'users.table.button.accepted': 'Approve',
  'users.table.button.rejected': 'Reject',
  'users.form.button.addAccount': 'Add Account',


  'admin.transactions.table.breadcrumb.start': 'Home',
  'admin.transactions.table.breadcrumb.transactions': 'Transactions',

  'admin.transactions.edit.breadcrumb.start': 'Home',
  'admin.transactions.edit.breadcrumb.transactions': 'Transactions',
  'admin.transactions.edit.breadcrumb.edit': 'Edit',

  'admin.transactions.table.title.id': 'Id',
  'admin.transactions.table.title.createdAt': 'Created At',
  'admin.transactions.table.title.investorUser': 'User',
  'admin.transactions.table.title.projectName': 'Project',
  'admin.transactions.table.title.amount': 'Amount',
  'admin.transactions.table.title.originId': 'Origin Id',
  'admin.transactions.table.title.status': 'Status',
  'admin.transactions.table.card.accredited': 'Accredited',
  'admin.transactions.table.card.developer': 'Developer',
  'admin.transactions.table.card.expired': 'Expired',
  'admin.transactions.table.edit': 'Edit',
  'admin.transactions.edit.amount': 'Amount',
  'admin.transactions.edit.currency': 'Currency',
  'admin.transactions.edit.state': 'State',
  'admin.transactions.edit.bindTransferIdRequiredMessage': 'BInd transfer Id is required',
  'admin.transactions.edit.bindTransferId': 'BInd transfer Id',
  'admin.transactions.edit.getBindTransfer': 'Fetch BInd transfer',
  'admin.transactions.edit.getBindTransferError': 'Error while fetching BInd transfer',
  'admin.transactions.edit.editTransaction': 'Edit transaction',
  'admin.transactions.edit.editTransactionError': 'Error on transaction edit',
  'admin.transactions.edit.cancel': 'Cancel',

  'admin.transactions.table.card.released': 'Done',
  'admin.transactions.table.card.rejected': 'Rejected',
  'admin.transactions.table.card.error': 'Error',
  'admin.transactions.table.card.pending': 'Pendign',
  'admin.transactions.table.card.onBindCall': 'Pending Bind call',
  'admin.transactions.table.card.onBindCallReturnAndRelease': 'In progress',
  'admin.transactions.table.card.releasedWithErrors': 'Done with errors',
  'admin.transactions.table.card.returnedReject': 'Return rejected',
  'admin.transactions.table.card.returnedWithErrors': 'Done with errors',
  'admin.transactions.table.card.returned': 'Devuelta',
  'admin.transactions.table.card.rejectWithErrors': 'Rejected with errors',
  'admin.transactions.table.card.rejectedRelease': 'Release rejected',
  'admin.transactions.table.card.returnedRelease': 'Return done',
  'admin.transactions.table.history': 'History',
  'admin.transactions.modal.close': 'Close',
  'admin.transactions.modal.upload': 'Last Update',

  'admin.transactions.table.breadcrumb.micros': 'Administración de Micrositios',
  'admin.microsite.error.selectProject': 'Por favor, seleccione un proyecto',

  'admin.transactions.table.title.linkToAccess': 'Link de acceso',
  'admin.transactions.table.title.name': 'Nombre',
  'admin.transactions.table.title.descp': 'Descripción',
  'admin.transactions.table.title.color': 'Color',
  'admin.transactions.createMicroButton': 'Crear Micrositio',
  'admin.transactions.editMicroButton': 'Editar Micrositio',
  'admin.microsite.error.linkExist': 'Por favor, eliga otro Link de acceso',
  'admin.microsite.logoImage': 'Logo',

  'validate.numericCamp': 'Numeric field',
  'validate.invalidCuit': 'Invalid CUIT',
  'validate.invalidCBU': 'Invalid CBU',
  'validate.invalidCBUAlias': 'Invalid Alias',
  'validate.validatePassword':
    "Password doesn't comply with the minimum security requirements",
  'validate.validateAge': 'You have to be at least 18 years old',
  'validate.specialCharacter':
    "The string shouldn't begin with a special character.",
  'validate.hexColor':
    'La cadena tiene que tener formato hexadecimal (#000000).',
  'validate.onlyText':
    'La cadena no puede contener caracteres especiales ni espacios.',
  'validate.validateDevelopment': 'Select an approved user',
  'validate.validURL': 'Invalid URL',
  'validate.confirmPassword': "Passwords doesn't match",
  'validate.validateCuil': 'Invalid CUIL format',
  'validate.onlyImage': 'The uploaded file is not an image',
  'validate.cannotExceedImage': 'The uploaded image should be less than 5 MB',
  'validate.selectValidAddress': 'Choose a valid location',
  'validate.errorSelectAddress': 'An error ocurred. Please, try again later.',

  'admin.country.table.breadcrumb.country': 'Países',

  'admin.country.table.code': 'Código',
  'admin.country.table.currency': 'Moneda',
  'admin.country.table.description': 'Descripción',
  'admin.country.table.MPAccessToken': 'MPAccessToken',
  'admin.country.table.country': 'País',


  'admin.menuBar.goToPropiInvest': 'Go to Wibai Web',
  'admin.menuBar.projects': 'Projects',
  'admin.menuBar.transactions': 'Transactions',
  'admin.menuBar.micro': 'Micrositios',
  'admin.menuBar.users': 'Users',
  'admin.menuBar.notifications': 'Notifications',
  'admin.menuBar.fundRelease': 'Fund Release',
  'admin.menuBar.returnFund': 'Return of Funds',
  'admin.menuBar.english': 'English',
  'admin.menuBar.spanish': 'Spanish',
  'admin.menuBar.language': 'Language',
  'admin.menuBar.countries': 'Países',

  'admin.fundRelease.breadcrumb.fundRelease': 'Fund Release',
  'admin.fundRelease.breadcrumb.fundReturn': 'Return of Funds',
  'admin.table.title.projectName': 'Project',
  'admin.table.title.currency': 'Currency',
  'admin.table.title.totalReturnAmount': 'Total amount to return',

  'admin.table.button.approve': 'Approve',
  'admin.table.button.reject': 'Reject',
  'admin.table.button.seeTransactions': 'View transactions',
  'admin.table.button.seeProject': 'View project',

  'admin.fundRelease.modal.accept':
    'Are you sure that you want to approve this release?',
  'admin.fundRelease.modal.reject':
    'Are you sure that you want to reject this release',
  'admin.fundReturn.modal.reject':
    'Are you sure that you want to reject this devolution?',
  'admin.fundReturn.modal.accept':
    'Are you sure that you want to accept this devolution?',

  'admin.transactions.status.pending': 'Pending',
  'admin.transactions.status.expired': 'Expired',
  'admin.transactions.status.accredited': 'Accredited',
  'admin.transactions.status.released': 'Released',
  'admin.transactions.status.noReleased': 'Not Released',
  'admin.transactions.status.refunded': 'Refunded',
  'admin.transactions.status.noRefunded': 'Not Refunded',
  'admin.transactions.status.error': 'Error',
  'admin.transactions.status.errorBind': 'Error Bind',

  'admin.transactions.status.onBindCall': 'In progress',

  'admin.returns.status.done': 'Done',
  'admin.returns.status.rejected': 'Rejected',
  'admin.returns.status.doneWhitErrors': 'Done with errors',
  'admin.returns.status.rejectedWhitErrors': 'Rejected with errors'
};

const appLocaleEn = {
  messages: {
    ...culture
  },
  antd: en_US,
  locale: 'en',
  data: appLocaleData
};

export default appLocaleEn;
