import axios from 'axios';

import history from '../history';

import { addAxiosToken } from '../service/axios';
import { changeState } from './auth';

export const CREATE_USER = 'CREATE_USER';
export const CHANGE_USER_STATE = 'CHANGE_USER_STATE';
export const LOGIN_DATA = 'LOGIN_DATA';
export const LOGIN_ACCOUNT = 'LOGIN_ACCOUNT';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const GET_USER_INFO = 'GET_USER_INFO';
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const CHANGE_REDIRECT_STATE = 'CHANGE_REDIRECT_STATE';

export const createUser = model => ({
  type: CREATE_USER,
  payload: axios.post('/account/register/', model)
    .then(json => ({ user: json.data }))
});

export const changeUserState = (state, complete) => ({
  type: CHANGE_USER_STATE,
  payload: { state, complete }
});

export const changeRedirect = () => ({
  type: CHANGE_REDIRECT_STATE,
});

export const loginInvestor = (model, redirect) => (dispatch) => {
  const response = dispatch({
    type: LOGIN_ACCOUNT,
    payload: axios.post('/auth/login', model)
      .then((json) => {
        addAxiosToken(json.data.token);

        return ({ ...json.data, provider: 'local', redirect });
      })
      .catch(error => Promise.reject(error))
  });

  response.then(() => dispatch(changeState(false)));
  return Promise.resolve(response);
};

export const loginWhitGoogle = (model, redirect) => (dispatch) => {
  const response = dispatch({
    type: LOGIN_ACCOUNT,
    payload: axios.post('/auth/login/google', model)
      .then((json) => {
        addAxiosToken(json.data.token);

        return ({ ...json.data, provider: 'google', redirect });
      })
      .catch(error => Promise.reject(error))
  });

  response.then(() => dispatch(changeState(false)));
  return Promise.resolve(response);
};

export const loginData = data => ({
  type: LOGIN_DATA,
  payload: { ...data }
});

export const signoutUser = (token, username) => (dispatch) => {
  dispatch({
    type: SIGNOUT_REQUEST,
    payload: { token, username }
  });
  history.push('/');
};

export const getUserInfo = username => ({
  type: GET_USER_INFO,
  payload: axios.get(`/users/${username}`)
    .then(response => ({ user: response.data }))
    .catch(error => Promise.reject(error))
});

export const removeToken = () => ({ type: REMOVE_TOKEN });
