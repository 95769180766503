import { defineMessages } from 'react-intl';

const messages = defineMessages({
  breadcrumbStart: { id: 'users.breadcrumb.start' },
  breadcrumbFundRelease: { id: 'admin.fundRelease.breadcrumb.fundRelease' },
  idTitle: { id: 'users.table.title.id' },
  projectName: { id: 'admin.table.title.projectName' },
  currency: { id: 'admin.table.title.currency' },
  totalReturnAmount: { id: 'admin.table.title.totalReturnAmount' },
  actionTitle: { id: 'users.table.title.action' },
  approve: { id: 'admin.table.button.approve' },
  reject: { id: 'admin.table.button.reject' },
  seeTransactions: { id: 'admin.table.button.seeTransactions' },
  seeProject: { id: 'admin.table.button.seeProject' },
  createdAtTitle: { id: 'admin.transactions.table.title.createdAt' },
  investorUserTitle: { id: 'admin.transactions.table.title.investorUser' },
  projectNameTitle: { id: 'admin.transactions.table.title.projectName' },
  amountTitle: { id: 'admin.transactions.table.title.amount' },
  originIdTitle: { id: 'admin.transactions.table.title.originId' },
  statusTitle: { id: 'admin.transactions.table.title.status' },
  close: { id: 'admin.transactions.modal.close' },
  transactions: { id: 'admin.menuBar.transactions' },
  acceptModal: { id: 'admin.fundRelease.modal.accept' },
  rejectModal: { id: 'admin.fundRelease.modal.reject' },
  cancelButton: { id: 'project.create.input.cancelButton' },
  acceptButton: { id: 'project.investDrawer.button.accepted' },

  pending: { id: 'admin.transactions.table.card.pending' },
  onBindCall: { id: 'admin.transactions.table.card.onBindCallReturnAndRelease' },
  released: { id: 'admin.transactions.table.card.released' },
  rejected: { id: 'admin.transactions.table.card.rejected' },
  error: { id: 'admin.transactions.table.card.error' },
  rejectedRelease: { id: 'admin.transactions.table.card.rejectedRelease' },
  releasedWithErrors: { id: 'admin.transactions.table.card.releasedWithErrors' },

  pendingTransaction: { id: 'admin.transactions.status.pending' },
  expiredTransaction: { id: 'admin.transactions.status.expired' },
  accreditedTransaction: { id: 'admin.transactions.status.accredited' },
  releasedTransaction: { id: 'admin.transactions.status.released' },
  rejectedReleaseTransaction: { id: 'admin.transactions.status.noReleased' },
  returnedTransaction: { id: 'admin.transactions.status.refunded' },
  returnedWithErrorsTransaction: { id: 'admin.transactions.table.card.returnedWithErrors' },
  errorTransaction: { id: 'admin.transactions.status.error' },
  returnedRejectTransaction: { id: 'admin.transactions.status.rejected' },
  onBindCallTransaction: { id: 'admin.transactions.status.errorBind' },
});


export default messages;
