import React from 'react';
import moment from 'moment';
import {
  Table, Button, Icon, Divider, Tag
} from 'antd';

const projectStatusColors = {
  inactive: 'cyan',
  active: 'green'
};

const DataTable = ({
  data, loading, intl, messages, editAction, activeMicrosite
}) => {
  const columns = [{
    title: intl.formatMessage(messages.idTitle),
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.creationDate),
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => (a.createdAt - b.createdAt),
    sortDirections: ['descend', 'ascend'],
    // eslint-disable-next-line react/display-name
    render: createdAt => (
      <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
    )
  }, {
    title: intl.formatMessage(messages.name),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.linkToAccess),
    dataIndex: 'accessLink',
    key: 'accessLink',
    sorter: (a, b) => a.accessLink.localeCompare(b.accessLink),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: intl.formatMessage(messages.activeKey),
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
    // eslint-disable-next-line react/display-name
    render: text => <Tag color={projectStatusColors[text]}>{intl.formatMessage(messages[text])}</Tag>,
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.actionTitle),
    key: 'action',
    dataIndex: '_id',
    // eslint-disable-next-line react/display-name
    render: (_id, record) => (
      <span>
        <Button
          onClick={() => editAction(record)}
          className="buttonActions"
          title={intl.formatMessage(messages.breadcrumbThirdEdit)}
        >
          <Icon type="edit" />
        </Button>

        <Divider type="vertical" />
        <Button
          onClick={() => activeMicrosite(record)}
          className="buttonActions"
          title={intl.formatMessage(record.status === 'active' ? messages.inactivateButton : messages.activateButton)}
        ><Icon type={record.status === 'active' ? 'minus-circle' : 'plus-circle'} />
        </Button>
      </span>
    )
  }];
  return (
    <Table dataSource={data} columns={columns} rowKey="_id" loading={loading} />
  );
};

export default DataTable;
