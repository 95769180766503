import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Layout, Breadcrumb, Row, Col, Button, Modal,
} from 'antd';

import { getAllMicrosites , activeMicrosite } from '../../../actions/microsite';

import messages from './commons/culture';
import DataTable from './commons/table';
import '../../commons/css/users.css';

const Users = ({
  history, intl
}) => {
  const dispatch = useDispatch();
  const microsite = useSelector(state => state.microsite);

  useEffect(() => {
    dispatch(getAllMicrosites());
  }, []);

  const handleCreate = () => {
    history.push('/admin/microsite/create');
  };

  const handleEdit = (data) => {
    history.push({ pathname: `/admin/microsite/edit/${data.id}` });
  };

  const handleActive = (microsite) => {
    const { confirm } = Modal;
    confirm({
      title: intl.formatMessage(microsite.status === 'active' ? messages.questionInactivateMicro : messages.questionActivateMicro),
      content: microsite.name,
      onOk() {
        dispatch(activeMicrosite (microsite.id)).then(() => document.location.reload(true))
      },
      onCancel() {},
    });
  }

  return (
    <Layout style={{ margin: '0 16px' }}>
      <Row>
        <Col span={21}>
          <Breadcrumb>
            <Breadcrumb.Item>{intl.formatMessage(messages.start)}</Breadcrumb.Item>
            <Breadcrumb.Item>{intl.formatMessage(messages.microsTitle)}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={3}>
          <Button
            type="primary"
            onClick={handleCreate}
            className="buttonUsers"
          >{intl.formatMessage(messages.newMicroButton)}
          </Button>
        </Col>
      </Row>
      <div className="content">
        <DataTable
          data={microsite.list}
          loading={microsite.isFetching}
          intl={intl}
          messages={messages}
          editAction={handleEdit}
          activeMicrosite={handleActive}
        />
      </div>
    </Layout>
  );
};

export default injectIntl(Users);
