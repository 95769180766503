import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Layout, Row, Col, Form, Button, Alert
} from 'antd';
import styled from 'styled-components';

import '../registration.css';
import { FormItemInput } from '../../../commons/formComponents/formComponents';
import { resetPassword } from '../../../../actions/registration';

import messages from '../culture';

const { Content } = Layout;

const RegisterContainer = styled.div`
  & {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 5rem 10%;
  }

  & h1 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0;
  }

  & h4.form-title {
    opacity: 0.55;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
  }

  & input {
    height: 45px;
  }

  & .ant-form-item {
    margin-bottom: 1rem;
  }
`;

const RegisterButton = styled.div`
  & button {
    border-radius: 5px;
    height: 45px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
    border-color: transparent;
    margin-top: ${props => props.secondary ? '1.5rem' : '2rem'};
  }

  & button:hover {
    border-color: transparent;
    color: #fff;
  }

  & button:active {
    border-color: transparent;
    color: #fff;
  }

  & button:focus {
    border-color: transparent;
    color: #fff;
  }
`;

class RecoverPasswordForm extends Component {
  state = { messageResponse: '', type: '' }

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'auto'
    });
  }

  handleSubmit = (e) => {
    const {
      form, reset, history, intl
    } = this.props;
    this.setState({ messageResponse: '' });

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        reset(values)
          .then(() => history.push({ pathname: '/account/login', state: { messageProps: intl.formatMessage(messages.recoverPasswordSuccess), titleProps: '' } }))
          .catch((error) => {
            if (error.response.status === 422) this.setState({ messageResponse: intl.formatMessage(messages.recoverPasswordErrorUser), type: 'error' });
          });
      }
    });
  }

  render() {
    const { form, loading, intl } = this.props;
    const { messageResponse, type } = this.state;

    return (
      <Content style={{ background: '#eee', paddingBottom: 20, paddingTop: 100 }}>
        {messageResponse && (
          <Row style={{ marginTop: 20 }} type="flex" justify="center">
            <Col md={{ span: 10 }} xs={{ span: 20 }}>
              <Alert message={messageResponse} type={type} closable />
            </Col>
          </Row>
        )}

        <Row style={{ marginTop: 10 }} type="flex" justify="center">
          <Col md={{ span: 10 }} xs={{ span: 20 }}>
            <RegisterContainer>
              <h1 className="primary-text">{intl.formatMessage(messages.titleRecoverPassword)}</h1>
              <h4 className="form-title">{intl.formatMessage(messages.subTitleRecoverPassword)}</h4>
              <Form onSubmit={this.handleSubmit}>
                <FormItemInput
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.usernameInputRegister)}
                  field="username"
                  rules={[
                    { type: 'email', message: intl.formatMessage(messages.errorMailInput) },
                    { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                  ]}
                />
                <RegisterButton>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                  >
                    {intl.formatMessage(messages.textSend)}
                  </Button>
                </RegisterButton>
              </Form>
            </RegisterContainer>
          </Col>
        </Row>
      </Content>
    );
  }
}

const RecoverPassword = Form.create({ name: 'recoverPassword' })(RecoverPasswordForm);

const mapStateToProps = state => ({ loading: state.registration.isFetching });

const mapDispatchToProps = dispatch => bindActionCreators({ reset: resetPassword }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RecoverPassword));
