import axios from 'axios';

export const GET_FUND_RELEASE = 'GET_FUND_RELEASE';
export const APROVE_RELEASE = 'APROVE_RELEASE';
export const REJECT_RELEASE = 'REJECT_RELEASE';
export const CHANGE_RELEASE_STATUS = 'CHANGE_RELEASE_STATUS';

export const getFundReleases = () => ({
  type: GET_FUND_RELEASE,
  payload: axios
    .get('/releases')
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});

export const changeReleaseStatus = (model, index) => ({
  type: CHANGE_RELEASE_STATUS,
  payload: axios
    .post('/releases/changeReleaseStatus', model)
    .then(response => ({ data: response.data, index }))
    .catch(error => Promise.reject(error))
});

// export const aproveReleases = (id, index) => ({
//   type: APROVE_RELEASE,
//   payload: axios
//     .post(`/releases/approve/${id}`)
//     .then(response => ({ data: response.data, index }))
//     .catch(error => Promise.reject(error))
// });

// export const rejectReleases = (id, index) => ({
//   type: REJECT_RELEASE,
//   payload: axios
//     .post(`/releases/reject/${id}`)
//     .then(response => ({ data: response.data, index }))
//     .catch(error => Promise.reject(error))
// });
