import { CHANGE_CULTURE, STOP_RELOADING, CHANGE_COUNTRY } from '../actions/culture';

const INITIAL_STATE = {
  culture: 'es-AR',
  isReLoading: false,
  country: 'arg'
};

const culture = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case `${CHANGE_CULTURE}`:
    return {
      culture: action.payload,
      isReLoading: true
    };
  case `${CHANGE_COUNTRY}`:
    return {
      ...state,
      country: action.payload
    };  
  case `${STOP_RELOADING}`:
    return {
      ...state,
      isReLoading: false
    };
  default:
    return state;
  }
};
export default culture;
