/* eslint-disable arrow-parens */
import React from 'react';
import {
  Table, Button, Icon, Tag, Divider
} from 'antd';
import moment from 'moment';

const projectStatusColors = {
  draft: 'cyan',
  published: 'green',
  finished: 'red'
};

const projectStatusActiveColors = {
  inactive: 'cyan',
  active: 'green'
};

const DataTable = ({
  data, loading, editAction, intl, messages, editProject, activeProject
}) => {
  const columns = [{
    title: intl.formatMessage(messages.idKey),
    dataIndex: 'id',
    key: 'id',
    // eslint-disable-next-line no-underscore-dangle
    sorter: (a, b) => (a.id - b.id),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.nameKey),
    dataIndex: 'name',
    key: 'name',
    onFilter: (value, record) => record.name.indexOf(value) === 0,
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['descend', 'ascend']
  }, {
    title: intl.formatMessage(messages.developerKey),
    dataIndex: 'developer.username',
    key: 'developer.username',
    sorter: (a, b) => a.developer.username.localeCompare(b.developer.username),
    sortDirections: ['descend', 'ascend'],
  }, {
    title: intl.formatMessage(messages.statusKey),
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => a.compileStatus.localeCompare(b.compileStatus),
    // eslint-disable-next-line react/display-name
  render: (text,record) => <Tag color={projectStatusColors[record.compileStatus]}>{intl.formatMessage(messages[record.compileStatus])}</Tag>,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: intl.formatMessage(messages.activeKey),
    dataIndex: 'statusActive',
    key: 'statusActive',
    sorter: (a, b) => a.statusActive.localeCompare(b.statusActive),
    // eslint-disable-next-line react/display-name
    render: (text) => <Tag color={projectStatusActiveColors[text]}>{intl.formatMessage(messages[text])}</Tag>,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: intl.formatMessage(messages.creationDateKey),
    dataIndex: 'creationDate',
    key: 'creationDate',
    sorter: (a, b) => a.creationDate.localeCompare(b.creationDate),
    sortDirections: ['descend', 'ascend'],
    // eslint-disable-next-line react/display-name
    render: creationDate => (
      <span>{moment(creationDate).format('DD/MM/YYYY')}</span>
    )
  }, {
    title: intl.formatMessage(messages.actionKey),
    key: 'action',
    dataIndex: 'id',
    // eslint-disable-next-line react/display-name
    render: (_id, record) => {
      // const campaign = record.campaigns.find(c => c.releasedOrNot);

      return (
        <span>
          <Button
            onClick={() => editAction(_id, record)}
            className="buttonActions"
            title={intl.formatMessage(messages.breadcrumbThirdEdit)}
          ><Icon type="edit" />
          </Button>
          {record.status !== 'published' && (
            <React.Fragment>
              <Divider type="vertical" />
              <Button
                onClick={() => editProject(record)}
                className="buttonActions"
                title={intl.formatMessage(messages.publishButton)}
              ><Icon type="check-circle" />
              </Button>
            </React.Fragment>
          )}

          {moment(record.campaigns[0].publishedAt).add(record.campaigns[0].days, 'days') >= moment() && (
            <React.Fragment>
              <Divider type="vertical" />
              <Button
                onClick={() => activeProject(record)}
                className="buttonActions"
                title={intl.formatMessage(record.statusActive === 'active' ? messages.inactivateButton : messages.activateButton)}
              ><Icon type={record.statusActive === 'active' ? 'minus-circle' : 'plus-circle'} />
              </Button>
            </React.Fragment>
          )}




        </span>
      );
    },
  }];

  return (
    <Table dataSource={data} columns={columns} rowKey="_id" loading={loading} />
  );
};

export default DataTable;
