import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Layout, Breadcrumb, Button, Row, Col, Modal, Alert
} from 'antd';

import { showProjects, publishProject, activeProject } from '../../../actions/projects';
import DataTable from './table';
import messages from './create/culture/messages';

class Projects extends Component {
  componentDidMount() {
    this.props.showProjects();
  }

  handleEditProject = (id, record) => {
    const { history } = this.props;
    history.push({ pathname: `/admin/projects/edit/${id}`, state: { project: record } });
  }

  handleAddNew = () => {
    const { history } = this.props;
    history.push('/admin/projects/create');
  }

  handleAccept = (project) => {
    const { confirm } = Modal;
    const { publish, intl } = this.props;

    confirm({
      title: intl.formatMessage(messages.questionPublish),
      content: project.name,
      onOk() {
        publish(project.id)
          .then(() => document.location.reload(true));
      },
      onCancel() {},
    });
  }

  handleActive = (project) => {
    const { confirm } = Modal;
    const { active, intl } = this.props;

    confirm({
      title: intl.formatMessage(project.statusActive === 'active' ? messages.questionInactivate : messages.questionActivate),
      content: project.name,
      onOk() {
        active(project.id)
          .then(() => document.location.reload(true));
      },
      onCancel() {},
    });
  }

  render() {
    const { projects, intl } = this.props;

    return (
      <Layout style={{ margin: '0 16px' }}>
        <Row>
          <Col span={21}>
            <Breadcrumb>
              <Breadcrumb.Item>{intl.formatMessage(messages.startTab)}</Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(messages.projectTab)}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={this.handleAddNew} className="buttonUsers">{intl.formatMessage(messages.newProjectButton)}</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{projects.error ? <Alert message={projects.error} type="error" className="errors" /> : null }</Col>
        </Row>
        <div className="content">
          <DataTable
            intl={intl}
            messages={messages}
            data={projects.list}
            loading={projects.isFetching}
            editAction={this.handleEditProject}
            editProject={this.handleAccept}
            activeProject={this.handleActive}
          />
        </div>
      </Layout>
    );
  }
}


const mapStateToProps = state => ({
  projects: state.projects,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  showProjects,
  publish: publishProject,
  active: activeProject
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Projects));
