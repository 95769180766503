import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  Layout, Row, Col, Form, Button, Alert, message, Input, Icon, Radio
} from 'antd';
import styled from 'styled-components';

import config from '../../../config';

import './registration.css';
import { FormItemInput } from '../../commons/formComponents/formComponents';
import { loginInvestor, loginWhitGoogle } from '../../../actions/user';
import { createInvestor, reSendConfirmation } from '../../../actions/registration';
import logo from '../../../images/wibai-logo.svg';

import messages from './culture';
import { constant } from 'lodash';

const { Content } = Layout;

const styles = {
  title: {
    color: '#3c0199',
    fontSize: '45px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    lineHeight: '1.21'
  },
  sendButton: {
    backgroundColor: '#3c0199',
    textFamily: 'Roboto',
    height: 56,
    marginBottom: 10,
    fontWeight: 800,
    borderRadius: 40
  },
  googleButton: {
    backgroundColor: '#417ddf',
    textFamily: 'Roboto',
    height: 56,
    color: 'white',
    fontWeight: 800,
    borderRadius: 40
  }
};

const LoginContainer = styled.div`
  & {
    background-color: #fff;
    padding: 3rem 2rem 2rem 2rem;
    border-radius: 5px;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.52);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
  }
  

  & form {
    margin-top: 2rem;
  }

  & input {
    height: 45px;
  }

  & input#register_username {
    margin-bottom: 1rem;
  }

  & .ant-alert-message {
    margin-right: 10px;
  }

  @media (max-width: 992px) {
    width: 80%;
    padding: 3rem 0;
  }

  @media (max-width: 480px) {
    width: 96%;
    padding: 3rem 0;
  }

  @media (max-width: 1025px) {
    button {
      font-size: 12px !important;
    }
  }
`;

const LoginButton = styled.div`
  & button {
    border-radius: 5px;
    height: 45px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
    border-color: transparent;
    margin-top: ${props => props.secondary ? '1.5rem' : '2rem'};
  }

  & button:hover {
    border-color: transparent;
    color: #fff;
  }

  & button:active {
    border-color: transparent;
    color: #fff;
  }

  & button:focus {
    border-color: transparent;
    color: #fff;
  }
`;

class LoginForm extends Component {
  state = { messageResponse: '', showResendPassword: false, view: true, invitado: false, showLogin: false }

  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.messageProps) message.success(location.state.messageProps);
    window.scroll({
      top: 0,
      behavior: 'auto'
    });
  }

  handleSubmit = (e) => {
    const {
      form, login, location, intl
    } = this.props;

    e.preventDefault();

    this.setState({ messageResponse: '' });

    form.validateFields((err, values) => {
      this.formIsValid = !err;
      if (!err) {
        const model = { ...values };
        login(model, location.state ? location.state.redirect + '/investment' : false)
          .catch((error) => {
            if (error.response.data.code === 'invalid_login') this.setState({ messageResponse: intl.formatMessage(messages.INVALID_USER_PASS) });
            else if (error.response.data.code === 'wrong_provider') this.setState({ messageResponse: intl.formatMessage(messages.WRONG_PROVIDER) });
            else if (error.response.data.code === 'user_not_confirmed') this.setState({ messageResponse: intl.formatMessage(messages.errorConfirmMail), showResendPassword: true });
          });
      }
    });
  }

  handleSubmitInvited = (e) => {
    const {
      form, location, login, create, intl
    } = this.props;

    e.preventDefault();

    this.setState({ messageResponse: '' });

    form.validateFields((err, values) => {
      this.formIsValid = !err;
      if (!err) {
        const username = values.emailGuest;
        const password = 'Invitado$2020';
        const model = {
          password,
          passwordConfirmation: password,
          name: values.nombre,
          lastName: values.apellido,
          username,
          guest: true
        };


        create(model)
          .then(() => {

            const loginData = {
              username,
              password
            };

            login(loginData, location.state ? location.state.redirect + '/investment' : false)
              .catch((error) => {
                console.log('test')
                if (error.response.data.code === 'invalid_login') this.setState({ messageResponse: intl.formatMessage(messages.INVALID_USER_PASS) });
                else if (error.response.data.code === 'wrong_provider') this.setState({ messageResponse: intl.formatMessage(messages.WRONG_PROVIDER) });
                else if (error.response.data.code === 'user_not_confirmed') this.setState({ messageResponse: intl.formatMessage(messages.errorConfirmMail), showResendPassword: true });
              });

          }).catch((error) => {
            console.log('test', error.response.data)
            if (error.response.data.code === 'invalid_login') this.setState({ messageResponse: intl.formatMessage(messages.INVALID_USER_PASS) });
            else if (error.response.data.code === 'wrong_provider') this.setState({ messageResponse: intl.formatMessage(messages.WRONG_PROVIDER) });
            else if (error.response.data.code === 'user_not_confirmed') this.setState({ messageResponse: intl.formatMessage(messages.errorConfirmMail), showResendPassword: true });
            else if (error.response.data.code === 'user_exists') this.setState({ messageResponse: 'Email ya registrado.', showLogin: true });
          });

      }
    });
  }

  onCloseAlert = () => {
    this.setState({ showResendPassword: false,  messageResponse: ''  });
  }

  handleResendConfirmationEmail = (e) => {
    const { form, resend, intl } = this.props;
    this.setState({ messageResponse: '' });

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        resend(values)
          .then(() => message.success(intl.formatMessage(messages.mailConfirm)))
          .catch((error) => {
            if (error.response.status === 422) this.setState({ messageResponse: intl.formatMessage(messages.userInvalid) });
          });
      }
    });
  }

  responseGoogle = (response) => {
    const { loginGoogle, location, intl } = this.props;
    this.setState({ messageResponse: '' });

    if (!response.error) {
      const googleToken = response.getAuthResponse().id_token;
      loginGoogle({ token: googleToken }, location.state ? location.state.redirect : false)
        .catch((error) => {
          if (error.response.status === 401) this.setState({ messageResponse: intl.formatMessage(messages.INVALID_USER_PASS) });
        });
    }
  }

  handleInvitado = () => {
    this.setState({ invitado: !this.state.invitado, messageResponse: ''  });
  }



  render() {
    const {
      form, user, intl, registration, location
    } = this.props;
    const { messageResponse, showResendPassword, view, invitado, showLogin } = this.state;

    return (
      <Content style={{ background: '#fff', paddingTop: 40 }} className="login">
        <LoginContainer>
          <img src={logo} alt="logo" style={{ width: '40%' }} />
          
          <Radio className={(location.state && location.state.redirect ? '' : 'display-none')} onClick={this.handleInvitado} checked={invitado} style={{ marginTop: 20 }}>Quiero colaborar como invitado</Radio>



          <Form style={{ width: '80%' }} onSubmit={this.handleSubmitInvited} className={(invitado ? '' : 'display-none')}>

            <div style={{ textAlign: 'center' }}>
              No hace falta que te registres, ingresá tu Nombre, Apellido e Email para que el dueño del proyecto pueda identificar tu aporte.
            </div>
            <Form.Item style={{ marginTop: 20 }} >
              <FormItemInput
                FormComponent={Form}
                form={form}
                placeholder={'Nombre'}
                field="nombre"
                rules={[
                  { required: (invitado ? true : false), message: intl.formatMessage(messages.obligatoryFieldInput) },
                ]}
              />
            </Form.Item>
            <Form.Item style={{ marginTop: 20 }} >
              <FormItemInput
                FormComponent={Form}
                form={form}
                placeholder={'Apellido'}
                field="apellido"
                rules={[
                  { required: (invitado ? true : false), message: intl.formatMessage(messages.obligatoryFieldInput) },
                ]}
              />
            </Form.Item>
            <Form.Item style={{ marginTop: 15 }}>
              <FormItemInput
                FormComponent={Form}
                form={form}
                placeholder={'Email'}
                field="emailGuest"
                autocomplete={false}
                rules={[
                  { type: 'email', message: intl.formatMessage(messages.errorMailInput) },
                  { required: (invitado ? true : false), message: intl.formatMessage(messages.obligatoryFieldInput) },
                ]}
              />
            </Form.Item>

            {messageResponse && (
              <Row style={{ marginTop: 20 }}>
                <Col md={24} xs={24}>
                  <Alert
                    message={(
                      <React.Fragment>
                        {messageResponse}
                        <Button type="link" onClick={this.handleInvitado}>
                            Iniciar Sesión
                        </Button>
                      </React.Fragment>
                    )}
                    type="error"
                    closable
                    onClose={this.onCloseAlert}
                  />
                  {showResendPassword && (
                    <Button type="link" style={{ marginTop: 10, float: 'right' }} onClick={this.handleResendConfirmationEmail}>
                      {intl.formatMessage(messages.reSendMail)}
                    </Button>
                  )}


                </Col>
              </Row>
            )}

            <Form.Item>
              <LoginButton>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={user.isFetching || registration.isFetching}
                >COLABORAR
                </Button>
              </LoginButton>
            </Form.Item>

            
          </Form>

          <Form style={{ width: '80%' }} onSubmit={this.handleSubmit} className={(invitado ? 'display-none' : '')}>
            <FormItemInput
              FormComponent={Form}
              form={form}
              placeholder={intl.formatMessage(messages.userInput)}
              field="username"
              rules={[
                { type: 'email', message: intl.formatMessage(messages.errorMailInput) },
                { required: (invitado ? false : true), message: intl.formatMessage(messages.obligatoryFieldInput) },
              ]}
            />
            <Form.Item>
              {form.getFieldDecorator('password', {
                rules: [
                  { required: (invitado ? false : true), message: intl.formatMessage(messages.obligatoryFieldInput) },
                  { max: 20, message: intl.formatMessage(messages.errorPasswordInput) }
                ]
              })(
                <Input
                  type={view ? 'password' : 'text'}
                  placeholder={intl.formatMessage(messages.passwordInput)}
                  suffix={(
                    <Icon
                      type={view ? 'eye-invisible' : 'eye'}
                      style={{ color: 'rgba(0,0,0,.45)', marginLeft: 5, cursor: 'pointer' }}
                      onClick={() => this.setState(prevState => ({ view: !prevState.view }))}
                    />
                  )}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Link to="/account/recoverPassword" style={{ float: 'right' }}>{intl.formatMessage(messages.forgotPassword)}</Link>
            </Form.Item>

            {messageResponse && (
              <Row style={{ marginTop: 20 }}>
                <Col md={24} xs={24}>
                  <Alert
                    message={messageResponse}
                    type="error"
                    closable
                    onClose={this.onCloseAlert}
                  />
                  {showResendPassword && (
                    <Button type="link" style={{ marginTop: 10, float: 'right' }} onClick={this.handleResendConfirmationEmail}>
                      {intl.formatMessage(messages.reSendMail)}
                    </Button>
                  )}

                  {showLogin && (
                    <LoginButton>
                      <Button type="danger" style={{ marginTop: 10, width: '100%' }} onClick={this.handleInvitado}>
                      Iniciar Sesión
                      </Button>
                    </LoginButton>
                  )}


                </Col>
              </Row>
            )}
            
            <Form.Item>
              <LoginButton>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={user.isFetching}
                >{intl.formatMessage(messages.loginText)}
                </Button>
              </LoginButton>
              <GoogleLogin
                clientId={config.GOOGLE_ID_CLIENT}
                buttonText="Login"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                render={renderProps => (
                  <LoginButton secondary><Button className="secondary" icon="google" onClick={renderProps.onClick} type="default" block>{intl.formatMessage(messages.loginGoogleText)}</Button></LoginButton>
                )}
              />



              <GoogleLogin
                clientId={config.GOOGLE_ID_CLIENT}
                render={renderProps => (
                  <button onClick={renderProps.onClick} style={{ display: 'none' }} disabled={true}>This is my custom Google button</button>
                )}
                buttonText="Login"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
              />


            </Form.Item>
            {/* <Form.Item>
              <Link
                to={{
                  pathname: '/account/register',
                  state: { redirect: location.state ? location.state.redirect : false }
                }}
                style={{ float: 'right', marginBottom: '50px' }}
              >{intl.formatMessage(messages.notHaveAccount)}
              </Link>
            </Form.Item> */}
          </Form>
        </LoginContainer>
        {/* <Row style={{ marginTop: 10 }} type="flex" justify="center">
          <Col md={{ span: 10 }} xs={{ span: 20 }}>
            <Form onSubmit={this.handleSubmit}>
              <FormItemInput
                FormComponent={Form}
                form={form}
                label={intl.formatMessage(messages.userInput)}
                field="username"
                rules={[
                  { type: 'email', message: intl.formatMessage(messages.errorMailInput) },
                  { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                ]}
              />
              <Form.Item label={intl.formatMessage(messages.passwordInput)}>
                {form.getFieldDecorator('password', {
                  rules: [
                    { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                    { max: 20, message: intl.formatMessage(messages.errorPasswordInput) }
                  ]
                })(
                  <Input
                    type={view ? 'password' : 'text'}
                    suffix={(
                      <Icon
                        type={view ? 'eye-invisible' : 'eye'}
                        style={{ color: 'rgba(0,0,0,.45)', marginLeft: 5, cursor: 'pointer' }}
                        onClick={() => this.setState(prevState => ({ view: !prevState.view }))}
                      />
                    )}
                  />
                )}
              </Form.Item>
              <Form.Item style={{ marginBottom: '20px' }}>
                <Link to="/account/recoverPassword" style={{ float: 'right' }}>{intl.formatMessage(messages.forgotPassword)}</Link>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={user.isFetching}
                  style={styles.sendButton}
                >{intl.formatMessage(messages.loginText)}
                </Button>
                <GoogleLogin
                  clientId={config.GOOGLE_ID_CLIENT}
                  buttonText="Login"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  render={renderProps => (
                    <Button icon="google" onClick={renderProps.onClick} type="default" style={styles.googleButton} block>{intl.formatMessage(messages.loginGoogleText)}</Button>
                  )}
                />
              </Form.Item>
              <Form.Item>
                <Link
                  to={{
                    pathname: '/account/register',
                    state: { redirect: location.state ? location.state.redirect : false }
                  }}
                  style={{ float: 'right', marginBottom: '50px' }}
                >{intl.formatMessage(messages.notHaveAccount)}
                </Link>
              </Form.Item>
            </Form>
          </Col>
        </Row> */}
      </Content>

    );
  }
}

const Login = Form.create({ name: 'register' })(LoginForm);

const mapStateToProps = state => ({ user: state.user, registration: state.registration });

const mapDispatchToProps = dispatch => bindActionCreators({
  create: createInvestor,
  login: loginInvestor,
  loginGoogle: loginWhitGoogle,
  resend: reSendConfirmation
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));
