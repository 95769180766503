import React, { Component } from 'react';
import { Drawer } from 'antd';
import SupportStep1 from './supportStep1';
import SupportStep2 from './supportStep2';

class SupportProyect extends Component {

    state = {
        visible: false
    };

    constructor(props) {
        super(props);
        this.step1 = React.createRef();
        this.step2 = React.createRef();
    }

    stepClick = () => {
        this.step1.current.changeVisibility();
        this.step2.current.changeVisibility();
    }

    showDrawer = () => {
        this.setState({
            visible: true
        });
    };

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        const { visible } = this.state;

        return (
            <Drawer
              placement="right"
              onClose={this.onClose}
              visible={visible}
              className="supportDrawer"
              width={400}
            >
                <SupportStep1 passClick={this.stepClick} ref={this.step1} />
                <SupportStep2 ref={this.step2} />
            </Drawer>
        );
    }
}

export default SupportProyect;
