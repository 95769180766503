/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { Col, Button, Progress } from 'antd';

import RemoteImage from '../../commons/remoteImage';
import imagePlaceholder from '../../../images/iconoUser.jpg';

const StyledCard = styled.div`
  & {
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #d1d1d1;
    background-color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  & .img-container {
    width: 100%;
    height: 262px;
    object-fit: contain;
    background-color: #ededed;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & img {
    width: auto !important;
    height: 262px !important;
    max-width: 100%;
  }

  & .body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 0 19px;
    text-align: center;
  }

  & h4 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }

  & h5 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #b7b7b7;
    margin-top: 30px;
    text-transform: uppercase;
  }

  & p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
`;

const CampaignContainer = styled.div`
  & {
    width: 100%;
  }

  & .bottomProgressMetadata {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
  }

  & .bottomProgressMetadata p {
    font-family: Roboto;
    font-size: 15.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }

  & .bottomProgressMetadata span {
    font-family: Roboto;
    font-size: 15.5px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    text-align: left;
  }
`;

const InversionButton = styled.div`
  & {
    text-align: center;
    margin-top: 1rem;
  }
  & button {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-transform: uppercase;
  }

  & button:hover {
    border-color: transparent;
    color: #fff;
  }
`;

const ProjectCard = ({
  project
}) => {
  const campaign = (project.campaigns || []).find(c => c.status === 'active' || c.status === 'pending');

  const percent = (campaign && campaign.totalRaised && campaign.objective) ? ((campaign.totalRaised * 100) / campaign.objective) : 0;

  return (
    <Col xs={24} lg={8} style={{marginTop: '2rem'}}>
      <StyledCard>
        <div>
          <div className="img-container">
            {project.mainImage
              ? (<RemoteImage url={project.mainImage.key} />)
              : (<img src={imagePlaceholder} alt="Placeholder" />)}
          </div>
          <div className="body-container">
            <div style={{ width: '100%' }}>
              <h5>{`${project.developer.name} ${project.developer.lastName}` || ''}</h5>
              <h4>{project.name || ''}</h4>
              <div style={{ wordWrap: 'break-word', textAlign: 'initial' }}>
                {project.briefDescription}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={{ padding: '2rem 19px' }}>
            {campaign && (
              <CampaignContainer>
                <Progress
                  percent={percent}
                  showInfo={false}
                  status="active"
                  strokeWidth={12}
                />
                <div className="bottomProgressMetadata">
                  <p>
                    <span className="warn-text">
                      ${' '}
                      <FormattedNumber
                        value={campaign.totalRaised}
                        currency="USD"
                      />{' '}
                      {project.country.currency}
                    </span>{' '}
                    recaudados
                  </p>
                </div>
              </CampaignContainer>

            )}
            <InversionButton>
              <Button type="primary">
                <Link to={`/projects/${project.id}`}>
                  VER PROYECTO
                </Link>
              </Button>
            </InversionButton>
          </div>
        </div>
      </StyledCard>
    </Col>
  );
};

export default ProjectCard;
