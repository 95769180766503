import React from 'react';
import styled from 'styled-components';
import { Row, Form, Card } from 'antd';

import { FormItemInput } from '../../../../../commons/formComponents/formComponents';
import { specialCharacter } from '../../../../../commons/validations';

import InputComponentEdit from '../commons/inputComponentEdit';
import TitleCard from '../commons/titleCard';

const Container = styled(Card)`
  margin: 20px;
  flex: 1;
`;

const BasicDataEdit = ({
  messages,
  userInfo,
  form,
  intl
}) => (
  <Container>
    <TitleCard
      iconType="audit"
      title={intl.formatMessage(messages.basicDateTitle)}
    />
    <Row gutter={24} style={{ marginTop: 40 }}>
      <InputComponentEdit title={intl.formatMessage(messages.name)}>
        <FormItemInput
          FormComponent={Form}
          form={form}
          field="name"
          placeholder={intl.formatMessage(messages.name)}
          initialValue={userInfo.name || null}
          rules={[
            { max: 200, message: `${intl.formatMessage(messages.maxCharacters)} 200` },
          ]}
        />
      </InputComponentEdit>

      <InputComponentEdit title={intl.formatMessage(messages.lastName)}>
        <FormItemInput
          FormComponent={Form}
          form={form}
          field="lastName"
          placeholder={intl.formatMessage(messages.lastName)}
          initialValue={userInfo.lastName || null}
          rules={[
            { required: true, message: intl.formatMessage(messages.requiredField) },
            { max: 200, message: `${intl.formatMessage(messages.maxCharacters)} 200` },
            { validator: specialCharacter(form, intl.formatMessage(messages.specialCharacter)) }
          ]}
        />
      </InputComponentEdit>

      <InputComponentEdit title={intl.formatMessage(messages.mpUserName)}>
        <FormItemInput
          FormComponent={Form}
          form={form}
          field="mpUserName"
          placeholder={intl.formatMessage(messages.mpUserName)}
          initialValue={userInfo.mpUserName ? userInfo.mpUserName : null}
          rules={[
            { type: 'email', message: intl.formatMessage(messages.invalidEmail) }
          ]}
        />
      </InputComponentEdit>

      <InputComponentEdit title={intl.formatMessage(messages.payPalUser)}>
        <FormItemInput
          FormComponent={Form}
          form={form}
          initialValue={userInfo.payPalUser || null}
          field="payPalUser"
          placeholder={intl.formatMessage(messages.payPalUser)}
          rules={[
            { max: 15, message: `${intl.formatMessage(messages.maxCharacters)} 15` },
          ]}
        />
      </InputComponentEdit>

    </Row>
  </Container>
);

export default BasicDataEdit;
