import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Layout, Row, Col, Form, Button, Alert, Input, Tooltip
} from 'antd';
import styled from 'styled-components';

import { validatePassword } from '../../../commons/validations';

import '../registration.css';
import { changePassword } from '../../../../actions/registration';

import messages from '../culture';

const { Content } = Layout;

const Container = styled.div`
  & {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 5rem 10%;
  }

  & h1 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0;
  }

  & h4.form-title {
    opacity: 0.55;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
  }

  & input {
    height: 45px;
  }

  & .ant-form-item {
    margin-bottom: 1rem;
  }
`;

const RecoverButton = styled.div`
  & button {
    border-radius: 5px;
    height: 45px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
    border-color: transparent;
    margin-top: ${props => props.secondary ? '1.5rem' : '2rem'};
  }

  & button:hover {
    border-color: transparent;
    color: #fff;
  }

  & button:active {
    border-color: transparent;
    color: #fff;
  }

  & button:focus {
    border-color: transparent;
    color: #fff;
  }
`;

class newPasswordForm extends Component {
  state = { messageResponse: '', type: '', confirmDirty: false, }

  componentDidMount() {
    window.scroll({ top: 0, behavior: 'auto' });
  }

  handleSubmit = (e) => {
    const {
      form,
      change,
      match,
      history,
      intl
    } = this.props;
    this.setState({ messageResponse: '' });

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const { token } = match.params;

        const model = { ...values, token };

        change(model)
          .then(() => history.push({ pathname: '/', state: { messageProps: intl.formatMessage(messages.newPassSuccess) } }))
          .catch((error) => {
            if (error.response.status === 422) this.setState({ messageResponse: intl.formatMessage(messages.recoverPasswordErrorUser), type: 'error' });
          });
      }
    });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form, intl } = this.props;

    if (value && value !== form.getFieldValue('password')) {
      callback(intl.formatMessage(messages.errorPassword));
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    const { confirmDirty } = this.state;

    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  render() {
    const { form, loading, intl } = this.props;
    const { messageResponse, type } = this.state;

    return (
      <Content style={{ background: '#eee', paddingBottom: 20, paddingTop: 100 }}>
        <Row type="flex" justify="center">
          <Col md={{ span: 10 }} xs={{ span: 20 }}>
            <Container>
              <h1 className="primary-text">{intl.formatMessage(messages.newPassTitle)}</h1>

              <Form onSubmit={this.handleSubmit} style={{ marginTop: 32 }}>

                <Form.Item>
                  <Tooltip
                    trigger={['focus']}
                    placement="topLeft"
                    title={intl.formatMessage(messages.inputTooltipPass)}
                    style={{ width: '100%' }}
                  >
                    {form.getFieldDecorator('password', {
                      rules: [
                        { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                        { max: 15, message: `${intl.formatMessage(messages.maxCharacters)} 15` },
                        { min: 8, message: `${intl.formatMessage(messages.minCharacters)} 8` },
                        { validator: this.validateToNextPassword },
                        { validator: validatePassword(form, intl.formatMessage(messages.validatePassword)) },
                      ],
                    })(<Input.Password
                      placeholder={intl.formatMessage(messages.passwordInput)}
                    />)}
                  </Tooltip>
                </Form.Item>

                <Form.Item>
                  {form.getFieldDecorator('confirmPassword', {
                    rules: [
                      { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                      { max: 15, message: `${intl.formatMessage(messages.maxCharacters)} 15` },
                      { min: 8, message: `${intl.formatMessage(messages.minCharacters)} 8` },
                      { validator: this.compareToFirstPassword },
                      { validator: validatePassword(form, intl.formatMessage(messages.validatePassword)) },
                    ],
                  })(<Input.Password
                    onBlur={this.handleConfirmBlur}
                    placeholder={intl.formatMessage(messages.newPassConfirm)}
                  />)}
                </Form.Item>

                <RecoverButton>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                  >{intl.formatMessage(messages.textSend)}
                  </Button>
                </RecoverButton>
              </Form>
            </Container>
          </Col>
        </Row>

        {messageResponse && (
          <Row style={{ marginTop: 20 }}>
            <Col span={10} push={7}>
              <Alert message={messageResponse} type={type} closable />
            </Col>
          </Row>
        )}

      </Content>
    );
  }
}

const newPassword = Form.create({ name: 'sendPassword' })(newPasswordForm);


const mapStateToProps = state => ({ loading: state.registration.isFetching });

const mapDispatchToProps = dispatch => bindActionCreators({ change: changePassword }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(newPassword));
