import React from 'react';
import { Form } from 'antd';

import {
  FormItemInputNumber, FormItemInputDecimal
} from '../../../../commons/formComponents/formComponents';

import { verifyNumber, verifyMaxLength } from '../../../../commons/validations';

const CreateCampaign = ({
  form, intl, messages, isEdit, campaignEdit
}) => (
  <React.Fragment>
    <FormItemInputDecimal
      FormComponent={Form}
      form={form}
      label={intl.formatMessage(messages.objective)}
      field="objective"
      initialValue={campaignEdit.objective}
      max={999999999999999}
      min={0}
      rules={[
        { required: true, message: intl.formatMessage(messages.requiredField) },
        { validator: verifyMaxLength(form, 15, `${intl.formatMessage(messages.maxCharacters)} 15`) },
        { validator: verifyNumber(form, intl.formatMessage(messages.numericCamp)) }
      ]}
    />
    {campaignEdit && (
      <FormItemInputDecimal
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.amountRaised)}
        field="totalRaised"
        disabled={isEdit}
        initialValue={campaignEdit.totalRaised}
        max={999999999999999}
        min={0}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) },
          { validator: verifyMaxLength(form, 15, `${intl.formatMessage(messages.maxCharacters)} 15`) },
          { validator: verifyNumber(form, intl.formatMessage(messages.numericCamp)) }
        ]}
      />
    )}
    <FormItemInputNumber
      FormComponent={Form}
      form={form}
      label={intl.formatMessage(messages.days)}
      field="days"
      initialValue={campaignEdit.days}
      rules={[
        { required: true, message: intl.formatMessage(messages.requiredField) },
        { max: 3, message: `${intl.formatMessage(messages.maxCharacters)} 3` },
        { validator: verifyNumber(form, intl.formatMessage(messages.numericCamp)) }
      ]}
    />
    {campaignEdit && (
      <FormItemInputNumber
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.contributors)}
        field="totalInvestors"
        disabled={isEdit}
        initialValue={campaignEdit.totalInvestors}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) },
          { max: 3, message: `${intl.formatMessage(messages.maxCharacters)} 3` },
          { validator: verifyNumber(form, intl.formatMessage(messages.numericCamp)) }
        ]}
      />
    )}
  </React.Fragment>
);

export default CreateCampaign;
