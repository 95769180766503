import axios from 'axios';

export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_BIND_TRANSFER = 'GET_BIND_TRANSFER';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_BY_USER = 'GET_TRANSACTIONS_BY_USER';
export const APPROVE_EDITED_TRANSACTION = 'APPROVE_EDITED_TRANSACTION';

export const getTransaction = transactionId => ({
  type: GET_TRANSACTION,
  payload: axios
    .get(`/transactions/${transactionId}`)
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});

export const getBindTransfer = bindTransactionId => ({
  type: GET_BIND_TRANSFER,
  payload: axios
    .get(`/bind/transfer/${bindTransactionId}`)
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});

export const getTransactions = () => ({
  type: GET_TRANSACTIONS,
  payload: axios
    .get('/transactions')
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});

export const getTransactionsByUser = () => ({
  type: GET_TRANSACTIONS_BY_USER,
  payload: axios
    .get('/transactions/user')
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});

export const approveEditedTransaction = (transactionId, status, amount) => ({
  type: APPROVE_EDITED_TRANSACTION,
  payload: axios
    .post('transactions/update-status', { transactionId, status, amount })
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});
