import React, { Component } from 'react';
import {
  Form,
  Button,
  Icon,
  Card
} from 'antd';

import {
  FormItemInput,
  UploadImage,
  FormItemTextArea
} from '../../../../commons/formComponents/formComponents';

class CreateAdvance extends Component {
  remove = (item) => {
    const { form, deleteAdvance, deleteRedux } = this.props;
    const advanceKeys = form.getFieldValue('advanceKeys');

    if (deleteRedux) deleteAdvance(item);

    form.setFieldsValue({
      advanceKeys: advanceKeys.filter(key => key !== item)
    });
  };

  add = () => {
    const { form } = this.props;
    const advanceKeys = form.getFieldValue('advanceKeys');

    let largest = 0;

    for (let i = 0; i <= advanceKeys.length - 1; i++) {
      if (advanceKeys[i] > largest) {
        largest = advanceKeys[i];
      }
    }

    const nextKeys = advanceKeys.concat(largest + 1);

    form.setFieldsValue({ advanceKeys: nextKeys });
  };

  render() {
    const {
      form,
      messages,
      intl,
      advanceImagesLength,
      advanceEdit
    } = this.props;

    form.getFieldDecorator('advanceKeys', { initialValue: [] });

    const advanceKeys = form.getFieldValue('advanceKeys');

    const formItems = advanceKeys.map((item, index) => {
      form.getFieldDecorator(`idA${item}`, {
        initialValue: { ...item }
      });

      return (
        <Card key={item} style={{ marginTop: 10, marginBottom: 15 }}>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Button type="danger" onClick={() => this.remove(item)}>
              <Icon type="delete" />
            </Button>
          </div>
          <FormItemInput
            FormComponent={Form}
            form={form}
            label={intl.formatMessage(messages.title)}
            field={`titleA${item}`}
            initialValue={advanceEdit[index] ? advanceEdit[index].title : null}
            rules={[
              {
                required: true,
                message: intl.formatMessage(messages.requiredField)
              },
              {
                max: 50,
                message: `${intl.formatMessage(messages.maxCharacters)} 50`
              }
            ]}
          />
          <FormItemTextArea
            FormComponent={Form}
            form={form}
            label={intl.formatMessage(messages.description)}
            field={`descriptionA${item}`}
            initialValue={
              advanceEdit[index] ? advanceEdit[index].description : null
            }
            minRows={4}
            maxRows={6}
            rules={[
              {
                required: true,
                message: intl.formatMessage(messages.requiredField)
              },
              {
                max: 200,
                message: `${intl.formatMessage(messages.maxCharacters)} 200`
              }
            ]}
          />
          {(!advanceImagesLength
            || (advanceEdit[index] && advanceEdit[index].notImage)
            || (advanceEdit[index] && advanceEdit[index].imageDidMount && advanceEdit[index].imageDidMount.url)
            || advanceEdit.length !== advanceKeys.length) && (
            <UploadImage
              FormComponent={Form}
              intl={intl}
              messages={messages}
              form={form}
              label={intl.formatMessage(messages.image)}
              field={`imageA${item}`}
              name={intl.formatMessage(messages.uploadImage)}
              unique
              setDidMount
              // defaultFileList={(advanceEdit[index]) ? [advanceEdit[index].imageDidMount] : []}
              // value={(advanceEdit[index]) ? [advanceEdit[index].image] : null}
              defaultFileList={(advanceEdit[index] && !advanceEdit[index].notImage) ? [advanceEdit[index].imageDidMount] : []}
              value={(advanceEdit[index] && !advanceEdit[index].notImage) ? [advanceEdit[index].image] : null}
              maxImagesLength={1}
            />
          )}
        </Card>
      );
    });

    return (
      <div>
        {formItems}
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Button type="dashed" onClick={this.add} style={{ marginBottom: 25 }}>
            <Icon type="plus-circle" />{' '}
            {intl.formatMessage(messages.addAdvance)}
          </Button>
        </div>
      </div>
    );
  }
}

export default CreateAdvance;
