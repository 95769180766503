/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import moment from 'moment';
import { Row } from 'antd';

import ProjectCard from './projectCard';

import { filterProjects } from '../../../actions/projects';

const MobileRow = styled.div`
  & .ant-col {
    margin-top: 2rem;
  }

  @media (max-width: 992px) {
    & .ant-col {
      margin-top: 1.5rem;
    }
  }
`;

const EmptyFilter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-family: Roboto;
  margin-top: 10rem;
`;

class FeatureProjects extends Component {
  componentDidMount() {
    window.scroll({ top: 0, behavior: 'auto' });

    const { filter, country } = this.props;
    filter('statusActive=active&status=published&sort=-campaigns.totalRaised', country);
  }

  render() {
    const {
      projects,
      filter,
      handleChangeDrawer,
      intl,
      messages,
    } = this.props;

    let featureProjects = [];
    const currentDate = moment();

    featureProjects = projects.map((project) => {
      let daysLeft = 0;
      let hourLeft = 0;
      let minutesLeft = 0;
      let isDate = false;

      const campaign = project.campaigns[0];
      daysLeft = campaign
        ? moment(campaign.publishedAt)
          .add(campaign.days, 'days')
          .diff(currentDate, 'days')
        : 0;

      if (daysLeft > 0) isDate = 'days';
      else {
        hourLeft = campaign
          ? moment(campaign.publishedAt)
            .add(campaign.days, 'days')
            .diff(currentDate, 'hours')
          : 0;
        if (hourLeft > 0) isDate = 'hours';
        else {
          minutesLeft = campaign
            ? moment(campaign.publishedAt)
              .add(campaign.days, 'days')
              .diff(currentDate, 'minutes')
            : 0;
          if (minutesLeft >= 0) isDate = 'minutes';
          else isDate = 'finished';
        }
      }

      const timeLeft = isDate !== 'finished' ? daysLeft || hourLeft || minutesLeft : null;

      if ((isDate !== 'finished' && campaign.status === 'active')) {
        return (
          <ProjectCard
            key={project._id}
            project={project}
            handleChangeDrawer={handleChangeDrawer}
            intl={intl}
            messages={messages}
            timeLeft={timeLeft}
            isDate={isDate}
          />
        );
      }
      return null;
    });


    return (
      <div style={{ marginTop: '2rem' }}>
        {featureProjects.length > 0 && (
          <MobileRow>
            <Row gutter={24} type="flex" justify="center">
              {featureProjects}
            </Row>
          </MobileRow>
        )}

        {filter && featureProjects.length === 0 && (
          <EmptyFilter>{intl.formatMessage(messages.noResults)}</EmptyFilter>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects.filterProjects,
  country: state.culture.country
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    filter: filterProjects,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureProjects);
