import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCountriesPublic } from "../../../../actions/country";

import { Select } from 'antd';

const { Option } = Select;

class LenguageComponent extends Component {

    componentDidMount() {
        this.props.getAllCountriesPublic();
    }

    render() {
        const { countries, handleChange, defaultCountry } = this.props

        if (countries.list.length === 0) {
            return (<div>Loading... </div>)
        }

        return (
            <React.Fragment>
                    <Select defaultValue={defaultCountry || 'arg'} style={{ width: 150 }} onChange={handleChange}>
                        {countries.list.map((country, index) => {
                            return <Option key={index} value={country.code} onClick={() => handleChange(country.code)}>
                                {country.code} | $ {country.currency}
                            </Option>
                        })}
                    </Select>
            </React.Fragment>
        );

    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ getAllCountriesPublic }, dispatch);

const mapStateToProps = state => ({
    countries: state.countries
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LenguageComponent);
