import axios from 'axios';

export const GET_COUNTRY_BY_ID = 'GET_COUNTRY_BY_ID';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const EDIT_COUNTRY = 'EDIT_COUNTRY';
export const CREATE_COUNTRY = 'CREATE_COUNTRY';

// Get country by ID
export const getCountryById = countryId => ({
  type: GET_COUNTRY_BY_ID,
  payload: axios
    .get(`/country/${countryId}`)
    .then(response => ({ country: response.data }))
    .catch(error => Promise.reject(error))
});

// Get all countries
export const getAllCountries = () => ({
  type: GET_COUNTRIES,
  payload: axios
    .get('/country')
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});

// Get all countries
export const getAllCountriesPublic = () => ({
  type: GET_COUNTRIES,
  payload: axios
    .get('/country/public')
    .then(response => ({ data: response.data }))
    .catch(error => Promise.reject(error))
});

// Edit country
export const editCountry = (model, id) => ({
  type: EDIT_COUNTRY,
  payload: axios
    .put('/country/', { ...model, id })
    .then(response => ({ messageResponse: response.data }))
    .catch(error => Promise.reject(error))
});

export const createCountry = model => ({
  type: CREATE_COUNTRY,
  payload: axios
    .post('/country/create', model)
    .then(response => ({ messageResponse: response }))
    .catch(error => Promise.reject(error))
});

// // Delete country
// export const deleteCountry = (model, id) => ({
//   type: DELETE_COUNTRY,
//   payload: axios
//     .delete('/country/', { ...model, id })
//     .then(response => ({ messageResponse: response.data }))
//     .catch(error => Promise.reject(error))
// });
