import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider, addLocaleData } from 'react-intl';
import moment from 'moment';
import appLocaleEs from './locales/es-AR';
import appLocaleEn from './locales/en-US';

const CultureHOC = App => class CulturalizedComponent extends React.Component {
  render() {
    // eslint-disable-next-line global-require
    let appLocale;
    switch (this.props.culture) {
    case 'en-US':
      appLocale = appLocaleEn;
      break;
    case 'es-AR':
    default:
      appLocale = appLocaleEs;
      break;
    }
    addLocaleData(appLocale.data);
    moment.locale(appLocale.locale);

    return (
      <ConfigProvider locale={appLocale.antd}>
        <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
          <App {...this.props} />
        </IntlProvider>
      </ConfigProvider>
    );
  }
};

export default CultureHOC;
