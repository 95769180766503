import React from 'react';
import { Form } from 'antd';

import {
  FormItemInput,
  UploadImage,
  FormItemTextArea,
  FormItemRadio,
  FormItemAutocomplete,
  FormItemSelect
} from '../../../../commons/formComponents/formComponents';

import { specialCharacter, validateDevelopment } from '../../../../commons/validations';

const BasicDate = ({
  form,
  intl,
  messages,
  users,
  basicDateEdit,
  isEdit,
  countries,
  handleFeaturedChange,
  featuredState
}) => {
  const userProp = users.usersByRol.map(user => user.username);

  return (
    <React.Fragment>
      <FormItemInput
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.nameProyect)}
        field="name"
        initialValue={basicDateEdit.name}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) },
          { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
        ]}
      />
      <FormItemAutocomplete
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.realEstateDeveloper)}
        initialValue={basicDateEdit.developer}
        field="developer"
        dataSource={userProp}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) },
          { max: 200, message: `${intl.formatMessage(messages.maxCharacters)} 200` },
          { validator: specialCharacter(form, intl.formatMessage(messages.specialCharacter)) },
          { validator: validateDevelopment(form, users, intl.formatMessage(messages.validateDevelopment)) }
        ]}
      />
      <FormItemSelect
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.category)}
        field="category"
        initialValue={basicDateEdit.category}
        options={[
          { key: 'Ecología', name: intl.formatMessage(messages.ecology), value: 'ecology' },
          { key: 'Social', name: intl.formatMessage(messages.social), value: 'social' },
          { key: 'Triple Impacto', name: intl.formatMessage(messages.tripleImpact), value: 'tripleImpact' },
          { key: 'Economía Colaborativa', name: intl.formatMessage(messages.collaborativeEconomy), value: 'collaborativeEconomy' },
          { key: 'Inclusión', name: intl.formatMessage(messages.inclusion), value: 'inclusion' },
          { key: 'Educación', name: intl.formatMessage(messages.education), value: 'education' },
        ]}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) }
        ]}
      />
      {(!isEdit || basicDateEdit.mainImage) && (
        <UploadImage
          FormComponent={Form}
          intl={intl}
          messages={messages}
          form={form}
          label={intl.formatMessage(messages.mainImage)}
          field="mainImage"
          defaultFileList={basicDateEdit.mainImage || []}
          name={intl.formatMessage(messages.uploadImage)}
          rules={[
            { required: true, message: intl.formatMessage(messages.requiredField) }
          ]}
          unique
          setDidMount
          value={basicDateEdit.mainImageDidMount}
          maxImagesLength={1}
        />
      )}
      {(!isEdit || basicDateEdit.images) && (
        <UploadImage
          FormComponent={Form}
          intl={intl}
          messages={messages}
          form={form}
          label={intl.formatMessage(messages.images)}
          field="images"
          defaultFileList={basicDateEdit.images || []}
          name={intl.formatMessage(messages.uploadImage)}
          rules={[
            { required: true, message: intl.formatMessage(messages.requiredField) }
          ]}
          setDidMount
          value={basicDateEdit.imagesDidMount}
          maxImagesLength={5}
          video
        />
      )}
      <FormItemTextArea
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.briefDescription)}
        field="briefDescription"
        initialValue={basicDateEdit.briefDescription}
        minRows={3}
        maxRows={6}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) },
          { max: 250, message: `${intl.formatMessage(messages.maxCharacters)} 250` },
        ]}
      />
      <FormItemTextArea
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.completeDescription)}
        field="completeDescription"
        initialValue={basicDateEdit.completeDescription}
        minRows={5}
        maxRows={7}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) },
          { max: 5000, message: `${intl.formatMessage(messages.maxCharacters)} 5000` },
        ]}
      />
      <FormItemRadio
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.regulatoryEntity)}
        field="featuredProject"
        initialValue={basicDateEdit.featuredProject}
        options={[
          { key: 1, name: intl.formatMessage(messages.yes), value: true },
          { key: 2, name: intl.formatMessage(messages.no), value: false }
        ]}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) },
        ]}
        onChange={handleFeaturedChange}
      />

      <FormItemSelect
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.featuredCategory)}
        field="featuredCategory"
        initialValue={basicDateEdit.featuredCategory}
        options={[
          { key: 'Emprendimiento del mes', name: 'Emprendimiento del mes', value: 'Emprendimiento del mes' },
          { key: 'Triple impacto', name: 'Triple impacto', value: 'Triple impacto' },
          { key: 'Idea destacada', name: 'Idea destacada', value: 'Idea destacada' },
          { key: 'En la recta final', name: 'En la recta final', value: 'En la recta final' },
        ]}
        rules={[
          { required: (!!(featuredState || basicDateEdit.featuredProject)), message: intl.formatMessage(messages.requiredField) }
        ]}
      />
      <FormItemSelect
        FormComponent={Form}
        form={form}
        label={intl.formatMessage(messages.countries)}
        field="country"
        initialValue={basicDateEdit.country}
        options={countries.map(country => ({
          key: country.id, name: country.description, value: country._id
        }))}
        rules={[
          { required: true, message: intl.formatMessage(messages.requiredField) }
        ]}
      />
    </React.Fragment>
  );
};

export default BasicDate;
