import { defineMessages } from 'react-intl';

const messages = defineMessages({
  maxCharacters: { id: 'general.validations.maxCharacters', defaultMessage: 'El máximo de caracteres es de' },
  minCharacters: { id: 'general.validations.minCharacters', defaultMessage: 'El mínimo de caracteres es de' },
  selectDate: { id: 'general.selectDate', defaultMessage: 'Por favor, seleccione una fecha' },
  uploadImage: { id: 'general.uploadImage', defaultMessage: 'Click para subir archivo' },
  requiredField: { id: 'general.validations.requiredField' },
  image: { id: 'admin.user.create.image' },
  images: { id: 'admin.project.create.images' },
  countries: { id: 'admin.country.table.breadcrumb.country' },

  startTab: { id: 'project.index.tab.start' },
  projectTab: { id: 'project.index.tab.project' },
  questionPublish: { id: 'project.index.questionPublish' },
  questionActivate: { id: 'project.index.questionActivate' },
  questionInactivate: { id: 'project.index.questionInactivate' },
  newProjectButton: { id: 'project.index.button.newProject' },

  idKey: { id: 'project.table.key.id' },
  nameKey: { id: 'project.table.key.name' },
  developerKey: { id: 'project.table.key.developer' },
  statusKey: { id: 'project.table.key.status' },
  activeKey: { id: 'project.table.key.active' },
  creationDateKey: { id: 'project.table.key.creationDate' },
  percentageKey: { id: 'project.table.key.percentage' },
  campaignStatusKey: { id: 'project.table.key.campaignStatus' },
  actionKey: { id: 'project.table.key.action' },
  draft: { id: 'project.table.status.draft' },
  published: { id: 'project.table.status.published' },
  pending: { id: 'project.table.status.pending' },
  finished: { id: 'project.table.status.finished' },
  active: { id: 'project.table.status.active' },
  inactive: { id: 'project.table.status.inactive' },
  canceled: { id: 'project.table.status.canceled' },
  publishButton: { id: 'project.table.button.publish' },
  activateButton: { id: 'project.table.button.activate' },
  inactivateButton: { id: 'project.table.button.inactivate' },
  returnsButton: { id: 'project.table.button.returns' },

  breadcrumbFirst: { id: 'project.create.breadcrumb.first' },
  breadcrumbSecond: { id: 'project.create.breadcrumb.second' },
  breadcrumbThird: { id: 'project.create.breadcrumb.third' },
  breadcrumbThirdEdit: { id: 'project.create.breadcrumb.thirdEdit' },

  basicData: { id: 'project.create.title.basicData' },
  typification: { id: 'project.create.title.typification' },
  characteristicsTransaction: { id: 'project.create.title.characteristicsTransaction' },
  projectDetail: { id: 'project.create.title.projectDetail' },
  campaign: { id: 'project.create.title.campaign' },
  advance: { id: 'project.create.title.advance' },

  totalInvestmentAmount: { id: 'project.create.input.totalInvestmentAmount' },
  propiInvestInvestmentAmount: { id: 'project.create.input.propiInvestInvestmentAmount' },
  metersBuilt: { id: 'project.create.input.metersBuilt' },
  terrainValue: { id: 'project.create.input.terrainValue' },
  costAcquisition: { id: 'project.create.input.costAcquisition' },
  costConstruction: { id: 'project.create.input.costConstruction' },
  closingDate: { id: 'project.create.input.closingDate' },
  createProjectButton: { id: 'project.create.input.createProjectButton' },
  cancelButton: { id: 'project.create.input.cancelButton' },
  typeAsset: { id: 'project.create.input.typeAsset' },
  typeFormat: { id: 'project.create.input.typeFormat' },
  typeOperation: { id: 'project.create.input.typeOperation' },
  nameProyect: { id: 'project.create.input.nameProyect' },
  realEstateDeveloper: { id: 'project.create.input.realEstateDeveloper' },
  category: { id: 'project.create.input.category' },
  featuredCategory: { id: 'project.create.input.featuredCategory' },
  briefDescription: { id: 'project.create.input.briefDescription' },
  regulatoryEntity: { id: 'project.create.input.regulatoryEntity' },
  projectLocation: { id: 'project.create.input.projectLocation' },
  riskLevel: { id: 'project.create.input.riskLevel' },
  visibility: { id: 'project.create.rewards.visibility' },
  
  type: { id: 'project.create.input.type' },
  text: { id: 'project.create.input.text' },
  order: { id: 'project.create.input.order' },

  title: { id: 'project.create.input.title' },
  objective: { id: 'project.create.input.objective' },
  currency: { id: 'project.create.input.currency' },
  days: { id: 'project.create.input.days' },
  contributors: { id: 'project.create.input.contributors' },
  amountRaised: { id: 'project.create.input.amountRaised' },
  mainImage: { id: 'project.create.input.mainImage' },
  completeDescription: { id: 'project.create.input.completeDescription' },
  minimunAmountOfInvestment: { id: 'project.create.input.minimunAmountOfInvestment' },
  minimunPercentageOfCollection: { id: 'project.create.input.minimunPercentageOfCollection' },
  maximunPercentageOfCollection: { id: 'project.create.input.maximunPercentageOfCollection' },
  annualProjectedProfitability: { id: 'project.create.input.annualProjectedProfitability' },
  tir: { id: 'project.create.input.tir' },
  equity: { id: 'project.create.input.equity' },
  periodofInvestment: { id: 'project.create.input.periodofInvestment' },
  projectedAnnualProfit: { id: 'project.create.input.projectedAnnualProfit' },
  capitalRecovery: { id: 'project.create.input.capitalRecovery' },
  accountBankForRelease: { id: 'project.create.input.accountBankForRelease' },
  accountBank: { id: 'project.create.input.accountBank' },
  description: { id: 'project.create.input.description' },

  yes: { id: 'project.create.option.yes' },
  no: { id: 'project.create.option.no' },

  high: { id: 'project.create.option.high' },
  medium: { id: 'project.create.option.medium' },
  low: { id: 'project.create.option.low' },

  ecology: { id: 'project.create.option.ecology' },
  social: { id: 'project.create.option.social' },
  tripleImpact: { id: 'project.create.option.tripleImpact' },
  collaborativeEconomy: { id: 'project.create.option.collaborativeEconomy' },
  inclusion: { id: 'project.create.option.inclusion' },
  education: { id: 'project.create.option.education' },

  purchaseOption: { id: 'project.create.option.purchaseOption' },
  ticket: { id: 'project.create.option.ticket' },
  concessionRentalContract: { id: 'project.create.option.concessionRentalContract' },
  commercialSociety: { id: 'project.create.option.commercialSociety' },
  escrow: { id: 'project.create.option.escrow' },
  trustProperty: { id: 'project.create.option.trustProperty' },
  commonInvestmentFund: { id: 'project.create.option.commonInvestmentFund' },
  propertytitle: { id: 'project.create.option.propertytitle' },

  mortgage: { id: 'project.create.option.mortgage' },
  buySell: { id: 'project.create.option.buySell' },
  buyBuildSell: { id: 'project.create.option.buyBuildSell' },
  buyRentSell: { id: 'project.create.option.buyRentSell' },
  buyBuildRentSell: { id: 'project.create.option.buyBuildRentSell' },

  onlyText: { id: 'project.create.option.onlyText' },
  imageAndText: { id: 'project.create.option.imageAndText' },
  onlyImage: { id: 'project.create.option.onlyImage' },

  dolar: { id: 'project.create.option.dolar' },
  pesos: { id: 'project.create.option.pesos' },

  createError: { id: 'project.create.error.400' },
  nameExists: { id: 'project.create.error.name_exists' },

  descriptionReturns: { id: 'project.returns.table.description' },
  returnPercentageReturns: { id: 'project.returns.table.returnPercentage' },
  projectReturns: { id: 'project.returns.breadcrumb.project' },
  returnsReturns: { id: 'project.returns.breadcrumb.returns' },
  addReturn: { id: 'project.returns.button.add' },
  confirmReturn: { id: 'project.returns.button.confirm' },
  obligatoryFieldInput: { id: 'login.input.error.obligatoryField' },

  addAdvance: { id: 'project.create.button.addAdvance' },
  addDetail: { id: 'project.create.button.addDetail' },
  minimunAmountOfInvestmentId: { id: 'project.id.minimunAmountOfInvestment' },
  totalInvest: { id: 'project.id.totalInvest' },
  totalInvests: { id: 'project.id.totalInvests' },
  toInvest: { id: 'project.id.button.toInvest' },
  closingDay: { id: 'project.id.button.closingDay' },
  multipleEquity: { id: 'project.id.button.multipleEquity' },
  wantCommunicate: { id: 'home.title.wantCommunicate' },
  year: { id: 'project.id.year' },
  day: { id: 'project.id.day' },
  remaining: { id: 'project.id.remaining' },

  cFinished: { id: 'project.campaign.finished' },
  numericCamp: { id: 'validate.numericCamp' },
  specialCharacter: { id: 'validate.specialCharacter' },
  validateDevelopment: { id: 'validate.validateDevelopment' },
  onlyImageComponent: { id: 'validate.onlyImage' },
  cannotExceedImageComponent: { id: 'validate.cannotExceedImage' },
  selectValidAddress: { id: 'validate.selectValidAddress' },
  errorSelectAddress: { id: 'validate.errorSelectAddress' },
  rewardsTitle: { id: 'project.create.rewards.title' },
  rewardsMercadoPago: { id: 'project.create.rewards.MPMercadoPago' },
  rewardsAmount: { id: 'admin.transactions.edit.amount' },
  rewardsCreate: { id: 'project.create.rewards.create' },
});

export default messages;
