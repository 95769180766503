import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Layout,
  Form,
  Input,
  Table,
  Row,
  Col,
  Button,
  Spin,
  Breadcrumb,
  message
} from 'antd';
import styled from 'styled-components';
import { getTransaction, getBindTransfer, approveEditedTransaction } from '../../../actions/transactions';
import messages from './culture';

const ContainerLoading = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const { Content } = Layout;

const styles = {
  content: { padding: 24, background: '#fff', minHeight: 360 },
};

const Container = styled.div`
  background-color: #FFF;
  padding: 24px;
  margin-bottom: 40px;
`;

class EditTransaction extends React.Component {

  constructor(props) {
    super(props);
    this.state = { loading: true, bindTransferId: undefined };
  }

  componentDidMount() {
    const {
      match, getTransactionById
    } = this.props;
    getTransactionById(match.params.id);
    this.setState({ loading: false });
  }

  getBindTransfer = () => {
    const { form, intl } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ bindTransferId: values.bindTransactionId });
        this.props.getBindTransfer(values.bindTransactionId)
          .catch((error) => {
            if (error.response.status === 400) message.error(intl.formatMessage(messages.getBindTransferError));
          });
      }
    });
  };

  updateTransaction = (status) => {
    const {
      transactionInfo, form, history, editTransaction, intl
    } = this.props;
    const { transactionEdit } = transactionInfo;
    form.validateFields((err, values) => {
      if (!err) {
        if (transactionEdit) {
          const amount = form.getFieldValue('amount');
          editTransaction(transactionEdit._id, status, amount)
            .then(() => history.push('/admin/transactions'))
            .catch((error) => {
              message.error(intl.formatMessage(messages.editTransferError));
            });
        }
      }
    });
  }

  compareAmount = () => (rule, value, callback) => {
    const { loading } = this.state;
    const { form } = this.props;
    const propInvestTransferAmount = form.getFieldValue('amount');
    const bindTransferAmount = form.getFieldValue('bindTransferAmount');
    const fielErrors = form.getFieldError(rule.field);
    if (!loading && fielErrors.length === 0 && propInvestTransferAmount && propInvestTransferAmount && propInvestTransferAmount !== bindTransferAmount) {
      form.setFields({ [rule.field]: { value, errors: [new Error('Different Amount')] } });
    } else {
      callback();
    }
  }

  render() {
    const { intl, transactionInfo } = this.props;
    const { getFieldDecorator } = this.props.form;

    const { loading } = this.state;

    if (loading || transactionInfo.isFetching) return (<ContainerLoading><Spin size="large" /></ContainerLoading>);

    const { currency, amount, status, investorUser, projectName, originId, transactionId } = transactionInfo.transactionEdit;

    const createdAt = moment(transactionInfo.transactionEdit.createdAt).format('DD/MM/YYYY');

    const dataSource = [];
    let editTransactionButtonDisabled = true;
    let cancelTransactionButtonDisabled = true;
    if (transactionInfo.bindTransfer && transactionInfo.bindTransfer.charge && transactionInfo.bindTransfer.status) {
      editTransactionButtonDisabled = false;
      dataSource.push(
        {
          currency: transactionInfo.bindTransfer.charge.value.currency,
          amount: parseFloat(transactionInfo.bindTransfer.charge.value.amount),
          status: transactionInfo.bindTransfer.status
        }
      );
    }

    if(status === 'pending' || status === 'expired'){
      editTransactionButtonDisabled = false;
    }

    if(status === 'accredited'){
      cancelTransactionButtonDisabled = false;
    }

    const tableColumns = [
      {
        title: intl.formatMessage(messages.amount),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: intl.formatMessage(messages.currency),
        dataIndex: 'currency',
        key: 'currency',
      },
      {
        title: intl.formatMessage(messages.state),
        dataIndex: 'status',
        key: 'status',
      },
    ];

    const formItemLayout = { labelCol: { lg: { span: 6 } }, wrapperCol: { lg: { span: 14 } } };

    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbHome)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbTransactions)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbEdit)}</Breadcrumb.Item>
        </Breadcrumb>
        <Container>
          <div style={styles.content}>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Form.Item label={intl.formatMessage(messages.amount)}>
                {getFieldDecorator('amount', {
                  initialValue: amount
                })(<Input disabled={editTransactionButtonDisabled} />)}
              </Form.Item>
              <Form.Item label={intl.formatMessage(messages.currency)}>
                {getFieldDecorator('currency', {
                  initialValue: currency
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item label={intl.formatMessage(messages.state)}>
                {getFieldDecorator('status', {
                  initialValue: intl.formatMessage(messages[status])
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item label={intl.formatMessage(messages.createdAtTitle)}>
                {getFieldDecorator('createdAt', {
                  initialValue: createdAt
                })(<Input disabled />)}
              </Form.Item>

              <Form.Item label={intl.formatMessage(messages.investorUserTitle)}>
                {getFieldDecorator('investorUser', {
                  initialValue: investorUser
                })(<Input disabled />)}
              </Form.Item>

              <Form.Item label={intl.formatMessage(messages.projectNameTitle)}>
                {getFieldDecorator('projectName', {
                  initialValue: projectName
                })(<Input disabled />)}
              </Form.Item>

              <Form.Item label={intl.formatMessage(messages.originIdTitle)}>
                {getFieldDecorator('originId', {
                  initialValue: originId
                })(<Input disabled />)}
              </Form.Item>

              <Form.Item label={intl.formatMessage(messages.transactionIdTitle)}>
                {getFieldDecorator('transactionId', {
                  initialValue: transactionId
                })(<Input disabled />)}
              </Form.Item>
             

              
              <Form.Item style={{display: 'none', marginTop: 55, marginBottom: 15 }} label={intl.formatMessage(messages.bindTransferId)}>
                <Row gutter={14}>
                  <Col span={12}>
                    {getFieldDecorator('bindTransactionId', {
                      rules: [{ required: false, message: intl.formatMessage(messages.bindTransferId) }],
                      initialValue: this.state.bindTransferId
                    })(<Input />)}
                  </Col>
                  <Col span={12}>
                    <Button loading={transactionInfo.isFetchingBindTransfer} onClick={this.getBindTransfer}>{intl.formatMessage(messages.getBindTransfer)}</Button>
                  </Col>
                </Row>
              </Form.Item>
              {transactionInfo.bindTransfer && (<Table dataSource={dataSource} columns={tableColumns} paging={false} />)}
              <ButtonContainer>
                <Button
                  style={{ marginRight: 15 }}
                  size="large"
                ><Link to="/admin/transactions/">{intl.formatMessage(messages.cancel)}</Link>
                </Button>
                <Button
                  type="danger"
                  htmlType="submit"
                  size="large"
                  style={{ marginRight: 15 }}
                  onClick={()=>{this.updateTransaction('expired')}}
                >Cancelar la transacción
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={editTransactionButtonDisabled}
                  onClick={()=>{this.updateTransaction('accredited')}}
                >{intl.formatMessage(messages.editTransfer)}
                </Button>
              </ButtonContainer>
            </Form>
          </div>
        </Container>
      </Content>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(EditTransaction);


const mapStateToProps = state => ({ transactionInfo: state.transactions, user: state.user });

const mapDispatchToProps = dispatch => bindActionCreators({
  getTransactionById: getTransaction,
  getBindTransfer,
  editTransaction: approveEditedTransaction
}, dispatch);

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);

export default injectIntl(connectedForm, {
  withRef: false,
});
