import v8n from 'v8n';

const isNumber = () => value => !Number.isNaN(Number(value));

const hasNumber = () => value => /\d/.test(value);

const hasSpecialCharacter = () => value => /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);

const verifyCuil = () => (value) => {
  let validate = true;
  if (value.length !== 13) return false;
  value.split('').map((char, index) => {
    if (index === 2 && !(char === '-')) return validate = false;
    if (index === 11 && !(char === '-')) return validate = false;
    if ((index !== 2 && index !== 11) && Number.isNaN(Number(char))) return validate = false;

    return validate;
  });

  return validate;
};

const verifyCUIT = () => (value) => {
  const cuitVerifier = '54327654321';
  const verifierDigits = cuitVerifier.toString().split('');
  const verifierRealDigits = verifierDigits.map(Number);
  const digits = value.toString().split('');
  const realDigits = digits.map(Number);

  let product = 0;
  for (let index = 0; index < verifierRealDigits.length; index++) {
    if (!Number.isNaN(verifierRealDigits[index]) && !Number.isNaN(verifierRealDigits[index])) {
      product += (verifierRealDigits[index] * realDigits[index]);
    }
  }

  return product % 11 === 0;
};

const verifyBankBranch = (code) => {
  if (code.length !== 8) return false;

  const bank = code.slice(0, 4);
  const branch = code.slice(4, 7);
  const checksum = code[7];

  const sum = bank[0] * 7 + bank[1] * 1 + bank[2] * 3 + bank[3] * 9 + branch[0] * 7 + branch[1] * 1 + branch[2] * 3;

  const diferencia = 10 - (sum % 10);

  const result = (checksum !== 0 && checksum === diferencia) || (checksum === 0 && diferencia === 10);

  return result;
};

const verifyAccount = (num) => {
  if (num.length !== 14) return false;

  const checksum = num[13];
  const sum = num[0] * 3 + num[1] * 9 + num[2] * 7 + num[3] * 1 + num[4] * 3 + num[5] * 9
    + num[6] * 7 + num[7] * 1 + num[8] * 3 + num[9] * 9
    + num[10] * 7 + num[11] * 1 + num[12] * 3;
  const diff = 10 - (sum % 10);

  const result = (checksum === 0 && diff === 10) || (checksum !== 0 && checksum === diff);

  return result;
};

const verifyCBU = () => (cbu) => {
  const cbuDigits = cbu.toString().split('');
  const cbuRealDigits = cbuDigits.map(Number);

  const bankBranchIsValid = verifyBankBranch(cbuRealDigits.slice(0, 8));
  const accountNumberIsValid = verifyAccount(cbuRealDigits.slice(8, 22));

  return bankBranchIsValid && accountNumberIsValid;
};

const verifyCBUAlias = () => (cbuAlias) => {
  const regExpression = /^[a-z0-9.-]+$/;
  return regExpression.test(cbuAlias);
};

v8n.extend({
  isNumber, hasNumber, hasSpecialCharacter, verifyCuil, verifyCUIT, verifyCBU, verifyCBUAlias
});

export const verifyMaxLength = (form, maxLength, message) => (rule, value, callback) => {
  if (value) {
    const valueString = value.toString().split('.');
    if (valueString.length === 1 && value.toString().length > maxLength) form.setFields({ [rule.field]: { value, errors: [new Error(message)] } });
    else if (value && (valueString[0].length > maxLength)) form.setFields({ [rule.field]: { value, errors: [new Error(message)] } });
    else callback();
  } else callback();
};

export const verifyNumber = (form, message) => (rule, value, callback) => {
  if (value && !v8n().isNumber().test(value)) form.setFields({ [rule.field]: { value, errors: [new Error(message)] } });
  else callback();
};

export const validateCUIT = (form, message) => (rule, value, callback) => {
  if (value && (!form.getFieldError(rule.field) || form.getFieldError(rule.field).length === 0) && value.length === 11 && !v8n()
    .verifyCUIT()
    .test(value)) {

    callback(message);
  } else {
    callback();
  }
};

export const validateCBU = (form, message) => (rule, value, callback) => {
  if (value && (!form.getFieldError(rule.field) || form.getFieldError(rule.field).length === 0) && value.length === 22 && !v8n()
    .verifyCBU()
    .test(value)) {

    callback(message);
  } else {
    callback();
  }
};

export const validateCBUAlias = (form, message) => (rule, value, callback) => {
  if (value && (!form.getFieldError(rule.field) || form.getFieldError(rule.field).length === 0) && value.length >= 6 && value.length <= 20 && !v8n()
    .verifyCBUAlias()
    .test(value)) {

    callback(message);
  } else {
    callback();
  }
};

export const validatePassword = (form, message) => (rule, value, callback) => {
  if (value
    && !v8n()
      .some.uppercase()
      .some.lowercase()
      .hasNumber()
      .hasSpecialCharacter()
      .test(value)) {
    form.setFields({
      [rule.field]: { value, errors: [new Error(message)] }
      // eslint-disable-next-line indent
        });
    //callback(culture.validatePassword);
  } else {
    callback();
  }
};

export const verifyAge = (form, message) => (rule, value, callback) => {
  if (value) {
    const day = value.get('date');
    const month = value.get('month');
    const year = value.get('year');
    if (!(new Date(year + 18, month, day) <= new Date())) {
      callback(message);
    }
  }
  callback();
};

export const specialCharacter = (form, message) => (rule, value, callback) => {
  if (value && !(/[A-Za-z_-]/.test(value[0]))) form.setFields({ [rule.field]: { value: value.slice(1), errors: [new Error(message)] } });
  else callback();
};

export const hexColor = (form, message) => (rule, value, callback) => {
  if (value && !(/^#[a-f0-9]{6}$/i.test(value))) form.setFields({ [rule.field]: { value, errors: [new Error(message)] } });
  else callback();
};

export const onlyTextAndNumber = (form, message) => (rule, value, callback) => {
  if (value && !(/^[a-zA-Z0-9]+$/.test(value))) form.setFields({ [rule.field]: { value, errors: [new Error(message)] } });
  else callback();
};

export const validateDevelopment = (form, users, message) => (rule, value, callback) => {
  let invalid = true;

  users.usersByRol.map((user) => {
    if (user.username === value) invalid = false;
    return users;
  });

  if (value && invalid) {
    form.setFields({ [rule.field]: { value, errors: [new Error(message)] } });
    callback(message);
  } else callback();
};

export const validURL = (form, message) => (rule, value, callback) => {
  const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  if (value && !pattern.test(value)) form.setFields({ [rule.field]: { value, errors: [new Error(message)] } });
  else callback();
};

export const confirmPassword = (form, message) => (rule, value, callback) => {
  const password = form.getFieldValue('password');

  if (value && value !== password) {
    callback(message);
  } else {
    callback();
  }
};

export const validateCuil = (form, message) => (rule, value, callback) => {
  if (value && !v8n()
    .verifyCuil()
    .test(value)) {
    form.setFields({ [rule.field]: { value, errors: [new Error(message)] } });
    callback(message);
  } else {
    callback();
  }
};

// export const validateIdentity = form => (rule, value, callback) => {
//   const imageFront = form.getFieldValue('positiveIdentificationFront');
//   if (!value && imageFront !== undefined) {
//     form.setFields({
//       [rule.field]: { value, errors: [new Error(culture.identityConfirmation)] }
//     });
//     callback(culture.identityConfirmation);
//   } else {
//     callback();
//   }
// };
