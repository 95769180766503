import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

const ReduxHOC = App => class CulturalizedComponent extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App {...this.props} />
        </PersistGate>
      </Provider>
    );
  }
};

export default ReduxHOC;
