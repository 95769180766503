import { defineMessages } from 'react-intl';

const messages = defineMessages({
  loginTitle: { id: 'login.title' },
  registerTitle: { id: 'register.title' },
  userInput: { id: 'login.input.user' },
  requiredField: {
    id: 'general.validations.requiredField',
    //defaultMessage: 'Campo requerido!',
  },
  passwordInput: { id: 'login.input.password' },
  errorConfirmMail: { id: 'login.message.error.email' },
  errorMailInput: { id: 'login.input.error.mail' },
  errorPasswordInput: { id: 'login.input.error.password' },
  obligatoryFieldInput: { id: 'login.input.error.obligatoryField' },
  forgotPassword: { id: 'login.text.forgotPassword' },
  notHaveAccount: { id: 'login.text.notHaveAccount' },
  loginText: { id: 'login.button.login' },
  reSendMail: { id: 'login.button.reSendMail' },
  mailConfirm: { id: 'login.modal.mailConfirm' },
  userInvalid: { id: 'login.message.userInvalid' },
  loginGoogleText: { id: 'login.button.loginGoogle' },
  INVALID_USER_PASS: { id: 'login.error.INVALID_USER_PASS' },
  WRONG_PROVIDER: { id: 'login.error.WRONG_PROVIDER' },
  USER_NOT_CONFIRMED: { id: 'login.error.USER_NOT_CONFIRMED' },
  maxCharacters: { id: 'general.validations.maxCharacters' },
  minCharacters: { id: 'general.validations.minCharacters' },
  nameInputRegister: { id: 'register.input.name' },
  lastNameInputRegister: { id: 'register.input.lastName' },
  usernameInputRegister: { id: 'register.input.username' },
  passwordInputRegister: { id: 'register.input.password' },
  passwordConfirmationInputRegister: { id: 'register.input.passwordConfirmation' },
  emailInputError: { id: 'register.input.error.mail' },
  textSend: { id: 'register.button.text.send' },
  registerGoogle: { id: 'register.button.text.registerGoogle' },
  inputTooltipPass: { id: 'register.input.tooltip' },
  errorPassword: { id: 'register.input.error.password' },
  accountSucces: { id: 'register.accountSucces' },
  titleRecoverPassword: { id: 'recoverPassword.title' },
  subTitleRecoverPassword: { id: 'recoverPassword.subTitle' },
  recoverPasswordSuccess: { id: 'recoverPassword.success' },
  recoverPasswordErrorUser: { id: 'recoverPassword.error.user' },
  newPassSuccess: { id: 'newPass.success' },
  newPassTitle: { id: 'newPass.title' },
  newPassConfirm: { id: 'newPass.input.confirm' },
  confirmationRequiredTitle: { id: 'confirmationRequired.title' },
  confirmationRequiredText: { id: 'confirmationRequired.text' },
  accountConfirmationTitle: { id: 'accountConfirmation.title' },
  accountConfirmationText: { id: 'accountConfirmation.text' },
  accountConfirmationButton: { id: 'accountConfirmation.button' },
  accountConfirmationError: { id: 'accountConfirmation.error' },
  dataCampOf: { id: 'project.id.data.campOf' },
  dataCharacter: { id: 'project.id.data.character' },
  selectDate: { id: 'general.selectDate' },
  errorImage: { id: 'project.id.error.image' },

  addressLabel: { id: 'profile.complete.text.address' },
  floorLabel: { id: 'profile.complete.text.floor' },
  departmentLabel: { id: 'profile.complete.text.department' },
  cpLabel: { id: 'profile.complete.text.cp' },
  addressVerificationLabel: { id: 'profile.complete.text.addressVerification' },
  profilePictureLabel: { id: 'profile.complete.text.profilePicture' },
  documentNumberLabel: { id: 'profile.complete.text.documentNumber' },
  imagesIdentificationLabel: { id: 'profile.complete.text.imagesIdentification' },
  FrontLabel: { id: 'profile.complete.text.Front' },
  BackLabel: { id: 'profile.complete.text.Back' },
  birthdateLabel: { id: 'general.user.birthdate' },
  mainPhoneLabel: { id: 'admin.user.mainPhone' },
  secondaryPhoneLabel: { id: 'admin.user.secondaryPhone' },
  websiteLabel: { id: 'profile.complete.text.website' },
  mpUserName: { id: 'profile.complete.text.mpUserName' },
  cuitLabel: { id: 'profile.complete.text.cuit' },
  accountNumberLabel: { id: 'project.investDrawer.stepTwo.accountNumber' },
  headlineLabel: { id: 'profile.complete.text.headline' },
  typeAccountLabel: { id: 'profile.complete.text.typeAccount' },
  cbuLabel: { id: 'profile.complete.text.cbu' },
  cbuAliasLabel: { id: 'profile.complete.text.cbuAlias' },

  dataTitle: { id: 'register.data.title' },
  dataTitleBank: { id: 'profile.complete.title.bankData' },
  subTitlePesonal: { id: 'register.data.subTitlePesonal' },
  placeholderLocation: { id: 'register.data.placeholderLocation' },
  loadImage: { id: 'register.data.loadImage' },
  tooltipImage: { id: 'register.data.tooltipImage' },
  tooltipImageMax: { id: 'register.data.tooltipImageMax' },
  tooltipImageNit: { id: 'register.data.tooltipImageNit' },
  placeholderNumAccount: { id: 'register.data.placeholderNumAccount' },
  placeholderTypeAccount: { id: 'register.data.placeholderTypeAccount' },
  accountTypeCA: { id: 'profile.complete.text.accountTypeCA' },
  accountTypeCC: { id: 'profile.complete.text.accountTypeCC' },
  currency: { id: 'project.create.input.currency' },
  dolar: { id: 'project.create.option.dolar' },
  pesos: { id: 'project.create.option.pesos' },

  moderateTitle: { id: 'moderate.title.success' },
  moderateText: { id: 'moderate.text.success' },
  numericCamp: { id: 'validate.numericCamp' },
  invalidCuit: { id: 'validate.invalidCuit' },
  invalidCBU: { id: 'validate.invalidCBU' },
  invalidCBUAlias: { id: 'validate.invalidCBUAlias' },
  validatePassword: { id: 'validate.validatePassword' },
  validateAge: { id: 'validate.validateAge' },
  specialCharacter: { id: 'validate.specialCharacter' },
  validURL: { id: 'validate.validURL' },
  onlyImageComponent: { id: 'validate.onlyImage' },
  cannotExceedImageComponent: { id: 'validate.cannotExceedImage' },
  selectValidAddress: { id: 'validate.selectValidAddress' },
  errorSelectAddress: { id: 'validate.errorSelectAddress' },
  error: { id: 'admin.transactions.table.card.error' },
  pending: { id: 'admin.transactions.table.card.pending' },
  expired: { id: 'admin.transactions.table.card.expired' },
  returned: { id: 'admin.transactions.table.card.returned' },
  released: { id: 'admin.transactions.table.card.released' },
  rejectedRelease: { id: 'admin.transactions.table.card.rejectedRelease' },
  returnedRelease: { id: 'admin.transactions.table.card.returnedRelease' },
  returnedWithErrors: { id: 'admin.transactions.table.card.returnedWithErrors' },
  returnedReject: { id: 'admin.transactions.table.card.returnedReject' },
  accredited: { id: 'admin.transactions.table.card.accredited' },
  onBindCall: { id: 'admin.transactions.table.card.onBindCall' },
  rejected: { id: 'admin.transactions.table.card.rejected' },
});

export default messages;
