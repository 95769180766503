import { GET_FUND_RELEASE, CHANGE_RELEASE_STATUS } from '../actions/fundRelease';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingProject: false,
  didInvalidate: false,
  list: [],
};

const fundReleases = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case `${GET_FUND_RELEASE}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      list: []
    };
  case `${GET_FUND_RELEASE}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      list: []
    };
  case `${GET_FUND_RELEASE}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      list: action.payload.data
    };

  case `${CHANGE_RELEASE_STATUS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
    };
  case `${CHANGE_RELEASE_STATUS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${CHANGE_RELEASE_STATUS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      list: [
        ...state.list.slice(0, action.payload.index),
        action.payload.data,
        ...state.list.slice(action.payload.index + 1),
      ]
    };

    // case `${APROVE_RELEASE}_REJECTED`:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     didInvalidate: true,
    //   };
    // case `${APROVE_RELEASE}_PENDING`:
    //   return {
    //     ...state,
    //     isFetching: true,
    //     didInvalidate: false,
    //   };
    // case `${APROVE_RELEASE}_FULFILLED`:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     didInvalidate: false,
    //     list: [
    //       ...state.list.slice(0, action.payload.index),
    //       action.payload.data,
    //       ...state.list.slice(action.payload.index + 1),
    //     ]
    //   };

    // case `${REJECT_RELEASE}_REJECTED`:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     didInvalidate: true,
    //   };
    // case `${REJECT_RELEASE}_PENDING`:
    //   return {
    //     ...state,
    //     isFetching: true,
    //     didInvalidate: false,
    //   };
    // case `${REJECT_RELEASE}_FULFILLED`:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     didInvalidate: false,
    //     list: [
    //       ...state.list.slice(0, action.payload.index),
    //       action.payload.data,
    //       ...state.list.slice(action.payload.index + 1),
    //     ]
    //   };

  default:
    return state;
  }
};

export default fundReleases;
