import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Layout, Menu, Row, Col, Drawer, Button, Icon } from "antd";

import { QuestionCircleOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';

import logo from "./wibai-logo.svg";
import iconPerfil from "./icon_perfil.png";
import { signoutUser } from "../../../../actions/user";
import { changeCulture, changeCountry } from "../../../../actions/culture";

import messages from "../../home/cultureHome";

import LenguageComponent from "./lenguage"

import "./header.css";

const { Header } = Layout;

const styles = {
  image: {
    padding: 4,
    height: 45,
    marginLeft: 10,
    float: "left",
    marginRight: 35,
    marginTop: 10
  },
  imageAdmin: {
    padding: 4,
    height: 35,
    marginLeft: 10,
    float: "left",
    marginRight: 35,
    marginTop: 0
  },
  icons: {
    height: 20,
    width: 20,
    cursor: "pointer",
    marginRight: 20
  }
};

const ContainerIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 30px;

  @media (max-width: 920px) {
    display: none;
  }
`;

const ContainerInfo = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: ${props => props.justify};
  align-items: center;
`;

const ButtonLogin = styled.div`
  & button {
    border: solid 0px;
    border-radius: 40px;
    box-shadow: none;
    width: 157px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: 0.28px;
    color: #6e48fa;
    margin-right: 18px;
    text-transform: uppercase;
    background-color: transparent;
  }

  & button& button:hover {
    background-color: transparent;
    border-color: #6e48fa;
    color: #6e48fa;
  }

  @media (max-width: 920px) {
    & button {
      display: none;
    }
  }
`;

// const ButtonRegister = styled.div`

//   & button {
//     width: 157px;
//     height: 45.6px;
//     border-radius: 40px;
//     box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
//     border none;
//     background-color: rgba(79, 0, 202, 0.67);
//     font-family: 'Roboto';
//     font-size: 12px;
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: 1.42;
//     letter-spacing: 0.28px;
//     color: #ffffff;
//   }

//   & button:hover {
//     background-color: #4f00cb;
//     color: #fff;
//   }

//   @media (max-width: 920px) {
//     & button {
//       display: none;
//     }
//   }
// `;

const ContainerAdmin = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: space-between;
  background-color: black;
  color: white;
  padding: 3px;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: bold;
  z-index: 3;
  position: fixed;
  width: 100%;
`;

const MobileButton = styled.div`
  @media (max-width: 920px) {
    & a {
      display: none;
    }
  }
`;

const CenteredContainer = styled.div`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

class HeaderComponent extends Component {
  state = { visible: false, style: "transparent", show: true };

  componentDidMount() {
    this.historyListener = this.props.history.listen((location, action) => {
      if (location.pathname.includes("login")) {
        this.setState({
          show: false
        });
      } else {
        if (
          location.pathname.length === 1 ||
          location.pathname.includes("microsite") ||
          (location.pathname.includes("projects") &&
            location.pathname.lastIndexOf("/") === 0)
        ) {
          this.setState({
            style: "transparent",
            show: true
          });
        } else {
          this.setState({
            style: "primary",
            show: true
          });
        }
      }
    });
    if (this.props.location.pathname.includes("login")) {
      this.setState({
        show: false
      });
    } else {
      if (
        this.props.location.pathname.length === 1 ||
        this.props.location.pathname.includes("microsite") ||
        (this.props.location.pathname.includes("projects") &&
          this.props.location.pathname.lastIndexOf("/") === 0)
      ) {
        this.setState({
          style: "transparent",
          show: true
        });
      } else {
        this.setState({
          style: "primary",
          show: true
        });
      }
    }

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.historyListener();
  }

  handleScroll = () => {
    if (
      this.props.location.pathname.length === 1 ||
      this.props.location.pathname.includes("microsite") ||
      (this.props.location.pathname.includes("projects") &&
        this.props.location.pathname.lastIndexOf("/") === 0)
    ) {
      if (window.pageYOffset > 0) this.setState({ style: "primary" });
      else this.setState({ style: "transparent" });
    }
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  changeCulture = culture => {
    this.props.changeCulture(culture);
    setTimeout(() => {
      window.location.reload();
    }, 5);
  };



  handleChange = e => {

    Promise.resolve(this.props.changeCountry(e)) // dispatch
      .then(function (response) {
        window.location.reload();
      })

    this.setState({
      modalVisible: false,
    });

  };

  render() {
    const { visible, show } = this.state;
    const { user, signout, intl, country } = this.props;

    if (user.username && user.token) {
      return (
        <div style={{ zIndex: 99 }}>
          {user.roles === "admin" && (
            <ContainerAdmin>
              <div>{intl.formatMessage(messages.adminView)}</div>
              <div>
                <Link to="/admin/projects">Volver al backoffice</Link>
              </div>
            </ContainerAdmin>
          )}
          <Header
            className={`header ${this.state.style}`}
            style={{
              paddingRight: 10,
              marginTop: user.roles === "admin" ? 30 : 0
            }}
          >
            <Row type="flex" justify="space-between">
              <CenteredContainer>
                <Link to="/">
                  <img src={logo} alt="logo" style={styles.imageAdmin} />
                </Link>
                {/* <Menu mode="horizontal" className="menuLocale">
                  <Menu.Item key="es" style={{ padding: '2px 10px', color: 'white' }} onClick={() => this.changeCulture('en-AR')}>
                    ES
                  </Menu.Item>
                  <Menu.Item key="en" style={{ padding: '2px 10px', color: 'white' }} onClick={() => this.changeCulture('en-US')}>
                    EN
                  </Menu.Item>
                </Menu> */}
              </CenteredContainer>
              <CenteredContainer>
                <ContainerInfo justify="center">
                  <Menu mode="horizontal" className="menuOpcionLog">
                    <Menu.Item key="section2">
                      <Link to="/aboutus">
                        {intl
                          .formatMessage(messages.aboutUs)
                          .toLocaleUpperCase()}
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="section3">
                      <Link to="/projects">
                        {intl
                          .formatMessage(messages.headerProjects)
                          .toLocaleUpperCase()}
                      </Link>
                    </Menu.Item>
                  </Menu>
                  <MobileButton>
                    <Link to="/" >
                      <LenguageComponent handleChange={this.handleChange} defaultCountry={country} />
                    </Link>
                  </MobileButton>
                </ContainerInfo>
              </CenteredContainer>
              <CenteredContainer>
                <ContainerIcons>
                  <Link to="/profile">
                      <UserOutlined className="iconStyleAlt" />
                  </Link>
                  <div onClick={signout}>
                    <PoweroffOutlined className="iconStyleAlt" />
                  </div>
                </ContainerIcons>
                <MobileButton>
                  <Link to="/aboutus">
                     <QuestionCircleOutlined className="iconStyle" /> 
                  </Link>
                </MobileButton>
                <Drawer
                  title={intl.formatMessage(messages.headerMenuDrawer)}
                  placement="right"
                  className="menu_drawer"
                  onClose={this.onClose}
                  visible={visible}
                >
                  <Menu mode="inline" className="menuOpcionMobile">
                    <Menu.Item key="section2">
                      <Link onClick={this.onClose} to="/aboutus">
                        {intl
                          .formatMessage(messages.aboutUs)
                          .toLocaleUpperCase()}
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="section3">
                      <Link onClick={this.onClose} to="/projects">
                        {intl
                          .formatMessage(messages.headerProjects)
                          .toLocaleUpperCase()}
                      </Link>
                    </Menu.Item>
                  </Menu>
                  <Menu mode="inline" className="menuUserMobile">
                    <Menu.Item key="login">
                      <Link onClick={signout} to="/account/logout">
                        {intl
                          .formatMessage(messages.logoutFooter)
                          .toLocaleUpperCase()}
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Drawer>
                <Button
                  className="menu__mobile-button"
                  type="primary"
                  onClick={this.showDrawer}
                >
                  <Icon type="menu" />
                </Button>
              </CenteredContainer>
            </Row>
          </Header>
        </div>
      );
    }

    return (
      <Header
        className={`header ${this.state.style}`}
        style={{ paddingRight: 10, display: show ? "block" : "none" }}
      >

        <Row>
          <Col xs={14} md={7}>
            <Link to="/">
              <img src={logo} alt="logo" style={styles.image} />
            </Link>
            {/* <Menu mode="horizontal" className="menuLocale" style={{ marginTop: 14 }}>
              <Menu.Item key="es" style={{ padding: '2px 10px', color: 'white' }} onClick={() => this.changeCulture('en-AR')}>
                ES
              </Menu.Item>
              <Menu.Item key="en" style={{ padding: '2px 10px', color: 'white' }} onClick={() => this.changeCulture('en-US')}>
                EN
              </Menu.Item>
            </Menu> */}
          </Col>
          <Col
            xs={10}
            md={17}
            className="menuHeader"
            style={{ display: "flex" }}
          >
            <ContainerInfo justify="flex-end">
              <Menu mode="horizontal" className="menuOpcionLog">
                <Menu.Item key="section2">
                  <Link to="/aboutus">
                    {intl.formatMessage(messages.aboutUs).toLocaleUpperCase()}
                  </Link>
                </Menu.Item>
                <Menu.Item key="section3">
                  <Link to="/projects">
                    {intl
                      .formatMessage(messages.headerProjects)
                      .toLocaleUpperCase()}
                  </Link>
                </Menu.Item>
              </Menu>
              <MobileButton>
                <Link to="/" >
                  <LenguageComponent handleChange={this.handleChange} defaultCountry={country} />
                </Link>
              </MobileButton>

              <ButtonLogin>
                <Button block>
                  <Link to="/account/login">INGRESAR</Link>
                </Button>
              </ButtonLogin>
              <ButtonLogin>
                <Button block className="registerBotton">
                  <Link to="/account/register">REGISTRARTE</Link>
                </Button>
              </ButtonLogin>
              <MobileButton>
                <Link to="/aboutus">
                  <QuestionCircleOutlined className="iconStyle" /> 
                </Link>
              </MobileButton>

            </ContainerInfo>
            <Drawer
              title={intl.formatMessage(messages.headerMenuDrawer)}
              placement="right"
              className="menu_drawer"
              onClose={this.onClose}
              visible={visible}
            >
              <Menu mode="inline" className="menuOpcionMobile">
                <Menu.Item key="section2">
                  <Link onClick={this.onClose} to="/aboutus">
                    {intl.formatMessage(messages.aboutUs).toLocaleUpperCase()}
                  </Link>
                </Menu.Item>
                <Menu.Item key="section3">
                  <Link onClick={this.onClose} to="/projects">
                    {intl
                      .formatMessage(messages.headerProjects)
                      .toLocaleUpperCase()}
                  </Link>
                </Menu.Item>
              </Menu>
              <Menu mode="inline" className="menuUserMobile">
                <Menu.Item key="login">
                  <Link onClick={this.onClose} to="/account/login">
                    {intl
                      .formatMessage(messages.loginFooter)
                      .toLocaleUpperCase()}
                  </Link>
                </Menu.Item>
                <Menu.Item key="register">
                  <Link onClick={this.onClose} to="/account/register">
                    {intl.formatMessage(messages.buttonRegister)}
                  </Link>
                </Menu.Item>

                <Menu.Item key="country">
                  <Link to="/" >
                    <LenguageComponent handleChange={this.handleChange} defaultCountry={country} />
                  </Link>
                </Menu.Item>

              </Menu>
            </Drawer>
            <Button
              className="menu__mobile-button"
              type="primary"
              onClick={this.showDrawer}
            >
              <Icon type="menu" />
            </Button>
          </Col>
        </Row>

      </Header>
    );
  }
}

const HeaderComponentWithRouter = withRouter(HeaderComponent);

const mapStateToProps = state => ({ user: state.user, country: state.culture.country });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ signout: signoutUser, changeCulture, changeCountry }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HeaderComponentWithRouter));
