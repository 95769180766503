import { defineMessages } from 'react-intl';

const messages = defineMessages({
  investTitle: { id: 'home.title.invest' },
  investTextContent: { id: 'home.text.content' },

  buttonReadMore: { id: 'home.button.readMore' },
  buttonRegister: { id: 'home.button.register' },
  buttonMoreProject: { id: 'home.button.moreProject' },

  titleProject: { id: 'home.title.project' },
  textContentProject: { id: 'home.text.contentProject' },
  questionProp: { id: 'home.title.questionProp' },
  questionWibai: { id: 'microsite.title.questionWibai' },
  crowfunding: { id: 'home.title.crowfunding' },
  crowfundingContent: { id: 'home.text.crowfunding' },
  forAll: { id: 'home.title.forAll' },
  forAllContent: { id: 'home.text.forAll' },
  simpleAndAccesible: { id: 'home.title.simpleAndAccesible' },
  simpleAndAccesibleContent: { id: 'home.text.simpleAndAccesible' },
  questionWhyProp: { id: 'home.title.questionWhyProp' },

  howProccess: { id: 'home.title.howProccess' },
  registerProccess: { id: 'home.oneProccess.registerProccess' },
  registerProccessContent: { id: 'home.oneProccessContent.registerProccess' },
  choseProccess: { id: 'home.twoProccess.choseProccess' },
  choseProccessContent: { id: 'home.twoProccessContent.choseProccess' },
  investProccess: { id: 'home.threeProccess.investProccess' },
  investProccessContent: { id: 'home.threeProccessContent.investProccess' },
  winProccess: { id: 'home.fourProccess.winProccess' },
  winProccessContent: { id: 'home.fourProccessContent.winProccess' },

  titleNumber: { id: 'home.title.titleNumber' },
  investNumber: { id: 'home.oneTitleNumber.investNumber' },
  approvedNumber: { id: 'home.twoTitleNumber.approvedNumber' },
  projectsNumber: { id: 'home.threeTitleNumber.projectsNumber' },
  capitalNumber: { id: 'home.fourTitleNumber.capitalNumber' },
  investNumberContent: { id: 'home.oneContentNumber.investNumber' },
  approvedNumberContent: { id: 'home.twoContentNumber.approvedNumber' },
  projectsNumberContent: { id: 'home.threeContentNumber.projectsNumber' },
  capitalNumberContent: { id: 'home.fourContentNumber.capitalNumber' },

  readyInvest: { id: 'home.title.readyInvest' },
  readyInvestContent: { id: 'home.text.readyInvestContent' },
  readyButton: { id: 'home.button.readyButton' },
  submit: { id: 'home.button.submit' },

  wantCommunicate: { id: 'home.title.wantCommunicate' },
  email: { id: 'home.title.email' },
  emailContent: { id: 'home.content.emailContent' },
  phone: { id: 'home.title.phone' },
  phoneContent: { id: 'home.content.phoneContent' },
  phoneContentWssp: { id: 'home.content.phoneContentWssp' },

  nameInput: { id: 'home.input.name' },
  emailInput: { id: 'home.input.email' },
  phoneInput: { id: 'home.input.phone' },
  messageInput: { id: 'home.input.message' },
  nameInputRule: { id: 'home.inputMessage.name' },
  emailInputRule: { id: 'home.inputMessage.email' },
  emailInputRuleError: { id: 'home.inputMessageError.email' },
  phoneInputRule: { id: 'home.inputMessage.phone' },
  messageInputRule: { id: 'home.inputMessage.message' },
  messageSuccess: { id: 'home.form.success' },

  titleProp: { id: 'home.footer.title.invest' },
  questionPropiFooter: { id: 'home.footer.question.propi' },
  questionWibaiFooter: { id: 'microsite.footer.question.wibai' },
  titleCompany: { id: 'home.footer.title.company' },
  aboutUs: { id: 'home.footer.content.aboutUs' },
  accessTitleFooter: { id: 'home.footer.title.access' },
  registerFooter: { id: 'home.footer.content.register' },
  loginFooter: { id: 'home.footer.content.login' },
  logoutFooter: { id: 'home.footer.content.logout' },

  rightsReserved: { id: 'home.footer.content.rightsReserved' },
  micrositeRightsReserved: { id: 'microsite.footer.content.rightsReserved' },
  termsAndConditions: { id: 'home.footer.content.termsAndConditions' },
  privacyPolicy: { id: 'home.footer.content.privacyPolicy' },
  contentFooter: { id: 'home.footer.content.contentFooter' },

  noResults: { id: 'home.featureProjects.noResults' },
  seeAllProjects: { id: 'home.featureProjects.seeAllProjects' },
  featureProjectsInvestTitle: { id: 'home.featureProjects.titleInvest' },
  daysFeatureProjects: { id: 'home.featureProjects.daysFeatureProjects' },
  remainingDaysFeatureProjects: {
    id: 'home.featureProjects.remainingDaysFeatureProjects'
  },
  tirFeatureProjects: { id: 'home.featureProjects.tirFeatureProjects' },
  multipleFeatureProjects: {
    id: 'home.featureProjects.multipleFeatureProjects'
  },
  investFeatureProjects: { id: 'home.featureProjects.investFeatureProjects' },

  adminView: { id: 'home.header.admin' },
  headerProjects: { id: 'home.header.projects' },
  headerProfile: { id: 'home.header.profile' },
  headerSignout: { id: 'home.header.signout' },
  headerMenuDrawer: { id: 'home.header.headerMenuDrawer' },

  projectTitleContent: { id: 'project.content' },

  breadcrumbHome: { id: 'project.breadcrumb.home' },
  breadcrumbAllProjects: { id: 'project.breadcrumb.allProjects' },
  breadcrumbAboutUs: { id: 'project.breadcrumb.aboutUs' },
  cFinished: { id: 'project.campaign.finished' },
  numericCamp: { id: 'validate.numericCamp' },
  specialCharacter: { id: 'validate.specialCharacter' }
});

export default messages;
