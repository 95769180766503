import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Layout,
  Form,
  Breadcrumb,
  Button,
  message
} from 'antd';
import styled from 'styled-components';

import { createCountry } from '../../../../actions/country';
import messages from '../culture';

import {
  FormItemInput,
} from '../../../commons/formComponents/formComponents';


const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const { Content } = Layout;

const styles = {
  content: { padding: 24, background: '#fff', minHeight: 360 },
};

const Container = styled.div`
  background-color: #FFF;
  padding: 24px;
  margin-bottom: 40px;
`;

class EditTransaction extends React.Component {

  handleSubmit = (e) => {
    const {
      form,
      create,
      history
    } = this.props;

    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        create(values)
          .then(() => history.push('/admin/countries'))
          .catch((error) => {
            const { data } = error.response;

            if (data && data.error.code === 11000) return message.error('Error al intentar crear país, CÓDIGO duplicado');
            if (data && data.error.code === "1") return message.error('Error al intentar crear país, Descripcion duplicada');
              
          });
      }
    });
  }

  render() {
    const { intl, form } = this.props;

    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbHome)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(messages.countries)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbEdit)}</Breadcrumb.Item>
        </Breadcrumb>
        <Container>
          <Form style={styles.content} onSubmit={this.handleSubmit}>
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.currencyTable)}
              field="currency"
              initialValue={''}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 3, message: `${intl.formatMessage(messages.maxCharacters)} 3` },
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.descriptionTable)}
              field="description"
              initialValue={''}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.codeTable)}
              field="code"
              initialValue={''}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 2, message: `${intl.formatMessage(messages.maxCharacters)} 2` },
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.MPAccessTokenTable)}
              field="MPAccessToken"
              initialValue={''}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
              ]}
            />
            <ButtonContainer>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
              >{intl.formatMessage(messages.createProjectButton)}
              </Button>
            </ButtonContainer>
          </Form>
        </Container>
      </Content>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'country' })(EditTransaction);

const mapDispatchToProps = dispatch => bindActionCreators({
  create: createCountry
}, dispatch);

const connectedForm = connect(null, mapDispatchToProps)(WrappedRegistrationForm);

export default injectIntl(connectedForm, {
  withRef: false,
});
