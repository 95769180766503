import {
  CREATE_INVESTOR, UPDATE_INVESTOR, CONFIMATION_ACCOUNT, RESET_PASSWORD, CHANGE_PASSWORD, RESEND_CONFIRMATION
} from '../actions/registration';

const INITIAL_STATE = {
  confirmed: null,
  status: null,
  response: null,
  visible: false,
  messageResponse: '',
  isFetching: false,
  didInvalidate: false
};

const registration = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${CREATE_INVESTOR}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      messageResponse: 'El usuario ya existe!',
      visible: true
    };
  case `${CREATE_INVESTOR}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      messageResponse: '',
    };
  case `${CREATE_INVESTOR}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      status: action.payload
    };

  case `${UPDATE_INVESTOR}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      messageResponse: action.payload.response.data.error,
      visible: true
    };
  case `${UPDATE_INVESTOR}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      messageResponse: '',
    };
  case `${UPDATE_INVESTOR}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      response: action.payload
    };

  case `${RESET_PASSWORD}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      messageResponse: '',
    };
  case `${RESET_PASSWORD}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
    };
  case `${RESET_PASSWORD}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true
    };
  case `${RESEND_CONFIRMATION}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      messageResponse: '',
    };
  case `${RESEND_CONFIRMATION}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
    };
  case `${RESEND_CONFIRMATION}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true
    };

  case `${CHANGE_PASSWORD}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      messageResponse: '',
    };
  case `${CHANGE_PASSWORD}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
    };
  case `${CHANGE_PASSWORD}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true
    };

  case `${CONFIMATION_ACCOUNT}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true
    };
  case `${CONFIMATION_ACCOUNT}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      messageResponse: '',
    };
  case `${CONFIMATION_ACCOUNT}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      confirmed: action.payload
    };
  default:
    return state;
  }
};

export default registration;
