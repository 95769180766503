import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Layout, Icon } from 'antd';
import styled from 'styled-components';

import './registration.css';
// import mail from '../../../images/mail.png';

import messages from './culture';

const { Content } = Layout;

const PaymentSuccessContainer = styled.div`
  width: 700px;

  & {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  & h1 {
    font-family: Roboto;
    font-size: 92px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 1.2rem 0;
  }

  & p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    max-width: 510px;
  }

  & i {
    font-size: 163px;
    position: absolute;
    top: 0;
    color: #fff;
    opacity: 0.1;
    right: 24px;
  }
`;

const Container = styled(Content)`
  padding-top: 150px;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
`;

class ConfirmationRequired extends Component {
  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'auto'
    });
  }

  render() {
    const { intl } = this.props;

    return (
      <Container className="success">
        <PaymentSuccessContainer>
          <Icon type="mail" />
          <h1>{intl.formatMessage(messages.confirmationRequiredTitle)}</h1>
          <p>{intl.formatMessage(messages.confirmationRequiredText)}</p>
        </PaymentSuccessContainer>
      </Container>
    );
  }
}

export default injectIntl(ConfirmationRequired);
