import { defineMessages } from 'react-intl';

const messages = defineMessages({
  start: { id: 'admin.transactions.table.breadcrumb.start' },
  transactions: { id: 'admin.transactions.table.breadcrumb.transactions' },
  breadCrumbHome: { id: 'admin.transactions.edit.breadcrumb.start' },
  breadCrumbTransactions: { id: 'admin.transactions.edit.breadcrumb.transactions' },
  breadCrumbEdit: { id: 'admin.transactions.edit.breadcrumb.edit' },
  idTitle: { id: 'admin.transactions.table.title.id' },
  createdAtTitle: { id: 'admin.transactions.table.title.createdAt' },
  investorUserTitle: { id: 'admin.transactions.table.title.investorUser' },
  projectNameTitle: { id: 'admin.transactions.table.title.projectName' },
  amountTitle: { id: 'admin.transactions.table.title.amount' },
  originIdTitle: { id: 'admin.transactions.table.title.originId' },
  transactionIdTitle: { id: 'admin.transactions.table.title.transactionId' },
  statusTitle: { id: 'admin.transactions.table.title.status' },
  accredited: { id: 'admin.transactions.table.card.accredited' },
  pending: { id: 'admin.transactions.table.card.pending' },
  expired: { id: 'admin.transactions.table.card.expired' },
  returned: { id: 'admin.transactions.table.card.returned' },
  released: { id: 'admin.transactions.table.card.released' },
  developer: { id: 'admin.transactions.table.card.developer' },
  rejectedRelease: { id: 'profile.myInvestments.status.rejectedRelease' },
  returnedRejected: { id: 'admin.transactions.table.card.returnedReject' },
  actionTitle: { id: 'users.table.title.action' },
  history: { id: 'admin.transactions.table.history' },
  edit: { id: 'admin.transactions.table.edit' },
  close: { id: 'admin.transactions.modal.close' },
  createdAt: { id: 'admin.transactions.modal.upload' },
  amount: { id: 'admin.transactions.edit.amount' },
  currency: { id: 'admin.transactions.edit.currency' },
  state: { id: 'admin.transactions.edit.state' },
  bindTransferId: { id: 'admin.transactions.edit.bindTransferId' },
  bindTransferIdRequired: { id: 'admin.transactions.edit.bindTransferIdRequiredMessage' },
  getBindTransfer: { id: 'admin.transactions.edit.getBindTransfer' },
  editTransfer: { id: 'admin.transactions.edit.editTransaction' },
  editTransferError: { id: 'admin.transactions.edit.editTransactionError' },
  cancel: { id: 'admin.transactions.edit.cancel' },
  rejectWithErrors: { id: 'admin.transactions.table.card.rejectWithErrors' },
  returnedRelease: { id: 'admin.transactions.table.card.returnedRelease' },
  returnedReject: { id: 'admin.transactions.table.card.returnedReject' },
  error: { id: 'admin.transactions.table.card.error' },
  onBindCall: { id: 'admin.transactions.table.card.onBindCall' },
  releasedWithErrors: { id: 'admin.transactions.table.card.releasedWithErrors' },
  returnedWithErrors: { id: 'admin.transactions.table.card.returnedWithErrors' },
  getBindTransferError: { id: 'admin.transactions.edit.getBindTransferError' },

  pendingTransaction: { id: 'admin.transactions.status.pending' },
  expiredTransaction: { id: 'admin.transactions.status.expired' },
  accreditedTransaction: { id: 'admin.transactions.status.accredited' },
  releasedTransaction: { id: 'admin.transactions.status.released' },
  rejectedReleaseTransaction: { id: 'admin.transactions.status.noReleased' },
  returnedTransaction: { id: 'admin.transactions.status.refunded' },
  returnedWithErrorsTransaction: { id: 'admin.transactions.table.card.returnedWithErrors' },
  errorTransaction: { id: 'admin.transactions.status.error' },
  returnedRejectTransaction: { id: 'admin.transactions.status.noRefunded' },
  onBindCallTransaction: { id: 'admin.transactions.status.errorBind' },
});

export default messages;
