import {
  LOGIN_ACCOUNT,
  REMOVE_TOKEN,
  LOGIN_DATA,
  SIGNOUT_REQUEST,
  CHANGE_USER_STATE,
  GET_USER_INFO,
  CHANGE_REDIRECT_STATE
} from '../actions/user';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  token: null,
  username: null,
  state: null,
  provider: null,
  complete: false,
  roles: false,
  userInfo: false,
  redirect: false,
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case `${LOGIN_ACCOUNT}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${LOGIN_ACCOUNT}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      token: action.payload.token,
      username: action.payload.username,
      state: action.payload.state,
      provider: action.payload.provider,
      roles: action.payload.roles,
      userInfo: action.payload.userInfo,
      redirect: action.payload.redirect,
    };
  case `${LOGIN_ACCOUNT}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true
    };

  case `${GET_USER_INFO}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${GET_USER_INFO}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      userInfo: action.payload.user,
    };
  case `${GET_USER_INFO}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true
    };

  case `${LOGIN_DATA}`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      token: action.payload.token,
      username: action.payload.username,
      state: action.payload.state,
      provider: action.payload.provider,
      roles: action.payload.roles,
      userInfo: action.payload.userInfo,
      redirect: action.payload.redirect,
    };

  case `${SIGNOUT_REQUEST}`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      token: false,
      username: null
    };

  case `${REMOVE_TOKEN}`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      token: null
    };

  case `${CHANGE_USER_STATE}`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      state: action.payload.state,
      complete: action.payload.complete,
    };

  case `${CHANGE_REDIRECT_STATE}`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      redirect: false,
    };

  default:
    return state;
  }
};

export default user;
