import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  Layout, Row, Col, Breadcrumb
} from 'antd';

import '../../../commons/css/slider.css';
import FilteredProjects from '../../home/filteredProjects';
import Filter from './filterProjects';

import { filterProjects } from '../../../../actions/projects';

import messages from '../../../admin/projects/create/culture/messages';
import messagesHome from '../../home/cultureHome';
import messagesFilter from './cultureFilter';

const { Content } = Layout;

const Container = styled.div`
  & {
    background: #fff;
    min-height: 500px;
  }

  & .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  @media (max-width: 768px) {
    & {
      margin: 0;
    }
  }
`;

const TopBanner = styled.div`
  & {
    position: relative;
  }

  & .bg {
    width: 100%;
    height: 400px;
    position: relative;
  }

  & .bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 0;
  }

  & .banner-content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #6e48fa;
    z-index: 1;
  }

  & .banner-container {
    position: relative;
  }

  & h1 {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: 'Roboto';
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 5 10 5 20;
  }

  & hr {
    width: 227px;
    border-color: rgba(255, 255, 255, 0.5);
  }

  & p {
    max-width: 566px;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0 0.5rem;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 536px;
    margin-top: 5rem;
  }

  & .buttons > div:first-child {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    & h1 {
      margin-left: 1.2rem;
      margin-right: 1.2rem;
    }

    & p {
      margin-left: 1.2rem;
      margin-right: 1.2rem;
    }
  }

  @media (max-width: 535px) {
    & .buttons {
      justify-content: center;
    }

    & .buttons > div:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

const MarginedContainer = styled.div`
  & {
    margin-top: 2rem;
    margin-bottom: 15rem;
    padding: 0 80px;
  }

  & .inner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & h2 {
    font-family: 'Roboto';
    font-size: 62px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #3c0199;
  }

  & h2.subtitle {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 55px;
    color: #3f069b;
  }

  & .separator {
    width: 90px;
    border-color: #3c0199;
    border-width: 0.75px;
    margin-bottom: 2rem;
  }

  & .description {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    max-width: 603px;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 536px;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  & .buttons > div:first-child {
    margin-right: 20px;
  }

  & #contactHeader {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  & .contact-title {
    display: flex;
    width: 100%;
    margin-bottom: 1.5em;
    align-items: center;
    justify-content: center;
  }

  & .contact-title h3 {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    margin: 0;
  }

  & .contact-title i {
    font-size: 24px;
    margin-right: 8px;
  }

  & .phones-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    font-family: 'Roboto';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    max-width: 315px;
  }

  & .phones-container > div:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }

  & .phones-container h3 {
    font-family: 'Roboto';
    font-size: 29px;
    font-weight: bold;
    color: #46eac4;
  }

  & .phones-container > div:not(:last-child) p {
    margin-bottom: 2rem;
  }

  & .phones-container > div:last-child h3 {
    margin-top: 2rem;
  }

  @media (max-width: 565px) {
    & .buttons {
      justify-content: center;
    }

    & .buttons > div:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    & {
      padding: 0 15px;
    }
  }
`;

class Projects extends Component {
  state = { tags: [] }

  handleChange = (event) => {
    const { name } = JSON.parse(event);
    const { tags } = this.state;

    if (!tags.includes(name)) this.setState(prevState => ({ tags: [...prevState.tags, name] }));
  }

  handleRemove = (index) => {
    this.setState(prevState => ({
      tags: [
        ...prevState.tags.slice(0, index),
        ...prevState.tags.slice(index + 1),
      ]
    }));
  }

  render() {
    const {
      intl,
      filter,
      projects,
      country,
      handleChangeDrawer
    } = this.props;


    return (
      <Content className="home">
        <Container style={{ marginTop: '5rem' }}>
          <TopBanner>
            <div className="bg" />
            <div className="banner-content">
              <h1>{intl.formatMessage(messagesHome.titleProject)}</h1>
              <hr />
              <p>
                {intl.formatMessage(messagesHome.projectTitleContent)}
              </p>
            </div>
          </TopBanner>
          <MarginedContainer>
            <Breadcrumb>
              <Breadcrumb.Item><Link to="/">{intl.formatMessage(messagesHome.breadcrumbHome)}</Link></Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(messagesHome.breadcrumbAllProjects)}</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
              <Col span={24}>
                <Filter
                  messages={messages}
                  intl={intl}
                  filter={filter}
                  projectFilter={projects}
                  messagesFilter={messagesFilter}
                  country={country}
                />
                <FilteredProjects
                  projectFilter={projects.filterProjects}
                  handleChangeDrawer={handleChangeDrawer}
                  intl={intl}
                  messages={messagesHome}
                  home
                />
              </Col>
            </Row>
          </MarginedContainer>

        </Container>
      </Content>
    );
  }
}

const mapStateToProps = state => ({ projects: state.projects, country: state.culture.country });

const mapDispatchToProps = dispatch => bindActionCreators({
  filter: filterProjects
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Projects));
