import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Spin } from "antd";
import styled from "styled-components";

import history from "../history";

import { initializeAxios } from "../service/axios";
import { validateToken, changeState } from "../actions/auth";
import { stopReLoading } from "../actions/culture";
import { changeUserState, changeRedirect } from "../actions/user";

import HomeRoutes from "./homeRoutes";
import AdminRoutes from "./adminRoutes";
import PrivateRouteComponent from "./privateRouteComponent";
import Routes from "./routes";
import Config from "../styles.config";


const ContainerLoading = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const GlobalStylesContainer = styled.div`
  & header {
    background-color: ${Config.primary};
  }

  & header .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom: 2px solid ${Config.warn};
  }

  & header .ant-menu-horizontal > .ant-menu-item-selected {
    border-bottom: 2px solid ${Config.warn};
  }

  & main.login {
    background-color: ${Config.primary} !important;
  }

  & main.success {
    background-color: ${Config.primary} !important;
  }

  & footer > div:first-child {
    background-color: ${Config.footer};
  }

  & button.ant-btn-primary {
    background-color: ${Config.warn};
    border-color: ${Config.warn};
    padding-top: 7px;
  }

  & .warn-text {
    color: ${Config.warn};
  }

  & .primary-bg {
    background-color: ${Config.primary};
  }

  & .primary-text {
    color: ${Config.primary};
  }

  & button.secondary {
    background-color: ${Config.secondary};
  }

  & .custom-timeline-item.primary .ant-timeline-item-head,
  & .hideOnDesktop .ant-timeline-item.primary .ant-timeline-item-head {
    background-color: ${Config.primary};
    border-color: ${Config.primary} !important;
  }

  & .custom-timeline-item.primary .ant-timeline-item-tail,
  & .hideOnDesktop .ant-timeline-item.primary .ant-timeline-item-tail {
    border-left: 3px solid ${Config.primary} !important;
    background-color: ${Config.primary};
  }

  & .rewards-content::-webkit-scrollbar-thumb {
    background: ${Config.primary};
  }

  & .rewards-content::-webkit-scrollbar-thumb:hover {
    background: ${Config.primary};
  }
`;

class Hoc extends Component {
  componentWillMount() {
    const { validate, user, changeAppStateRedux } = this.props;
    initializeAxios();
    this.props.stopReLoading();

    if (user.token) validate(user.token);
    else changeAppStateRedux(false);
  }

  componentDidUpdate(prevProps) {
    const { user, changeRedirectState } = this.props;

    if (prevProps.user.state !== user.state) {
      if (user.redirect) {
        changeRedirectState();
        history.push(user.redirect);
      } else if (user.complete) history.push("/moderate");
      // else if (user.state === 'new') history.push('/account/data');
      else if (user.roles === "admin") history.push("/admin/projects");
      else return history.push("/");
    }
  }

  render() {

    const { user, auth, changeUserStateRedux, isReloading } = this.props;

    if (auth.isFetching || isReloading) {
      return (
        <ContainerLoading>
          <Spin size="large" />
        </ContainerLoading>
      );
    }

    return (
      <LayoutMenu user={user} changeUserStateRedux={changeUserStateRedux} />
    );
  }
}

class LayoutMenu extends Component {
  componentWillMount() {
    const { user } = this.props;

    if (
      (!user.username || user.roles === "admin") &&
      window.location.pathname === "/profile"
    ) {
      return history.push("/");
    }
    if (!user.complete && window.location.pathname === "/moderate") {
      return history.push("/");
    }
    if (window.location.pathname === "/account/data" && !user.token) {
      return history.push("/");
    }
    if (
      user.token &&
      window.location.pathname.includes("/account") &&
      !(window.location.pathname === "/account/data")
    ) {
      return history.push("/");
    }
    if (user.state !== "new" && window.location.pathname === "/account/data") {
      return history.push("/");
    }
    if (user.roles !== "admin" && window.location.pathname.includes("/admin")) {
      if (user.username) return history.push("/");
      return history.push("/account/login");
    }
  }

  render() {

    const { user } = this.props;

    return (
      <GlobalStylesContainer>
        <Router history={history}>
          <Switch>
            <Route path="/account" component={Routes} />
            <PrivateRouteComponent
              path="/admin"
              pathname="/account/login"
              component={AdminRoutes}
              {...this.props}
              token={user.token}
            />
            <Route path="/" component={HomeRoutes} />
          </Switch>
        </Router>
      </GlobalStylesContainer>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  auth: state.auth,
  isReloading: state.culture.isReLoading,
  country: state.culture.country
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      validate: validateToken,
      changeUserStateRedux: changeUserState,
      changeAppStateRedux: changeState,
      changeRedirectState: changeRedirect,
      stopReLoading
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Hoc);
