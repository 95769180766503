import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
import styled from 'styled-components';

import HeaderComponent from '../components/web/commons/header/header';
import FooterComponent from '../components/web/commons/footer/footer';
import Home from '../components/web/home/home';
import AboutUs from '../components/web/home/aboutUs';
import Terms from '../components/web/home/terms';
import Projects from '../components/web/projects/projects/projects';
import Project from '../components/web/projects/project';
import PaymentSuccessComponent from '../components/web/projects/paymentSuccess';
import Profile from '../components/web/registration/profile/profile';
import Moderate from '../components/web/registration/moderate';
import Microsite from '../components/web/microsite/microsite';
import InvestDrawer from '../components/commons/investDrawer/investDrawer';

const Container = styled.div`
  & {
    font-family: Roboto;
    font-size: 16px;
  }

  & main {
    background-color: #fff;
  }

  // & main:not(.home),
  // & main:not(.microsite) {
  //   padding-left: 80px !important;
  //   padding-right: 80px !important;
  // }

  & main.success {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & main.success + footer {
    display: none;
  }

  & header {
    height: auto;
    padding: 10px 154px !important;
  }

  & footer > div {
    padding-left: 154px !important;
    padding-right: 154px !important;
  }

  @media (max-width: 992px) {
    & main:not(.home) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    & header {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    & footer > div {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }
`;

const homeRoutes = ({ styles }) => (
  <Container>
    <Layout>
      <HeaderComponent />
      <Route
        exact
        path="/"
        component={props => (
          <InvestDrawer {...props}>
            <Home styles={styles} {...props} />
          </InvestDrawer>
        )}
      />

      <Route
        exact
        path="/aboutus"
        component={props => (
          <InvestDrawer {...props}>
            <AboutUs styles={styles} {...props} />
          </InvestDrawer>
        )}
      />

      <Route
        exact
        path="/terminos-y-condiciones"
        component={props => (
          <InvestDrawer {...props}>
            <Terms styles={styles} {...props} />
          </InvestDrawer>
        )}
      />

      <Route
        exact
        path="/projects"
        component={props => (
          <InvestDrawer {...props}>
            <Projects styles={styles} {...props} />
          </InvestDrawer>
        )}
      />
      <Route
        exact
        path="/projects/:id"
        component={props => (
          <InvestDrawer {...props}>
            <Project styles={styles} {...props} />
          </InvestDrawer>
        )}
      />
      <Route
        exact
        path="/projects/:id/:investment"
        component={props => (
          <InvestDrawer {...props}>
            <Project styles={styles} {...props} />
          </InvestDrawer>
        )}
      />
      <Route
        exact
        path="/projects/payment/success"
        component={props => (
          <PaymentSuccessComponent styles={styles} {...props} />
        )}
      />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/moderate" component={Moderate} />
      <Route
        exact
        path="/microsite/:site"
        component={props => (
          <InvestDrawer {...props}>
            <Microsite styles={styles} {...props} />
          </InvestDrawer>
        )}
      />
      <FooterComponent />
    </Layout>
  </Container>
);

export default homeRoutes;
