import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promise from 'redux-promise-middleware';

import reducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'culture'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [thunk, promise];

if (process.env.NODE_ENV !== 'production') middleware.push(createLogger());

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
);

export const persistor = persistStore(store);
