import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const styles = {
  appFooter: { textAlign: 'center' },
};

const FooterComponent = ({ name }) => (
  <Footer style={styles.appFooter}>
    {name}
  </Footer>
);

export default FooterComponent;
