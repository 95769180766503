import { CHANGE_FIELD, SEND_FORM } from '../actions/contactForm';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  fields: {
    name: { value: '' },
    email: { value: '' },
    phone: { value: '' },
    message: { value: '' },
  },
  success: false
};

const contactForm = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${CHANGE_FIELD}`:
    return {
      ...state,
      fields: {
        ...state.fields,
        ...action.payload.field
      }
    };

  case `${SEND_FORM}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true
    };
  case `${SEND_FORM}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  case `${SEND_FORM}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      success: true,
      fields: {
        name: { value: '' },
        email: { value: '' },
        phone: { value: '' },
        message: { value: '' },
      },
    };
  default:
    return state;
  }
};

export default contactForm;
