import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Layout, Row, Col, Spin
} from 'antd';
import { getMicrositeByName } from '../../../actions/microsite';
import { getDownloadUrl } from '../../../service/firebaseStorageService';
// import MicrositeProjectCard from './micrositeProjectCard';
// import MicrositeFooter from './micrositeFooter';
// import MicrositeHeader from './micrositeHeader/micrositeHeader';
import ProjectCard from '../home/projectCard';

const { Content } = Layout;

const Container = styled.div`
  & {
    background: #fff;
    min-height: 500px;
  }

  & .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  @media (max-width: 768px) {
    & {
      margin: 0;
    }
  }
`;

const MobileRow = styled.div`
  & {
    width: 100%;
  }

  @media (max-width: 992px) {
    & .ant-col {
      margin-top: ${props => props.steps ? 'calc(1.5rem + 80px)' : '1.5rem'};
    }
  }
`;

const TopBanner = styled.div`
  & {
    position: relative;
  }

  & .bg {
    width: 100%;
    height: 25vw;
    position: relative;
  }

  & .banner-content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;
  }

  & .banner-trans {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.background ? props.background : 'rgba(60, 1, 153, 0.43)'};
  }

  & .banner-container {
    position: relative;
  }

  & img {

    width: 100%;

  }

  & h1 {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }

  & hr {
    width: 227px;
    border-color: rgba(255, 255, 255, 0.5);
  }

  & p {
    max-width: 566px;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 536px;
    margin-top: 5rem;
  }

  & .buttons > div:first-child {
    margin-right: 20px;
  }

  @media (max-width: 535px) {

    & .bg {
      height: 30vh;
    }
    & .buttons {
      justify-content: center;
    }

    & .buttons > div:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

const MarginedContainer = styled.div`
  & {
    margin-top: 0rem;
    padding: 0 80px;
  }

  & .inner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & h2 {
    font-family: Roboto;
    font-size: 22.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
    margin-left: 2rem;
    margin-top: 4rem;
    width: 100%;
  }


  & .separator {
    width: 90px;
    border-color: #3c0199;
    border-width: 0.75px;
    margin-bottom: 2rem;
  }

  & .description {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    max-width: 603px;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 536px;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  & .buttons > div:first-child {
    margin-right: 20px;
  }

  & #contactHeader {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  & .contact-title {
    display: flex;
    width: 100%;
    margin-bottom: 1.5em;
    align-items: center;
    justify-content: center;
  }

  & .contact-title h3 {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    margin: 0;
  }

  & .contact-title i {
    font-size: 24px;
    margin-right: 8px;
  }

  & .phones-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    font-family: 'Roboto';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    max-width: 315px;
  }

  & .phones-container > div:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }

  & .phones-container h3 {
    font-family: 'Roboto';
    font-size: 29px;
    font-weight: bold;
    color: #46eac4;
  }

  & .phones-container > div:not(:last-child) p {
    margin-bottom: 2rem;
  }

  & .phones-container > div:last-child h3 {
    margin-top: 2rem;
  }

  @media (max-width: 565px) {
    & .buttons {
      justify-content: center;
    }

    & .buttons > div:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    & {
      padding: 0 15px;
    }
  }
`;

const SiteInfo = styled.div`
  & {
    max-width: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 0rem;
    flex-direction: column;
  }

  & h3 {
    font-family: Roboto;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.79;
    letter-spacing: normal;
    color: #605e5e;
  }

  & p {
    font-family: 'Helvetica Neue';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
`;

const CenteredContainer = styled.div`
  & {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
`;

const hexToRGB = (h, alpha) => {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

  // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return "rgba("+ +r + "," + +g + "," + +b + ", " + alpha + ")";
};

const Microsite = ({ handleChangeDrawer }) => {
  const { site } = useParams();
  const dispatch = useDispatch();
  const microsite = useSelector(state => state.microsite);

  const [logoUrl, setLogoUrl] = useState(null);
  const [ projects, setProjects ] = useState(null);

  useEffect(() => {
    dispatch(getMicrositeByName(site))
      .then((respMicrosite) => {
        setProjects(respMicrosite.value.microsite.projectList);
        getDownloadUrl(respMicrosite.value.microsite.logoImage.key).then(url => setLogoUrl(url));
      });
  }, []);

  const { name, description, color } = microsite.micrositeByID;

  if ((!name && !microsite.didInvalidate) || microsite.isFetching) return (<Container><CenteredContainer><Spin size="large" /></CenteredContainer></Container>);
  if (microsite.didInvalidate) return (<Container><CenteredContainer><h1>No existe el microsite solicitado</h1></CenteredContainer></Container>);

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Content>
        <Container>
          <TopBanner background={hexToRGB(color, '0.43')}>
            <div className="bg" />
            <div className="banner-content">
              <img src={logoUrl} alt="logo" />
            </div>
            <div className="banner-trans"></div>
          </TopBanner>
          <MarginedContainer>
            <Row>
              <Col span={24}>
              <div className="inner-content">
                <h2>Proyectos</h2>
              </div>
              </Col>
            </Row>
            {projects && (
              <div style={{ marginTop: '0rem', marginBottom: '8rem' }}>
                <MobileRow>
                  <Row gutter={24} type="flex" justify="center">
                    {projects.map(project => (
                      <ProjectCard
                        key={project._id}
                        project={project}
                        handleChangeDrawer={handleChangeDrawer}
                      />
                    ))}
                  </Row>
                </MobileRow>
              </div>
            )}
          </MarginedContainer>
        </Container>
      </Content>
    </div>
  );
};

export default Microsite;
