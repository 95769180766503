import { CREATE_INVEST } from '../actions/invest';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingProject: false,
  didInvalidate: false,
  data: false
};

const invest = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case `${CREATE_INVEST}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      data: false
    };
  case `${CREATE_INVEST}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      data: false
    };
  case `${CREATE_INVEST}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      data: action.payload.data
    };

  default:
    return state;
  }
};

export default invest;
