import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PageTitle from '../components/commons/pageTitle';

const privateRouteComponent = ({
  component: Component,
  token,
  pathname,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      token
        ? (<PageTitle {...props} component={Component} title="Wibai - BackOffice" />)
        : (<Redirect to={{ pathname, state: { from: props.location } }} />)
    )}
  />
);

export default privateRouteComponent;
