import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import styled from 'styled-components';
import moment from 'moment';
import ColorPicker from 'rc-color-picker';
import {
  Input, Select, Upload, Icon, message, Button, DatePicker, Tooltip, AutoComplete, Radio, InputNumber, Card, Spin
} from 'antd';
import 'rc-color-picker/assets/index.css';

import RichTextEditor from './textEditor';

import Avatar from './upload';
import { uploadImage } from '../../../service/firebaseStorageService';

const { TextArea } = Input;
const { Option } = Select;
const RadioGroup = Radio.Group;

export const FormItemInput = ({
  FormComponent,
  form,
  label,
  field,
  rules,
  type,
  disabled,
  placeholder,
  className,
  initialValue,
  maxLength,
  onlyPlaceholder,
  addonBefore
}) => (
  <FormComponent.Item label={!onlyPlaceholder && label} className={className}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <Input
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        addonBefore={addonBefore}
        autocomplete="off"
      />
    )}
  </FormComponent.Item>
);

export const FormItemInputTooltipColor = ({
  FormComponent,
  form,
  label,
  field,
  rules,
  className,
  initialValue,
  changeHandler,
}) => {
  const colorForm = form.getFieldValue('color');

  let color;

  if (colorForm !== initialValue) {
    if (/^#[a-f0-9]{6}$/i.test(colorForm)) color = colorForm;
    else color = '#000000';
  } else color = initialValue || '#ff0000';

  return (
    <FormComponent.Item label={label} className={className}>
      {form.getFieldDecorator(field, { rules, initialValue })(
        <Input
          suffix={(
            <div style={{ marginTop: 5 }}>
              <ColorPicker
                color={color}
                onClose={changeHandler}
                className="some-class"
              >
                <span className="rc-color-picker-trigger" />
              </ColorPicker>
            </div>
          )}
        />
      )}
    </FormComponent.Item>
  );
};

export const FormItemTextArea = ({
  FormComponent, form, label, field, rules, disabled, placeholder, className, initialValue, minRows, maxRows, maxLength, onlyPlaceholder
}) => (
  <FormComponent.Item label={!onlyPlaceholder && label} className={className}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <TextArea
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        autosize={{ minRows, maxRows }}
      />
    )}
  </FormComponent.Item>
);

export const FormItemReadOnly = ({
  FormComponent, form, label, field, type, className, initialValue, rules
}) => (
  <FormComponent.Item label={label} className={className}>
    {form.getFieldDecorator(field, { rules, initialValue })(<Input type={type} readOnly />)}
  </FormComponent.Item>
);

export const FormItemAutocomplete = ({
  FormComponent, form, label, field, rules, className, dataSource, initialValue
}) => (
  <FormComponent.Item label={label} className={className}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <AutoComplete dataSource={dataSource} filterOption onChange={() => form.setFieldsValue({ accountBankForRelease: '' })} />
    )}
  </FormComponent.Item>
);

export const FormItemInputTooltip = ({
  FormComponent, form, label, field, rules, type, disabled, className, title
}) => (
  <FormComponent.Item label={label} className={className}>
    <Tooltip
      trigger={['focus']}
      className="numeric-input"
      placement="topLeft"
      title={title}
    >
      {form.getFieldDecorator(field, { rules })(
        <Input type={type} disabled={disabled} />
      )}
    </Tooltip>
  </FormComponent.Item>
);

export const FormItemInputPassword = ({
  FormComponent, form, label, field, rules, disabled, className, tooltipTitle
}) => (
  <FormComponent.Item label={label} className={className}>
    {form.getFieldDecorator(field, { rules })(
      <Tooltip
        trigger={['focus']}
        placement="topLeft"
        title={tooltipTitle}
      >
        <Input.Password disabled={disabled} />
      </Tooltip>
    )}
  </FormComponent.Item>
);

export const FormItemInputNumber = ({
  FormComponent, form, label, field, rules, placeholder, initialValue, addonAfter, disabled, maxLength, onFieldsChange
}) => (
  <FormComponent.Item onFieldsChange={onFieldsChange} label={label}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <Input
        placeholder={placeholder}
        addonAfter={addonAfter}
        disabled={disabled}
        maxLength={maxLength}
      />
    )}
  </FormComponent.Item>
);

export const FormItemInputDecimal = ({
  FormComponent, form, label, field, rules, placeholder, initialValue, max, min, disabled, maxLength
}) => (
  <FormComponent.Item label={label}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <InputNumber
        placeholder={placeholder}
        decimalSeparator=","
        maxLength={maxLength}
        step={0.1}
        precision={2}
        max={max}
        min={min}
        style={{ width: '100%' }}
        disabled={disabled}
      />
    )}
  </FormComponent.Item>
);

export const FormItemInputDecimalAddonAfter = ({
  FormComponent, form, label, field, rules, placeholder, initialValue, max, min, disabled, maxLength, addonAfter
}) => (
  <FormComponent.Item label={label}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <InputNumber
        placeholder={placeholder}
        decimalSeparator=","
        maxLength={maxLength}
        step={0.1}
        precision={2}
        max={max}
        min={min}
        disabled={disabled}
        style={{ width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        formatter={value => `${value}${addonAfter}`}
        parser={value => value.replace(addonAfter, '')}

      />
    )}
  </FormComponent.Item>
);

export const FormItemInputPercentage = ({
  FormComponent, form, label, field, rules, placeholder, initialValue
}) => (
  <FormComponent.Item label={label}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <InputNumber
        placeholder={placeholder}
        min={0}
        max={100}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        step={0.1}
        precision={2}
        style={{ width: '100%' }}
      />
    )}
  </FormComponent.Item>
);

export const FormItemInputMoney = ({
  FormComponent, form, label, field, rules, placeholder, initialValue
}) => {
  const prefixField = `${field}Prefix`;
  const prefixSelector = form.getFieldDecorator(prefixField, {
    initialValue: 'u$s',
  })(
    <Select style={{ width: 70 }}>
      <Option value="u$s">u$s</Option>
      <Option value="$">$</Option>
    </Select>,
  );
  return (
    <FormComponent.Item label={label}>
      {form.getFieldDecorator(field, { rules, initialValue })(<Input addonBefore={prefixSelector} placeholder={placeholder} />)}
    </FormComponent.Item>
  );
};

export const FormItemSelect = ({
  FormComponent,
  form,
  label,
  field,
  rules,
  placeholder,
  options,
  initialValue,
  onChangeState,
  disabled
}) => (
  <FormComponent.Item label={label} style={{ width: '100%' }}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <Select
        style={{ width: '100%' }}
        placeholder={placeholder}
        disabled={disabled}
        onChange={value => onChangeState && onChangeState(value)}
      >
        {options.map(item => (<Option key={item.key} value={item.value}>{item.name}</Option>))}
      </Select>
    )}
  </FormComponent.Item>
);

export const FormItemSelectMultiple = ({
  FormComponent, form, label, field, rules, placeholder, options
}) => (
  <FormComponent.Item label={label}>
    {form.getFieldDecorator(field, { rules })(
      <Select mode="multiple" placeholder={placeholder}>
        {options.map(item => (<Option key={item.key} value={item.value}>{item.name}</Option>))}
      </Select>
    )}
  </FormComponent.Item>
);

export const FormItemDatePicker = ({
  FormComponent, form, label, field, rules, placeholder, disabledDate, initialValue
}) => (
  <FormComponent.Item label={label}>
    {form.getFieldDecorator(field, { rules, initialValue: initialValue ? moment(initialValue, 'DD/MM/YYYY') : null })(
      <DatePicker
        placeholder={placeholder}
        disabledDate={disabledDate}
        format="DD/MM/YYYY"
      />
    )}
  </FormComponent.Item>
);

export const FormItemRichTextEditor = ({
  FormComponent, form, label, field, rules
}) => (
  <FormComponent.Item label={label}>
    {form.getFieldDecorator(field, { rules })(<RichTextEditor
      field={field}
      initialValue={form.getFieldValue(field)}
      onChange={((value) => {
        const formValue = {};
        formValue[field] = value;
        form.setFieldsValue(formValue);
      })}
    />)}
  </FormComponent.Item>
);

export const UploadIdentity = ({
  FormComponent, form, label, field, rules
}) => (
  <FormComponent.Item label={label}>
    {form.getFieldDecorator(field, { rules })(
      <Avatar field={field} rules={rules} />
    )}
  </FormComponent.Item>
);

export const FormItemRadio = ({
  FormComponent, form, label, field, rules, options, initialValue, onChange
}) => (
  <FormComponent.Item label={label}>
    {form.getFieldDecorator(field, { rules, initialValue })(
      <RadioGroup onChange={(onChange ? (e)=>onChange(e) : '' )}>
        {options.map(item => (<Radio key={item.key} value={item.value}>{item.name}</Radio>))}
      </RadioGroup>
    )}
  </FormComponent.Item>
);

const ListGoogle = styled.div`
  cursor: pointer;
  margin: 0;
  padding: 5px 16px;
  background-color: ${props => props.active ? 'rgb(230, 247, 255)' : 'white'};
  &:hover {
    color: #1890ff
  }
  &:active {
    background-color: rgb(230, 247, 255);
  }
`;

export class UploadImage extends React.Component {
  state = { disabled: false }

  componentDidMount() {
    const {
      maxImagesLength, defaultFileList, unique, setDidMount, form, field, value
    } = this.props;

    if (defaultFileList && defaultFileList.length && unique) this.onChange();

    if (setDidMount && value) {
      if (maxImagesLength) {
        form.setFieldsValue({ [field]: { fileList: value.map(img => ({ response: img.key, name: img.name })) } });
      } else {
        form.setFieldsValue({ [field]: { file: { response: value.key, name: value.name } } });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { maxImages, form, field } = this.props;

    if (prevProps.maxImages && maxImages) {
      // eslint-disable-next-line react/no-did-update-set-state
      if ((prevProps.maxImages.fileList && maxImages.fileList) && (prevProps.maxImages.fileList.length > maxImages.fileList.length)) this.setState({ disabled: false });
    }
    if (form.getFieldValue(field)) {
      const { file, fileList } = form.getFieldValue(field);
      if (fileList && !fileList.length && file) form.setFieldsValue({ [field]: undefined });
    }
  }

  beforeUpload = (file) => {
    const { intl, messages } = this.props;
    const isImage = (file.type === 'image/jpeg') || (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'video/mp4');
    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isImage) {
      message.error(intl.formatMessage(messages.onlyImageComponent));
    }

    if (!isLt10M) {
      message.error(intl.formatMessage(messages.cannotExceedImageComponent));
    }

    return isImage && isLt10M;
  };

  handleRequest = ({
    onSuccess, onError, file, onProgress
  }) => {
    uploadImage(file).then((snapshot) => {
      onProgress({ percent: 100 });
      onSuccess(snapshot.metadata.fullPath);
      if (this.props.onUploaded) this.props.onUploaded(snapshot.metadata.fullPath, null, this.props.field, snapshot.metadata.fullPath);
    }).catch((error) => {
      onError(error);

      if (this.props.onUploaded) this.props.onUploaded(null, error, this.props.field);
    });
  };

  onChange = () => {
    const { unique, maxImages, maxImagesLength } = this.props;

    if (unique) return this.setState(prevState => ({ disabled: !prevState.disabled }));
    if (maxImages && (maxImages.fileList.length === maxImagesLength)) return this.setState({ disabled: true });
  }

  render() {
    const {
      FormComponent, form, label, field, rules, name, defaultFileList, tooltipTitle, video
    } = this.props;
    const { disabled } = this.state;

    const formatAccept = video ? 'image/*, video/*' : 'image/*';

    return (
      <FormComponent.Item label={label}>
        {form.getFieldDecorator(field, { rules })(
          <Upload
            name="logo"
            listType="picture-card"
            accept={formatAccept}
            beforeUpload={this.beforeUpload}
            customRequest={this.handleRequest}
            onChange={this.onChange}
            multiple={false}
            defaultFileList={defaultFileList}
          >
            {!disabled && (
              <Tooltip title={tooltipTitle}>
                <Button disabled={disabled}>
                  <Icon type="upload" /> {name}
                </Button>
              </Tooltip>
            )}
          </Upload>
        )}
      </FormComponent.Item>
    );
  }
}

export class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    if (props.address) this.state = { address: props.address.address };
    else this.state = { address: '' };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    const {
      handleChangeLocation,
      form,
      field,
      isCitySelector,
      intl,
      messages
    } = this.props;

    geocodeByAddress(address)
      .then((results) => {
        let requiredCP = true;
        if (!isCitySelector) {
          let error = true;
          results[0].address_components.map((item) => {
            if (item.types[0] === 'postal_code') requiredCP = false;
            if (item.types[0] === 'street_number') error = false;
            return error;
          });

          if (error) return form.setFields({ [field]: { errors: [new Error(intl.formatMessage(messages.selectValidAddress))] } });
        }

        this.setState({ address: results[0].formatted_address });
        getLatLng(results[0]).then(({ lat, lng }) => handleChangeLocation({ ...results[0], lat, lng }, requiredCP));

      })
      .catch(() => form.setFields({ [field]: { errors: [new Error(intl.formatMessage(messages.errorSelectAddress))] } }));
  };

  render() {
    const {
      form, label, rules, initialValue, field, FormComponent, placeholder, isCitySelector, intl, messages
    } = this.props;
    const { address } = this.state;

    const searchOptions = { componentRestrictions: { country: 'ar' } };

    if (isCitySelector) searchOptions.types = ['(cities)'];
    else searchOptions.types = ['address'];

    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
        onError={() => form.setFields({ [field]: { errors: [new Error(intl.formatMessage(messages.selectValidAddress))] } })}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading
        }) => (
          <FormComponent.Item label={label}>
            {form.getFieldDecorator(field, { rules, initialValue: initialValue || address })(
              <div>
                <Input {...getInputProps({ placeholder })} />
                <div className="autocomplete-dropdown-container">
                  {(loading || suggestions.length !== 0) && (
                    <Card>
                      {loading
                        ? <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                        : suggestions.map((suggestion) => {
                          const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                          return (
                            <ListGoogle key={suggestion.description} {...getSuggestionItemProps(suggestion, { className })} active={suggestion.active}>
                              {suggestion.description}
                            </ListGoogle>
                          );
                        })
                      }
                    </Card>
                  )}
                </div>
              </div>
            )}
          </FormComponent.Item>
        )}
      </PlacesAutocomplete>
    );
  }
}
