import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Layout, Row, Col, Form, Button, Alert, Input, Tooltip
} from 'antd';
import styled from 'styled-components';

import './registration.css';

import config from '../../../config';

import { FormItemInput } from '../../commons/formComponents/formComponents';
import { validatePassword, specialCharacter } from '../../commons/validations';
import { createInvestor, createUserGoogle } from '../../../actions/registration';

import messages from './culture';

const { Content } = Layout;

const RegisterContainer = styled.div`
  & {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 5rem 10%;
  }

  & h1 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0;
  }

  & h4.form-title {
    opacity: 0.55;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
  }

  & input {
    height: 45px;
  }

  & .ant-form-item {
    margin-bottom: 1rem;
  }

  .primary-text {
    color: #707070 !important;
  }
`;

const RegisterButton = styled.div`
  & button {
    border-radius: 5px;
    height: 45px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
    border-color: transparent;
    margin-top: ${props => props.secondary ? '1.5rem' : '2rem'};
  }

  & button:hover {
    border-color: transparent;
    color: #fff;
  }

  & button:active {
    border-color: transparent;
    color: #fff;
  }

  & button:focus {
    border-color: transparent;
    color: #fff;
  }
`;

class RegistrationForm extends Component {
  confirmDirty = false;

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: 'auto'
    });
  }

  handleSubmit = (e) => {
    const { form, create, history } = this.props;

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const model = { ...values };
        create(model)
          .then(() => history.push('/account/register/confirmrequired'));
      }
    });
  }

  responseGoogle = (response) => {
    const { createGoogle, history, intl } = this.props;

    if (!response.error) {
      const googleToken = response.getAuthResponse().id_token;
      createGoogle({ token: googleToken })
        .then(() => {
          history.push({
            pathname: '/account/login',
            state: { messageProps: intl.formatMessage(messages.accountSucces) },
          });
        });
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form, intl } = this.props;

    if (value && value !== form.getFieldValue('password')) callback(intl.formatMessage(messages.errorPassword));
    else callback();
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.confirmDirty) form.validateFields(['passwordConfirmation'], { force: true });
    callback();
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;

    this.confirmDirty = this.confirmDirty || !!value;
  };

  render() {
    const { form, investor, intl } = this.props;

    return (
      <Content style={{ background: '#eee', paddingBottom: 20, paddingTop: 100 }}>
        <Row style={{ marginBottom: 20 }} type="flex" justify="center">
          <Col md={{ span: 10 }} xs={{ span: 20 }}>
            {investor.didInvalidate ? <Alert message={investor.messageResponse} type="error" className="messageResponse" /> : null }
          </Col>
        </Row>

        <Row type="flex" justify="center">
          <Col md={{ span: 10 }} xs={{ span: 20 }}>
            <RegisterContainer>
              <h1 className="primary-text">{intl.formatMessage(messages.registerTitle)}</h1>
              <h4 className="form-title">
                DATOS PERSONALES
              </h4>
              <Form onSubmit={this.handleSubmit}>
                <FormItemInput
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.nameInputRegister)}
                  field="name"
                  rules={[
                    { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                    { max: 70, message: `${intl.formatMessage(messages.maxCharacters)} 70` },
                    { validator: specialCharacter(form, intl.formatMessage(messages.specialCharacter)) }
                  ]}
                />
                <FormItemInput
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.lastNameInputRegister)}
                  field="lastName"
                  rules={[
                    { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                    { max: 70, message: `${intl.formatMessage(messages.maxCharacters)} 70` },
                    { validator: specialCharacter(form, intl.formatMessage(messages.specialCharacter)) }
                  ]}
                />
                <FormItemInput
                  FormComponent={Form}
                  form={form}
                  placeholder={intl.formatMessage(messages.emailInputError)}
                  field="username"
                  rules={[
                    { type: 'email', message: intl.formatMessage(messages.emailInputError) },
                    { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                  ]}
                />
                <Form.Item>
                  <Tooltip
                    trigger={['focus']}
                    placement="topLeft"
                    title={intl.formatMessage(messages.inputTooltipPass)}
                  >
                    {form.getFieldDecorator('password', {
                      rules: [
                        { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                        { max: 20, message: `${intl.formatMessage(messages.maxCharacters)} 20` },
                        { min: 8, message: `${intl.formatMessage(messages.minCharacters)} 8` },
                        { validator: validatePassword(form, intl.formatMessage(messages.validatePassword)) },
                        { validator: this.validateToNextPassword }
                      ],
                    })(<Input.Password placeholder={intl.formatMessage(messages.passwordInputRegister)} />)}
                  </Tooltip>
                </Form.Item>
                <Form.Item>
                  {form.getFieldDecorator('passwordConfirmation', {
                    rules: [
                      { required: true, message: intl.formatMessage(messages.obligatoryFieldInput) },
                      { validator: this.compareToFirstPassword },
                    ],
                  })(<Input.Password onBlur={this.handleConfirmBlur} placeholder={intl.formatMessage(messages.passwordConfirmationInputRegister)} />)}
                </Form.Item>
                <Form.Item style={{ marginTop: 50 }}>
                  <RegisterButton>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={investor.isFetching}
                    >
                      {intl.formatMessage(messages.textSend)}
                    </Button>
                  </RegisterButton>
                  <GoogleLogin
                    clientId={config.GOOGLE_ID_CLIENT}
                    buttonText="Login"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    render={renderProps => (
                      <RegisterButton secondary>
                        <Button
                          icon="google"
                          onClick={renderProps.onClick}
                          type="default"
                          block
                          className="secondary"
                        >{intl.formatMessage(messages.registerGoogle)}
                        </Button>
                      </RegisterButton>
                    )}
                  />
                </Form.Item>
              </Form>

            </RegisterContainer>
          </Col>
        </Row>
      </Content>
    );
  }
}

const StepOne = Form.create({ name: 'register' })(RegistrationForm);

const mapStateToProps = state => ({
  investor: state.registration,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  create: createInvestor,
  createGoogle: createUserGoogle
}, dispatch);

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(StepOne);

export default injectIntl(connectedForm, {
  withRef: false,
});
