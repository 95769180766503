import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Layout,
  Form,
  Spin,
  Breadcrumb,
  Button
} from 'antd';
import styled from 'styled-components';

import { getCountryById, editCountry } from '../../../actions/country';
import messages from './culture';

import {
  FormItemInput,
} from '../../commons/formComponents/formComponents';

const ContainerLoading = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const { Content } = Layout;

const styles = {
  content: { padding: 24, background: '#fff', minHeight: 360 },
};

const Container = styled.div`
  background-color: #FFF;
  padding: 24px;
  margin-bottom: 40px;
`;

class EditTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    const {
      getCountryId, match
    } = this.props;
    getCountryId(match.params.id);
    this.setState({ loading: false });
  }

  handleSubmit = (e) => {
    const {
      form,
      edit,
      match,
      history
    } = this.props;

    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        edit(values, match.params.id)
          .then(() => history.push('/admin/countries'));
      }
    });
  }

  render() {
    const { intl, countryInfo, form } = this.props;

    const { loading } = this.state;

    if (loading || countryInfo.isFetching) return (<ContainerLoading><Spin size="large" /></ContainerLoading>);

    return (
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbHome)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(messages.countries)}</Breadcrumb.Item>
          <Breadcrumb.Item>{intl.formatMessage(messages.breadCrumbEdit)}</Breadcrumb.Item>
        </Breadcrumb>
        <Container>
          <Form style={styles.content} onSubmit={this.handleSubmit}>
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.currencyTable)}
              field="currency"
              disabled
              initialValue={countryInfo.countryByID.currency}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.descriptionTable)}
              field="description"
              disabled
              initialValue={countryInfo.countryByID.description}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.codeTable)}
              field="code"
              disabled
              initialValue={countryInfo.countryByID.code}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
              ]}
            />
            <FormItemInput
              FormComponent={Form}
              form={form}
              label={intl.formatMessage(messages.MPAccessTokenTable)}
              field="MPAccessToken"
              initialValue={countryInfo.countryByID.MPAccessToken}
              rules={[
                { required: true, message: intl.formatMessage(messages.requiredField) },
                { max: 80, message: `${intl.formatMessage(messages.maxCharacters)} 80` },
              ]}
            />
            <ButtonContainer>
              <Button
                style={{ marginRight: 15 }}
                size="large"
              ><Link to="/admin/countries/">{intl.formatMessage(messages.cancelButton)}</Link>
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={countryInfo.isFetching}
              >{intl.formatMessage(messages.createProjectButton)}
              </Button>
            </ButtonContainer>
          </Form>
        </Container>
      </Content>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'country' })(EditTransaction);

const mapStateToProps = state => ({ countryInfo: state.countries, user: state.user });

const mapDispatchToProps = dispatch => bindActionCreators({
  getCountryId: getCountryById,
  edit: editCountry
}, dispatch);

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);

export default injectIntl(connectedForm, {
  withRef: false,
});
