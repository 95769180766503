import { GET_COUNTRY_BY_ID, GET_COUNTRIES, EDIT_COUNTRY, CREATE_COUNTRY } from '../actions/country';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingCreate: false,
  didInvalidate: false,
  list: [],
  countryByID: false,
};

const countries = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case `${GET_COUNTRIES}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${GET_COUNTRIES}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${GET_COUNTRIES}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        list: action.payload.data
      };

    case `${GET_COUNTRY_BY_ID}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        countryByID: false
      };
    case `${GET_COUNTRY_BY_ID}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        countryByID: false
      };
    case `${GET_COUNTRY_BY_ID}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        countryByID: action.payload.country
      };

    case `${EDIT_COUNTRY}_REJECTED`:
      return {
        ...state,
        isFetchingCreate: false,
        didInvalidate: true
      };
    case `${EDIT_COUNTRY}_PENDING`:
      return {
        ...state,
        isFetchingCreate: true,
        didInvalidate: false
      };
    case `${EDIT_COUNTRY}_FULFILLED`:
      return {
        ...state,
        isFetchingCreate: false,
        didInvalidate: false,
      };

    case `${CREATE_COUNTRY}_REJECTED`:
      return {
        ...state,
        isFetchingCreate: false,
        didInvalidate: true
      };
    case `${CREATE_COUNTRY}_PENDING`:
      return {
        ...state,
        isFetchingCreate: true,
        didInvalidate: false
      };
    case `${CREATE_COUNTRY}_FULFILLED`:
      return {
        ...state,
        isFetchingCreate: false,
        didInvalidate: false,
      };
    default:
      return state;
  }

};

export default countries;
