import es_ES from 'antd/lib/locale-provider/es_ES';
import appLocaleData from 'react-intl/locale-data/es';
import 'moment/locale/es';

const culture = {
  'general.breadCrumb.home': 'Inicio',
  'general.breadCrumb.users': 'Usuarios',
  'general.breadCrumb.create': 'Crear',
  'admin.user.firstName': 'Nombre',
  'admin.user.lastName': 'Apellido',
  'general.country': 'País',
  'general.countryPlaceHolder': 'Seleccione su país',
  'general.state': 'Provincia',
  'general.statePlaceHolder': 'Seleccione su provincia',
  'general.city': 'Ciudad',
  'general.roles': 'Roles',
  'general.rolesPlaceholder': 'Seleccione los roles',
  'general.user.password': 'Contraseña',
  'general.address': 'Dirección',
  'general.roles.administrator': 'Administrador',
  'general.roles.realStateDeveloper': 'Desarrollador',
  'admin.user.create.image': 'Imagen',
  'general.user.birthdate': 'Fecha de nacimiento',
  'admin.user.create.birthdatePlaceholder': 'Seleccione su fecha de nacimiento',
  'general.email': 'Correo electrónico',
  'admin.user.mainPhone': 'Teléfono principal',
  'admin.user.secondaryPhone': 'Teléfono secundario',
  'admin.user.create.createUserButton': 'Guardar',
  'general.validations.requiredField': 'Campo requerido',
  'general.validations.maxCharacters': 'El número máximo de caracteres es de ',
  'general.validations.minCharacters': 'El número mínimo de caracteres es de ',
  'general.selectDate': 'Por favor, seleccione una fecha',
  'admin.user.create.ducplicatedCBU':
    'Por favor verifique que los CBU ingresados no sean iguales',
  'admin.user.create.duplicatedCBUAlias':
    'Por favor verifique que los Alias ingresados no sean iguales',
  'admin.user.create.emailExists': 'Email ya registrado',
  'admin.user.create.emailAlreadyExistent': 'El mail ya está registrado',
  'general.validations.invalidEmail': 'El formato del mail es inválido',
  'general.field': 'Campo',
  'general.characters': 'Caracteres',
  'general.uploadImage': 'Haga click para cargar un archivo',

  'project.create.breadcrumb.first': 'Inicio',
  'project.create.breadcrumb.second': 'Proyectos',
  'project.create.breadcrumb.third': 'Crear',
  'project.create.breadcrumb.thirdEdit': 'Editar',

  'project.create.error.400': 'Vefirique los datos e intente nuevamente',
  'project.create.error.name_exists':
    'El nombre del proyecto ya existe, intente con otro.',

  'project.create.title.basicData': 'Datos básicos',
  'project.create.title.typification': 'Tipificación',
  'project.create.title.characteristicsTransaction':
    'Características de la transacción',
  'project.create.title.projectDetail': 'Detalle del proyecto',
  'project.create.title.campaign': 'Campaña',
  'project.create.title.advance': 'Avances',

  'project.create.input.totalInvestmentAmount': 'Monto de Inversión total',
  'project.create.input.propiInvestInvestmentAmount': 'Monto de Inversión Wibai',
  'project.create.input.metersBuilt': 'Metros a construir',
  'project.create.input.terrainValue': 'Valor del terreno',
  'project.create.input.costAcquisition': 'Costo de adquisición',
  'project.create.input.costConstruction': 'Costo de construcción',
  'project.create.input.closingDate': 'Fecha de cierre',
  'project.create.input.createProjectButton': 'Guardar',
  'project.create.input.cancelButton': 'Cancelar',
  'project.create.input.typeAsset': 'Tipo de Activo',
  'project.create.input.typeFormat': 'Tipo de Formato',
  'project.create.input.typeOperation': 'Tipo de Operación',
  'project.create.input.nameProyect': 'Nombre del proyecto',
  'project.create.input.realEstateDeveloper': 'Creador del proyecto',
  'project.create.input.category': 'Categoría',
  'project.create.input.featuredCategory': 'Categoría Destacada',
  
  'project.create.input.briefDescription': 'Descripción breve',
  'project.create.input.regulatoryEntity': '¿Marcar este proyecto destacado?',
  'project.create.input.projectLocation': 'Ubicación del Proyecto',
  'project.create.input.riskLevel': 'Nivel de riesgo',
  'project.create.rewards.title': 'Titulo',
  'project.create.rewards.MPMercadoPago': 'Mercado Pago',
  'project.create.rewards.create': 'Agregar Recompensa',
  'project.create.rewards.visibility': 'Visible',

  'project.create.input.type': 'Tipo',
  'project.create.input.text': 'Texto',
  'project.create.input.image': 'Imágen',
  'project.create.input.order': 'Orden',

  'project.create.input.title': 'Título',
  'project.create.input.objective': 'Monto a recaudar',
  'project.create.input.currency': 'Moneda',
  'project.create.input.days': 'Días restantes',
  'project.create.input.amountRaised': 'Monto recaudado',
  'project.create.input.contributors': 'Aportantes',
  'project.create.input.mainImage': 'Imagen principal',
  'project.create.input.minimunAmountOfInvestment': 'Monto mínimo de Inversión',
  'project.create.input.minimunPercentageOfCollection':
    'Porcentaje Mínimo de recaudación',
  'project.create.input.maximunPercentageOfCollection':
    'Porcentaje Máximo de recaudación',
  'project.create.input.annualProjectedProfitability':
    'Rentabilidad proyectada anual',
  'project.create.input.tir': 'TIR objetivo',
  'project.create.input.equity': 'Múltiplo de Equity',
  'project.create.input.periodofInvestment': 'Período de inversión',
  'project.create.input.projectedAnnualProfit': 'Ganancia proyectada anual',
  'project.create.input.capitalRecovery': 'Recupero de capital',
  'project.create.input.accountBankForRelease': 'Cuenta para liberación',
  'project.create.input.accountBank': 'Cuenta del Desarrollador',
  'project.create.input.completeDescription': 'Descripción completa',

  'project.create.input.description': 'Descripción',

  'project.create.option.yes': 'Sí',
  'project.create.option.no': 'No',

  'project.create.option.high': 'Alto',
  'project.create.option.medium': 'Medio',
  'project.create.option.low': 'Bajo',

  'project.create.option.ecology': 'Ecología',
  'project.create.option.social': 'Social',
  'project.create.option.tripleImpact': 'Triple Impacto',
  'project.create.option.collaborativeEconomy': 'Economía Colaborativa',
  'project.create.option.inclusion': 'Inclusión',
  'project.create.option.education': 'Educación',

  'project.create.option.purchaseOption': 'Opción de compra',
  'project.create.option.ticket': 'Boleto',
  'project.create.option.concessionRentalContract':
    'Contrato de concesión/alquiler',
  'project.create.option.commercialSociety': 'Sociedad comercial',
  'project.create.option.escrow': 'Fideicomiso',
  'project.create.option.trustProperty': 'Fideicomiso con propiedad',
  'project.create.option.commonInvestmentFund': 'Fondo común de inversión',
  'project.create.option.propertytitle': 'Título de propiedad',
  'project.create.option.mortgage': 'Hipoteca',

  'project.create.option.buySell': 'Comprar y vender',
  'project.create.option.buyBuildSell': 'Comprar, agregar valor y vender',
  'project.create.option.buyRentSell': 'Comprar, alquilar y vender',
  'project.create.option.buyBuildRentSell':
    'Comprar, agregar valor, alquilar y vender',

  'project.create.option.onlyText': 'Sólo texto',
  'project.create.option.imageAndText': 'Texto e imagen',
  'project.create.option.onlyImage': 'Sólo imagen',

  'project.create.option.dolar': 'Dólares',
  'project.create.option.pesos': 'Pesos',

  'project.create.button.addAdvance': 'Agregar Avance',
  'project.create.button.addDetail': 'Agregar detalle del proyecto',
  'admin.project.create.images': 'Imágenes',

  'project.index.questionPublish':
    'Está seguro que desea publicar el proyecto ?',
  'project.index.questionActivate':
    'Está seguro que desea activar el proyecto ?',
  'project.index.questionInactivate':
    'Está seguro que desea inactivar el proyecto ?',
  'project.index.tab.start': 'Inicio',
  'project.index.tab.project': 'Proyectos',
  'project.index.button.newProject': 'Nuevo Proyecto',
  'micro.index.button.newMicro': 'Nuevo Micrositio',
  'micro.index.questionActivate':
  'Está seguro que desea activar el mirositio?',
'micro.index.questionInactivate':
  'Está seguro que desea inactivar el mirositio?',
  'micro.table.key.active': 'Estado',

  'micro.edit.bank': 'Cuenta Bancaria',
  'micro.edit.bankTitle': 'TITULAR',
  'micro.edit.bankCbu': 'CBU',
  'micro.edit.bankCuit': 'CUIT',
  'micro.edit.bankCount': 'Nª CUENTA',
  'micro.edit.bankType': 'TIPO',
  'micro.edit.unicCode': 'CÓDIGO ÚNICO',

  'project.table.key.id': 'Id',
  'project.table.key.name': 'Nombre',
  'project.table.key.developer': 'Creador del proyecto',
  'project.table.key.status': 'Estado',
  'project.table.key.active': 'Proyecto',
  'project.table.key.creationDate': 'Fecha de creación',
  'project.table.key.percentage': 'Porcentaje de meta cumplida',
  'project.table.key.campaignStatus': 'Estado de la campaña',
  'project.table.key.action': 'Acciones',

  'project.table.status.draft': 'Borrador',
  'project.table.status.published': 'Publicada',
  'project.table.status.pending': 'Pendiente',
  'project.table.status.finished': 'Finalizado',
  'project.table.status.active': 'Activo',
  'project.table.status.inactive': 'Inactivo',
  'project.table.status.canceled': 'cancelado',
  'project.table.button.publish': 'Publicar',
  'project.table.button.activate': 'Activar',
  'project.table.button.inactivate': 'Inactivar',
  'project.table.button.returns': 'Ver retornos',

  'project.returns.table.description': 'Descripción',
  'project.returns.table.returnPercentage': 'Porcentaje de retorno',
  'project.returns.breadcrumb.project': 'Proyecto',
  'project.returns.breadcrumb.returns': 'Retornos',
  'project.returns.button.add': 'Cargar retorno',
  'project.returns.button.confirm': 'Confirmar retorno',

  // Home

  'home.header.admin': 'vista de administrador',
  'home.header.projects': 'Ver Proyectos',
  'home.header.profile': 'PERFIL',
  'home.header.signout': 'CERRAR SESIÓN',
  'home.header.headerMenuDrawer': 'Menú',

  'home.title.invest': 'Invertí en el mercado inmobiliario.',
  'home.text.content':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.',
  'home.button.readMore': 'LEER MAS',
  'home.button.register': 'REGISTRARME',
  'home.button.moreProject': 'VER TODOS LOS PROYECTOS',
  'home.title.project': 'Descubrí los proyectos de nuestra comunidad',
  'home.text.contentProject':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'home.title.questionProp': '¿Qué es Wibai?',
  'home.title.crowfunding': 'CROWDFUNDING INMOBILIARIO',
  'home.text.crowfunding': 'Wibai es la primera plataforma de financiamiento colectivo de proyectos inmobiliarios de Argentina.',
  'home.title.forAll': 'ES PARA TODOS',
  'home.text.forAll': 'Para que los pequeños y medianos colaboradores puedan participar.',
  'home.title.simpleAndAccesible': 'SIMPLE Y ACCESIBLE',
  'home.text.simpleAndAccesible': 'Invertí desde cualquier lugar y en cualquier momento tanto Pesos como Dólares.',
  'home.title.questionWhyProp': '¿Por que invertir con Wibai?',
  'home.title.howProccess': '¿Cómo es el Proceso?',
  'home.oneProccess.registerProccess': 'REGISTRARSE',
  'home.oneProccessContent.registerProccess':
    'Para que los pequeños y medianos colaboradores .',
  'home.twoProccess.choseProccess': 'ELEGÍ',
  'home.twoProccessContent.choseProccess':
    'Para que los pequeños y medianos colaboradores.',
  'home.threeProccess.investProccess': 'INVERTÍ',
  'home.threeProccessContent.investProccess':
    'Para que los pequeños y medianos colaboradores.',
  'home.fourProccess.winProccess': 'GANÁ',
  'home.fourProccessContent.winProccess':
    'Para que los pequeños y medianos colaboradores.',
  'home.title.titleNumber': 'Los números nos avalan:',
  'home.oneTitleNumber.investNumber': '+43000',
  'home.twoTitleNumber.approvedNumber': '$763MM',
  'home.threeTitleNumber.projectsNumber': '9',
  'home.fourTitleNumber.capitalNumber': '$328MM',
  'home.oneContentNumber.investNumber': 'colaboradores registrados',
  'home.twoContentNumber.approvedNumber': 'Aprobados por CFA',
  'home.threeContentNumber.projectsNumber': 'Proyectos fondeados',
  'home.fourContentNumber.capitalNumber': 'Capital invertido',
  'home.title.readyInvest': 'Estoy listo para invertir.',
  'home.text.readyInvestContent':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.',
  'home.button.readyButton': 'EMPEZAR',
  'home.button.submit': 'ENVIAR',
  'home.title.wantCommunicate': 'Quiero comunicarme.',
  'home.title.email': 'Por correo:',
  'home.content.emailContent':
    'Dejanos tu pregunta en el siguiente formulario y nos estaremos comunicando con vos para brindarte toda la información que necesites.',
  'home.title.phone': 'Telefónicamente:',
  'home.content.phoneContent':
    'Líneas Telefónicas Rotativas. Lunes a Viernes de 10:00 a 18:00.',
  'home.content.phoneContentWssp':
    'Comunicación por WhatsApp. Lunes a Viernes de 10:00 a 18:00.',

  'home.input.name': 'Nombre y Apellido*',
  'home.inputMessage.name': 'Por favor, ingrese el nombre y apellido',
  'home.input.email': 'Email*',
  'home.inputMessage.email': 'Por favor, ingrese el email',
  'home.inputMessageError.email': 'No es un email válido',
  'home.input.phone': 'Teléfono*',
  'home.inputMessage.phone': 'Por favor, ingrese el teléfono',
  'home.input.message': 'Mensaje**',
  'home.inputMessage.message': 'Por favor, ingrese el mensaje',
  'home.form.success': 'Su mensaje ha sido enviado.',

  'home.footer.title.invest': 'INVERSIONES',
  'home.footer.question.propi': '¿Por qué Wibai?',
  'home.footer.title.company': 'LA EMPRESA',
  'home.footer.content.aboutUs': '¿Que hacemos?',
  'home.footer.title.access': 'ACCESOS',
  'home.footer.content.register': 'Registrarse',
  'home.footer.content.login': 'Iniciar Sesión',
  'home.footer.content.logout': 'Cerrar Sesión',

  'home.footer.content.rightsReserved':
    '© 2019 Wibai. Todos los derechos reservados. Diseñado y desarrollado en Buenos Aires.',
  'home.footer.content.termsAndConditions': 'Términos y condiciones',
  'home.footer.content.privacyPolicy': 'Política de Privacidad',
  'home.footer.content.contentFooter':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',

  'home.featureProjects.noResults': 'No hay resultados para la búsqueda',
  'home.featureProjects.seeAllProjects': 'Ver todo',
  'home.featureProjects.titleInvest': 'INVERTIR',
  'home.featureProjects.daysFeatureProjects': 'día',
  'home.featureProjects.remainingDaysFeatureProjects': 'restante',
  'home.featureProjects.tirFeatureProjects': 'TIR',
  'home.featureProjects.multipleFeatureProjects': 'Múltiplo',
  'home.featureProjects.investFeatureProjects': 'Inversión',

  'project.content':
    'Encontrá emprendimientos, iniciativas público-privadas y campañas solidarias que necesitan de tu aporte para crecer y multiplicar su impacto.',
  'project.breadcrumb.home': 'Home',
  'project.breadcrumb.allProjects': 'Todos los proyectos',
  'project.breadcrumb.aboutUs': '¿Que hacemos?',

  'project.filter.title': 'Filtrar',
  'project.filter.selectTitle': 'TIR',
  'project.filter.option.active': 'Activo',
  'project.filter.option.finished': 'Finalizado',

  'profile.tag.pending': 'Pendiente de aprobación',
  'profile.tag.enabled': 'Habilitado',
  'profile.tag.toCorrect': 'A corregir',

  'profile.noComplete.title': 'Completa tus datos.',
  'profile.noComplete.firstContent':
    'Es necesario que completes el resto de la información para',
  'profile.noComplete.secondContent': 'poder navegar y aportar.',
  'profile.noComplete.button': 'COMPLETAR MIS DATOS',
  'profile.complete.tab.myData': 'Mis Datos',
  'profile.complete.tab.myInvests': 'MIS INVERSIONES',

  'profile.complete.title.basicDate': 'Datos Básicos',
  'profile.complete.text.name': 'Nombre',
  'profile.complete.text.document': 'DNI',
  'profile.complete.text.lastName': 'Apellido',
  'profile.complete.text.payPalUser': 'Usuario de PayPal',
  'profile.complete.text.mpUserName': 'Usuario de Mercado Pago',
  'profile.complete.text.floor': 'Piso',
  'profile.complete.text.department': 'Departamento',
  'profile.complete.text.birthdate': 'Nacimiento',
  'profile.complete.text.secondaryPhone': 'Teléfono secundario',

  'profile.complete.title.bankData': 'Datos Bancarios',
  'profile.complete.text.accountTypeCA': '21 - CA Caja de Ahorro',
  'profile.complete.text.accountTypeCC': '20 - CC Cuenta corriente',
  'profile.complete.text.cuit': 'CUIT/CUIL',
  'profile.complete.text.headline': 'Titular',
  'profile.complete.text.cbu': 'CBU',
  'profile.complete.text.numberAccount': 'N°Cuenta',
  'profile.complete.text.cbuAlias': 'Alias',
  'profile.complete.text.send': 'GUARDAR PERFIL',
  'profile.complete.text.typeAccount': 'Tipo de Cuenta',
  'profile.complete.text.businessName': 'Titular',
  'profile.complete.text.numAccount': 'Número de cuenta',
  'profile.complete.text.cp': 'CP',
  'profile.complete.text.addressVerification': 'Verificación de domicilio',
  'profile.complete.text.profilePicture': 'Foto de perfil',
  'profile.complete.text.documentNumber': 'Número de documento',
  'profile.complete.text.imagesIdentification': 'Imágenes de tu identificación',
  'profile.complete.text.Front': 'Frente',
  'profile.complete.text.Back': 'Dorso',

  'profile.myInvestments.title.nameProyect': 'Nombre del Proyecto',
  'profile.myInvestments.subTitle.invests': 'MIS INVERSIONES',
  'profile.myInvestments.subTitle.return': 'MIS RETORNOS',
  'profile.myInvestments.table.amount': 'MONTO',
  'profile.myInvestments.table.date': 'FECHA',
  'profile.myInvestments.table.status': 'ESTADO',
  'profile.myInvestments.table.percentage': 'PORCENTAJE',
  'profile.myInvestments.table.note': 'NOTA',
  'profile.myInvestments.filter.all': 'Todas las campañas',
  'profile.myInvestments.alert.title': 'Aún no posee inversiones',
  'profile.myInvestments.alert.description':
    'Las inversiones realizadas aparecerán en esta sección.',
  'profile.myInvestments.status.pending': 'Pendiente',
  'profile.myInvestments.status.expired': 'Cancelada',
  'profile.myInvestments.status.returned': 'Regresada',
  'profile.myInvestments.status.released': 'Liberada',
  'profile.myInvestments.status.accredited': 'Acreditada',
  'profile.myInvestments.status.rejectedRelease': 'Liberación Rechazada',
  'profile.myInvestments.status.returnedRelease': 'Liberación Devuelta',

  'project.investDrawer.title.yourInvest': 'Tu inversión',
  'project.investDrawer.text.yourInvest':
    'Ingresa el monto y origen de fondos para obtener los datos para realizar tu inversión',
  'project.investDrawer.title.yourCode': 'Código',
  'project.investDrawer.text.yourCode':
    'Copiá el siguiente código e ingresalo al momento de hacer la transferencia.',

  'project.investDrawer.button.next': 'APORTAR',
  'project.investDrawer.valueReward': 'Valor del aporte',
  'project.investDrawer.select.rewards': 'Recompensas',
  'project.investDrawer.select.input': 'Selecciona cuanto quieres aportar',
  'project.investDrawer.button.downloadPDF': 'DESCARGAR DATOS',
  'project.investDrawer.button.accepted': 'Aceptar',

  'project.investDrawer.messageError.number': 'Ingrese un valor numerico.',
  'project.investDrawer.messageError.origin':
    'Ingrese un origen de los fondos.',

  'project.investDrawer.modal.title.moderate':
    'Usuario pendiente de aprobación',
  'project.investDrawer.modal.title.new': 'Usuario nuevo',
  'project.investDrawer.modal.title.rejected': 'Usuario no validado',

  'project.investDrawer.modal.title.user_not_exists':
    'No puede realizar la operacion con este usuario.',
  'project.investDrawer.modal.title.amount_lower':
    'El monto ingresado es inferior al monto mínimo de inversión del proyecto.',
  'project.investDrawer.modal.title.currency_not_supported':
    'Moneda actual no admitida.',

  'project.investDrawer.modal.newOne':
    'Su usuario no se encuentra habilitado para hacer aportes. Debe completar los datos bancarios de su',
  'project.investDrawer.modal.newLink': 'perfil',
  'project.investDrawer.modal.newTwo': 'para poder hacerlo.',

  'project.investDrawer.modal.rejectedOne':
    'Su usuario no se encuentra habilitado para hacer inversiones. Debe revisar los datos de su',
  'project.investDrawer.modal.rejectedLink': 'perfil',
  'project.investDrawer.modal.rejectedTwo':
    'y enviar los cambios para que sean analizados por el equipo de Wibai.',

  'project.investDrawer.modal.moderate':
    'Su usuario aún no se encuentra habilitado para hacer inversiones. Recibirá una notificación cuando sus datos sean analizados. Para más información comuníquese al siguiente correo electrónico propinvest@prop-invest.com.',

  'project.investDrawer.modal.rejected':
    'Su usuario no se encuentra habilitado para hacer inversiones. Debe revisar los datos de su <Link to="/profile">perfil</Link> y enviar los cambios para que sean analizados por el equipo de Wibai.',

  'project.investDrawer.stepOne.origin': 'Origen de los fondos',
  'project.investDrawer.stepOne.currency': 'U$S',

  'project.investDrawer.stepTwo.nationalAccountData': 'Datos Cuenta Nacional',
  'project.investDrawer.stepTwo.headline': 'Titular',
  'project.investDrawer.stepTwo.cuit': 'CUIT',
  'project.investDrawer.stepTwo.cbuOrAlias': 'CBU/Alias',
  'project.investDrawer.stepTwo.accountNumber': 'N° de cuenta',

  'project.investDrawer.pdf.date': 'FECHA',
  'project.investDrawer.pdf.headline': 'Titular',
  'project.investDrawer.pdf.cuit': 'CUIT',
  'project.investDrawer.pdf.cbuOrAlias': 'CBU/ALIAS',
  'project.investDrawer.pdf.accountNumber': 'N° de cuenta',
  'project.investDrawer.pdf.currency': 'Moneda',
  'project.investDrawer.pdf.code': 'Código',
  'project.campaign.finished': 'Campaña finalizada',

  'login.title': 'Iniciar Sesión',
  'login.input.user': 'Usuario:',
  'login.input.error.mail': 'No es un email válido!',
  'login.input.password': 'Contraseña:',
  'login.input.error.password': 'El máximo de caracteres es de 20',
  'login.input.error.obligatoryField': 'Campo obligatorio!',
  'login.text.forgotPassword': 'Olvidé mi contraseña',
  'login.text.notHaveAccount':
    'No tienes cuenta? Registrate haciendo click aquí.',
  'login.button.login': 'INICIAR SESIÓN',
  'login.button.loginGoogle': 'INICIAR SESIÓN CON GOOGLE',
  'login.error.INVALID_USER_PASS': 'Usuario o contraseña inválidos',
  'login.error.WRONG_PROVIDER':
    'Para iniciar sesión deberá utilizar la opción "Ingresar con Google".',
  'login.error.USER_NOT_CONFIRMED':
    'Es necesario confirmar su dirección de correo electrónico para poder ingresar a la aplicación',
  'login.button.reSendMail': 'Reenviar correo',
  'login.message.error.email': 'Está pendiente la confirmación del mail',
  'login.modal.mailConfirm':
    'Te hemos reenviado un correo electrónico para la activación de tu cuenta',
  'login.message.userInvalid': 'El usuario es incorrecto',

  'register.title': 'Registro',
  'register.input.name': 'Nombre',
  'register.input.lastName': 'Apellido',
  'register.input.username': 'Mail',
  'register.input.password': 'Password',
  'register.input.passwordConfirmation': 'Confirmación de password',
  'register.input.error.mail': 'Ingrese una dirección de correo válida',
  'register.input.error.password': 'Las contraseñas no coinciden',
  'register.button.text.send': 'ENVIAR',
  'register.accountSucces': 'Su cuenta ha sido creada de forma exitosa',
  'register.button.text.registerGoogle': 'REGISTRARME CON GOOGLE',
  'register.input.tooltip':
    'La clave deberá cumplir con los siguientes requisitos: Contener al menos una letra mayúscula y una letra minúscula. Contener al menos un caracter especial Mínimo de 8 caracteres y máximo de 20.',

  'recoverPassword.title': 'Recuperar Contraseña',
  'recoverPassword.subTitle': 'Ingresa tu correo electrónico',
  'recoverPassword.success':
    'Te hemos enviado un correo electrónico para que recuperes tu contraseña.',
  'recoverPassword.error.user': 'El usuario es incorrecto',
  'newPass.success': 'Se modifico la contraseña con exito!',
  'newPass.title': 'Ingrese su nueva contraseña',
  'newPass.input.confirm': 'Confirmar Contraseña',

  'confirmationRequired.title': 'Correo Enviado',
  'confirmationRequired.text': 'Gracias por registrarte en Wibai. Te hemos enviado un correo electrónico para que realices la verificación de tu cuenta.',

  'accountConfirmation.title': 'Verificación Realizada',
  'accountConfirmation.text': 'Tu correo electrónico ha sido verificado correctamente. Inicia sesión en Wibai para continuar.',
  'accountConfirmation.button': 'INGRESAR',
  'accountConfirmation.error': 'Confirmación de token inválido!',

  'project.id.minimunAmountOfInvestment': 'Monto mínimo de Inversión',
  'project.id.totalInvest': 'inversor',
  'project.id.totalInvests': 'colaboradores',
  'project.id.button.toInvest': 'invertir',
  'project.id.button.closingDay': 'Día de cierre',
  'project.id.button.multipleEquity': 'Múltiplo de Equidad',
  'project.id.year': 'año',
  'project.id.day': 'día',
  'project.id.remaining': 'restante',

  'project.id.data.campOf': 'Campo de',
  'project.id.data.character': 'caracteres',
  'project.id.error.image': 'Error al tratar de subir la imagen',

  'register.data.title': 'Información General',
  'register.data.subTitlePesonal': 'Datos Personales',
  'register.data.placeholderLocation': 'Calle XXX, Localidad, Provincia.',
  'register.data.loadImage': 'Cargar imagenes',
  'register.data.tooltipImage':
    'Puede ser un servicio, un resumen bancario o un certificado de domicilio emitido por la autoridad pertinente. En el documento deberá visualizarse tu nombre y el domicilio ingresado, y el mismo no debe tener más de 90 días de emisión. La imagen debe ser a color, no debe estar cortada y debe tener buena resolución.',
  'register.data.tooltipImageMax': 'Tamaño máximo permitido: 5mb',
  'register.data.tooltipImageNit':
    'Las imágenes tienen que ser nítidas, a color y no presentar ningún tipo de cortes o zonas no legibles',
  'register.data.placeholderNumAccount':
    'Ingrese el número de su cuenta bancaria',
  'register.data.placeholderTypeAccount':
    'Por favor selecione el tipo de cuenta',

  'moderate.title.success': 'Registro Exitoso',
  'moderate.text.success': 'Sus datos han sido enviado correctamente.',

  'users.breadcrumb.start': 'Inicio',
  'users.breadcrumb.users': 'Usuarios',
  'users.breadcrumb.transactions': 'Transacciones',
  'users.button.newUser': 'Nuevo Usuario',
  'users.modal.acccept': '¿Está seguro que desea Aprobar este usuario?',
  'users.modal.reject': '¿Está seguro que desea Rechazar este usuario?',
  'users.table.name.new': 'Pendiente de aprobación',
  'users.table.name.moderate': 'Incompleto',
  'users.table.name.accepted': 'Habilitado',
  'users.table.name.rejected': 'A corregir',
  'users.table.roles.admin': 'administrador',
  'users.table.roles.developer': 'desarrollador',
  'users.table.roles.inversor': 'colaborador',
  'users.table.title.id': 'Id',
  'users.table.title.name': 'Nombre',
  'users.table.title.lastName': 'Apellido',
  'users.table.title.username': 'Usuario',
  'users.table.title.state': 'Estado',
  'users.table.title.roles': 'Rol',
  'users.table.title.action': 'Acciones',
  'users.table.button.viewEdit': 'Ver/Editar',
  'users.table.button.accepted': 'Aprobar usuario',
  'users.table.button.rejected': 'Rechazar usuario',
  'users.form.button.addAccount': 'Agregar cuenta',

  'admin.transactions.table.breadcrumb.start': 'Inicio',
  'admin.transactions.table.breadcrumb.transactions': 'Transacciones',

  'admin.transactions.edit.breadcrumb.start': 'Inicio',
  'admin.transactions.edit.breadcrumb.transactions': 'Transacciones',
  'admin.transactions.edit.breadcrumb.edit': 'Editar',

  'admin.transactions.table.title.id': 'Id',
  'admin.transactions.table.title.createdAt': 'Fecha de creación',
  'admin.transactions.table.title.investorUser': 'Usuario',
  'admin.transactions.table.title.projectName': 'Proyecto',
  'admin.transactions.table.title.amount': 'Monto',
  'admin.transactions.table.title.transactionId': 'Código de Transación',
  'admin.transactions.table.title.originId': 'Código de operación',
  'admin.transactions.table.title.status': 'Estado',
  'admin.transactions.table.card.accredited': 'Acreditada',
  'admin.transactions.table.card.developer': 'Desarrollador',
  'admin.transactions.table.card.expired': 'Cancelada',
  'admin.transactions.table.card.returned': 'Devuelta',
  'admin.transactions.table.card.released': 'Realizada',
  'admin.transactions.table.card.rejected': 'Rechazada',
  'admin.transactions.table.card.error': 'Error',
  'admin.transactions.table.card.pending': 'Pendiente',
  'admin.transactions.table.card.onBindCall': 'Llamada bind pendiente',
  'admin.transactions.table.card.onBindCallReturnAndRelease': 'En proceso',
  'admin.transactions.table.card.releasedWithErrors': 'Realizada con errores',
  'admin.transactions.table.card.returnedReject': 'Devolución rechazada',
  'admin.transactions.table.card.returnedWithErrors': 'Realizada con errores',
  'admin.transactions.table.card.rejectWithErrors': 'Rechazada con errores',
  'admin.transactions.table.card.rejectedRelease': 'Rechazada',
  'admin.transactions.table.card.returnedRelease': 'Devolución realizada',
  'admin.transactions.table.history': 'Historial',
  'admin.transactions.table.edit': 'Editar',
  'admin.transactions.modal.close': 'Cerrar',
  'admin.transactions.modal.upload': 'Fecha',
  'admin.transactions.edit.amount': 'Monto',
  'admin.transactions.edit.currency': 'Moneda',
  'admin.transactions.edit.state': 'Estado',
  'admin.transactions.edit.bindTransferIdRequiredMessage': 'Id de la transacción de BInd requerido',
  'admin.transactions.edit.bindTransferId': 'Id de transferencia de BInd',
  'admin.transactions.edit.getBindTransfer': 'Obtener transferencia de BInd',
  'admin.transactions.edit.getBindTransferError': 'Error al obtener la transferencia de BInd',
  'admin.transactions.edit.editTransaction': 'Confirmar la transacción',
  'admin.transactions.edit.editTransactionError': 'Error al editar la transacción',
  'admin.transactions.edit.cancel': 'Cancelar',

  'admin.country.table.breadcrumb.country': 'Países',

  'admin.country.table.code': 'Código',
  'admin.country.table.currency': 'Moneda',
  'admin.country.table.description': 'Descripción',
  'admin.country.table.MPAccessToken': 'MPAccessToken',
  'admin.country.table.country': 'País',
  'admin.country.table.country.add': 'Nuevo País',

  'admin.transactions.table.breadcrumb.micros': 'Administración de Micrositios',
  'admin.microsite.error.selectProject': 'Por favor, seleccione un proyecto',

  'admin.transactions.table.title.linkToAccess': 'Link de acceso',
  'admin.transactions.table.title.name': 'Nombre',
  'admin.transactions.table.title.descp': 'Descripción',
  'admin.transactions.table.title.color': 'Color',
  'admin.transactions.createMicroButton': 'Crear Micrositio',
  'admin.transactions.editMicroButton': 'Editar Micrositio',
  'admin.microsite.error.linkExist': 'Por favor, eliga otro Link de acceso',
  'admin.microsite.logoImage': 'Logo',

  'validate.numericCamp': 'Campo numérico',
  'validate.invalidCuit': 'CUIT inválido',
  'validate.invalidCBU': 'CBU inválido',
  'validate.invalidCBUAlias': 'Alias de CBU inválido',
  'validate.validatePassword':
    'La contraseña no cumple con los requisitos de seguridad definidos',
  'validate.validateAge': 'Tienes que tener 18 años o más!',
  'validate.specialCharacter':
    'La cadena no podrá comenzar con caracteres especiales.',
  'validate.hexColor':
    'La cadena tiene que tener formato hexadecimal (#000000).',
  'validate.onlyText':
    'La cadena no puede contener caracteres especiales ni espacios.',
  'validate.validateDevelopment': 'Por favor seleccione un usuario validado.',
  'validate.validURL': 'El campo ingresado no es una URL valida.',
  'validate.confirmPassword': 'La contraseña y la confirmación no coinciden',
  'validate.validateCuil': 'El formato de CUIL ingresado no es válido.',
  'validate.onlyImage': 'Sólo se admiten archivos de tipo imagen!',
  'validate.cannotExceedImage': 'No puede superar los 10 MB!',
  'validate.selectValidAddress': 'Por favor, seleccione una ubicación válida',
  'validate.errorSelectAddress': 'Ocurrio un error, intente nuevamente',

  'admin.menuBar.goToPropiInvest': 'Ir a Wibai Web',
  'admin.menuBar.projects': 'Proyectos',
  'admin.menuBar.transactions': 'Transacciones',
  'admin.menuBar.micro': 'Micrositios',
  'admin.menuBar.users': 'Usuarios',
  'admin.menuBar.notifications': 'Notificaciones',
  'admin.menuBar.fundRelease': 'Liberación de fondos',
  'admin.menuBar.returnFund': 'Devolución de fondos',
  'admin.menuBar.english': 'Inglés',
  'admin.menuBar.spanish': 'Español',
  'admin.menuBar.language': 'Idioma',
  'admin.menuBar.countries': 'Países',

  'admin.fundRelease.breadcrumb.fundRelease': 'Liberación de fondos',
  'admin.fundRelease.breadcrumb.fundReturn': 'Devolución de fondos',
  'admin.table.title.projectName': 'Proyecto',
  'admin.table.title.currency': 'Moneda',
  'admin.table.title.totalReturnAmount': 'Monto total que se va a liberar',

  'admin.table.button.approve': 'Aprobar',
  'admin.table.button.reject': 'Rechazar',
  'admin.table.button.seeTransactions': 'Ver transacciones',
  'admin.table.button.seeProject': 'Ver proyecto',
  'admin.fundRelease.modal.accept':
    'Está seguro que desea Aprobar esta liberación?',
  'admin.fundRelease.modal.reject':
    'Está seguro que desea Rechazar esta devolución?',
  'admin.fundReturn.modal.reject':
    'Está seguro que desea Rechazar este Devolución?',
  'admin.fundReturn.modal.accept':
    'Está seguro que desea Aprobar esta Devolución?',

  'admin.transactions.status.pending': 'Pendiente',
  'admin.transactions.status.expired': 'Cancelada',
  'admin.transactions.status.accredited': 'Acreditada',
  'admin.transactions.status.released': 'Liberada',
  'admin.transactions.status.noReleased': 'No Liberada',
  'admin.transactions.status.refunded': 'Devuelta',
  'admin.transactions.status.noRefunded': 'No Devuelta',
  'admin.transactions.status.error': 'Error',
  'admin.transactions.status.errorBind': 'Error Bind',

  'admin.transactions.status.onBindCall': 'En proceso',

  'admin.returns.status.done': 'Realizada',
  'admin.returns.status.rejected': 'Rechazada',
  'admin.returns.status.doneWhitErrors': 'Realizada con errores',
  'admin.returns.status.rejectedWhitErrors': 'Rechazada con errores'
};

const appLocaleEs = {
  messages: {
    ...culture
  },
  antd: es_ES,
  locale: 'es',
  data: appLocaleData
};

export default appLocaleEs;
