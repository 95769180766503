import React, { Component } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import {
  Layout, Row, Col, Button
} from 'antd';

import messagesHome from './cultureHome';
import topBanner from '../../../images/about/cruces.png';
import firstButton from '../../../images/about/gub.png';
import secondButton from '../../../images/about/ong.png';
import thirdButton from '../../../images/about/empr.png';
import fourButton from '../../../images/about/emprende.png';

const { Content } = Layout;

const Container = styled.div`
  & {
    background-color: #6e48fa;
  }

  & .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  & .back-doble {
    background: rgba(110,72,250,1);
    background: -moz-linear-gradient(top, rgba(110,72,250,1) 0%, rgba(110,72,250,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(110,72,250,1)), color-stop(50%, rgba(110,72,250,1)), color-stop(51%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(110,72,250,1) 0%, rgba(110,72,250,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(110,72,250,1) 0%, rgba(110,72,250,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(110,72,250,1) 0%, rgba(110,72,250,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(110,72,250,1) 0%, rgba(110,72,250,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6e48fa', endColorstr='#ffffff', GradientType=0 );
  }

  @media (max-width: 768px) {
    & {
      margin: 0;
    }
  }
`;

const TopBanner = styled.div`
  & {
    position: relative;
  }

  & .bg {
    width: 100%;
    background-color: 'red';
    height: 340px;
    position: relative;
  }
  min-height: 240px;
  & .bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${topBanner});
  }

  & .banner-content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  & .banner-container {
    position: relative;
  }

  & h1 {
    font-family: 'Roboto';
    font-size: 45px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 3rem;
  }

  & hr {
    width: 227px;
    border-color: rgba(255, 255, 255, 0.5);
  }

  & p {
    max-width: 60%;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 536px;
    margin-top: 5rem;
  }

  & .buttons > div:first-child {
    margin-right: 20px;
  }
  
  @media (max-width: 768px) {
    & h1 {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    & p {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  @media (max-width: 535px) {

    & .bg::before {
      background-image: none;
    }

    & p {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    & .buttons {
      justify-content: center;
    }

    & .buttons > div:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;


const About = styled.div`
& {

}

& .back {
  background-color: #6e48fa;
}

& .firstButton {
  height: 200px;
  position: relative;
}

& .firstButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${firstButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

& .secondButton {
  height: 200px;
  position: relative;
}

& .secondButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${secondButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

& .thirdButton {
  height: 200px;
  position: relative;
}

& .thirdButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${thirdButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

& .fourButton {
  height: 200px;
  position: relative;
}

& .fourButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
    height: 90%;
  background-image: url(${fourButton});
      background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 5%;
}

@media (max-width: 768px) {
  & .firstButton{
    min-height: 228px;
    margin-bottom: 6%;
    margin-top: 2rem;
  }
  & .secondButton{
    min-height: 228px;
    margin-bottom: 10%;
  }
  & .thirdButton{
    min-height: 228px;
    margin-bottom: 6%;
  }
  & .fourButton{
    min-height: 228px;
    margin-bottom: 6%;
  }
}

`;

const TextAbout = styled.div`
& {
  padding: 5% 20%;
  text-align:center;
  color: #6e48fa;
}

`;


const ButtonLogin = styled.div`
  & button {
    border: solid 2px #3eda6e;
    border-radius: 40px;
    width: 30%;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    -webkit-letter-spacing: 0.28px;
    -moz-letter-spacing: 0.28px;
    -ms-letter-spacing: 0.28px;
    letter-spacing: 0.28px;
    color: #3eda6e;
    margin-right: 18px;
    text-transform: uppercase;
    background-color: transparent;
    margin-top: 3%;
    padding: 0%;
    height: 60px;
  }

  & button& button:hover {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
  }

  @media (max-width: 920px) {
    & button {
      width: 100%;
    }
  }
`;
class AboutUs extends Component {

  render() {
    const {
      intl,
    } = this.props;

    return (
      <Content className="home">
        <Container  style={{ marginTop: '5rem' }}>
          <TopBanner>
            <div className="bg" />
            <div className="banner-content">
              <h1>{intl.formatMessage(messagesHome.aboutUs)}</h1>
              <p>El crowdfunding es una herramienta para potenciar la interacción entre organizaciones y <br/>
              su comunidad. El aporte colectivo impulsa el desarrollo de proyectos de impacto y ayuda a que se hagan<br/>
               realidad de manera ágil y transparente.
              </p>
            </div>
          </TopBanner>

          <About>
            <Row className="back-doble">
              <Col xs={0} sm={0} md={0} lg={4} xl={4} />
              <Col xs={24} sm={24} md={24} lg={4} xl={4} className="firstButton" />
              <Col xs={24} sm={24} md={24} lg={4} xl={4} className="secondButton" />
              <Col xs={24} sm={24} md={24} lg={4} xl={4} className="thirdButton" />
              <Col xs={24} sm={24} md={24} lg={4} xl={4} className="fourButton" />
              <Col xs={0} sm={0} md={0} lg={4} xl={4} />
            </Row>
          </About> 
        </Container>

        <TextAbout>
          <Row>
                <Col span={24} className="text-button"> 
                Wibai es el vehículo para que Gobiernos, ONGs, Empresas y Emprendedores digitalicen sus <br/>
                procesos de recaudación de fondos, implementen programas de desarrollo emprendedor, pre-<br/>incubación de empresas, matching-funds, concursos y hackathones.
               
                <ButtonLogin>
                  <Button block>
                    <a href="mailto:info@wibai.com.ar">CONTACTANOS</a>
                  </Button>
                </ButtonLogin>
                </Col>
          </Row>
        </TextAbout>

      </Content>
    );
  }
}

export default injectIntl(AboutUs);
