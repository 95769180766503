import React, { Component, Fragment } from 'react';
import { Spin } from 'antd';
import { getDownloadUrl } from '../../service/firebaseStorageService';

const styles = {
  img: { width: '100%', height: 'auto' },
  imgDesktop: { width: '10%', height: 'auto' },
  spin: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

class RemoteImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null
    };
  }

  componentDidMount = () => {
    getDownloadUrl(this.props.url).then(url => this.setState({ url }, () => {
      if (this.props.onFetch) {
        this.props.onFetch({
          index: this.props.index,
          url,
          name: this.props.name
        });
      }
    }));
  };

  render() {
    const { url } = this.state;
    return (
      <Fragment>
        {url ? (
          <img src={url} style={(this.props.style ? styles[this.props.style]: styles.img)} alt="Remote" />
        ) : (
          <Spin style={styles.spin} />
        )}
      </Fragment>
    );
  }
}

export default RemoteImage;
