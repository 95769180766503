import {
  GET_TRANSACTION, GET_BIND_TRANSFER, GET_TRANSACTIONS, GET_TRANSACTIONS_BY_USER, APPROVE_EDITED_TRANSACTION
} from '../actions/transactions';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingBindTransfer: false,
  errorFetchingBindTransfer: false,
  isFetchingProject: false,
  didInvalidate: false,
  list: [],
  userTransactions: [],
};

const transactions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case `${GET_TRANSACTIONS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      list: []
    };
  case `${GET_TRANSACTIONS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      list: []
    };
  case `${GET_TRANSACTIONS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      list: action.payload.data
    };

  case `${GET_TRANSACTIONS_BY_USER}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      userTransactions: []
    };
  case `${GET_TRANSACTIONS_BY_USER}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      userTransactions: []
    };
  case `${GET_TRANSACTIONS_BY_USER}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      userTransactions: action.payload.data
    };
  case `${GET_TRANSACTION}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      transactionEdit: undefined
    };
  case `${GET_TRANSACTION}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
      transactionEdit: undefined
    };
  case `${GET_TRANSACTION}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      transactionEdit: action.payload.data
    };
  case `${GET_BIND_TRANSFER}_REJECTED`:
    return {
      ...state,
      isFetchingBindTransfer: false,
      errorFetchingBindTransfer: true,
      didInvalidate: true,
      bindTransfer: undefined
    };
  case `${GET_BIND_TRANSFER}_PENDING`:
    return {
      ...state,
      isFetchingBindTransfer: true,
      errorFetchingBindTransfer: false,
      didInvalidate: false,
      bindTransfer: undefined
    };
  case `${GET_BIND_TRANSFER}_FULFILLED`:
    return {
      ...state,
      isFetchingBindTransfer: false,
      errorFetchingBindTransfer: false,
      didInvalidate: false,
      bindTransfer: action.payload.data
    };
  case `${APPROVE_EDITED_TRANSACTION}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true
    };
  case `${APPROVE_EDITED_TRANSACTION}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false
    };
  case `${APPROVE_EDITED_TRANSACTION}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      transactionEdit: action.payload.data
    };
  default:
    return state;
  }
};

export default transactions;
