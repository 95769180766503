import React, { Component } from 'react';
import { Layout, Icon, Button } from 'antd';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import messages from './culture';

const { Content } = Layout;

const PaymentSuccessContainer = styled.div`
  & {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  & h1 {
    font-family: Roboto;
    font-size: 92px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 1.2rem 0;
  }

  & p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    max-width: 510px;
  }

  & i {
    font-size: 163px;
    position: absolute;
    top: 0;
    color: #fff;
    opacity: 0.1;
    right: 24px;
  }
`;


const GoBackButton = styled.div`
  & button {
    background-color: transparent;
    border-radius: 5px;
    border: solid 1.5px #ffffff;
    height: 60px;
    width: 330px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.8px;
    text-align: center;
    color: #ffffff;
    margin-top: 1rem;
  }

  & button:hover {
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    background-color: transparent;
  }

  & button:active {
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    background-color: transparent;
  }

  & button:focus {
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    background-color: transparent;
  }
`;

class Moderate extends Component {
  componentDidMount = () => {
    window.scroll({
      top: 0,
      behavior: 'auto'
    });
  };

  render() {
    const { intl } = this.props;

    return (
      <Content style={{ paddingTop: 40 }} className="success">
        <PaymentSuccessContainer>
          <Icon type="check-circle" />
          <h1>{intl.formatMessage(messages.moderateTitle)}</h1>
          <p>{intl.formatMessage(messages.moderateText)}</p>
          <GoBackButton>
            <Button>
              <Link to="/">VOLVER AL INICIO</Link>
            </Button>
          </GoBackButton>
        </PaymentSuccessContainer>
      </Content>
    );
  }
}

export default injectIntl(Moderate);
