import {
  CREATE_MICROSITE, GET_MICROSITES, EDIT_MICROSITE, GET_MICROSITE_BY_ID, ACTIVE_MICROSITE
} from '../actions/microsite';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingCreate: false,
  didInvalidate: false,
  list: [],
  micrositeByID: false,
};

const projects = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case `${GET_MICROSITES}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${GET_MICROSITES}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${GET_MICROSITES}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        list: action.payload.microsites
      };

    case `${GET_MICROSITE_BY_ID}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        micrositeByID: false
      };
    case `${GET_MICROSITE_BY_ID}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        micrositeByID: false
      };
    case `${GET_MICROSITE_BY_ID}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        micrositeByID: action.payload.microsite
      };

    case `${CREATE_MICROSITE}_REJECTED`:
      return {
        ...state,
        isFetchingCreate: false,
        didInvalidate: true
      };
    case `${CREATE_MICROSITE}_PENDING`:
      return {
        ...state,
        isFetchingCreate: true,
        didInvalidate: false
      };
    case `${CREATE_MICROSITE}_FULFILLED`:
      return {
        ...state,
        isFetchingCreate: false,
        didInvalidate: false
      };

    case `${EDIT_MICROSITE}_REJECTED`:
      return {
        ...state,
        isFetchingCreate: false,
        didInvalidate: true
      };
    case `${EDIT_MICROSITE}_PENDING`:
      return {
        ...state,
        isFetchingCreate: true,
        didInvalidate: false
      };
    case `${EDIT_MICROSITE}_FULFILLED`:
      return {
        ...state,
        isFetchingCreate: false,
        didInvalidate: false,
      };

    case `${ACTIVE_MICROSITE}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${ACTIVE_MICROSITE}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${ACTIVE_MICROSITE}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        messageResponse: action.payload
      };

    default:
      return state;
  }

};

export default projects;
