import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import {
  Layout, Breadcrumb, Row, Col, Modal, Button, Table, Tag
} from 'antd';

import { getFundReleases, changeReleaseStatus } from '../../../actions/fundRelease';
import DataTable from './table';

import messages from './cultureFundRelease';

import '../../commons/css/users.css';

const tags = {
  error: 'red',
  pending: 'orange',
  expired: 'red',
  returned: 'green',
  released: 'green',
  rejectedRelease: 'magenta',
  returnedRelease: 'green',
  returnedWithErrors: 'red',
  returnedReject: 'red',
  accredited: 'green',
  OnBindCall: 'red',
  rejected: 'magenta',
};

const ModalContainer = styled(Modal)`
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
  width: 520px;
  top: 50px;
`;

class FundRelease extends Component {
  state = {
    openModal: false, actionModal: false, transaccion: [], select: false
  }

  componentDidMount() {
    const { getAllFundReleases } = this.props;
    getAllFundReleases();
  }

  handleChangeModal = (transaccion) => {
    this.setState(prevState => ({ openModal: !prevState.openModal, transaccion }));
  }

  viewProject = (id) => {
    const { history } = this.props;
    history.push({ pathname: `/projects/${id}` });
  }

  handleChangeAcction = () => {
    this.setState(prevState => ({ actionModal: !prevState.actionModal }));
  }

  handleChangeModalAcction = (select, id, index) => {
    this.setState(prevState => ({
      actionModal: !prevState.actionModal,
      select: { select, id, index }
    }));
  }

  handleOk = () => {
    const {
      // aprove,
      // reject
      changeStatus
    } = this.props;
    const { select } = this.state;

    const model = {
      id: select.id,
      status: select.select === 'accept' ? 'released' : 'rejected'
    };

    changeStatus(model, select.index);

    this.setState({ actionModal: false });
  }

  render() {
    const { fundReleases, intl } = this.props;
    const {
      openModal, transaccion, actionModal, select
    } = this.state;

    const columns = [{
      title: intl.formatMessage(messages.idTitle),
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
    }, {
      title: intl.formatMessage(messages.createdAtTitle),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
      // eslint-disable-next-line react/display-name
      render: createdAt => (
        <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
      )
    }, {
      title: intl.formatMessage(messages.investorUserTitle),
      dataIndex: 'investorUser',
      key: 'investorUser',
      sorter: (a, b) => a.investorUser.localeCompare(b.investorUser),
      sortDirections: ['descend', 'ascend'],
    }, {
      title: intl.formatMessage(messages.amountTitle),
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => (a.amount - b.amount),
      sortDirections: ['descend', 'ascend'],
    }, {
      title: intl.formatMessage(messages.originIdTitle),
      dataIndex: 'originId',
      key: 'originId',
      sorter: (a, b) => a.originId.localeCompare(b.originId),
      sortDirections: ['descend', 'ascend'],
    }, {
      title: intl.formatMessage(messages.statusTitle),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      // eslint-disable-next-line react/display-name
      render: status => (<Tag color={tags[status]}>{intl.formatMessage(messages[`${status}Transaction`])}</Tag>),
    }];

    return (
      <Layout style={{ margin: '0 16px' }}>
        <ModalContainer
          title={intl.formatMessage(messages.transactions)}
          visible={openModal}
          onCancel={() => this.handleChangeModal([])}
          footer={[
            <div key="submit" style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <Button key="submit" type="primary" onClick={() => this.handleChangeModal([])}>
                {intl.formatMessage(messages.close)}
              </Button>
            </div>
          ]}
        >
          <Table dataSource={transaccion} columns={columns} rowKey="id" />
        </ModalContainer>

        <Modal
          title={select.select === 'accept' ? intl.formatMessage(messages.acceptModal) : intl.formatMessage(messages.rejectModal)}
          visible={actionModal}
          onOk={this.handleChangeAcction}
          onCancel={() => this.handleChangeModalAcction(false)}
          footer={[
            <Button
              key="back"
              onClick={() => this.handleChangeModalAcction(false)}
            >
              {intl.formatMessage(messages.cancelButton)}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={fundReleases.loading}
              onClick={this.handleOk}
            >
              {intl.formatMessage(messages.acceptButton)}
            </Button>,
          ]}
        />

        <Row>
          <Col span={21}>
            <Breadcrumb>
              <Breadcrumb.Item>{intl.formatMessage(messages.breadcrumbStart)}</Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(messages.breadcrumbFundRelease)}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div className="content">
          <DataTable
            data={fundReleases.list}
            loading={fundReleases.isFetching}
            rejectUserAction={this.showRejectionModal}
            editAction={this.handleEditProject}
            intl={intl}
            messages={messages}
            // handleChangeModal={this.handleChangeModal}
            handleChangeModalAcction={this.handleChangeModalAcction}
            viewProject={this.viewProject}
          />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ fundReleases: state.fundReleases });

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllFundReleases: getFundReleases,
  changeStatus: changeReleaseStatus,
  // aprove: aproveReleases,
  // reject: rejectReleases
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FundRelease));
