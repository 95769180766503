import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Layout, Row, Col } from 'antd';

import logo from '../header/micrositelogo.png';
import messages from '../../home/cultureHome';

const styles = {
  image: {
    marginBottom: '3rem'
  },
  footer: {
    padding: 0
  }
};

const TopFooter = styled.div`
  & {
    background-color: #3b308f;
    color: #fff;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding: 6rem 0;
  }

  & h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
`;

const LinksContainer = styled.div`
  & {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    justify-content: center;
  }

  & h4 {
    font-size: 17px;
    font-weight: bold;
    color: #fff;
    margin-top: 0;
    text-transform: uppercase;
  }

  & a {
    color: #ffffff;
  }
`;

const BottomFooter = styled.div`
  & {
    background-color: #333333;
    color: #fff;
    padding: 2rem 0 3rem 0;
  }

  & p {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  & span {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  & a {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    text-decoration: underline;
  }
`;

const { Footer } = Layout;

const FooterComponent = ({ intl }) => (
  <Footer style={styles.footer}>
    <TopFooter>
      <Row>
        <Col md={24}>
          <Row>
            <Col md={24} lg={12}>
              <Link to="/">
                <img src={logo} alt="logo" style={styles.image} />
              </Link>
            </Col>
            <Col md={24} lg={4}>
              <LinksContainer>
                <h4>Proyectos</h4>
                <Link to="/projects">
                  Ver Proyectos
                </Link>
                {
                  /**
                  <Link to="/">
                    {intl.formatMessage(messages.questionPropiFooter)}
                  </Link>
                  <Link to="/">{intl.formatMessage(messages.howProccess)}</Link> 
                  **/

                }
              </LinksContainer>
            </Col>
            <Col md={24} lg={4}>
              <LinksContainer>
                <h4>Nosotros</h4>
                <Link to="/aboutus">
                  {intl.formatMessage(messages.aboutUs)}
                </Link>
                {/**
                 <Link to="/">{intl.formatMessage(messages.questionProp)}</Link>
                 **/}
              </LinksContainer>
            </Col>
            <Col md={24} lg={4}>
              <LinksContainer>
                <h4>{intl.formatMessage(messages.accessTitleFooter)}</h4>
                <Link to="/account/register">
                  {intl.formatMessage(messages.registerFooter)}
                </Link>
                <Link to="/account/login">
                  {intl.formatMessage(messages.loginFooter)}
                </Link>
              </LinksContainer>
            </Col>
          </Row>
          {/* <Row>
            <Col md={24} lg={{ offset: 18, span: 6, pull: 18 }}>
              <IconsContainer>
                <Row>
                  <Col span={4}>
                    <Link to="/">
                      <Icon type="facebook" />
                    </Link>
                  </Col>
                  <Col span={4}>
                    <Link to="/">
                      <Icon type="twitter" />
                    </Link>
                  </Col>
                  <Col span={4}>
                    <Link to="/">
                      <Icon type="instagram" />
                    </Link>
                  </Col>
                </Row>
              </IconsContainer>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </TopFooter>
    <BottomFooter>
      <Row>
        <Col md={24}>
          <Row>
            <Col md={24} lg={12}>
              <span>{intl.formatMessage(messages.rightsReserved)}</span>
            </Col>
            <Col md={24} lg={12}>
              <Row type="flex" justify="end" gutter={24}>
                <Col lg={8} md={24}>
                  <Link to="/terminos-y-condiciones">
                    {intl.formatMessage(messages.termsAndConditions)}
                  </Link>
                </Col>
                <Col lg={8} md={24}>
                  <Link to="/">
                    {intl.formatMessage(messages.privacyPolicy)}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </BottomFooter>
  </Footer>
);

export default injectIntl(FooterComponent);
