import React from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import BasicData from './basicData';


const myDataUserContainer = ({
  userInfo, intl, messages, handleChange
}) => (
    <Row>

      <Col span={12} offset={6}>
        <BasicData
          userInfo={userInfo}
          intl={intl}
          messages={messages}
          handleChange={handleChange}
        />

      </Col>


      {
        /** 
       <BankData
        userInfo={userInfo}
        intl={intl}
        messages={messages}
      />      
        **/
      }
    </Row>
  );

export default myDataUserContainer;
