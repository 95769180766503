import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

const TypographyTitle = styled.div`
  opacity: 0.62;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #5c5c5c;
`;

const ContainerDataAndIcon = styled.div`
  margin-top: 28.5px;
  display: flex;
  flex: 1;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
`;

const IconAuditContainer = styled(Icon)`
  object-fit: contain;
  margin-right: 7px;
  font-size: 18px;
  color: #5c5c5c;
  opacity: 0.7;
`;

const TitleCard = ({
  iconType,
  title
}) => (
  <ContainerDataAndIcon>
    <IconAuditContainer type={iconType} />
    <TypographyTitle>{title}</TypographyTitle>
  </ContainerDataAndIcon>
);

export default TitleCard;
