import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Button 
} from 'antd';

import { getAllCountries } from '../../../actions/country';
import DataTable from './table';

import messages from './culture';

class Users extends Component {
  componentDidMount() {
    this.props.getAll();
  }

  editCountry = (id) => {
    const { history } = this.props;
    history.push({ pathname: `/admin/countries/edit/${id}` });
  }

  handleAddNew = () => {
    const { history } = this.props;
    history.push('/admin/countries/create');
  }

  render() {
    const { countries, intl } = this.props;

    return (
      <Layout style={{ margin: '0 16px' }}>
        <Row>
          <Col span={21}>
            <Breadcrumb>
              <Breadcrumb.Item>{intl.formatMessage(messages.start)}</Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(messages.countries)}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={this.handleAddNew} className="buttonUsers">{intl.formatMessage(messages.newCountryButton)}</Button>
          </Col>
        </Row>
        <div className="content">
          <DataTable
            data={countries.list}
            loading={countries.isFetching}
            intl={intl}
            messages={messages}
            editAction={this.editCountry}
          />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ countries: state.countries });

const mapDispatchToProps = dispatch => bindActionCreators({ getAll: getAllCountries }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Users));
