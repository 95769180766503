import React, { Component } from 'react';
import { Button, Form, Input } from 'antd';

class SupportStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
  }

  handleClick = () => {
    this.props.passClick();
  };

  changeVisibility = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    if (!this.state.visible) {
      return null;
    }
    return (
      <div>
        <p>Ingrese el monto que deseas aportar al proyecto, luego geneará el comprobante</p>
        <h3>Monto:</h3>
        <Form layout="vertical" hideRequiredMark>
          <Form.Item label="monto">
            <Input addonBefore="U$S" placeholder="" />
          </Form.Item>
          <Button type="button" htmlType="submit" className="bt-support green" onClick={this.handleClick}>GENERAR COMPROBANTE</Button>
        </Form>
      </div>
    );
  }
}

export default SupportStep1;
