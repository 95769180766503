import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Input, Layout, Breadcrumb, Row, Col, Button, Modal, Alert,
} from 'antd';

import { showUsers, updateUser } from '../../../actions/users';
import DataTable from './table';

import messages from './cultureUsers';

import '../../commons/css/users.css';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectionModalVisible: false
    };
  }

  componentDidMount() {
    this.props.showUsers();
  }

  handleAddNew = () => {
    const { history } = this.props;
    history.push('/admin/users/create');
  }

  showRejectionModal = (user) => {
    this.setState({
      userToReject: user,
      rejectionModalVisible: true,
    });
  };

  hideRejectionModal = () => {
    this.setState({
      userToReject: undefined,
      rejectionModalVisible: false
    });
  }

  rejectUser = () => {
    const { updateUserStatus } = this.props;

    const updatedModel = {
      username: this.state.userToReject.username,
      status: 'rejected',
      rejectionReason: this.state.rejectionReason,
      user: this.state.userToReject
    };

    updateUserStatus(updatedModel).then(() => {
      this.setState({
        rejectionModalVisible: false,
        userToReject: undefined
      });
    });

  };

  onRejectionReasonChange = (e) => {
    this.setState({
      rejectionReason: e.target.value
    });
  }

  handleAccept = (user) => {
    const { confirm } = Modal;
    const { updateUserStatus, intl } = this.props;

    const updatedModel = {
      username: user.username,
      status: 'accepted',
      user
    };

    confirm({
      title: intl.formatMessage(messages.accceptModal),
      content: `${user.username}`,
      onOk() {
        return updateUserStatus(updatedModel);
      },
      onCancel() {},
    });
  }

  handleEditProject = (id, record) => {
    const { history } = this.props;
    history.push({ pathname: `/admin/users/edit/${record.username}` });
  }

  render() {
    const { users, error, intl } = this.props;

    return (
      <Layout style={{ margin: '0 16px' }}>
        <Modal
          title={intl.formatMessage(messages.rejectModal)}
          visible={this.state.rejectionModalVisible}
          onOk={this.rejectUser}
          onCancel={this.hideRejectionModal}
          okText="Aceptar"
          cancelText="Cancelar"
        >
          <Input placeholder="Motivo del rechazo" value={this.state.rejectionReason} onChange={this.onRejectionReasonChange} />
        </Modal>
        <Row>
          <Col span={21}>
            <Breadcrumb>
              <Breadcrumb.Item>{intl.formatMessage(messages.breadcrumbStart)}</Breadcrumb.Item>
              <Breadcrumb.Item>{intl.formatMessage(messages.breadcrumbUsers)}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={this.handleAddNew} className="buttonUsers">{intl.formatMessage(messages.newUser)}</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {error ? <Alert message={users.error} type="error" className="errors" /> : null }
          </Col>
        </Row>
        <div className="content">
          <DataTable
            data={users.list}
            loading={users.isFetching}
            acceptUserAction={this.handleAccept}
            rejectUserAction={this.showRejectionModal}
            editAction={this.handleEditProject}
            intl={intl}
            messages={messages}
          />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateUserStatus: updateUser,
  showUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Users));
