import {
  SHOW_PROJECTS,
  GET_PROJECT,
  EDIT_PROJECT,
  CREATE_PROJECT,
  PUBLISH_PROJECT,
  ACTIVE_PROJECT,
  GET_PROJECTS,
  DELETE_ADVANCE,
  DELETE_REWARD,
  FILTER_PROJECT,
  FEATURED_PROJECT,
  GENERATE_MP,
  GENERATE_TF,
} from '../actions/projects';

const INITIAL_STATE = {
  list: [],
  project: {},
  isFetching: false,
  isFetchingProject: false,
  didInvalidate: false,
  isFetchingMP: false,
  isFetchingTF: false,
  messageResponse: '',
  featureProjects: [],
  filterProjects: []
};

const projects = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${SHOW_PROJECTS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${SHOW_PROJECTS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${SHOW_PROJECTS}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        list: action.payload.list
      };

    case `${GET_PROJECT}_REJECTED`:
      return {
        ...state,
        isFetchingProject: false,
        didInvalidate: true
      };
    case `${GET_PROJECT}_PENDING`:
      return {
        ...state,
        isFetchingProject: true,
        didInvalidate: false,
        project: {}
      };
    case `${GET_PROJECT}_FULFILLED`:
      return {
        ...state,
        isFetchingProject: false,
        didInvalidate: false,
        project: action.payload.project
      };

    case `${GET_PROJECTS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${GET_PROJECTS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${GET_PROJECTS}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        featureProjects: action.payload.featureProjects
      };

    case `${FILTER_PROJECT}_REJECTED`:
      return {
        ...state,
        isFetchingProject: false,
        didInvalidate: true
      };
    case `${FILTER_PROJECT}_PENDING`:
      return {
        ...state,
        isFetchingProject: true,
        didInvalidate: false
      };
    case `${FILTER_PROJECT}_FULFILLED`:
      return {
        ...state,
        isFetchingProject: false,
        didInvalidate: false,
        filterProjects: action.payload.projects
      };

    case `${FEATURED_PROJECT}_REJECTED`:
      return {
        ...state,
        isFetchingProject: false,
        didInvalidate: true
      };
    case `${FEATURED_PROJECT}_PENDING`:
      return {
        ...state,
        isFetchingProject: true,
        didInvalidate: false
      };
    case `${FEATURED_PROJECT}_FULFILLED`:
      return {
        ...state,
        isFetchingProject: false,
        didInvalidate: false,
        featureProjects: action.payload.projects
      };

    case `${EDIT_PROJECT}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${EDIT_PROJECT}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${EDIT_PROJECT}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        messageResponse: action.payload
      };
    case `${PUBLISH_PROJECT}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${PUBLISH_PROJECT}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${PUBLISH_PROJECT}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        messageResponse: action.payload
      };
    case `${ACTIVE_PROJECT}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${ACTIVE_PROJECT}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${ACTIVE_PROJECT}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        messageResponse: action.payload
      };
    case `${GENERATE_MP}_REJECTED`:
      return {
        ...state,
        isFetchingMP: false,
        didInvalidate: true
      };
    case `${GENERATE_MP}_PENDING`:
      return {
        ...state,
        isFetchingMP: true,
        didInvalidate: false
      };
    case `${GENERATE_MP}_FULFILLED`:
      return {
        ...state,
        isFetchingMP: false,
        didInvalidate: false,
        messageResponse: action.payload
      };
    case `${GENERATE_TF}_REJECTED`:
      return {
        ...state,
        isFetchingTF: false,
        didInvalidate: true
      };
    case `${GENERATE_TF}_PENDING`:
      return {
        ...state,
        isFetchingTF: true,
        didInvalidate: false
      };
    case `${GENERATE_TF}_FULFILLED`:
      return {
        ...state,
        isFetchingTF: false,
        didInvalidate: false,
        messageResponse: action.payload
      };
    case `${CREATE_PROJECT}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true
      };
    case `${CREATE_PROJECT}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case `${CREATE_PROJECT}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false
      };
    case DELETE_ADVANCE:
      return {
        ...state,
        project: {
          ...state.project,
          advances: [
            ...state.project.advances.slice(0, action.payload),
            ...state.project.advances.slice(action.payload + 1)
          ]
        }
      };

    case DELETE_REWARD:
      return {
        ...state,
        project: {
          ...state.project,
          campaigns: [
            {
              ...state.project.campaigns[0],
              rewards: [
                ...state.project.campaigns[0].rewards.slice(0, action.payload),
                ...state.project.campaigns[0].rewards.slice(action.payload + 1)
              ]
            }
          ]
        }
      };
    default:
      return state;
  }
};

export default projects;
