import axios from 'axios';

export const SHOW_PROJECTS = 'SHOW_PROJECTS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECTS = 'GET_PROJECTS';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const PUBLISH_PROJECT = 'PUBLISH_PROJECT';
export const ACTIVE_PROJECT = 'ACTIVE_PROJECT';
export const DELETE_ADVANCE = 'DELETE_ADVANCE';
export const DELETE_REWARD = 'DELETE_REWARD';
export const FILTER_PROJECT = 'FILTER_PROJECT';
export const FEATURED_PROJECT = 'FEATURED_PROJECT';
export const GENERATE_MP = 'GENERATE_MP';
export const GENERATE_TF = 'GENERATE_TF';

export const showProjects = () => ({
  type: SHOW_PROJECTS,
  payload: axios
    .get('/projects/')
    .then(response => ({ list: response.data }))
    .catch(error => Promise.reject(error))
});

export const getProjectById = id => ({
  type: GET_PROJECT,
  payload: axios
    .get(`/projects/${id}`)
    .then(response => ({ project: response.data }))
    .catch(error => Promise.reject(error))
});

export const projectList = (country) => ({
  type: GET_PROJECTS,
  payload: axios
    .get(`/projects/actives/${country}`)
    .then(response => ({ featureProjects: response.data }))
    .catch(error => Promise.reject(error))
});

export const filterProjects = (query, country ) => ({
  type: FILTER_PROJECT,
  payload: axios
    .get(`/projects/find/${country}/?${query}`)
    .then(response => ({ projects: response.data }))
    .catch(error => Promise.reject(error))
});


export const featuredProjects = (country) => ({
  type: FEATURED_PROJECT,
  payload: axios
    .get(`/projects/featured/${country}`)
    .then(response => ({ projects: response.data }))
    .catch(error => Promise.reject(error))
});

export const editProject = (model, id) => ({
  type: EDIT_PROJECT,
  payload: axios
    .put('/projects/', { ...model, id })
    .then(response => ({ messageResponse: response.data }))
    .catch(error => Promise.reject(error))
});

export const publishProject = id => ({
  type: EDIT_PROJECT,
  payload: axios
    .put(`/projects/publish/${id}`)
    .then(response => ({ messageResponse: response.data }))
    .catch(error => Promise.reject(error))
});

export const activeProject = id => ({
  type: ACTIVE_PROJECT,
  payload: axios
    .put(`/projects/active/${id}/`)
    .then(response => ({ messageResponse: response.data }))
    .catch(error => Promise.reject(error))
});

export const createProject = model => ({
  type: CREATE_PROJECT,
  payload: axios
    .post('/projects/create', model)
    .then(response => ({ messageResponse: response }))
    .catch(error => Promise.reject(error))
});

export const generateMercadoPago = model => ({
  type: GENERATE_MP,
  payload: axios
    .post('/invest/mercadopago', model)
    .then(response => ({ response }))
    .catch(error => Promise.reject(error))
});

export const generateTransfer = model => ({
  type: GENERATE_TF,
  payload: axios
    .post('/invest/transferencia', model)
    .then(response => ({ response }))
    .catch(error => Promise.reject(error))
});


export const deleteAdvance = advanceIndex => ({
  type: DELETE_ADVANCE,
  payload: advanceIndex
});

export const deleteReward = rewardIndex => ({
  type: DELETE_REWARD,
  payload: rewardIndex
});
