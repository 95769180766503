import React from 'react';
import styled from 'styled-components';

import MyDataUserContainer from './myData/myDataContainer';
import MyDataEditContainer from './myDataEdit/myDataEditContainer';

import Projects from './myInvestments';

const ContainerCompleteData = styled.div`
  margin: 10px;
  // display: flex;
  flex: 1;
  margin-bottom: 20px;
`;

const dataUser = ({
  userInfo,
  intl,
  messages,
  edit,
  handleChange,
  projects
}) => (
  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
    <ContainerCompleteData>
      {!edit
        ? (
          <MyDataUserContainer
            intl={intl}
            messages={messages}
            userInfo={userInfo}
            handleChange={handleChange}
          />
        ) : (
          <MyDataEditContainer
            intl={intl}
            messages={messages}
            userInfo={userInfo}
            handleChange={handleChange}
          />
        )}
    </ContainerCompleteData>
    <Projects
      intl={intl}
      messages={messages}
      projects={projects}
    />
  </div>
);

export default dataUser;
