import React, { Component } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import {
  Layout
} from 'antd';


const { Content } = Layout;

const Container = styled.div`
  & {
    margin-top: 10%;
    padding: 0 5%;
  }

  & h1{
    font-size: 2em;
  }

  @media (max-width: 768px) {
    & {
      margin-top: 35%;
    }
  }
`;

class AboutUs extends Component {

  componentDidMount() {
    window.scroll({ top: 0, behavior: 'auto' });
  }

  render() {
    return (
      <Content className="home">
        <Container>
        <h1>TÉRMINOS Y CONDICIONES DE USO</h1>
        
        Los siguientes términos y condiciones (en adelante los "Términos y Condiciones") serán aplicables al uso del sitio web “wibai.com.ar” (en adelante el "Sitio") y a los servicios (en adelante y junto con el Sitio denominados los “Servicios”) ofrecidos por WIBAI S.A. (en adelante “Wibai”) a través del Sitio.
        <br/><br/>  
            Wibai opera el Sitio, el cual consiste en una plataforma de crowdfunding o financiamiento colectivo mediante la cual los usuarios (“Promotores”) podrán publican proyectos (“Proyectos”) para ser patrocinados directamente por otros usuarios (“Patrocinantes”).
        <br/><br/>
            1. ¿Por qué leer detenidamente estos términos y condiciones de uso?

            Si desea utilizar los Servicios deberá leer previamente los Términos y Condiciones, así como cualquier otro documento al que se haga referencia. La utilización de los Servicios significará su aprobación y aceptación sin reservas de los presentes Términos y Condiciones. En caso que no acepte los Términos y Condiciones deberá abandonar inmediatamente el Sitio y abstenerse de utilizar los Servicios.

            Wibai se reserva el derecho, a su sola discreción, de modificar los Términos y Condiciones y/o toda otra documentación complementaria. Dichas modificaciones y su entrada en vigencia le serán informadas mediante su publicación en el Sitio. Si continúa utilizando los Servicios, implicará que acepta los nuevos Términos y Condiciones. Si manifiesta su rechazo a las modificaciones, quedará automáticamente inhabilitado su ingreso a los Servicios a partir de la notificación del rechazo y deberá abstenerse de ingresar al Sitio.

            Estos Términos y Condiciones prevalecen sobre la información general y las respuestas a preguntas frecuentes publicadas en el Sitio.

            <br/><br/>
            2. Creación de una Cuenta para utilizar los Servicios.

            Podrá navegar por el Sitio sin necesidad de registrarse. Sin embargo, para utilizar algunas de las funciones del Sitio, deberá registrarse y elegir un nombre de usuario y contraseña. Deberá brindar información precisa y verdadera y deberá actualizar la información conforme la misma sea modificada. No podrá suplantar la identidad de otra persona, ni elegir nombres ofensivos o que infrinjan derechos de terceros.

            Para registrarse y crear una cuenta en el Sitio deberá ser mayor de 18 años y tener capacidad legal para contratar. Si desea registrarse como empresa, deberá tener capacidad legal para contratar a nombre de la misma y tener facultad para obligarla bajo estos Términos y Condiciones.

            Es importante que mantenga la confidencialidad de la contraseña ya que será responsable por todas las operaciones efectuadas en el Sitio a través de su cuenta. Si descubre que su cuenta fue utilizada por otra persona y sin su permiso deberá notificarlo en forma inmediata mediante los medios de contacto informados en el Sitio.

            Wibai se reserva el derecho de rechazar cualquier solicitud de registro, así como también podrá proceder a la cancelación de su solicitud previamente aceptada y cerrar su cuenta, sin tener que brindar los motivos de dicha decisión y sin que ello genere un derecho a resarcimiento alguno.

            <br/><br/>
            3. Prohibiciones.

            Wibai pone a disposición de sus usuarios una plataforma en la cual podrán interactuar de manera cordial y respetuosa. Le pedimos que contribuya a respetar las siguientes normas obligatorias:
            <br/>
            <br/>a) No incumpla la ley. No utilice los Servicios con fines ilícitos, debiendo evitar realizar cualquier acto que pueda vulnerar los derechos de terceros.
            <br/>b) No use indebidamente los datos personales de terceros. No realice ninguna operación y/o distribución de contenido que signifique una violación de la legislación vigente en materia de datos personales.
            <br/>c) No brinde información falsa. Absténgase de llevar a cabo cualquier tipo de acto que pudiere resultar engañoso o fraudulento.
            <br/>d) No acose. Absténgase de difundir contenido de carácter racista, xenófobo, pornográfico y/o que atente contra los derechos humanos, el honor, la intimidad y/o la imagen de terceras personas.
            <br/>e) No venda productos y/o servicios ilegales. Está prohibido ofrecer productos y/o servicios considerados ilegales o que contradigan la ley o estos Términos y Condiciones.
            <br/>f) No dañe los sistemas informáticos ajenos. Utilice el Sitio correctamente evitando cualquier tipo de acción que pudiere dañar los sistemas, equipos y/o software del Sitio y/o sitios a los cuales se pudiere acceder desde éste. Absténgase de utilizar mecanismos y/o software alguno que impida o pudiere impedir el adecuado funcionamiento del Sitio y/o que genere una congestión desproporcionada o desmesurada sobre los procesos del Sitio. No intente descifrar u obtener el código de cualquier software utilizado en el Sitio y/o para la prestación de los Servicios.
            <br/>g) No envíe spam.
            <br/>h) No ceda los derechos u obligaciones bajo estos Términos y Condiciones. 

            <br/><br/>
            4. Cómo Funcionan los Proyectos.

            Los Promotores publican los Proyectos en el Sitio a fin de captar la colaboración directa de los Patrocinadores. Cuando un Patrocinador colabora con un Proyecto está contratando directamente con el Promotor. En ningún caso, podrá entenderse que Wibai tiene participación en la contratación realizada entre el Promotor y el Patrocinador ni que es parte en la misma. Wibai se limita a poner a disposición el Sitio y no tiene ninguna participación en la oferta, desarrollo y/o comercialización de los Proyectos.

            Los Promotores publican sus Proyectos e indican el monto de financiación necesario (“Recaudación”) y el plazo dentro del cual deberán alcanzar la Recaudación (“Intervalo”). Los Proyectos podrán incluir recompensas a cargo de los Promotores (“Recompensas”), las cuales podrán ser dispuestas a favor de los Patrocinadores y/o de terceros.

            Una vez que un Proyecto logra alcanzar el monto total de la Recaudación y dentro del plazo del Intervalo, el Promotor deberá entregar las Recompensas, en caso que las hubiere, dentro del plazo y conforme a las condiciones detalladas al publicar el Proyecto. Si el Proyecto no logra alcanzar el monto total de la Recaudación dentro del plazo del Intervalo, los Patrocinadores recibirán un reembolso de las sumas aportadas al Proyecto, sin ningún tipo de compensación. Los Patrocinadores no podrán retractar y/o cancelar su colaboración a los Proyectos.

            Wibai no es responsable por el correcto cumplimiento de los Proyectos y/o la entrega de las Recompensas por parte del Promotor. En caso que los Promotores, por distintas causas, no logren cumplir con las condiciones de sus Proyectos y/o entrega de las Recompensas, deberán publicar y/o notificar a cada Patrocinador las razones del incumplimiento y brindar un detalle de los gastos y empleo de los fondos del Proyecto.

            Los Promotores podrán, en cualquier momento – incluso luego de alcanzado el monto de Recaudación – cancelar el Proyecto, con o sin expresión de causa. En dicho caso, deberán los Promotores reintegrar la totalidad de las colaboraciones obtenidas por parte de los Patrocinadores, sin compensación y/o interés alguno.

            Cualquier acción por incumplimiento de las obligaciones de los Promotores, deberá dirigirse directamente al Promotor, comprometiéndose los Promotores y los Patrocinadores a defender y mantener indemne a Wibai, sus directores y empleados por cualquier responsabilidad, reclamo, pérdida y gasto que pudiere surgir, directa o indirectamente, como resultado y/o con relación a, el incumplimiento de las obligaciones de los Promotores.

            <br/><br/>
            5. Responsabilidad por las Recompensas Entregadas.

            Wibai no es responsable por los bienes y/o servicios entregados como Recompensa y, en ningún caso, será responsable de ningún daño ocasionado por los mismos, ni otorgará reembolso alguno a los Patrocinadores y/o terceros. Wibai no otorga garantía alguna sobre las Recompensas, su calidad, características, plazos de entrega ni ningún otro aspecto.

            <br/><br/>
            6. Comisión a cargo del Promotor.

            Para publicar un Proyecto a través del Sitio los Promotores deberán abonar un precio por la utilización de los Servicios, el cual será acordado en cada caso y en forma previa a la publicación. (QUE PASA SI HAY DEVOLUCIONES)

            <br/><br/>
            7. Responsabilidad de los Promotores.

            Los Promotores reconocen y aceptan que la utilización de los Servicios será bajo su exclusivo riesgo y/o responsabilidad. Asimismo, los Promotores asumen plena responsabilidad frente a Wibai y frente a terceros por los daños y perjuicios que se produjeran como consecuencia del accionar propio, de sus dependientes y/o de terceros y los que resulten de la inobservancia de las leyes, de las presentes condiciones y/o del mal uso que se hagan del Servicio, debiendo indemnizar y mantener indemne a Wibai ante cualquier reclamo que pudiera interponerse por hechos provocados por los Promotores y/o por la publicación de los Proyectos.

            <br/><br/>
            8. Propiedad Intelectual.

            El Sitio, las marcas comerciales, textos, imágenes, sonido y todos aquellos programas, bases de datos y archivos que permitan acceder y utilizar los Servicios son de propiedad exclusiva de Wibai o le han sido otorgados bajo licencia exclusiva a Wibai, estando protegidos por las leyes y los tratados internacionales de derecho de autor y propiedad intelectual. Su utilización, modificación, transformación, distribución y/o reproducción total o parcial se encuentra prohibida, salvo que exista autorización expresa y por escrito de Wibai. Wibai no concede ningún tipo de licencia o autorización de uso sobre ningún derecho de propiedad industrial o sobre cualquier otro contenido inserto en el Sitio.

            Por su parte, el contenido publicado en el Sitio por los Promotores es de propiedad de los mismos. No obstante, al subir contenido al Sitio, el Promotor concede a Wibai un derecho irrevocable y gratuito para utilizarlo con fines publicitarios y/o de promoción.

            <br/><br/>
            9. Exclusión de Responsabilidad.

            La utilización de los Servicios corre exclusivamente por su cuenta y riesgo. Los Servicios se prestan "tal cual", "según disponibilidad" y sin garantías de ningún tipo, ni expresas ni implícitas. Wibai no será responsable por ningún daño, pérdida y/o gasto directo o indirecto que surja en relación con el Sitio y/o su imposibilidad de uso, y/o en relación con cualquier falla en el rendimiento, error, omisión, interrupción, defecto, cortes de energía, interrupciones de cualquier índole, demoras en la operación y/o transmisión, virus de computadora y/o cualquier otra falla que no sea atribuible a Wibai.

            Wibai no responde por eventuales daños o pérdidas derivados del uso de los Servicios. Wibai no supervisa ninguna etapa de la publicación y desarrollo de los Proyectos ni tiene vinculación alguna con los Proyectos ofrecidos ni con las colaboraciones a cargo de los Patrocinadores. Por lo tanto, al utilizar los Servicios, Ud. libera a Wibai de responsabilidad frente a todo reclamo que guarde relación con eventuales controversias por los Servicios. El acceso a contenido a través de los Servicios, corre por su cuenta y riesgo. Será el único responsable de los daños o pérdidas irrogados como consecuencia de dicho acceso.

            Asimismo, los Promotores se obligan a mantener indemne a Wibai, sus accionistas y empleados, de cualquier reclamo, sanción y/o gasto derivado de su utilización de los Servicios y/o incumplimientos de los presentes términos y condiciones de uso.

            A modo enunciativo, no significando una limitación taxativa, Ud. acepta que Wibai no será responsable por:
            <br/>
            <br/>a) la veracidad y exactitud de la información publicada en el Sitio por los usuarios y/o la información distribuida a través del Servicio;
            <br/>b) los inconvenientes en la prestación del Servicio que se encuentren relacionados con fallas en el funcionamiento de sistemas de terceros;
            <br/>c) los daños ocasionados en sus equipos;
            <br/>d) violaciones por parte de los usuarios y/o terceros a disposiciones legales, morales y de orden público en la utilización del Servicio, así como por infracciones a los derechos de propiedad intelectual e industrial, derechos a la intimidad, a la privacidad y a la imagen de las personas;
            <br/>e) la pérdida de la información brindada una vez cancelada su registración;
            <br/>f) los daños y perjuicios ocasionados por culpa de otros usuarios y/o terceros y/o los derivados de la violación por parte de cualquier persona de los Términos y Condiciones Generales, las leyes y/o reglamentaciones vigentes.
            
            <br/><br/>
            10. Limitación de Responsabilidad.

            Wibai, sus administradores, empleados y socios no responderán en ningún caso por daños indirectos, fortuitos, punitivos, consecuentes, especiales o ejemplares de ningún tipo. La responsabilidad de Wibai con relación a un Proyecto determinado no superará en ningún caso la Comisión cobrada al Promotor por dicho Proyecto.
            
            <br/><br/>
            11. Vinculación con sitios de terceros.
            
            El Sitio se encuentra enlazado a distintos sitios web de terceros mediante la utilización de links y mediante la distribución de la información brindada por el usuario que posibilita su publicación en sitios web pertenecientes a terceros, como ser buscadores y redes sociales. Wibai no se responsabiliza por el contenido de los sitios web donde se publica la información brindada por los usuarios ni garantiza el correcto funcionamiento de los mismos.

            <br/><br/>
            11. Cancelaciones y suspensiones.

            En caso de violaciones a cualquier disposición contenida en los Términos y Condiciones, Wibai se reserva el derecho de suprimir los Proyectos y/o las publicaciones e inclusive proceder a la inmediata cancelación de su registro, imposibilitándole el acceso al Sitio y/o a la utilización de los Servicios, sin que ello signifique derecho a obtener cualquier tipo de indemnización e implicando la pérdida y/o destrucción automática de la totalidad de la información brindada al momento del registro y/o utilización del Servicio. 

            Asimismo, y teniendo en cuenta la gravedad del incumplimiento, a su sola discreción, Wibai podrá advertirlo o suspenderlo en forma temporal.

            <br/><br/>
            12. Indemnidad.

            Deberá indemnizar y mantener indemne a Wibai y a sus representantes y empleados, por cualquier reclamo o demanda de otros usuarios o por parte de terceros relacionado con sus actividades en el Sitio, el cumplimiento y/o el incumplimiento de los Términos y Condiciones, así como respecto de cualquier violación de leyes o derechos de terceros.

            <br/><br/>
            13. Jurisdicción y ley aplicable.

            El acuerdo derivado de los presentes Términos y Condiciones estará regido por las leyes vigentes en la República Argentina. Cualquier cuestión derivada del incumplimiento o interpretación de las obligaciones emergentes de dicho acuerdo será sometida a la jurisdicción de la Justicia Nacional Ordinaria de la Ciudad de Buenos Aires.

            <br/><br/>
            14. Domicilio.

            Toda notificación y/o comunicación que deba efectuarse bajo estos Términos de Condiciones deberá realizarse por escrito: (i) al usuario: a la cuenta de correo electrónico por él denunciada al momento de su registración o posterior modificación; (ii) a Wibai: a la cuenta de correo electrónico <a href="mailto:info@wibai.com.ar">info@wibai.com.ar</a>.

            La versión actualizada de estos términos entrará en vigor el __ de junio de 2020, a las 12 a.m., hora de la República Argentina y resultará de aplicación a partir de esa fecha.
            <br/><br/><br/><br/>
         </Container>


      </Content>
    );
  }
}

export default injectIntl(AboutUs);
