import { defineMessages } from 'react-intl';

const messages = defineMessages({
  investDrawerYourInvest: { id: 'project.investDrawer.title.yourInvest' },
  investDrawerYourInvestText: { id: 'project.investDrawer.text.yourInvest' },
  investDrawerYourCode: { id: 'project.investDrawer.title.yourCode' },
  investDrawerYourCodeText: { id: 'project.investDrawer.text.yourCode' },
  investDrawerNext: { id: 'project.investDrawer.button.next' },
  valueReward: { id: 'project.investDrawer.valueReward' },
  rewards: { id: 'project.investDrawer.select.rewards' },
  inputSelect: { id: 'project.investDrawer.select.input' },
  investDrawerDownloadPDF: { id: 'project.investDrawer.button.downloadPDF' },
  investDrawerAccepted: { id: 'project.investDrawer.button.accepted' },
  investDrawerUser_not_exists: { id: 'project.investDrawer.modal.title.user_not_exists' },
  investDrawerAmount_lower: { id: 'project.investDrawer.modal.title.amount_lower' },
  investDrawerCurrency_not_supported: { id: 'project.investDrawer.modal.title.currency_not_supported' },
  investDrawerNew: { id: 'project.investDrawer.modal.new' },
  investDrawerModerate: { id: 'project.investDrawer.modal.moderate' },
  investDrawerModalModerate: { id: 'project.investDrawer.modal.title.moderate' },
  investDrawerModalNew: { id: 'project.investDrawer.modal.title.new' },
  investDrawerModalRejected: { id: 'project.investDrawer.modal.title.rejected' },
  investDrawerMessageErrorNumber: { id: 'project.investDrawer.messageError.number' },
  investDrawerMessageErrorOrigin: { id: 'project.investDrawer.messageError.origin' },
  investDrawerModalNewOne: { id: 'project.investDrawer.modal.newOne' },
  investDrawerModalNewLink: { id: 'project.investDrawer.modal.newLink' },
  investDrawerModalNewTwo: { id: 'project.investDrawer.modal.newTwo' },
  investDrawerModalRejectedOne: { id: 'project.investDrawer.modal.rejectedOne' },
  investDrawerModalRejectedLink: { id: 'project.investDrawer.modal.rejectedLink' },
  investDrawerModalRejectedTwo: { id: 'project.investDrawer.modal.rejectedTwo' },
  investDrawerStepOneOrigin: { id: 'project.investDrawer.stepOne.origin' },
  investDrawerStepOneCurrency: { id: 'project.investDrawer.stepOne.currency' },
  investDrawerStepOneNationalAccountData: { id: 'project.investDrawer.stepTwo.nationalAccountData' },
  investDrawerStepOneHeadline: { id: 'project.investDrawer.stepTwo.headline' },
  investDrawerStepOneCuit: { id: 'project.investDrawer.stepTwo.cuit' },
  investDrawerStepOneCbuOrAlias: { id: 'project.investDrawer.stepTwo.cbuOrAlias' },
  investDrawerStepOneAccountNumber: { id: 'project.investDrawer.stepTwo.accountNumber' },
  investDrawerPdfDate: { id: 'project.investDrawer.pdf.date' },
  investDrawerPdfHeadline: { id: 'project.investDrawer.pdf.headline' },
  investDrawerPdfCuit: { id: 'project.investDrawer.pdf.cuit' },
  investDrawerPdfCbuOrAlias: { id: 'project.investDrawer.pdf.cbuOrAlias' },
  investDrawerPdfAccountNumber: { id: 'project.investDrawer.pdf.accountNumber' },
  investDrawerPdfCurrency: { id: 'project.investDrawer.pdf.currency' },
  investDrawerPdfCode: { id: 'project.investDrawer.pdf.code' },
  requiredField: { id: 'general.validations.requiredField' },
});

export default messages;
